export default {
  control: {
    // backgroundColor: '#fff',
    // height: '200px',
    width: '400px',
    border: 'none',
    // minHeight: '16vh',

    fontSize: 12,
    fontWeight: 'normal',
  },

  // highlighter: {
  //   overflow: 'hidden',
  // },

  // input: {
  //   margin: 0,
  // },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
      backgroundColor: 'blue',
    },

    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 60,
      border: 'none',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      // minHeight: 63,
      // outline: 0,
      // border: 0,
      // maxHeight: 100,
      overflow: 'auto',
      // position: 'absolute',
      // bottom: 14,
    },
  },

  suggestions: {
    list: {
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,0.15)',
      color: 'black',
      fontSize: 10,
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#f1f5f6',
      },
    },
  },
};
