import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import swal from 'sweetalert';
import {
  customBaseUrl,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPostFormData,
  httpPut,
} from '../action/http';
import {
  followOrUnfollowPage,
  handleBlockUser,
  handleBookmark,
  handleFollow,
  handleRepost,
  handleToggleMute,
  handleUnFollow,
  joinGroup,
  leaveAndDeleteGroup,
} from '../components/pages/Wayagram/container';
import {
  searchGroups,
  searchPages,
  searchPosts,
  searchTrendingTags,
  searchWayagramPeople,
} from '../store/calls';
import validateImage from './validateImage';

const token = localStorage.getItem('token');

export const forceUpdate = (todoUpdates) => todoUpdates((n) => n + 1);

export const isEmpty = (value) => {
  /* eslint-disable no-unused-expressions */
  value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
};

export const capitalize = (item) => _.capitalize(item);

export const capFirstWord = (str) => _.startCase(_.toLower(str));

export const checkAcess = (value) => {
  const regexFormat = /^[[0-9+-]+$/;
  return regexFormat.test(value);
};

export const findHashTag = (string) => {
  const str = string;
  // const n = str?.search('#'); // find character, n = 6 in this case
  // str = str?.slice(0, n) + str?.slice(n + 1); // remove char at index n
  return str.replace('#', '');
};

export const sort = (data) => {
  return [...data].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
};

export const handlePreview = (e, setImage, setPreview) => {
  const imageData = e.target.files[0];
  const validFormat = validateImage(imageData);
  if (validFormat.valid) {
    setImage(imageData);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(imageData);
  } else {
    swal('Oops!', `${validFormat.message}`, 'error');
  }
};

const convertMinutes = (num, type) => {
  if (type === 'hr') {
    return Math.floor(num * 60);
  }
  if (type === 'days') {
    return Math.floor(num * 1440); // 60*24
  }
  return 0;
};

export const convertTimeToMs = (days, hours, min) => {
  const date = new Date(); // Todays date - the Date() constructor will default to the current date/time if no value is passed to it

  const addDays = convertMinutes(Number(days), 'days');
  const addHours = convertMinutes(Number(hours), 'hr');
  const addMinutes = Number(min);

  const totalMin = addDays + addHours + addMinutes;

  // Add minutes
  date.setTime(date.getTime() + totalMin * 60 * 1000); // Convert minutes to milliseconds
  return date;
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // message.error('You can only upload JPG/PNG file!');
    swal('Oops!', `You can only upload JPG/PNG file!`, 'error');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // message.error('Image must smaller than 2MB!');
    swal('Oops!', `Image must smaller than 2MB!`, 'error');
  }
  return isJpgOrPng && isLt2M;
}

export const handleSearchFriends = async (val, activeTab, userId, page) => {
  let resData;
  // const userId = loggedInUserId || id;
  if (activeTab === 'Posts') {
    resData = await searchPosts(val);
  }
  if (activeTab === 'People') {
    resData = await searchWayagramPeople(val, userId);
  }
  if (activeTab === 'Groups') {
    resData = await searchGroups(val, page, userId);
  }
  if (activeTab === 'Pages') {
    resData = await searchPages(val, page, userId);
  }
  if (activeTab === 'Trending') {
    const removeHash = findHashTag(val);
    resData = await searchTrendingTags(removeHash, 'ASC');
  }
  return resData;
};

export const handleWayaSubmit = async (
  type,
  username,
  objectId,
  loggedInUser,
  active,
  searchValue,
  handleSearch,
  setList
) => {
  const { id, UserId: userId } = loggedInUser;
  let res;
  if (active === 'People') {
    if (type === 'Follow' || type === 'Send Request') {
      res = await handleFollow(userId, username);
    } else if (type === 'Unfollow') {
      res = await handleUnFollow(userId, username);
    }
  }
  if (active === 'Pages') {
    if (type === 'Follow' || type === 'UnFollow') {
      res = await followOrUnfollowPage(id, objectId);
    }
  }

  if (active === 'Groups') {
    if (type === 'Join') {
      res = await joinGroup(id, objectId);
    } else if (type === 'Leave') {
      res = await leaveAndDeleteGroup(id, objectId);
    }
  }

  if (res.status) {
    swal('Done', res.message, 'success').then(async () => {
      if (active === 'People') {
        const resData = await searchWayagramPeople(searchValue, id);
        setList(resData);
      } else {
        await handleSearch(searchValue, active, id);
      }
    });
  } else {
    swal('Oops!', res.message, 'error');
  }
};

export const apiAlertResponse = (res, callback) => {
  if (res?.status) {
    NotificationManager.success(res.message, 'Success', 3000);
    callback();
  } else {
    NotificationManager.error(res?.message, 'Oops!', 3000);
  }
};

export const apiModalResponse = (res, callback, share) => {
  if (res?.status) {
    callback({
      status: true,
      type: 'success',
      actionName: 'Go back',
      action: () => callback((data) => ({ ...data, status: false })),
      message: res?.message,
      retry: () => share(true),
    });
    // apiAlertResponse(res, setReload);
  } else {
    callback({
      status: true,
      type: 'failed',
      actionName: 'Go back',
      action: () => callback((data) => ({ ...data, status: false })),
      message: res?.message,
      retry: () => callback((data) => ({ ...data, status: false })),
    });
  }
};

export const addBookmark = async (postData, profileId, setReload) => {
  // const postData = { postId };
  const url = `/all-posts/create-bookmark`;
  const res = await handleBookmark(url, { ...postData, profileId });
  apiAlertResponse(res, setReload);
};

// export const addEventBookmark = async (eventId, profileId, setReload) => {
//   const postData = { eventId };
//   const url = `/all-posts/create-bookmark-event?profileId=${profileId}`;
//   const res = await handleBookmark(url, postData);
//   apiAlertResponse(res, setReload);
// };

export const deletePost = async (postId) => {
  const data = { post_id: postId };
  const res = await httpDelete(
    `/all-posts/trash`,
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};
export const deleteContestPost = async (postData) => {
  const data = {
    contentId: postData?.contentId,
    contestantId: postData?.contestantId,
  };
  const res = await httpDelete(
    `/contests/delete-content`,
    data,
    customBaseUrl.contestUrl
  );
  return res;
};

export const deleteActivity = async (activityId, uid) => {
  const data = { activity_id: activityId };
  const res = await httpDelete(
    `/main/delete-activity-log?profile_id=${uid}`,
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const blockUser = async (username, authId, setReload, url) => {
  const res = await handleBlockUser(authId, username, url);
  apiAlertResponse(res, setReload);
};

export const handleFollowUser = async (username, authId, setReload) => {
  // const authId = wayagramProfile.UserId;
  const res = await handleFollow(authId, username);
  apiAlertResponse(res, setReload);
};

export const repostPost = async (
  originalPostId,
  profileId,
  comment,
  setReload,
  groupId
) => {
  const res = await handleRepost(originalPostId, profileId, comment, groupId);
  apiAlertResponse(res, setReload);
  return res;
};

export const repostContest = async ({ contestId, profileId }, setReload) => {
  const data = {
    contestId,
    profileId,
  };
  const url = `/contests/repost`;
  const res = await httpPost(url, data, customBaseUrl.contestUrl);
  apiAlertResponse(res, setReload);
};

export const handleMuteUser = async (username, authId, setReload) => {
  const res = await handleToggleMute(authId, username);
  apiAlertResponse(res, setReload);
};

export const handleUserVote = async (data) => {
  const res = await httpPost(
    '/all-posts/init-payment',
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleUserPayment = async (data) => {
  const res = await httpPost(
    '/all-posts/init-payment',
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleCreateNewOrder = async (data) => {
  const res = await httpPost(
    '/all-posts/order/create',
    data,
    customBaseUrl.wayagramUrl,
    false,
    null,
    true
  );
  return res;
};

export const handleGetOrdersByBuyerId = async (buyerId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/order/seller_or_buyer_id?profileId=${buyerId}`
  );
  return res;
};

export const handleUpdateDeliveryFeeAndDuration = async (data) => {
  const res = await httpPatch(
    '/all-posts/order/update_delivery_details',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );

  return res;
};

export const handleUpdateDeliveryOption = async (data) => {
  const res = await httpPut(
    '/all-posts/order/update',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );

  return res;
};

export const handleProductReview = async (data) => {
  const res = await httpPost(
    'all-posts/add-product-review',
    data,
    customBaseUrl.wayagramUrl,
    false,
    false,
    true
  );
  return res;
};

export const handleAcceptDeliveryFeeAndDuration = async (data) => {
  const res = await httpPatch(
    '/all-posts/order/confirm_delivery_details_update',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );

  return res;
};

export const handleRejectDeliveryFeeAndDuration = async (data) => {
  const res = await httpPatch(
    '/all-posts/order/reject_delivery_details_update',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );

  return res;
};

export const handleEventPayment = async (data) => {
  const res = await httpPost(
    '/all-posts/init-payment',
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handlePromotionPayment = async (data) => {
  const res = await httpPost(
    '/all-posts/init-payment',
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleLikePost = async (
  { postId, isLiked, profileId, type },
  setReload
) => {
  const data = {
    post_id: postId,
    profile_id: profileId,
    type,
  };
  const url = `/all-posts/${isLiked ? 'like' : 'unlike'}-post`;
  const res = await httpPost(url, data, customBaseUrl.wayagramUrl);
  apiAlertResponse(res, setReload);
  return res;
};
export const handleLikeContest = async (
  { contestId, profileId },
  setReload
) => {
  const data = {
    contestId,
    profileId,
  };
  const url = `/contests/like`;
  const res = await httpPost(url, data, customBaseUrl.contestUrl);
  apiAlertResponse(res, setReload);
};

export const handleLikeComment = async (
  { postId, isLiked, profileId, type },
  setReload
) => {
  const data = {
    comment_id: postId,
    profile_id: profileId,
    type,
  };
  const url = `/all-posts/${isLiked ? 'unlike' : 'like'}-comment`;
  const res = await httpPost(url, data, customBaseUrl.wayagramUrl);
  apiAlertResponse(res, setReload);
};

export const handleShowInterest = async (evnentId, going, id, setReload) => {
  const data = { event_id: evnentId, going: going ? 'no' : 'yes' };
  const res = await httpPost(
    `/all-posts/show-interest-in-event?profile_id=${id}`,
    data,
    customBaseUrl.wayagramUrl
  );
  apiAlertResponse(res, setReload);
};

export const handleAttend = async (evnentId, going, id, setReload) => {
  const data = {
    event_id: evnentId,
    going: going ? 'no' : 'yes',
    profile_id: id,
  };
  const res = await httpPatch(
    `/all-posts/attend-event`,
    data,
    customBaseUrl.wayagramUrl
  );
  apiAlertResponse(res, setReload);
};

export const handleDeleteMedia = async (data) => {
  const res = await httpDelete(
    `/delete-file-upload`,
    data,
    customBaseUrl.fileResourseUrl,
    false
  );
  return res;
};

export const handleSendOtp = async (value, type, action) => {
  const data = type === 'email' ? { email: value } : { phone: value };
  const res = await httpPost(
    `/role-access/send-email-otp?action=${action}`,
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleSendOpenOtp = async (value, type, action) => {
  const data = { [type]: value };
  const res = await httpPost(
    `/role-access/send-${type}-otp?action=${action}`,
    data,
    customBaseUrl.wayagramUrl,
    true
  );
  return res;
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(value);

export const copyIdToClipboard = (link, title) => {
  navigator.clipboard.writeText(link);
  NotificationManager.success(title, 'Copied!', 3000);
};

export const checkIfParticipant = (participants, profileId) => {
  const participantIds = new Set(
    participants?.map((participant) => participant?.ProfileId)
  );
  return participantIds?.has(profileId);
};
// export const download = (img) => {
//   const element = document.createElement('a');
//   const file = new Blob([img], { type: 'image/*' });
//   element.href = URL.createObjectURL(file);
//   console.log(file, img);
//   element.download = 'image.jpg';
//   element.click();
// };

export const postProductToCart = async (data) => {
  const res = await httpPost(
    `/all-posts/add-cart`,
    data,
    customBaseUrl.wayagramUrl,
    false,
    null,
    true
  );
  return res;
};

export const getCarts = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/carts?profileId=${profileId}`
  );
  return res;
};

export const fetchProducts = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-all-products`
  );
  return res;
};

export const fetchProductByUser = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-products?profileId=${profileId}`
  );
  return res;
};

export const deleteCartItem = async (profileId, productId, type) => {
  const data = { profileId, productId, type };
  const res = await httpDelete(
    `/all-posts/remove-cart`,
    data,
    customBaseUrl.wayagramUrl,
    false
  );
  return res;
};

export const getFlyers = async () => {
  // const res = await httpGet(`${customBaseUrl.wayagramUrl}/all-posts/flyers`);
  const res = await axios.get(`${customBaseUrl.wayagramUrl}/all-posts/flyers`);

  return res.data;
};
export const createDonationHelper = async (data) => {
  const res = await httpPostFormData(
    '/all-posts/create-donation',
    data,
    customBaseUrl.wayagramUrl,
    false,
    false,
    true
  );

  return res;
};

export const updateDonationHelper = async (data) => {
  const res = await axios.put(
    `${customBaseUrl.wayagramUrl}/all-posts/update-donation`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`,
        client_id: 'WAYAGRAM',
        client_type: 'PERSONAL',
      },
    }
  );
  return res.data;
};

export const sendEmailForNewOrder = async (data) => {
  const res = await httpPost(
    '/all-posts/order/send_new_order_email',
    data,
    customBaseUrl.wayagramUrl,
    false,
    null,
    true
  );
  return res;
};

export const deleteOrder = async (data) => {
  const res = await httpDelete(
    `all-posts/order/delete`,
    data,
    customBaseUrl.wayagramUrl,
    false,
    null
  );
  return res;
};

export const confirmDeliveryStatus = async (data) => {
  console.log('wayagram-data', data);
  const res = await httpPatch(
    '/all-posts/order/confirm_delivery_status',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );
  return res;
};

export const confirmDeliveryWithToken = async (data) => {
  const res = await httpPatch(
    '/all-posts/order/receive_delivery_with_token',
    data,
    customBaseUrl.wayagramUrl,
    false,
    true
  );
  return res;
};
