import { create } from 'zustand';

const useStore = create((set) => ({
  // state
  allProducts: [],
  myProducts: [],
  productCart: [],
  openCart: false,
  checkOutData: [],
  categories: [],
  flyers: [],
  activeTab: 'allShops',
  openEditProductModal: { data: null },
  percentagePricing: [],
  showShareDonationModal: false,
  donationDataForShare: null,
  donationIsEnded: false,
  contactSellerOnChat: null,
  homePageCatFilter: '',
  loginNav: { to: null, from: null },
  // actions
  getAllProducts: (data) => set(() => ({ allProducts: data })),
  getMyProduct: (data) => set(() => ({ myProducts: data })),
  getProductCart: (data) => set(() => ({ productCart: data })),
  sendProductToCart: (data) =>
    set((state) => {
      return { productCart: [...state.productCart, data] };
    }),
  setOpenCart: () => set((state) => ({ openCart: !state.openCart })),
  setCheckOutData: (data) => set(() => ({ checkOutData: data })),
  removeProductFromCart: (data) =>
    set((state) => ({
      productCart: state.productCart.filter(
        (item) => item?.CartProduct?.productId !== data
      ),
    })),
  setCategories: (data) => set(() => ({ categories: data })),
  setFlyers: (data) => set(() => ({ flyers: data })),
  addNewProductToAllProduct: (data) =>
    set((state) => ({ allProducts: [data, ...state?.allProducts] })),
  addNewProductToMyProduct: (data) =>
    set((state) => ({ myProducts: [data, ...state?.myProducts] })),
  selectActiveTab: (data) => set(() => ({ activeTab: data })),
  setOpenEditProductModal: (data) =>
    set((state) => ({ ...state, openEditProductModal: data })),
  setPercentagePricing: (data) => set(() => ({ percentagePricing: data })),
  setShowShareDonationModal: (data) =>
    set(() => ({ showShareDonationModal: data })),
  setDonationDataForShare: (data) =>
    set(() => ({ donationDataForShare: data })),
  setDonationIsEnded: (data) => set(() => ({ donationIsEnded: data })),
  setContactSellerOnChat: (data) => set(() => ({ contactSellerOnChat: data })),
  setHomePageCatFilter: (data) => set(() => ({ homePageCatFilter: data })),
  setLoginNav: (data) => set((state) => ({ ...state, loginNav: data })),
}));

export default useStore;
