import React from 'react';
import PropTypes from 'prop-types';

export default function Container({ onClick, className, children }) {
  return (
    <div
      role="button"
      tabIndex={0}
      aria-hidden="true"
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: '',
};

Container.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
