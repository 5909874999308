import styled from 'styled-components';
import device from '../../../../utils/device';

const FundWrapper = styled.div`
  #fund-wallet-modal {
    .modal-container {
      display: none;
    }
  }
`;

export const FundTab = styled.div`
  @media ${device.maxmobileL} {
    font-size: 7px !important;
  }
`;

export const FundViaBankAccountOptions = styled.div`
  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 2px;
  width: 200px;
`;

export default FundWrapper;
