import React, { useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import validateImage from '../../../../../utils/validateImage';
import './eventModal.scss';
import users from '../../../../../assets/image/users.svg';
import tag from '../../../../../assets/image/tag.svg';
import location from '../../../../../assets/image/location.svg';
import contact from '../../../../../assets/image/contact.svg';
import { numberFormat } from '../../../../../utils/helper';
import { CameraIcon } from '../../../Icons';

const PublishEventModal = (props) => {
  const { handleFormInput, values, theme } = props;
  const [previeweventPoster, setPrevieweventPoster] = useState(
    values?.eventPoster
  );

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'eventPoster') {
        handleFormInput(imageData, 'eventPoster');
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'eventPoster') {
          setPrevieweventPoster(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const handleCancel = () => {
    setPrevieweventPoster('');
    handleFormInput('', 'eventPoster');
  };

  return (
    <div>
      <div className="event-icon-container">
        <div
          className="banner-div"
          style={{
            backgroundImage: `url(${previeweventPoster})`,
            backgroundSize: 'cover',
            height: '140px',
            backgroundPosition: 'center',
            border: 1,
            borderStyle: 'dotted',
            borderTopLeftRadius: 14,
            borderTopRightRadius: 14,
            margin: '0px 2px',
            width: '100%',
          }}
        >
          <i
            onClick={handleCancel}
            className="mdi mdi-close text-danger"
            style={{
              position: 'absolute',
              backgroundColor: '#fff',
              top: '5px',
              right: '5px',
              borderRadius: '50%',
              height: '1em',
              width: '1em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
          <span className="upload-banner-input">
            <CameraIcon color={theme?.modalStyle?.color} />
          </span>
          <input
            type="file"
            className="upload-banner-input2"
            onChange={(e) => handlePreview(e, 'eventPoster')}
          />
          <p>Click here to upload cover photo</p>
        </div>
      </div>
      <div className="event-preview-container my-1">
        {/* {values?.virtual === true ? (
          <div className="event-url-container mt-3">
            <div className="event-url-detail">{values?.urlLink}</div>
            <div className="event-url-copy">
              <img src={fileCopy} alt="flie-copy" />
            </div>
          </div>
        ) : (
          ''
        )} */}

        <div className="event-details-container mb-4" style={theme.modalStyle}>
          <div className="event-title mt-3 mb-1" style={theme.modalStyle}>
            {values?.eventName}
          </div>
          <div className="event-date mt-2 mb-3">
            {moment(Number(values?.eventStart)).format(
              'MMMM Do YYYY, h:mm:ss a'
            )}
          </div>

          <div className="event-group">
            <img src={users} alt="organizers name" />
            {values?.eventOrganizer}
          </div>
          <div className="event-group">
            <img src={tag} alt="payment" />
            {values?.isPaid === 'true'
              ? `Pay to enter: ${numberFormat(values?.amount)}`
              : 'Free to enter'}
          </div>
          <div className="event-group">
            <img src={location} alt="location" />
            {`location: ${values?.location}`}
          </div>
          {/* <div className="event-group">
            <img src={contact} alt="registered" />
            {`registered: ${values?.registered || 0}`}
          </div> */}
          <div className="event-group">
            <img src={contact} alt="registered" />
            Max Capacity: {values?.no_of_ticket || 0}
          </div>
          {/* <div className="event-group">
            <img src={privateImg} alt="private" />
            {values?.isPrivate === true ? 'Private Event' : 'Public Event'}
          </div> */}
        </div>
        {values?.isPaid === true && (
          <div className="payment-info my-3 mx-auto">
            <div className="mb-1">Payment Info</div>
            <div>
              Waya will take a commission from the payment and the rest of the
              money will be paid into my account at the end of post duration or
              its expirey date.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublishEventModal;
