import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
// import { Skeleton } from 'antd';
import './inde.css';
// import styles from './index.module.css';

// import MyWallet from '../HerosPage/MyWallet';
// import He from '../HerosPage/index';
import Layout from '../../../shared/Layout';
// import EmptyWallet from './EmptyWallet';
import card from '../../../../assets/card.svg';
import {
  Bank,
  EyeIcon,
  Next,
  // CopyIcon,
  Wallet as WalletIcon,
} from '../../../shared/Icons';
// import { customBaseUrl, httpGet } from '../../../../action/http';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import Funding from './fundingModal';
import { customBaseUrl, httpPost } from '../../../../action/http';
import EmptyWallet from './EmptyWallet';

const Wallet = (props) => {
  const [showTransactions, setShowTransactions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [walletToShow, setWalletToShow] = useState(1);
  const [showFunding, setShowFunding] = useState(false);
  const [showBanks, setSHowBanks] = useState(false);
  const [timer, setTimer] = useState(0);
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  const { walletList, reload, setReload } = useContext(WalletContext);
  function name() {
    setShowTransactions(true);
  }

  const createWall = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpPost(
      '/api/v1/wallet/create',
      {
        userId,
        // fullName: 'loggedInUser.displayName',
        email: loggedInUser.email,
        phoneNumber: loggedInUser.phone,
        accountName: loggedInUser.displayName,
      },
      customBaseUrl.walletUrl
    );
    console.log({ res });
    if (res.status) {
      setReload(!reload);
    }
  };
  const nextSlide = () => {
    if (currentIndex < walletList?.length - walletToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Function to navigate to the previous slide
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (loggedInUser && !walletList?.length) createWall();
    const updateCardsToShow = () => {
      if (window.innerWidth >= 1024) {
        setWalletToShow(1);
      } else if (window.innerWidth >= 768) {
        setWalletToShow(1);
      } else {
        setWalletToShow(1);
      }
    };
    updateCardsToShow();
    window.addEventListener('resize', updateCardsToShow);
    return () => window.removeEventListener('resize', updateCardsToShow);
  }, [loggedInUser]);
  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
  }, []);
  return (
    <Layout route={props} currentPage="wallet" pageName="Wallet" showName>
      {showBanks ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EmptyWallet
            setSHowBanks={setSHowBanks}
            showBanks={showBanks}
            walletList={walletList}
          />
        </div>
      ) : (
        <div className="p-7 py-10 border m-2">
          <div className="relative z-10 overflow-hidden overflow-x-hidden overflow-y-hidden">
            <div className="p-10 bg-gradient-to-r from-[#ffd8ca] to-[#fffbfa]">
              <div className="mb-9">
                {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h5>{walletList?.wayabank_account_number}</h5>
                  <CopyIcon />
                </div> */}
                <div className="text-gray-500 font-semibold mb-2">
                  Total Balance
                </div>
                <div className="flex gap-4 mb-2">
                  <div className="text-3xl font-semibold">
                    NGN {walletList?.balance}
                  </div>
                  {/* <img alt="" src="./eye.svg" /> */}
                  <EyeIcon color="black" />
                </div>
                <h3 className="text-xs text-gray-600 text-semibold">
                  Last updated {Math.floor(timer / 60)} min ago.
                </h3>
              </div>
              <div className="">
                <div className="">
                  <button
                    onClick={() => setShowFunding(true)}
                    type="button"
                    className="text-white bg-[#ff652e] font-bold rounded-3xl px-5 py-3 gap-2 flex"
                  >
                    <WalletIcon />
                    Fund wallet
                  </button>
                </div>
              </div>
            </div>
            <div className="h-[600px] bg-[#f3f3f3] w-[500px] top-[28px] rounded-full -right-[150px] absolute z-50" />
            <div className="h-[330px] bg-[#ff652e] w-[330px] top-[110px] rounded-full -right-[100px] absolute z-50" />
          </div>
          <div
            style={{ display: 'none' }}
            className="absolute bottom-[10px] right-4 flex space-x-4"
          >
            <button
              type="button"
              onClick={prevSlide}
              className="bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition duration-300"
              aria-label="Previous Slide"
            >
              <FaArrowLeft className="w-4 h-4" />
            </button>
            <button
              type="button"
              onClick={nextSlide}
              className="bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition duration-300"
              aria-label="Next Slide"
            >
              <FaArrowRight className="w-4 h-4" />
            </button>
          </div>
          <div className="mt-10">
            {name}
            <div className="flex flex-col">
              <div className="flex justify-between mb-10">
                <h3 className="font-semibold">Transaction History</h3>
                <h3 className="font-bold text-[#ff652e] items-center flex gap-2 underline">
                  See all transaction <Next />
                </h3>
              </div>

              <div className="max-w-5xl w-full mx-auto my-5">
                {showTransactions ? (
                  <div className="">
                    <h3 className="font-bold text-sm mb-5">Today</h3>
                    <div className="flex justify-between mb-5">
                      <div className="flex gap-3">
                        <Bank />
                        {/* <img alt="" src="./bank.svg" /> */}
                        <div>
                          <h5 className="text-sm font-semibold">
                            Holistic logistics technology
                          </h5>
                          <h5 className="text-xs text-gray-500 font-semibold">
                            Payment
                          </h5>
                        </div>
                      </div>
                      <div className="">
                        <h5 className="text-sm font-semibold text-orange-600 text-right">
                          - &#8358;2,500.00
                        </h5>
                        <h5 className="text-xs text-gray-500 font-semibold text-right">
                          Aug 11, 12:35 PM
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <img alt="" src={card} className="w-52 h-52" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showFunding ? (
        <Funding
          showModal={showFunding}
          hideModal={setShowFunding}
          setReload={setReload}
          reload={reload}
          title="Funding Otion"
          description=""
          loggedInUser={loggedInUser}
          showBanks={showBanks}
          setSHowBanks={setSHowBanks}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Wallet;
