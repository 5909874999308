import React from 'react';
import { useHistory } from 'react-router';
import { Modal, ModalHeader } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import avatarImg from '../../../../assets/image/default-dp.svg';
import { userTheme } from '../../../../store/initialState';

const MomentViewers = (props) => {
  const { showModal, hideModal, members, profileData } = props;
  const history = useHistory();
  const theme = useRecoilValue(userTheme);

  return (
    <Modal isOpen={showModal} centered toggle={() => hideModal(false)}>
      <ModalHeader style={theme.modalStyle}>
        <p style={theme.modalStyle}>Views {`(${profileData?.viewCount})`}</p>
      </ModalHeader>
      <div className="" style={theme.modalStyle}>
        {members?.length ? (
          members.map((member) => (
            <div className="row" key={member?.id} style={{ fontSize: 10 }}>
              <div
                className="req-img col-md-4 text-center px-3 py-1"
                style={{ height: 50, width: 50 }}
                onClick={() => {
                  history.push(`/wayagram-profile/${member?.Profile?.id}`);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={member?.Profile?.avatar || avatarImg}
                  alt=""
                />
              </div>
              <div className="req-det col-md-8 mx-2 py-1">
                <div
                  className="det-det"
                  onClick={() => {
                    history.push(`/wayagram-profile/${member?.Profile?.id}`);
                  }}
                >
                  <div>{member?.Profile?.displayName}</div>
                  <div>{`@${member?.Profile?.username}`}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="wl-100 my-4 text-center">No View</div>
        )}
      </div>
    </Modal>
  );
};

export default MomentViewers;
