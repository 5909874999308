import React, { useEffect, useState } from 'react';
// import ImagePreview from '../../shared/Modals/ImagePreview';
import ImageViewerModal from '../../shared/Modals/Post/ImageViewerModal';

const MomentCard = ({
  data,
  imgPlaceholder,
  allMoments,
  pid,
  setReload,
  reloadMoment,
  reload,
  type,
  ind,
}) => {
  const [visible, setVisible] = useState(false);
  const datum = data ? data[0] : {};
  useEffect(() => {
    // console.log('ind', ind);
    // console.log('allmoments', allMoments);
  }, []);

  return datum?.type === 'image' ? (
    <div
      className="moment-card rounded-circle img-card"
      style={{
        backgroundImage: `url(${
          datum?.image || datum?.content || data?.ProductImages[0]?.imageUrl
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: 100,
        height: 100,
      }}
      onClick={() => setVisible(true)}
    >
      <div>
        {datum?.type === 'text' ? (
          <p style={{ textAlign: 'center' }}>{datum?.content}</p>
        ) : (
          ''
        )}
        <div className="moment-inner">
          <img
            className="moment-img"
            src={datum?.userAvatar || imgPlaceholder}
            alt="Profile"
          />
          <h6 className="fs-8 text-white">{datum?.userName}</h6>
        </div>
      </div>
      {/* <ImagePreview data={data} visible={visible} setVisible={setVisible} /> */}
      {visible && (
        <ImageViewerModal
          data={data}
          hideModal={setVisible}
          showModal={visible}
          allMoments={allMoments}
          id={datum?.ProfileId}
          pid={pid}
          setReload={setReload}
          reloadMoment={reloadMoment}
          reload={reload}
          setVisible={setVisible}
          type="moment"
          ind={ind}
        />
      )}
    </div>
  ) : type === 'product' ? (
    <div
      className="rounded-circle avatar-section d-flex shadow-4-strong img-card"
      style={{
        backgroundImage: `url(${
          data?.ProductImages?.length > 0 && data?.ProductImages[0]?.imageUrl
        })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: 100,
        height: 100,
      }}
      onClick={() => setVisible(true)}
    >
      {/* <div>
        <div className="moment-inner">
          <img
            className="moment-img"
            src={datum?.userAvatar || imgPlaceholder}
            alt="Profile"
          />
          <h6 className="fs-6 text-white">{datum?.userName}</h6>
        </div>
      </div> */}
      {/* <ImagePreview data={data} visible={visible} setVisible={setVisible} /> */}
      {visible && (
        <ImageViewerModal
          data={data}
          hideModal={setVisible}
          showModal={visible}
          allMoments={allMoments}
          id={type === 'product' ? data?.ProfileId : datum?.ProfileId}
          pid={pid}
          setReload={setReload}
          reloadMoment={reloadMoment}
          reload={reload}
          setVisible={setVisible}
          type="product"
        />
      )}
    </div>
  ) : (
    ''
  );
  // });
};

export const EmptyMomentCard = ({ image, imgPlaceholder, showPost }) => {
  return (
    <div onClick={() => showPost(true)} className="img-card">
      <img
        src={image || imgPlaceholder}
        alt="Avatar"
        style={{ width: 100, height: 100 }}
        className="moment-img rounded-circle avatar-section d-flex"
      />
      <div
        style={{ width: 100, height: 100, fontSize: 12 }}
        className="overlay-text"
      >
        Create New Moment
      </div>
    </div>
  );
};

export default MomentCard;
