import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import imgPlaceholder from '../../../assets/image/default-dp.svg';
// import PostModal from '../Modals/Post';
import ProfileMenu, { Actions } from './profileMenu';
import { UploadPost } from '../../pages/Wayagram/GramUtils';
// import { WayagramContext } from '../../../store/context/WayagramContext';
import { LayoutContext } from '../../../store/context/LayoutContext';
import CartIconNotification from './CartIconNotification';
import useStore from '../../../store/zustandStore/store';

const WayagramNavbar = (props) => {
  const location = useLocation();
  const navigate = useHistory();
  const currentPath = location.pathname;

  const { productCart, setOpenCart } = useStore((state) => state);

  const { theme } = props;
  const wayaData = localStorage.getItem('wayaUserData');
  const data = wayaData !== 'undefined' && JSON?.parse(wayaData)?.user;

  const themeColor = theme.mode === 'light' ? 'dark' : 'white';
  // const { setReload } = useContext(WayagramContext);
  const { setShowPostModal } = useContext(LayoutContext);

  const handleClickCartIcon = () => {
    if (currentPath !== '/shops') {
      navigate.push('/shops');
    }
    setOpenCart();
  };

  return data ? (
    <div className="topbar">
      <div className="navbar-mid">
        <Link to="/wayagram-discover" style={{ textDecoration: 'none' }}>
          <h6 className={`text-${themeColor}`}>Discover</h6>
        </Link>
      </div>
      <div className="">
        <UploadPost showPost={setShowPostModal} />
      </div>
      <div className="">
        <Actions />
      </div>
      <div className="">
        <CartIconNotification
          items={productCart}
          handleClickCartIcon={handleClickCartIcon}
        />
      </div>
      <div className="">
        <ProfileMenu imgPlaceholder={imgPlaceholder} />
      </div>
    </div>
  ) : (
    <div className="topbar">
      <div className="">
        <ProfileMenu imgPlaceholder={imgPlaceholder} />
      </div>
    </div>
  );
};

export default WayagramNavbar;
