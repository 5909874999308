import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function ProgressBar(props) {
  const { step, totalSteps } = props;
  const barArr = new Array(totalSteps).fill(0);
  return (
    <div className="progress-bar-group">
      {barArr.map((item, index) => (
        <div
          key={Math.random()}
          className={index <= step ? 'bar active' : 'bar'}
        >
          {/* `&apos;` */}
        </div>
      ))}
    </div>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
