import { useRecoilValue } from 'recoil';
import React, { useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListContainer } from './style';
import Button from '../Button';
import placeHolderImage from '../../../assets/image/default-dp.svg';
import { capFirstWord } from '../../../utils/helper';
import { userTheme } from '../../../store/initialState';
import { WayagramContext } from '../../../store/context/WayagramContext';

export default function List({
  headerText,
  onAction,
  loading,
  list,
  notShowMore,
  //  from,
  //  buttonTitle,
  showName = true,
}) {
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  const history = useHistory();
  const theme = useRecoilValue(userTheme);
  const [selected, setSelected] = useState();
  const handleAction = (item) => {
    setSelected(item.id);
    if (headerText === 'People') onAction(loggedInUser.UserId, item.username);
    else onAction(loggedInUser.id, item.id);
  };

  return (
    <ListContainer color={theme.style.color} mode={theme.mode}>
      {showName && <div className="header">{headerText}</div>}
      {list?.length > 0 &&
        list.map((item) => (
          <div className="inner" key={Math.random()}>
            <div className="profile-div">
              <div className="image-div">
                <img
                  src={item?.imageUrl || item?.avatar || placeHolderImage}
                  alt="default"
                />
              </div>
              <div
                className="text-div add-cursor"
                onClick={() => {
                  if (headerText === 'Groups')
                    history.push(`/wayagram-group/${item?.id}`);
                  if (headerText === 'Pages')
                    history.push(`/wayagram-page/${item?.id}`);
                  if (headerText === 'People')
                    history.push(`/wayagram-profile/${item?.id}`);
                }}
              >
                <span>
                  {capFirstWord(item?.displayName) ||
                    capFirstWord(item?.name) ||
                    capFirstWord(item?.title) ||
                    `${capFirstWord(item?.username)}`}
                </span>
                <span className="text-muted s-text d-block">
                  {item?.description?.length > 20
                    ? `${item?.description?.slice(0, 20)}...`
                    : item?.description}
                </span>
              </div>
            </div>
            <div className="action-btn my-auto">
              <Button
                style={{ padding: 2, height: 50, width: 100 }}
                className="btn btn-outline-primary d-flex justify-content-center white-space: normal  px-2 align-items-center"
                loading={loading && item?.id === selected}
                onClick={() => handleAction(item)}
                disabled={item?.isFollowing || item?.connection?.following}
                content={
                  item?.isFollowing || item?.connection?.following
                    ? 'Following'
                    : item?.isPending || item?.connection?.isPending
                    ? 'Request Sent'
                    : item.notPublic || item?.connection?.notPublic
                    ? 'Send Request'
                    : 'Follow'
                }
              />
            </div>
          </div>
        ))}
      {loading && (
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <Skeleton circle width={50} height={50} />
            <div
              style={{
                display: 'inline-block',
                width: 200,
                marginLeft: '0.5em',
              }}
            >
              <Skeleton />
              <Skeleton />
            </div>
          </div>
        </div>
      )}

      {!loading && list.length <= 0 && (
        <div className="text-center"> No data found </div>
      )}

      {list?.length > 0 && !notShowMore && (
        <div
          className="show-more"
          onClick={() => {
            history.push({
              pathname: '/wayagram-discover',
              from: 'wayagram',
              state: { activeTab: headerText },
            });
          }}
        >
          SHOW MORE
        </div>
      )}
    </ListContainer>
  );
}

List.defaultProps = {
  headerText: 'People to Follow',
};

List.propTypes = {
  headerText: PropTypes.string,
};
