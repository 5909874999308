//  import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { Share } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../../shared/Button';
import placeHolderImage from '../../../../assets/image/default-dp.svg';
import truncateWithEllipses from '../../../../utils/truncate';

const CandidateCard = ({ item, loading }) => {
  const { avatar, displayName, state, votes } = item || {};
  const history = useHistory();
  return (
    <div
      style={{ fontSize: 12, width: 200 }}
      className="suggest-box mx-1 my-3 row text-center border"
    >
      <div
        className="rounded"
        // size={48}
        // src={avatar || placeHolderImage}
        onClick={() => history.push(`/wayagram-profile/${item.id}`)}
      >
        <img
          src={avatar || placeHolderImage}
          className="rounded"
          width="150"
          height="150"
          alt="profi"
        />
      </div>
      <div>
        <span className="name">
          <p>{truncateWithEllipses(displayName, 20)}</p>
          <p className="mx-0">{state}</p>
        </span>
        <Link
          to={`/wayagram-profile/contest/${item?.email}/${item?.contestId}/${item?.contestantId}`}
        >
          <Button
            style={{ fontSize: 8 }}
            className="btn border rounded-pill mt-1 h6"
            content="View Page"
            //  onClick={() => onAction(username, item.id)}
            loading={loading}
          />
        </Link>
        <div
          className="row shadow-lg bg-gray rounded mt-1 py-2"
          style={{ fontSize: 8 }}
        >
          <div className="col">No of votes {votes ?? 0}</div>
          <div className="col">
            <Share style={{ fontSize: 10 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateCard;
