import React, { useEffect, useState, useContext } from 'react';
// import moment from 'moment';
import {
  Link,
  useParams,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
//  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { DateRange } from '@material-ui/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { ArrowBack } from '@material-ui/icons';
import medal from '../../../../assets/image/Medal First Place.png';
import candidate from '../../../../assets/image/right-user.svg';
import Loader from '../../../shared/Loader';
import commentIcon from '../../../../assets/image/message-icon.png';
//  import Button from '../../../shared/Button';
import Layout from '../../../shared/Layout';
import RightSidebar from '../RightSidebar';
import '../index';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import church from '../../../../assets/image/church-one.svg';
import pollIcon from '../../../../assets/image/polls-post.svg';
import placeHolderImage from '../../../../assets/image/default-dp.svg';
import CandidateCard from './candidatesCards';
import OtherDropdown from '../../../shared/Dropdown/OtherDropdown';
import { objectToGetParams, whatsappLink } from '../../../shared/ShareButton';
import { capFirstWord, copyIdToClipboard } from '../../../../utils/helper';
import truncateWithEllipses from '../../../../utils/truncate';
import { HeaderContext } from '../../../../store/context/HeaderContext';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import ContestantDetails from './contestantDetails';
import SEO from '../../../../utils/seo';
import { dateFormater } from '../../../../utils/formatDate';

// import phone from '../../../../assets/image/bg-section4.png';

const ContestDetails = (props) => {
  const history = useHistory();
  const {
    wayagramProfile: { email, id, isOrganiser },
  } = useContext(WayagramContext);
  const { profileId, contestId } = useParams();
  const [details, setDetails] = useState([]);
  const [states, setStates] = useState([]);
  //  const [stateData, setStateData] = useState([]);
  const [showSharePost, setShowSharePost] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalVote, setTotalVote] = useState(0);
  const [rankings, setRankings] = useState([]);
  const [contestantData, setContestantData] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [leaders, setLeaders] = useState({
    candidate: '',
    cVote: 0,
    sch: '',
    sVote: 0,
  });

  const handleGetContestDetails = async () => {
    // const search = `all-posts/search-products?query=${value}`;
    const wayaData = localStorage.getItem('wayaUserData');
    const data = wayaData !== 'undefined' && JSON?.parse(wayaData)?.user;
    const uri = !data
      ? `open/contests/view?contestId=${contestId}`
      : `contests?id=${contestId}`;
    //  const uri = `contests?profileId=${profileId}`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`, !data);
    if (!data) setDetails(res?.data);
    if (res?.status) {
      const contest = res?.data[0];
      console.log({ d: res?.data, contest });
      setDetails(!data ? res?.data : contest?.contest);
      console.log({ dtea: contest?.contest });
      setLoading(false);
      setTotalVote(contest?.contest?.rankings?.totalVotes ?? 0);
      console.log('contes', contest?.contest?.Contestants);
      const lead = contest?.contest?.Contestants?.filter(
        (e) => e?.rank?.position === 1
      )[0];
      setLeaders({
        ...leaders,
        candidate: `${lead?.firstName} ${lead?.lastName}`,
        cVote: `${lead?.voteCounts}`,
        affiliation: `${lead?.affiliationName}`,
        aVote: `${lead?.rank?.votePercentage}`,
      });
      // const uniqStates = [];
      // contest?.contest?.Contestants.forEach((x) => {
      //   if (
      //     uniqStates.some((val) => {
      //       return val?.state === x?.state;
      //     })
      //   ) {
      //     uniqStates.forEach((k) => {
      //       if (k.state === x.state) {
      //         /* eslint-disable no-param-reassign */
      //         k.occurrence += 1;
      //       }
      //     });
      //   } else {
      //     const a = {};
      //     a.state = x.state;
      //     a.occurrence = 1;
      //     uniqStates.push(a);
      //   }
      // });
      // setStateData(uniqStates);
    } else {
      setLoading(false);
    }
    //  setLoading(false);
  };
  const getStateRankings = async () => {
    const res = await httpGet(
      `${customBaseUrl.contestUrl}/contests/contestants?contestId=${contestId}&status=ACTIVE&isLeadingContestantsQuery=true`
    );
    if (res?.status) {
      console.log('fds', res?.data?.leadingContestants[0]);
      setRankings(res?.data);
    } else {
      console.log(res);
    }
  };

  const getStates = async () => {
    const res = await httpGet(`${customBaseUrl.contestUrl}/users/states`);
    if (res?.status) {
      setStates(res?.data);
    } else {
      console.log(res);
    }
  };
  const handleLike = async () => {
    if (id) {
      const payload = {
        contestId,
        profileId,
      };
      const res = await httpPost(
        `/contests/like`,
        payload,
        customBaseUrl.contestUrl
      );
      if (res?.status) {
        handleGetContestDetails();
      } else {
        console.log(res);
      }
    } else {
      NotificationManager.error('Kindly sign in to like a contest');
    }
  };
  const handleRepost = async () => {
    if (id) {
      const payload = {
        contestId,
        profileId,
      };
      const res = await httpPost(
        `/contests/repost`,
        payload,
        customBaseUrl.contestUrl
      );
      if (res?.status) {
        handleGetContestDetails();
      } else {
        console.log(res);
      }
    } else {
      NotificationManager.error('Kindly log in to repost a contest!');
    }
  };
  // const dropDownOptions = [
  //   // 'Edit',
  //   // 'Manage Contestants',
  //   // 'Manage States',
  //   // 'Manage Dashboard',
  //   // 'Delete Contest',
  //   // 'Share Contest',
  //   // 'Invite Contestants',
  // ];
  // const handleDropdownAction = (item, row) => {
  //   console.log({ item, row });
  //   if (item === 'Share Contest') setShowSharePost(true);
  // };

  // const getStateLogo = async (state) => {
  //   const log = await states?.filter((e) => e.state === state)[0];
  //   return log?.logo;
  // };
  const handleGetContestant = async () => {
    const uri = `contests/me?email=${email}&contestId=${contestId}`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      // const contest = res?.data?.filter((e) => e?.contest?.id === contestId)[0];
      // const bioData = await contest?.contest?.Contestants?.filter(
      //   (e) => e?.id === contestantId
      // )[0];
      setContestantData(res?.data);
    }
  };
  useEffect(() => {
    console.log({ states, profileId, contestId });
    getStates();
    handleGetContestant();
    getStateRankings();
    handleGetContestDetails();
  }, [profileId, email]);
  const { setHeaders } = useContext(HeaderContext);

  const handleSharePost = async (item) => {
    const url = `${process.env.REACT_APP_WEBSITE_APP}/contest-dashboard/${localStorage?.profileId}/${details?.id}?from=contest&contestid=${details?.id}`;
    setHeaders({
      image:
        'https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG',
      title: details?.description,
      url,
    });
    const shareText = details?.description
      ? truncateWithEllipses(details?.description, 30)
      : '';
    if (item === 'whatsapp') {
      window.open(
        // `https://wa.me/?text=${description}%0D%0A${url}`,
        whatsappLink(url, shareText),
        '_blank'
      );
    } else if (item === 'twitter') {
      window.open(
        `https://twitter.com/share/${objectToGetParams({
          url,
          text: shareText,
        })}`,
        '_blank'
      );
    } else if (item === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
          u: url,
          quote: shareText,
          // hashtag: '#wayagram',
          // imageurl:
          //   'https://social-waya-bucket.s3.eu-west-1.amazonaws.com/bts-1651849826823.octet-stream',
          // imageurl: PostImages[0].imageURL,
        })}`,
        '_blank'
      );
    } else if (item === 'mail') {
      window.open(
        `mailto:${objectToGetParams({
          subject: 'share post',
          body: shareText ? `${shareText} Check out this contest ${url}` : url,
        })}`
      );
    } else if (item === 'link') {
      //  const d = `${shareText} \n${url}\n`;
      copyIdToClipboard(url, 'Contest link copied to clipboard');
    }
    const postData = {
      item_id: details?.id,
      item_type: 'Contest',
      shared_to: item,
    };
    await httpPost(
      `all-posts/share-post?profile_id=${profileId}`,
      postData,
      customBaseUrl.wayagramUrl
    );
  };
  // const today = new Date().getTime();
  return (
    <Layout
      route={props}
      currentPage="contest"
      //  pageName="Wayagram Official Contest Management Dashboard"
      showName
    >
      <SEO
        title={details?.title}
        description={details?.description}
        image={details?.miscellaneous?.coverPhoto[0]}
        name="contest"
        type="article"
      />
      <Modal isOpen={showDetails} toggle={() => setShowDetails(false)} centered>
        <ModalHeader className="text-left" toggle={() => setShowDetails(false)}>
          <span>Application Details</span>
        </ModalHeader>
        <ModalBody centerMode>
          <ContestantDetails
            showModal={showDetails}
            hideModal={setShowDetails}
            data={contestantData}
          />
        </ModalBody>
      </Modal>
      {details?.length < 1 ? (
        loading && <Loader />
      ) : (
        <div className="row wayagram-div">
          {contestantData?.status === 'ACTIVE' ? (
            <div className="text-end col-md-8">
              <a
                href={`/wayagram-profile/contest/${contestantData?.email}/${contestId}/${contestantData?.id}`}
              >
                <button type="button" className="btn btn-primary mx-4">
                  Manage Profile
                </button>
              </a>
            </div>
          ) : (
            ''
          )}
          <div className="col-md-8 col-sm-12 col-xs-12">
            <Link to="/contest">
              <ArrowBack />
            </Link>
            <div className="mt-4 mx-auto rounded search-container shadow-lg py-5 px-2">
              <div className="text-warning">
                {contestantData?.status === 'PENDING'
                  ? 'Application Status: Pending'
                  : ''}
              </div>
              <div className="border rounded mb-3 max-width: 540px;">
                <div className="p-3">
                  <div className="row">
                    <div className="row d-flex align-items-center col-md-8 text-start">
                      <div
                        style={{ width: 50, height: 50 }}
                        className="border border-primary rounded-circle"
                      >
                        <img
                          src={details?.organiser?.coverPhoto}
                          alt="avatar"
                          style={{
                            borderRadius: '50%',
                            borderWidth: 2,
                            borderColor: '#ff6700',
                          }}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col">
                        {`${details?.organiser?.firstName} ${details?.organiser?.lastName}`}
                      </div>
                    </div>
                    <div className="col-2">
                      <label>
                        <h5
                          style={{ fontSize: 14 }}
                          className={`${
                            details?.status === 'ACTIVE'
                              ? 'text-success'
                              : 'text-danger'
                          }`}
                        >
                          {details?.status}
                        </h5>
                      </label>
                    </div>
                    <div className="col-md-2 text-end" />
                  </div>
                  <div className="row mt-3">
                    <div className="row col-md-6 text-start">
                      <div
                        className="col-md-6"
                        style={{ width: '100%', height: '100%', top: 100 }}
                      >
                        {/* <img src={coverPhoto} className="w-100 h-100 img-fluid" alt="..." /> */}
                        <Carousel
                          //  autoPlay
                          width={250}
                          centerSlidePercentage={70}
                          centerMode={false}
                          infiniteLoop
                          interval={2000}
                          showThumbs={false}
                          showArrows={false}
                        >
                          <div style={{ width: 250, height: 250 }}>
                            <img
                              className="w-100 h-100 img-fluid rounded"
                              alt="..."
                              src={
                                details?.miscellaneous?.coverPhoto[0] ||
                                placeHolderImage
                              }
                            />
                          </div>
                          <div style={{ width: 250, height: 250 }}>
                            <video
                              width="400"
                              height="400"
                              controls
                              className="w-100 h-100 img-fluid"
                            >
                              <source
                                src={details?.miscellaneous?.coverVideo}
                              />
                              <track
                                src="captions_en.vtt"
                                kind="captions"
                                label="english_captions"
                              />
                            </video>
                          </div>
                        </Carousel>
                        <div className="col-md-12 row mt-3">
                          <div
                            style={{ fontSize: 12 }}
                            className="row mx-2 z-index-2"
                          >
                            <div className="col">
                              <i
                                className={
                                  details?.isLiked
                                    ? 'mdi mdi-heart text-danger'
                                    : 'mdi mdi-heart-outline text_text add-cursor'
                                }
                                role="button"
                                tabIndex={0}
                                aria-hidden="true"
                                onClick={handleLike}
                              />
                              {details?.likeCounts}
                            </div>
                            <div className="col">
                              <img
                                onClick={() => {
                                  if (id) {
                                    history.push(
                                      `/contest/post/${details?.id}`
                                    );
                                  } else {
                                    NotificationManager.error(
                                      'Kindly log in to post a comment!'
                                    );
                                  }
                                }}
                                src={commentIcon}
                                alt="comment"
                                style={{
                                  width: '1em',
                                  height: '1em',
                                  cursor: 'pointer',
                                }}
                              />
                              {details?.commentCounts}
                            </div>
                            <div onClick={handleRepost} className="col">
                              <i
                                className={
                                  details?.isRepost
                                    ? 'mdi mdi-twitter-retweet text-primary'
                                    : 'mdi mdi-twitter-retweet'
                                }
                                style={{ fontSize: 12 }}
                              />
                              {details?.repostCounts}
                            </div>
                            <div
                              className="share-div col"
                              style={{ position: 'relative' }}
                            >
                              <i
                                className="mdi mdi-share-variant"
                                role="button"
                                tabIndex={0}
                                aria-hidden="true"
                                onClick={() => {
                                  setShowSharePost(!showSharePost);
                                  //  setSelectedPostId(postId);
                                }}
                              />
                              {showSharePost ? (
                                <OtherDropdown
                                  hide={setShowSharePost}
                                  options={[
                                    {
                                      name: 'Share to Twitter',
                                      type: 'twitter',
                                    },
                                    {
                                      name: 'Share to Facebook',
                                      type: 'facebook',
                                    },
                                    {
                                      name: 'Send in Whatsapp',
                                      type: 'whatsapp',
                                    },
                                    { name: 'Send in Mail', type: 'mail' },
                                    { name: 'Copy link', type: 'link' },
                                  ]}
                                  postId={details?.id}
                                  handleClick={(item) => handleSharePost(item)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-start">
                      <div className="row">
                        <h6 className="card-title">{details?.title}</h6>
                        <hr
                          className="shadow-lg bg-white"
                          style={{ height: 0.1 }}
                        />
                      </div>
                      <div className="my-0 row">
                        <label>Description</label>
                        {!seeMore ? (
                          <p
                            style={{ fontSize: 14, overflow: 'auto' }}
                            className="card-text my-1"
                          >
                            {truncateWithEllipses(details?.description, 50)}
                            {details?.description?.length > 50 ? (
                              <label
                                onClick={() => setSeeMore(!seeMore)}
                                className="text-primary"
                              >
                                see more
                              </label>
                            ) : (
                              ''
                            )}
                          </p>
                        ) : (
                          <p
                            style={{ fontSize: 14, overflow: 'auto' }}
                            className="card-text my-1"
                          >
                            {details?.description}
                            {details?.description?.length > 50 ? (
                              <label
                                onClick={() => setSeeMore(!seeMore)}
                                className="text-primary"
                              >
                                see less
                              </label>
                            ) : (
                              ''
                            )}
                          </p>
                        )}
                        <hr
                          className="shadow-lg bg-white my-1"
                          style={{ height: 0.1 }}
                        />
                      </div>
                      <div style={{ fontSize: 11 }} className="row">
                        <div className="col">
                          <label className="fw-bold">
                            Registration Start Date
                          </label>
                          <br />
                          {dateFormater(details?.registrationStartDate)}
                        </div>
                        <div className="col">
                          <label className="fw-bold">
                            Registration End Date
                          </label>
                          <br />
                          {dateFormater(details?.registrationEndDate)}
                        </div>
                      </div>
                      <div style={{ fontSize: 11 }} className="row mt-2">
                        <div className="col">
                          <label className="fw-bold">Start Date</label>
                          <br />
                          {dateFormater(details?.startDate)}
                        </div>
                        <div className="col">
                          <label className="fw-bold">End Date</label>
                          <br />
                          {dateFormater(details?.endDate)}
                        </div>
                        <hr
                          className="shadow-lg bg-white"
                          style={{ height: 0.1 }}
                        />
                      </div>
                      {details?.currentStage === 'stage-one' ? (
                        ''
                      ) : (
                        <>
                          <div style={{ fontSize: 11 }} className="row">
                            <div className="col-md-5">
                              {' '}
                              <img
                                src={church}
                                className="w-10 h-10"
                                alt="..."
                              />
                              {details?.category === 'school'
                                ? 'Leading School:'
                                : 'Leading Church:'}
                            </div>
                            <div className="col text-start">
                              {rankings?.leadingContestants[0]
                                ? rankings?.leadingContestants[0]
                                    ?.affiliationName ?? 'N/A'
                                : 'N/A'}
                            </div>
                            <div className="col">
                              {rankings?.leadingContestants[0]
                                ? Math.round(
                                    rankings?.leadingContestants[0]?.rank
                                      ?.votePercentage * 100
                                  ) / 100 ?? 0 % 'votes'
                                : ''}
                            </div>
                          </div>
                          <div style={{ fontSize: 11 }} className="row">
                            <div className="col-md-5">
                              {' '}
                              <img
                                src={candidate}
                                className="w-10 h-10 mb-0"
                                alt="..."
                              />
                              Leading Candidate:
                            </div>
                            <div className="col text-start">
                              {rankings?.leadingContestants[0]
                                ? `${rankings?.leadingContestants[0]?.firstName} ${rankings?.leadingContestants[0]?.lastName}` ??
                                  'N/A'
                                : 'N/A'}
                            </div>
                            <div className="col">
                              {rankings?.leadingContestants[0]
                                ? leaders?.cVote ?? 0
                                : ''}
                            </div>
                            <hr
                              className="shadow-lg bg-white"
                              style={{ height: 0.1 }}
                            />
                          </div>
                        </>
                      )}
                      <div className="row mt-0" style={{ fontSize: 12 }}>
                        <label className="fw-bold col-md-3">Stage: </label>
                        <div className="col text-start">
                          {capFirstWord(details?.currentStage ?? 'N/A')}
                        </div>
                      </div>
                      {/* <div className="row mt-1 text-center">
                        <p>
                          <img src={medal} className="w-10 h-10" alt="..." />
                          Winner’s Award
                          <br />
                          <span style={{ fontSize: 14 }}>
                            {details?.winnerPrize}
                          </span>
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row mt-1 text-center">
                      <p>
                        <img src={medal} className="w-10 h-10" alt="..." />
                        Winner’s Award
                        <br />
                        <span style={{ fontSize: 14 }}>
                          {details?.winnerPrize}
                        </span>
                      </p>
                    </div>
                    {/* {!contestantData?.status &&
                    details?.status === 'ACTIVE' &&
                    !isOrganiser ? (
                      details?.maxContestants > details?.Contestants?.length ? (
                        <>
                          {new Date(details?.registrationStartDate).getTime() <=
                            today ||
                          new Date(details?.registrationEndDate).getTime >=
                            today ? (
                            <div className="col-md-12 text-center">
                              // <Link to={`/register-contest/${contestId}`}>
                              <button
                                onClick={() => {
                                  if (id) {
                                    history.push(
                                      `/register-contest/${contestId}`
                                    );
                                  } else {
                                    NotificationManager.error(
                                      'Kindly log in to register for this contest'
                                    );
                                  }
                                }}
                                disabled={
                                  !(
                                    new Date(
                                      details?.registrationStartDate
                                    ).getTime() < today
                                  ) ||
                                  contestantData?.Contest?.id ||
                                  !(
                                    new Date(
                                      details?.registrationEndDate
                                    ).getTime() >= today
                                  )
                                }
                                type="button"
                                className="btn btn-primary mb-5 w-2/3"
                                //  onClick={() => console.log('cli')}
                                hidden={
                                  new Date(
                                    details?.registrationEndDate
                                  ).getTime() < today
                                }
                              >
                                {new Date(
                                  details?.registrationStartDate
                                ).getTime() < today &&
                                new Date(
                                  details?.registrationEndDate
                                ).getTime() >= today
                                  ? `REGISTER`
                                  : new Date(
                                      details?.registrationEndDate
                                    ).getTime() < today
                                  ? 'REGISTRATION CLOSED'
                                  : today -
                                    new Date(
                                      details?.registrationEndDate
                                    ).getTime()}
                              </button>
                              // </Link>
                            </div>
                          ) : (
                            <div className="col-md-8 text-center">
                              <button
                                hidden={
                                  new Date(
                                    details?.registrationEndDate
                                  ).getTime() < today
                                }
                                type="button"
                                disabled
                                className="btn btn-primary mb-5 w-2/3"
                                onClick={() => console.log('cli')}
                              >
                                {new Date(
                                  details?.registrationStartDate
                                ).getTime() >= today
                                  ? `REGISTERATION NOT STARTED`
                                  : 'REGISTRATION CLOSED'}
                              </button>
                            </div>
                          )}
                        </>
                      ) : new Date(details?.registrationStartDate).getTime() <
                          today &&
                        new Date(details?.registrationEndDate).getTime() >=
                          today ? (
                        <h6 className="fw-bold text-center text-danger">
                          Maximum Contestant Limit Reached
                        </h6>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )} */}
                    {!contestantData?.status &&
                    details?.status === 'ACTIVE' &&
                    !isOrganiser ? (
                      <>
                        <div className="col-md-12 text-center">
                          <button
                            onClick={() => {
                              if (id) {
                                history.push(`/register-contest/${contestId}`);
                              } else {
                                NotificationManager.error(
                                  'Kindly log in to register for this contest'
                                );
                              }
                            }}
                            type="button"
                            className="btn btn-primary mb-5 w-2/3"
                            //  onClick={() => console.log('cli')}
                          >
                            REGISTER
                          </button>
                          {/* </Link> */}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {details?.currentStage === 'stage-one' ? (
                  ''
                ) : (
                  <div
                    className="row shadow-lg bg-white rounded mx-2"
                    style={{ fontSize: 10 }}
                  >
                    <div className="col-md-4 ml-3 py-2">
                      <img src={pollIcon} className="w-10 h-10" alt="..." />
                      Total Votes {totalVote}
                    </div>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="row mt-2">
                          <div className="col-md-5">
                            {' '}
                            <img src={church} className="w-10 h-10" alt="..." />
                            {details?.category === 'school'
                              ? 'Leading School:'
                              : 'Leading Church'}
                          </div>
                          {rankings?.leadingContestants[0] ? (
                            <div className="col-md-4 text-left">
                              {rankings?.leadingContestants[0]
                                ?.affiliationName ?? 'N/A'}
                            </div>
                          ) : (
                            ''
                          )}
                          {rankings?.leadingContestants[0] ? (
                            <div className="col-md-3">
                              {Math.round(
                                rankings?.leadingContestants[0]?.rank
                                  ?.votePercentage * 100
                              ) / 100 ?? 0}
                              % votes
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          {' '}
                          <img
                            src={candidate}
                            className="w-10 h-10 mb-0"
                            alt="..."
                          />
                          Leading Candidate:
                        </div>
                        {rankings?.leadingContestants[0] ? (
                          <div className="col-md-4 text-left">
                            {`${rankings?.leadingContestants[0]?.firstName} ${rankings?.leadingContestants[0]?.lastName}` ??
                              'N/A'}
                          </div>
                        ) : (
                          'N/A'
                        )}
                        {rankings?.leadingContestants[0] ? (
                          <div className="col-md-3">
                            {leaders?.cVote ?? 0} votes
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <p className="mt-3 mb-1 mx-2">States</p>
                <PerfectScrollbar style={{ maxWidth: 'auto', display: 'flex' }}>
                  {rankings?.stateAnalysis?.length > 0
                    ? rankings?.stateAnalysis?.map((e) => (
                        <div
                          key={e?.state}
                          //  className="mb-3 text-center mx-1 row border"
                          style={{ fontSize: 12, width: 200 }}
                          className="suggest-box mx-1 my-3 row text-center border"
                        >
                          <div className="rounded my-3">
                            <img
                              style={{ width: 100, height: 100 }}
                              src={`https://social-waya-bucket.s3.eu-west-1.amazonaws.com/${e?.state}`}
                              className=""
                              alt="..."
                            />
                          </div>
                          <div
                            style={{ fontSize: 10 }}
                            className="col text-center mt-3"
                          >
                            <p className="fw-bold">
                              {e?.state?.toUpperCase()}{' '}
                              {e?.state.toLowerCase() !== 'abuja'
                                ? 'STATE'
                                : ''}
                            </p>
                            <p>{e?.totalContestants ?? 0} contestants</p>
                            <p>
                              {`${e?.activeContestants} / ${e?.totalContestants}`}{' '}
                              Qualified Contestants
                            </p>
                            <Link
                              to={`/contest/contestants/states/${e?.state}/${contestId}/${profileId}`}
                            >
                              <button
                                style={{ fontSize: 10 }}
                                className="btn text-primary border rounded-pill mt-1 h6"
                                type="button"
                              >
                                Click here to view more
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))
                    : ''}
                </PerfectScrollbar>
              </div>
            </div>
            <div className="mt-4 mx-auto rounded search-container shadow-lg py-5">
              <div>
                <img
                  src={candidate}
                  className="w-10 h-10 mb-3 mx-3"
                  alt="..."
                />
                Top 10 Leading Candidates
                <span className="text-end px-5">
                  {id ? (
                    <Link to={`/contest/contestants/${contestId}/${profileId}`}>
                      View All
                    </Link>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <PerfectScrollbar style={{ maxWidth: 'auto', display: 'flex' }}>
                {details?.Contestants
                  ? Object.keys(details?.Contestants)
                      ?.filter(
                        (e) => details?.Contestants[e]?.status === 'ACTIVE'
                      )
                      .map((e) => (
                        <CandidateCard
                          key={e}
                          item={{
                            username: 'fff',
                            id: details?.Contestants[e]?.id,
                            displayName: `${details?.Contestants[e]?.firstName} ${details?.Contestants[e]?.lastName}`,
                            state: `${
                              details?.Contestants[e]?.state
                                .charAt(0)
                                .toUpperCase() +
                              details?.Contestants[e]?.state.slice(1)
                            } - Nigeria`,
                            avatar:
                              details?.Contestants[e]?.miscellaneous
                                ?.contestPhoto[0],
                            contestId: details?.Contestants[e]?.contestId,
                            profileId: details?.Contestants[e]?.profileId,
                            email: details?.Contestants[e]?.email,
                            contestantId: details?.Contestants[e]?.id,
                            votes: details?.Contestants[e]?.voteCounts,
                          }}
                        />
                      ))
                  : ''}
              </PerfectScrollbar>
            </div>
            {/* <div className="mt-4 col d-flex justify-content-around">
              <img src={candidate} className="w-10 h-10" alt="..." />
              <Button
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="close contest"
              >
                Next
              </Button>
              <Button
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="View contest voters"
              >
                Next
              </Button>
              <Button
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="Promote Contest"
              >
                Next
              </Button>
              <Button
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="Retrieve payment"
              >
                Next
              </Button>
              <Button
                className="btn btn-primary ml-auto mb-3 mt-2"
                color="primary"
                content="Deactivate Contest"
              >
                Next
              </Button>
            </div> */}
          </div>
          {id ? <RightSidebar isSearch /> : ''}
        </div>
      )}
      {/* {showDetails ? (
        <ContestantDetails
          showModal={showDetails}
          hideModal={setShowDetails}
          data={contestantData}
        />
      ) : (
        ''
      )} */}
    </Layout>
  );
};

export default ContestDetails;
