import React from 'react';
import PropTypes from 'prop-types';

import './eventModal.scss';

import { locationType } from './data';

const SearchEventLocation = (props) => {
  const { handleFormInput, values, theme, setValues } = props;
  const handleLocationType = (type) => {
    if (type === 'Virtual') {
      setValues({ ...values, selectedType: type, virtual: true });
    } else {
      setValues({ ...values, selectedType: type, virtual: false });
    }
  };
  return (
    <div className="event-inner-content">
      <div className="location-type-container">
        {locationType.map((item) => (
          <div
            key={item}
            className={
              values.selectedType === item
                ? 'location-type-box active-type'
                : 'location-type-box'
            }
            onClick={() => handleLocationType(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {values.selectedType === 'Virtual' ? (
        <div>
          <div className="input-container">
            <input
              name="location"
              value={values.location}
              onChange={(e) => {
                handleFormInput(e.target.value, 'location');
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
              placeholder="Event Location"
            />
            <span>
              Where are you hosting the event? e.g Zoom, Skype
              <span className="text-primary"> *</span>
            </span>
          </div>
          <div className="input-container">
            <input
              name="urlLink"
              value={values.urlLink}
              onChange={(e) => {
                handleFormInput(e.target.value, 'urlLink');
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
              placeholder="url Link to event"
            />
            <span>
              Please input the link to the event
              <span className="text-primary"> *</span>
            </span>
          </div>
        </div>
      ) : (
        <div className="input-container mt-5">
          <input
            name="location"
            value={values.location}
            onChange={(e) => {
              handleFormInput(e.target.value, 'location');
            }}
            className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            placeholder="Event Location"
          />
          <span>
            Where is the location
            <span className="text-primary"> *</span>
          </span>
        </div>
      )}
    </div>
  );
};

SearchEventLocation.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default SearchEventLocation;
