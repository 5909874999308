import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
// import ToggleInput from '../../ToggleInput';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import SuccessModal from '../SuccessModal';
import { CardContext } from '../../../../store/context/CardContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';

const Index = (props) => {
  const { showModal, hideModal, id } = props;
  const [data, setData] = useState({});
  const { setReload, reload, availableBank } = useContext(CardContext);
  const { profile: userProfile } = useContext(ProfileContext);
  // const [password, setPassword] = useState('');
  const [bankValue, setBankValue] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const resolveBank = async (code, name, value) => {
    showLoader();
    // setData({ ...data, bankName });
    setBankValue(value);
    setBankName(name);
    setBankCode(code);
    if (data.accountNumber === '') {
      swal('Oops!', 'Account Number is required', 'error');
      return;
    }
    const postData = {
      accountNumber: data.accountNumber,
      bankCode: code,
    };
    const res = await httpPost(
      `/bankAccount/resolveAccountNumber`,
      postData,
      customBaseUrl.cardUrl
    );
    if (res.status) {
      hideLoader();
      setData({ ...data, accountName: res.data.account_name });
    } else {
      swal('Oops!', res.message, 'error');
    }
    hideLoader();
  };

  const handleAddBank = async () => {
    showLoader();

    const postData = {
      ...data,
      bankName,
      bankCode,
      userId: userProfile.userId,
    };
    const res = await httpPost(
      `/bankAccount/add/${userProfile.userId}`,
      postData,
      customBaseUrl.cardUrl
    );
    if (res.status) {
      hideLoader();
      setReload(!reload);
      setShowSuccessModal(true);
    } else {
      swal('Oops!', res.message, 'error');
    }
    hideLoader();
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Add Bank Account</ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <input
              placeholder="Account Number"
              type="text"
              onChange={(e) => {
                setData({ ...data, accountNumber: e.target.value });
              }}
            />
            <span className="input-detail">input account number</span>
          </div>

          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              value={bankValue}
              onChange={async (e) => {
                const [name, code] = e.target.value.split(',');
                resolveBank(code, name, e.target.value);
              }}
            >
              <option value="Select" disabled selected>
                Select Bank
              </option>
              {availableBank.data.length
                ? availableBank.data.map((item) => (
                    <option
                      value={`${item.name},${item.code}`}
                      key={Math.random()}
                    >
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">please select a Bank</span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Account Name"
              type="text"
              disabled
              value={data.accountName}
            />
            <span className="input-detail">name of account holder</span>
          </div>

          {/* <div className="inputbox-with-one-input toggle-pass-wrapper">
            <ToggleInput
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div> */}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          onClick={handleAddBank}
        >
          Next
        </Button>
      </ModalFooter>
      <SuccessModal
        showModal={showSuccessModal}
        hideModal={setShowSuccessModal}
        id="success-wrapper"
        message="Bank account added successfully"
        hideBankAccountModal={hideModal}
      />
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
