import React from 'react';
import { BsCartPlus } from 'react-icons/bs';
import { Badge } from 'antd';

const CartIconNotification = ({ items, handleClickCartIcon }) => {
  return (
    <Badge count={items?.length} color="#ff6634">
      <BsCartPlus
        size={25}
        style={{ cursor: 'pointer' }}
        onClick={() => handleClickCartIcon()}
      />
    </Badge>
  );
};

export default CartIconNotification;
