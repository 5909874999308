import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './index.scss';

const PollDisclaimer = (props) => {
  const { showModal, hideModal, setShowPoll } = props;
  const closeModal = () => {
    hideModal(false);
    setShowPoll(false);
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="poll-disclaimer"
      centered
    >
      <ModalBody>
        <div className="disclaimer-content">
          <h5>Payment Link</h5>
          <div>
            <p>
              `Any paid vote for will be credited into your 0109073201 Rubies
              Bank (default wallet)`
            </p>
          </div>
        </div>
        <div className="button-group">
          <div className="box border-right" onClick={closeModal}>
            Cancel
          </div>
          <div className="box" onClick={closeModal}>
            Okay
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PollDisclaimer;
