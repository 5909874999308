/* eslint-disable no-unused-expressions */
import React, { useState, useContext, useEffect } from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { SketchPicker } from 'react-color';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';
//  import { ColorPicker, useColor } from 'react-color-palette';
import { createCanvas } from 'canvas';
import {
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
  Tooltip,
} from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { GrCamera, GrEdit } from 'react-icons/gr';
//  import 'react-color-palette/css';
import './imgGrid.scss';
import { useRecoilValue } from 'recoil';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import '../FundWallet/index.scss';
import { customBaseUrl, httpPostFormData } from '../../../../action/http';
import Button from '../../Button';
import { WayagramContext } from '../../../../store/context/WayagramContext';
// import { ImagePreviewOnly } from './postUtils';
import 'cropperjs/dist/cropper.css';
import { VideoIcon } from '../../Icons';
import { userTheme } from '../../../../store/initialState';

/* eslint-disable prefer-destructuring */
const CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

const CreateMoment = (props) => {
  const { showModal, hideModal, id, mode, selectedData } = props;
  const [postData, setPostData] = useState({ ...selectedData, content: '' });
  const [loading, setLoading] = useState(false);
  const [updateCrop, setUpdateCrop] = useState(false);
  const {
    wayagramProfile: profile,
    reloadMoment,
    setReloadMoment,
  } = useContext(WayagramContext);
  const [preview, setPreview] = useState([]);
  const [cropper, setCropper] = useState(undefined);
  const [tooltype, setTooltype] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showTx, setShowTx] = useState(false);
  const [showEmo, setShowEmo] = useState(false);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [txColor, setTxColor] = useState('#000000');
  const [selectedEmoji, setSelectedEmoji] = useState('1f60a');

  // const [mediaType, setMediaType] = useState('');
  const theme = useRecoilValue(userTheme);
  const handleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  // const [cropData, setCropData] = useState('#');

  // const onChangeHandler = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   setPostData({ ...postData, file: files[0] });
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setPreview(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setPostData({ ...postData, file: files[0] });
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader?.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreview(cropper?.getCroppedCanvas()?.toDataURL());
    }
  };

  const handleRotate = (direction) => {
    if (direction === 'right') cropper.rotate(90);
    if (direction === 'left') cropper.rotate(-90);
    setUpdateCrop(!updateCrop);
  };

  const createWayagramMoment = async () => {
    setLoading(true);
    const newForm = new FormData();
    if (!postData.content && !postData.file) {
      setLoading(false);
      swal('Oops!', 'you can not post empty moment', 'error');
      return;
    }
    newForm.append('profile_id', profile.id);
    if (postData.file && preview) {
      const prev = await fetch(preview);
      const blob = await prev.blob();
      const prevFile = new File([blob], postData.file?.name);
      postData.file && newForm.append('file', prevFile);
      postData?.content && newForm.append('content', postData?.content);
      // newForm.append('backgroundColor', bgColor);
      // newForm.append('textColor', txColor);
    }
    if (postData.content && !postData.file) {
      const canvas = createCanvas(512, 512);
      const ctx = canvas.getContext('2d');
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = txColor;
      // ctx.fillText(postData.content, 50, 100);
      CanvasTextWrapper(canvas, postData.content, {
        strokeText: false,
        textAlign: 'center',
        font: '30px Impact',
        verticalAlign: 'middle',
        paddingX: 10,
        paddingY: 10,
      });
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const base64 = canvas.toDataURL('image/png');
      const cavaRes = await fetch(base64);
      const blob = await cavaRes.blob();
      const file = new File([blob], 'converted.png');
      newForm.append('file', file);
    }
    newForm.append('millisecCreatedAt', `${Date.now()}`);
    const res = await httpPostFormData(
      `/all-posts/create-moment`,
      newForm,
      customBaseUrl.wayagramUrl
    );

    if (res?.status) {
      setLoading(false);
      hideModal(false);
      setReloadMoment(!reloadMoment);
      NotificationManager.success(res?.message, 'Done!', 3000);
    } else {
      setLoading(false);
      swal('Oops!', res?.message, 'error');
    }
  };

  useEffect(() => {
    if (cropper) getCropData();
  }, [updateCrop]);

  const addEmo = (emojiData) => {
    setPostData({
      ...postData,
      content:
        postData.content +
        (emojiData.isCustom ? emojiData.unified : emojiData.emoji),
    });
    setShowEmo(false);
    setSelectedEmoji(emojiData.unified);
    console.log(selectedEmoji);
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <Modal isOpen={showBg} toggle={() => setShowBg(false)} centered>
        <SketchPicker
          onChange={(color) => {
            setBgColor(color.hex);
            setShowBg(false);
          }}
          color={bgColor}
          width={600}
        />
      </Modal>
      <Modal isOpen={showTx} toggle={() => setShowTx(false)} centered>
        <SketchPicker
          onChange={(color) => {
            setTxColor(color.hex);
            setShowTx(false);
          }}
          color={txColor}
          width={600}
        />
      </Modal>
      <Modal isOpen={showEmo} toggle={() => setShowEmo(false)} centered>
        <EmojiPicker
          onEmojiClick={addEmo}
          autoFocusSearch={false}
          emojiStyle={EmojiStyle.NATIVE}
          width={600}
          searchPlaceHolder=""
          searchDisabled
        />
      </Modal>
      <ModalHeader className="text-left" toggle={() => hideModal(false)}>
        {mode === 'create' ? 'Create' : 'Edit'} Moments
      </ModalHeader>
      <ModalBody>
        <div className="mx-auto modal-body">
          <div className="image-div d-flex">
            <img src={profile?.avatar || imgPlaceholder} alt="placeholder" />
            <div className="">Whats on your mind ?</div>
          </div>
          <textarea
            style={{ color: txColor, background: bgColor }}
            type="text"
            className="form-control mb-3"
            placeholder="Type Your Moment"
            value={postData.content}
            onChange={(e) =>
              setPostData({ ...postData, content: e.target.value })
            }
          />
          {/* <div className="display-button">
            <button type="submit">Text</button>
            <button type="submit">Gallery</button>
          </div> */}
          {/* <ImagePreviewOnly preview={preview} /> */}
          <div>
            <Cropper
              src={preview}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              checkOrientation={false}
              zoomTo={0.5}
              initialAspectRatio={1}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides
            />
          </div>
          {preview.length ? (
            <div className="d-flex">
              <button
                type="button"
                className="text-light btn btn-secondary mt-2"
                onClick={() => handleRotate('left')}
              >
                left
              </button>
              <button
                type="button"
                className="ml-auto text-light btn btn-secondary mt-2"
                onClick={() => handleRotate('right')}
              >
                right
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mb-3 mt-2 modal-footer">
          <div
            className="add-cursor me-3"
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div className="galler-icon">
              <GrEdit onClick={() => setShowTx(true)} />
            </div>
          </div>
          <div
            className="add-cursor me-3"
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div
              className=""
              onClick={() => setShowBg(true)}
              style={{ width: 30, height: 30, background: bgColor }}
            >
              {' '}
            </div>
          </div>
          <div
            className="add-cursor me-3"
            style={{ position: 'relative', display: 'inline-block' }}
            onClick={() => setShowEmo(true)}
          >
            <Emoji unified="1f60a" size={40} />
          </div>
          <div
            className="me-3 add-cursor "
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div className="galler-icon ">
              <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                id="single"
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <GrCamera />
            </div>
          </div>
        </div>
        <div
          className="add-cursor me-3"
          style={{ position: 'relative', display: 'inline-block' }}
          id="videoPost"
          onMouseEnter={() => {
            setTooltype('video');
          }}
          onMouseLeave={() => {
            setTooltype('');
          }}
        >
          <input
            type="file"
            style={{
              width: '20px',
              opacity: '0',
              position: 'absolute',
              color: 'transparent',
            }}
            className="image-div-input"
            accept="video/*"
            multiple="multiple"
            title=""
            // onChange={(e) => {
            //   setMediaType('VIDEO');
            //   handlePreview(e);
            // }}
          />
          <VideoIcon color={theme?.modalStyle?.color} />
          {tooltype === 'video' && (
            <Tooltip
              key="video"
              placement="bottom"
              isOpen={tooltipOpen}
              target="videoPost"
              toggle={handleTooltip}
            >
              video
            </Tooltip>
          )}
        </div>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary ml-auto mb-3 mt-2"
          color="primary"
          content="Post"
          onClick={createWayagramMoment}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreateMoment.defaultProps = {
  id: '',
  mode: 'create',
  // selectedData: {},
};

CreateMoment.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  mode: PropTypes.string,
  // selectedData: PropTypes.node,
};

export default CreateMoment;
