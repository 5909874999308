import { Button, Flex } from 'antd';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../shared/Icons';
// import { ArrowBack } from '@material-ui/icons';
// import './index.css';
// import MyWallet from '../HerosPage/MyWallet';
// import He from '../HerosPage/index';
// import Layout from '../../../shared/Layout';
// import { Good } from '../../../shared/Icons';

const EmptyWallet = (props) => {
  const { setSHowBanks, walletList } = props;
  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);

  return (
    <div className="max-w-md">
      {/* <ArrowBack onClick={() => setSHowBanks(false)} /> */}
      <div className="relative flex justify-between rounded-md bg-[#fe6533] pl-5 pr-0 pb-0 pt-7 w-full max-w-md">
        <button
          className="absolute font-semibold text-white text-xl bg-[#fe6533]"
          style={{ top: '8px', right: '16px' }}
          type="button"
          onClick={() => setSHowBanks(false)}
        >
          X
        </button>
        <div className="py-3">
          <h3 className="text-gray-100 font-medium mb-3">Bank Transfer</h3>
          <h6 className="text-gray-100 h-30">
            Please utilise the provided information to transfer funds to your
            wayagram wallet using any bank application or internet banking
            services.
          </h6>
        </div>
        {/* <div className="">
          <Good />
        </div> */}
      </div>
      <div className="rounded border px-3 mx-5 py-3">
        <p className="my-2">
          <h6>Bank Name</h6>
          <h5 className="fw-bold">Waya Micro Finance Bank</h5>
        </p>
        <p className="my-2">
          <h6>Bank Account Number</h6>
          <h5 className="fw-bold">{walletList?.wayabank_account_number}</h5>
        </p>
        <p className="my-2">
          <h6>Bank Account Name</h6>
          <h5 className="fw-bold">{walletList?.wayabank_account_name}</h5>
        </p>
        <div className=" mt-4">
          <CopyToClipboard
            text={walletList?.wayabank_account_number}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1000);
            }}
          >
            <div className="d-flex mx-2 my-1">
              <Button
                className="bg-[#fe6533] content-center pb-2"
                icon={<CopyIcon />}
              >
                {copied === false ? 'Copy Number' : 'Copied'}
              </Button>
            </div>
          </CopyToClipboard>
        </div>
      </div>
      <div className="rounded border px-3 mx-5 my-2 py-3">
        <p className="my-2">
          <h6>Bank Name</h6>
          <h5 className="fw-bold">9PSB Bank</h5>
        </p>
        <p className="my-2">
          <h6>Bank Account Number</h6>
          <h5 className="fw-bold">{walletList?.p9psb_account_number}</h5>
        </p>
        <p className="my-2">
          <h6>Bank Account Name</h6>
          <h5 className="fw-bold">{walletList?.p9psb_account_name}</h5>
        </p>
        <div className=" mt-4">
          <CopyToClipboard
            text={walletList?.p9psb_account_number}
            onCopy={() => {
              setCopied2(true);
              setTimeout(() => {
                setCopied2(false);
              }, 1000);
            }}
          >
            <div className="d-flex mx-2 my-1 content-center">
              <Flex gap="small" align="center" wrap>
                <Button
                  className="bg-[#fe6533] content-center pb-2"
                  icon={<CopyIcon />}
                >
                  {copied2 === false ? 'Copy Number' : 'Copied'}
                </Button>
              </Flex>
            </div>
          </CopyToClipboard>
        </div>
      </div>
      {/* <div className="w-full max-w-md p-5">
        <h1 className="font-semibold text-sm">Transaction Details</h1>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">Status</h1>
          <h1 className="font-medium bg-green-200 text-green-700 rounded-full text-[9px] px-2 py-[1px]">
            Transfer Successful
          </h1>
        </div>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">
            Transaction number
          </h1>
          <h1 className="font-semibold text-xs">#902u90h298h289g272</h1>
        </div>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">
            Transaction number
          </h1>
          <h1 className="font-semibold text-xs">#902u90h298h289g272</h1>
        </div>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">
            Transaction number
          </h1>
          <h1 className="font-semibold text-xs">#902u90h298h289g272</h1>
        </div>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">
            Transaction number
          </h1>
          <h1 className="font-semibold text-xs">#902u90h298h289g272</h1>
        </div>
        <div className="flex justify-between w-full mt-1 items-center">
          <h1 className="font-medium text-xs text-gray-400">
            Transaction number
          </h1>
          <h1 className="font-semibold text-xs">#902u90h298h289g272</h1>
        </div>
      </div> */}
    </div>
  );
};

export default EmptyWallet;
