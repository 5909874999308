import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import ussdIcon from '../../../assets/images/ussd-icon.png';

export default function UssdModal(props) {
  const { showModal, hideModal, modalData, ussdCode } = props;

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      className="text-center mt-5 mb-3 h-100"
    >
      <div
        className="p-2 text-center section-a mt-5"
        style={{ height: '200px' }}
      >
        <h3>{modalData?.data.type}</h3>
        <h3 className="mt-3">
          <img src={ussdIcon} alt="ussd" /> USSD Code
        </h3>
        <div className="text-primary p-2 dial-card copy-card mx-auto mt-2 mb-2">
          <a
            className="text-decoration-none"
            href={`tel:${ussdCode} || ${modalData?.data.ussd_code}`}
          >
            {modalData?.message}
          </a>
        </div>
        <div>
          <a
            className="text-decoration-none"
            href={`tel:${ussdCode} || ${modalData?.data.ussd_code}`}
          >
            {modalData?.data.ussd_code}
          </a>
        </div>
      </div>
    </Modal>
  );
}

UssdModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};
