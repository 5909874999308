import React, { useContext, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import RepostDropDown from './RepostDropDown';
import OtherDropdown from '../../Dropdown/OtherDropdown';
import commentIcon from '../../../../assets/image/message-icon.png';
import { objectToGetParams, whatsappLink } from '../../ShareButton';
import truncateWithEllipses from '../../../../utils/truncate';
import { copyIdToClipboard, handleLikePost } from '../../../../utils/helper';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { HeaderContext } from '../../../../store/context/HeaderContext';
import useStore from '../../../../store/zustandStore/store';

const CardBottomContent = ({
  data,
  likes,
  setlikes,
  profileId,
  reload,
  setReload,
  setShowCreateComment,
  setPayDonation,
  userId,
  // setShowPayProduct,
  // setProductType,
  handlePostComment,
  fromLocation,
  commentType,
  type,
  handleBuyNow,
}) => {
  const {
    description,
    commentCount,
    repostCount,
    id,
    kind,
    Donation,
    Product,
    isPoll,
    isRepost,
    Post,
    // PostImages,
    updatedAt,
    isLiked,
    // likeCount,
  } = data;

  const postId =
    fromLocation === 'product'
      ? Post?.id
      : fromLocation === 'profileDonation'
      ? Post?.id
      : id;
  const { setHeaders } = useContext(HeaderContext);
  const { activeTab } = useStore((state) => state);
  const history = useHistory();
  const path = history.location.pathname;
  const { setOpenEditProductModal } = useStore((state) => state);

  const handleSharePost = async (item) => {
    const url = `${
      process.env.REACT_APP_WEBSITE_APP || 'https://staging.wayagram.ng'
    }/post/${postId}?from=${type === 'contest' ? 'cshared' : 'shared'}`;
    setHeaders({
      image:
        'https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG',
      title: description,
      url,
    });
    const shareText = description ? truncateWithEllipses(description, 30) : '';
    if (item === 'whatsapp') {
      window.open(
        // `https://wa.me/?text=${description}%0D%0A${url}`,
        whatsappLink(url, shareText),
        '_blank'
      );
    } else if (item === 'twitter') {
      window.open(
        `https://twitter.com/share/${objectToGetParams({
          url,
          text: shareText,
        })}`,
        '_blank'
      );
    } else if (item === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
          u: url,
          quote: shareText,
          // hashtag: '#wayagram',
          // imageurl:
          //   'https://social-waya-bucket.s3.eu-west-1.amazonaws.com/bts-1651849826823.octet-stream',
          // imageurl: PostImages[0].imageURL,
        })}`,
        '_blank'
      );
    } else if (item === 'mail') {
      window.open(
        `mailto:${objectToGetParams({
          subject: 'share post',
          body: shareText ? `${shareText} Check out this post ${url}` : url,
        })}`
      );
    } else if (item === 'link') {
      //  const d = `${shareText} \n${url}\n`;
      copyIdToClipboard(url, 'Post link copied to clipboard');
    }
    const postData = {
      item_id: postId,
      item_type: isPoll
        ? 'Poll'
        : Donation
        ? 'Donation'
        : Product
        ? 'Product'
        : 'Post',
      shared_to: item,
    };
    await httpPost(
      `all-posts/share-post?profile_id=${profileId}`,
      postData,
      customBaseUrl.wayagramUrl
    );
  };

  const [showSharePost, setShowSharePost] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  // const [likeClicked, setLikeClicked] = useState(isLiked);
  // const [visibleLike, setVisibleLike] = useState(likeCount);
  const [visibleIsLike, setVisibleIsLike] = useState(isLiked);
  const [loading, setLoading] = useState(false);
  // console.log("main", {likeCount, visibleLike})
  //  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handlePostLike = async () => {
    if (!profileId) return NotificationManager.error('Sign in to like a post');
    console.log({
      kind,
      userId,
      profileId,
      path: path.split('/')[1],
    });
    //  await delay(5);
    setLoading(true);
    if (!visibleIsLike) {
      setlikes(Number(likes) + 1);
      setVisibleIsLike(true);
    } else {
      setlikes(Number(likes) - 1);
      setVisibleIsLike(false);
    }
    const resp = await handleLikePost(
      { postId, isLiked: !visibleIsLike, profileId, type: 'user' },
      setReload
      // (res) => {
      //   console.log({ res });
      //   //  setReload(!reload);
      // }
    );

    // console.log({ loading });
    if (resp?.status) {
      //  setReload(!reload);
      setLoading(false);
    } else {
      setLoading(false);
      setVisibleIsLike(isLiked);
      setlikes(likes);
    }
    return {};
  };

  return (
    <div className="bottom-content">
      <div className="bottom-content-container">
        {type !== 'contest' ? (
          <>
            <div className="comment-div">
              <img
                src={commentIcon}
                alt="comment"
                onClick={handlePostComment}
                style={{ width: '1em', height: '1em', cursor: 'pointer' }}
              />
              <span className="ms-2">
                {commentCount || Post?.commentCount || data?.commentCounts}
              </span>
            </div>
            {(likes || likes === 0) && (
              <div
                className="like-div"
                style={{ pointerEvents: loading ? 'none' : '' }}
              >
                <i
                  className={
                    visibleIsLike
                      ? 'mdi mdi-heart text-danger'
                      : 'mdi mdi-heart-outline text_text add-cursor text-secondary'
                  }
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                  onClick={() => {
                    // setLikeClicked(!likeClicked);
                    handlePostLike();

                    /* if (isLiked) setlikes(Number(likes) - 1);
                  if (!isLiked) setlikes(Number(likes) + 1);
                  ; */
                  }}
                />
                <span className="ms-2">{likes} </span>
              </div>
            )}
            <div className="repost-div">
              <RepostDropDown
                isRepost={isRepost}
                postId={postId}
                profileId={profileId}
                setReload={setReload}
                reload={reload}
                commentType={commentType}
                contestId={id}
                setShowRepost={setShowCreateComment}
              />
              <span className={isRepost ? 'ms-2 text-success' : 'ms-2'}>
                {repostCount || Post?.repostCount || data?.repostCounts}
              </span>
            </div>
          </>
        ) : (
          ''
        )}
        <div className="share-div" style={{ position: 'relative' }}>
          {/*  eslint-disable jsx-a11y/click-events-have-key-events  */}
          {type === 'contest' ? (
            new Date().getTime() - new Date(updatedAt).getTime() > 300000 ? (
              <>
                <i
                  className="mdi mdi-share-variant"
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                  onClick={() => {
                    setShowSharePost(!showSharePost);
                    setSelectedPostId(postId);
                  }}
                />
              </>
            ) : (
              ''
            )
          ) : (
            <>
              <i
                className="mdi mdi-share-variant"
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={() => {
                  setShowSharePost(!showSharePost);
                  setSelectedPostId(postId);
                }}
              />
            </>
          )}
          {showSharePost && postId === selectedPostId ? (
            <OtherDropdown
              hide={setShowSharePost}
              options={[
                { name: 'Share to Twitter', type: 'twitter' },
                { name: 'Share to Facebook', type: 'facebook' },
                { name: 'Send in Whatsapp', type: 'whatsapp' },
                { name: 'Send in Mail', type: 'mail' },
                { name: 'Copy link', type: 'link' },
              ]}
              postId={postId}
              handleClick={(item) => handleSharePost(item)}
            />
          ) : (
            ''
          )}
        </div>
        {(kind === 'donation' || fromLocation === 'profileDonation') &&
          userId !== profileId && (
            //  path.split('/')[1] !== 'wayagram-profile' && (
            <div
              className="donation-button"
              onClick={() => setPayDonation(true)}
            >
              Donate
            </div>
          )}
        {(kind === 'product' || fromLocation === 'product') &&
          userId !== profileId &&
          path === '/wayagram' && (
            <div
              className="donation-button"
              style={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => handleBuyNow()}
            >
              Buy product
            </div>
          )}

        {(kind === 'product' || fromLocation === 'product') &&
          path === '/shops' &&
          // userId === profileId &&
          activeTab === 'myShops' && (
            <div
              className="donation-button"
              style={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setOpenEditProductModal(data);
                history.push(`/shops/edit-product/${id}`);
              }}
            >
              Manage Product
            </div>
          )}
      </div>
    </div>
  );
};

export default CardBottomContent;
