import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { GramCard } from './style';
import { WayagramContext } from '../../../store/context/WayagramContext';
import TopCardContent from './GramCards/TopCardContent';
import RepostCardMiddleContent from './GramCards/RepostCardMiddleContent';

export default function GramRepost({ data }) {
  const { wayagramProfile } = useContext(WayagramContext);
  const profileId = wayagramProfile.id;
  const profile = data?.profile || data?.Profile || data.DonatorProfile;
  const history = useHistory();
  const { id } = data;
  const myRef = React.useRef(null);

  return (
    <>
      <GramCard
        ref={myRef}
        className="p-3 mt-3"
        onClick={() => history.push(`/post/${id}`)}
      >
        <TopCardContent profile={profile} postId={id} userId={profileId} />
        <RepostCardMiddleContent data={data} />
      </GramCard>
    </>
  );
}
