import React, { useContext, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useRecoilValue } from 'recoil';
import { Dropdown, Avatar, Badge, Button, Space } from 'antd';
import NotificationDrawer from './notificationDrawer';
import { Notification } from '../Icons';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { resetNotifications } from '../../pages/Wayagram/container';
import { userTheme } from '../../../store/initialState';
import { AppSocket } from '../../../action/socket';

const Actions = () => {
  const {
    notifications,
    wayagramProfile,
    setWayagramReload,
    wayagramReload,
    wayagramProfileId,
  } = useContext(WayagramContext);
  const { data } = notifications || {};
  const [visible, setVisible] = useState(false);
  const theme = useRecoilValue(userTheme);

  const handleOpen = async () => {
    setVisible(true);
    const res = await resetNotifications(wayagramProfileId);
    if (res?.status) setWayagramReload(!wayagramReload);
  };

  return (
    <>
      <Space>
        <Button type="link" onClick={handleOpen}>
          <Badge count={wayagramProfile?.notificationCount || 0} size="small">
            <Notification mode={theme.mode} />
          </Badge>
        </Button>
      </Space>
      <NotificationDrawer
        visible={visible}
        onClose={setVisible}
        data={data || []}
        theme={theme}
      />
    </>
  );
};

export { Actions };

const ProfileMenu = ({ imgPlaceholder }) => {
  const wayaData = localStorage.getItem('wayaUserData');
  const data = wayaData ? JSON?.parse(wayaData)?.user : {};
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  const { avatar, id } = loggedInUser ?? {};
  const { email, isCorporate } = data || {};
  const { referenceCode } = data;
  const history = useHistory();
  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(referenceCode);
    NotificationManager.success(
      'Referral code copied successful',
      'Copied!',
      3000
    );
    // swal('Copied', `MerchantId copied successful`, 'success');
  };
  const logout = async () => {
    await AppSocket.disconnect();
    const from = new URLSearchParams(window?.location.search).get('from');
    const loc = window?.location;
    localStorage.clear();
    if (from === 'shared') {
      const postId = loc?.pathname?.split('/')[2];
      //  console.log({ from, path: loc, postId });
      history.push(`/?r=sharedr&postId=${postId}`);
    } else if (from === 'ncontest') {
      const conId = loc?.pathname?.split('/')[2];
      //  console.log({ from, path: loc, postId });
      history.push(`/?c=contestr&contestid=${conId}`);
    } else if (from === 'ncontest_p') {
      const cemail = loc?.pathname?.split('/')[3];
      const cid = loc?.pathname?.split('/')[4];
      const cpid = loc?.pathname?.split('/')[5];
      //  console.log({ from, path: loc, postId });
      history.push(`/?p=contest_pr&cid=${cid}&cpid=${cpid}&email=${cemail}`);
    } else {
      history.push('/');
    }
  };
  const menu = data ? (
    <div className="user-menu">
      <div className="user-menu-div">
        <Link to={`/wayagram-profile/${id}`}>
          <img src={avatar || imgPlaceholder} alt="default" />
        </Link>
        <Space direction="horizontal">
          <div>
            <p>{`${data?.firstName} ${data?.lastName}`}</p>
            <span>{referenceCode}</span>
            <br />
            {isCorporate && <span>Business Account</span>}
          </div>
          <Button type="link" onClick={copyIdToClipboard}>
            Copy referral code
          </Button>
        </Space>
      </div>
      <div className="user-menu-down">
        <p>Logged in as</p>
        <p>{email}</p>
        <button type="button" onClick={logout}>
          Log Out
        </button>
      </div>
    </div>
  ) : (
    <div className="user-menu">
      <div className="user-menu-down">
        <button type="button" onClick={() => history.push('/login')}>
          Log in
        </button>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="user-dropdown">
        <Badge count={0}>
          <Avatar
            className="avatar"
            shape="circle"
            size="large"
            icon={<UserOutlined />}
            src={avatar || imgPlaceholder}
          />
          {/* <span>{displayName}</span> */}
        </Badge>
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
