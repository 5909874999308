import styled from 'styled-components';
import device from '../../../utils/device';

/*  =================================
NOTIFICATION DROPDROP
================================= */

export const NotificationDropdown = styled.div`
width: 160px;
min-height:80px
padding: 8px;
position: relative;
background: ${(props) => props.theme.transparent};
z-index: 5;
`;

export const NotificationToggle = styled.span``;

export const NotificationMenu = styled.div`
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 30px;
  background: ${(props) => props.theme.white};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  padding: 5px 0 10px;
`;
export const NotificationOptions = styled.article`
  width: 100%;
  height: 64px;
  padding: 8px;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  p {
    margin: 0;
  }
  p.title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1c1c;
  }
  p.content {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => props.theme.subHeader};
  }
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 11px;
    color: ${(props) => props.theme.header};
  }
`;

export const MomentDropdown = styled(NotificationDropdown)`
  width: 30px;
  height: 100%;
  @media ${device.maxmobileL} {
    right: -12px !important;
  }
`;

export const MomentToggle = styled(NotificationToggle)`
  width: 20px;
  text-align: right;
  &:hover {
    color: ${(props) => props.theme.transparent};
    cursor: pointer;
  }
`;

export const MomentMenu = styled(NotificationMenu)`
  width: 200px;
  min-height: 40px;
  box-shadow: 0px 8px 16px #dcdcdc;
  border-radius: 8px;
  overflow: hidden;
  right: ${(props) => props?.right};
  left: ${(props) => props?.left};
  background-color: ${(props) =>
    props.mode === 'light' ? '#f8f8f8' : '#1c1c1c'} !important;
`;

export const MomentOptions = styled(NotificationOptions)`
  height: 32px;
  :hover {
    box-shadow: 0px;
    background-color: ${(props) =>
      props.mode === 'light' ? '#f8f8f8' : '#1c1c1c'} !important;
  }
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) =>
      props.mode === 'light' ? '#000000' : '#ffffff'} !important;
  }
`;

export const OtherDropdownWrapper = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.mode === 'light' ? '#f8f8f8' : '#1c1c1c'} !important;
  color: inherit;
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  position: absolute;
  z-index: 6;
  top: 35px;
  width: 176px;
  left: ${(props) => props.left};
  @media ${device.tablet} {
    left: -50px;
  }
  @media ${device.maxmobileL} {
    left: -87px;
  }
`;
