import React from 'react';

const FormInput = ({
  label,
  type,
  id,
  name,
  value,
  min,
  onChange,
  defaultValue = '',
  placeholder,
  options,
}) => {
  return (
    <div className="quote__form-group">
      <label htmlFor={id}>{label}</label>
      {type === 'select' ? (
        <select
          id={id}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          name={name}
          className="quotes-select"
        >
          {options &&
            options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </select>
      ) : type === 'textarea' ? (
        <textarea
          id={id}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          className="quotes-textarea"
        />
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          min={min}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          className="quotes-input"
        />
      )}
    </div>
  );
};

export default FormInput;
