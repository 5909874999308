import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BuyerStat from './Bid/pages/Stat';
import BidFilterTab from './Bid/pages/BidFilterTab';
import BuyerCloseBids from './Bid/Buyer/BuyerCloseBids';
import BuyerDeliveryStatus from './Bid/Buyer/BuyerDeliveryStatus';
import BuyerTransactions from './Bid/Buyer/BuyerTransactions';
import OpenBidTable from './Bid/pages/BidTable';

const BuyerContent = ({ stats = {}, userDetails, transactionData = [] }) => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);

  const totalOpenBids = stats.totalOpenBids || 0;
  const totalClosedBids = stats.totalClosedBids || 0;
  const totalWonBids = stats.totalWonBids || 0;

  const totalBids = totalOpenBids + totalClosedBids + totalWonBids;

  const calculatePercentage = (value) =>
    totalBids > 0 ? ((value / totalBids) * 100).toFixed(1) : 0;

  const bidStats = [
    {
      title: 'Open Bids',
      value: totalOpenBids,
      percentage: calculatePercentage(totalOpenBids),
    },
    {
      title: 'Closed Bids',
      value: totalClosedBids,
      percentage: calculatePercentage(totalClosedBids),
    },
    {
      title: 'Won Bids',
      value: totalWonBids,
      percentage: calculatePercentage(totalWonBids),
    },
  ];

  return (
    <>
      <div className="bidding__quote__button__class">
        <button
          type="button"
          onClick={() => history.push('/bid/quote')}
          className="bidding__quote__button"
        >
          Get a Quote
        </button>
      </div>
      <div className="bidding__stats">
        {bidStats.map(({ title, value, percentage }) => (
          <BuyerStat
            key={title}
            title={title}
            value={value}
            percentage={`${percentage}%`}
          />
        ))}
      </div>
      <BidFilterTab
        tabs={['Open Bids', 'Closed Bids', 'Delivery Status', 'Transaction']}
        activeIndex={activeIndex}
        onClick={setActiveIndex}
        counts={[
          totalOpenBids,
          totalClosedBids,
          totalWonBids,
          transactionData.length,
        ]}
      />
      {activeIndex === 0 && <OpenBidTable userDetails={userDetails} />}
      {activeIndex === 1 && <BuyerCloseBids userType={userDetails.id} />}
      {activeIndex === 2 && <BuyerDeliveryStatus userType={userDetails.id} />}
      {activeIndex === 3 && (
        <BuyerTransactions userType={userDetails.id} data={transactionData} />
      )}
    </>
  );
};

export default BuyerContent;
