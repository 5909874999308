import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { SortSidebarIcons } from '../Icons';
import { createPostStyle, logoutStyle } from './style';

const MenuList = ({
  item,
  currentPage,
  setShowRetrieveModal,
  setShowPostModal,
  setShowInterestModal,
  history,
  //  wayagramProfileId,
  theme,
}) => {
  const [showDropdown, setShowDropdown] = useState(true);
  const { activeText, type, text, img, url, count } = item;
  const themeColor = theme.mode === 'light' ? 'dark' : 'white';
  const profileId = localStorage.getItem('profileId');

  const redirect = (page, t) => {
    if (t === 'menu') {
      setShowDropdown(!showDropdown);
    }
    if (page === 'create') {
      setShowPostModal(true);
    }
    if (page === 'interests') {
      setShowInterestModal(true);
    }
    if (page === 'retrieve_payment') {
      setShowRetrieveModal(true);
    }
    if (page === 'profile') {
      history.push(`/wayagram-profile/${profileId}`);
    }
    if (page === 'external' || t === 'external') {
      window.location.replace(url);
    }
    if (page === 'logout') {
      localStorage.clear();
      history.push('/');
    }
  };

  return (
    <li>
      <div
        className={
          activeText === 'logout'
            ? 'list'
            : activeText === currentPage
            ? 'sidebar-menu-li-active list'
            : 'sidebar-menu-li list'
        }
      >
        <Link
          to={
            activeText !== 'logout' &&
            activeText !== 'create' &&
            activeText !== 'interests' &&
            activeText !== 'external' &&
            `/${activeText}`
          }
          onClick={() => {
            redirect(activeText, type);
          }}
          className={
            activeText === currentPage
              ? `active text-${themeColor} mid-items-tab no-deco`
              : 'mid-items-tab no-deco'
          }
          style={
            activeText === 'create'
              ? createPostStyle
              : activeText === 'logout'
              ? logoutStyle
              : {
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }
          }
        >
          {activeText === 'logout' ? (
            <img
              src={img}
              alt=""
              style={activeText === 'logout' ? { height: '25px' } : {}}
            />
          ) : (
            <SortSidebarIcons
              name={text === 'Profile' ? 'profile' : activeText}
              active={currentPage}
            />
          )}
          {activeText === 'create' ? (
            <span className="text-white">{text}</span>
          ) : (
            <span
              className={`ms-3 ${
                activeText === currentPage ? '' : `text-${themeColor}`
              }`}
              style={type === 'external' ? { fontSize: '0.9em' } : {}}
            >
              {activeText === 'chat' ? (
                <Badge count={count ?? ''} size="small">
                  <span style={{ fontSize: 18, marginRight: 7 }}>{text} </span>
                </Badge>
              ) : (
                text
              )}
            </span>
          )}
        </Link>
      </div>
    </li>
  );
};

export default MenuList;
