import React, { useContext, useState } from 'react';
// import ReactHashtag from 'react-hashtag';
import { useHistory } from 'react-router';
import { Tagify } from 'react-tagify';
import swal from 'sweetalert';
// import { useMutation, useQueryClient } from 'react-query';
// import { useSetRecoilState } from 'recoil';
// import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';
// import { WalletContext } from '../../../../store/context/WalletContext';
import { NotificationManager } from 'react-notifications';
import { WayagramContext } from '../../../../store/context/WayagramContext';
// import { singlePoll } from '../../../../store/initialState';
import {
  apiModalResponse,
  handleUserPayment,
  handleUserVote,
  postProductToCart,
} from '../../../../utils/helper';
import TransactionStatusModal from '../../Modals/Post/MakeDonation/TransactionStatus';
import PayForProduct from '../../Modals/Post/PayForProduct';
import Share from '../../Modals/share';
import GramRepost from '../GramRepost';
import DonationCard from './DonationCard';
import PaidCard from './PaidCard';
import PollOptionCard from './PollOptionCard';
import PostImageCard from './PostImageCard';
import { PostBody } from '../style';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import useStore from '../../../../store/zustandStore/store';
import { OrderContext } from '../../../../store/context/OrderContext';
import PaymentOptionModal from '../../Modals/PaymentOptionModal';
import { customBaseUrl, httpPost } from '../../../../action/http';

const CardMiddleContent = ({
  data,
  setShowPayProduct,
  showPayProduct,
  productType,
  setProductType,
  fromLocation,
}) => {
  const {
    paidPostProfile,
    Poll,
    PostImages,
    miscellaneous,
    Donation,
    isPaid,
    isPoll,
    description,
    kind,
    Product,
    OriginalOwner,
    isRepostWithComment,
    id,
    PostId,
    ...others
  } = data;
  const { orders } = useContext(OrderContext);
  const { sendProductToCart, setOpenCart } = useStore((state) => state);
  const [openPaymentOptionModal, setOpenPaymentOptionModal] = useState({
    status: false,
    paymentDetails: {},
    orderItem: {},
  });
  // useEffect(() => {
  //   console.log('wayagram-showShareDonationModal', showShareDonationModal);
  // }, [showShareDonationModal]);

  // useEffect(() => {
  //   console.log('wayagram-data', data);
  // }, [data]);
  // const shareURL = process.env.REACT_APP_WEBSITE_APP;

  const { contentFile } = miscellaneous || [];
  delete others.isDeleted;
  delete others.currentState;
  delete others.ProductTrnx;
  delete others.millisecCreatedAt;
  delete others.millisecUpdatedAt;
  delete others.shareCount;
  delete others.updatedAt;
  delete others.Post;
  delete others.no_of_purchase;
  delete others.Profile;
  delete others.v;
  const profile = data?.profile || data?.Profile || data.DonatorProfile;
  // const userId = data.ProfileId;
  const { wayagramProfileId, wayagramProfile } = useContext(WayagramContext);
  const { profile: userProfile } = useContext(ProfileContext);
  const history = useHistory();
  const [showSharePeople, setShowSharePeople] = useState(false);
  const [trxStatusModal, setTrxStatusModal] = useState({
    status: false,
    type: '',
    message: '',
    actionName: '',
    action: null,
  });
  const [vid, setVid] = useState('');

  const handleVote = async (optionId) => {
    const d = {
      // ...others,
      profile_id: wayagramProfileId,
      itemId: optionId,
      ...(isPaid && { amount: Poll.amount }),
      email: profile.email,
      firstName: userProfile.firstName,
      lastName: userProfile.surname,
      phoneNumber: profile.phone,
      paymentType: 'POLL',
      paymentGateway: 'WAYAQUICK',
      authUserId: wayagramProfile?.UserId,
    };
    const res = await handleUserVote(d);
    if (res?.status && isPaid) {
      setShowPayProduct(false);
      window.open(
        res?.data?.authorization_url,
        '_blank',
        'resizable=yes, scrollbars=yes, titlebar=yes, width=600, height=700, top=10, left=10'
      );
    } else {
      apiModalResponse(res, setTrxStatusModal, setShowSharePeople);
    }
  };

  const handlePayProduct = async (_, qty, location) => {
    const d = {
      email: userProfile.email,
      firstName: userProfile.firstName,
      lastName: userProfile.surname,
      phoneNumber: userProfile.phoneNumber,
      profile_id: wayagramProfileId,
      itemId: Product.id,
      paymentType: 'PRODUCT',
      paymentGateway: 'WAYAQUICK',
      description: data.description,
      amount: Product.amount * qty,
      ...location,
      qty,
    };

    const res = await handleUserPayment(d);
    if (res?.status) {
      setShowPayProduct(false);
      window.open(
        res?.data?.authorization_url,
        '_blank',
        'resizable=yes, scrollbars=yes, titlebar=yes, width=600, height=700, top=10, left=10'
      );
    } else {
      apiModalResponse(res, setTrxStatusModal, setShowSharePeople);
    }
  };

  const handleSubmit = (account, wNo, pin) => {
    if (productType?.type === 'vote') handleVote(productType?.id, account, pin);
    else handlePayProduct(account, wNo, pin);
  };

  const handleClickVote = (pid) => {
    const notPaid = Poll ? !Poll?.isPaid : !data.isPaid;
    setProductType({ type: 'vote', id: pid });
    if (notPaid) handleVote(pid);
    else {
      setVid(pid);
      setOpenPaymentOptionModal({
        status: true,
        paymentDetails: {
          totalAmount: Poll?.amount,
          email: Poll?.description,
          type: 'poll',
        },
        orderItem: Poll,
      });
      // setShowPayProduct(true);
    }
  };

  const handleNavigate = (text, type) => {
    // console.log({ type, text });
    if (type === 'tag' || type === 'mention') {
      history.push({
        pathname: '/wayagram-discover',
        from: 'wayagram',
        data: text,
        state: { activeTab: type === 'tag' ? 'Posts' : 'People' },
      });
    } else {
      history.push(`/post/${PostId || id}`);
    }
  };

  const handleAddProductToCart = async (qty = 1) => {
    const isUserLoggedIn = localStorage.getItem('wayaProfileId');
    if (!isUserLoggedIn) {
      NotificationManager.error(
        'You need to login to add to cart',
        'Error',
        3000
      );

      return;
    }
    const pendingOrderExist = orders.find(
      (order) => order?.sellerProfileId === data?.Product?.ProfileId
    );
    if (pendingOrderExist && pendingOrderExist.paymentStatus === 'PENDING') {
      swal(
        'Info',
        'You have a pending order with this seller. Kindly delete or pay for the order before adding new product from this seller to cart'
      );
      return;
    }
    const d = {
      profileId: wayagramProfileId,
      productId: data.Product.id,
      quantity: qty,
    };

    const res = await postProductToCart(d, wayagramProfileId);
    if (res?.status) {
      // sendProductToCart({ ...res?.data, Product });
      // setShowAddToCart(false);
      const updatedCart = {
        CartProduct: {
          cartId: res?.data?.cart?.id,
          productId: res?.data?.cart?.productDetails?.id,
          quantity: 1,
        },
        ...res?.data?.cart?.productDetails,
        sellerDetails: res?.data?.cart?.sellerDetails,
      };
      sendProductToCart(updatedCart);
      NotificationManager.success(
        'Product added to cart successfully',
        'Success',
        3000
      );
    } else {
      NotificationManager.error(res?.message, 'Error', 3000);
    }
  };

  const handleBuyNow = () => {
    const isUserLoggedIn = localStorage.getItem('wayaProfileId');
    if (!isUserLoggedIn) {
      NotificationManager.error(
        'You need to login to add to cart',
        'Error',
        3000
      );

      return;
    }
    handleAddProductToCart(1);
    setOpenCart(true);
    history.push('/shops');
  };
  const handleCheckOut = async (paymentGateway) => {
    if (!wayagramProfile) {
      NotificationManager.error('Please login to continue', 'Error', 3000);
      return;
    }

    // if (openPaymentOptionModal?.orderItem.totalAmount === 0) {
    //   NotificationManager.error('Amount cannot be zero or less', 'Error', 3000);
    //   setDisablePayBtn(false);
    //   return;
    // }
    try {
      const voterProfileId = wayagramProfile?.id;
      const postData = {
        voterProfileId,
        paymentGateway,
        authUserId: wayagramProfile?.UserId,
        paymentType: 'POLL',
        profile_id: wayagramProfile?.id,
        itemId: vid,
        firstName: wayagramProfile?.displayName?.split(' ')[0],
        lastName: wayagramProfile?.displayName?.split(' ')[1],
        email: wayagramProfile?.email,
        phoneNumber: wayagramProfile?.phone,
        amount: Poll?.amount,
      };

      httpPost(
        `/all-posts/init-payment`,
        postData,
        customBaseUrl?.wayagramUrl
      ).then((res) => {
        // const voteCallback = {
        //   url: `/wayagram-profile/contest/${contestantData?.email}/${contestId}/${contestantId}?from=vote`,
        //   ref: res?.data?.tranId,
        // };
        if (res.status && isPaid) {
          //  localStorage.setItem('votecallback', JSON.stringify(voteCallback));
          window.open(`${res?.data?.authorization_url}`, '_self');
        }
      });
    } catch (error) {
      NotificationManager.error(error.message, 'Error', 3000);
    }
  };
  return (
    <div className="middle-content">
      <div className="middle-content-description">
        {kind === 'donation' || fromLocation === 'profileDonation' ? (
          <div>
            <DonationCard
              Donation={Donation || data}
              description={description}
              profile={profile}
              page={fromLocation}
              handleNavigate={handleNavigate}
            />
          </div>
        ) : (
          <div
            className="mb-3 mt-3 add-cursor hashtag"
            //  onClick={() => handleNavigate()}
          >
            <PostBody>
              {/* <ReactHashtag
                onHashtagClick={(v) => {
                  history.push({
                    pathname: '/wayagram-discover',
                    from: 'wayagram',
                    data: v,
                  });
                }}
              >
                {description || ''}
              </ReactHashtag> */}
              {/* <div style={{ marginLeft: '3rem' }}> */}
              <Tagify onClick={(text, type) => handleNavigate(text, type)}>
                {description || ''}
              </Tagify>
              {/* </div> */}
            </PostBody>
            {OriginalOwner && isRepostWithComment && (
              <GramRepost data={OriginalOwner} />
            )}
          </div>
        )}
      </div>

      <div onClick={() => handleNavigate()} style={{ marginBottom: '2rem' }}>
        {PostImages?.length && PostImages.length < 2 ? (
          <div className="img-div">
            {PostImages[0]?.imageURL.includes('mp4') ||
            PostImages[0]?.imageURL.includes('webm') ||
            PostImages[0]?.imageURL.includes('wmv') ? (
              <div
                style={{
                  height: '100%',
                  maxWidth: '32rem',
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <video
                  controls
                  autoPlay
                  muted
                  loop
                  width="80%"
                  height="auto"
                  className="video-post"
                  playsInline
                  style={{
                    objectFit: 'fill',
                  }}
                >
                  <source src={PostImages[0]?.imageURL} type="video/webm" />
                  <source src={PostImages[0]?.imageURL} type="video/mp4" />
                  <source src={PostImages[0]?.imageURL} type="video/wmv" />
                  Your browser does not support the video tag.
                  <track
                    // src="captions_en.vtt"
                    kind="captions"
                    srcLang="en"
                    label="english_captions"
                  />
                </video>
              </div>
            ) : (
              <div>
                <a
                  href={PostImages[0]?.imageURL}
                  rel="noopener noreferrer"
                  download
                  alt="post-image"
                >
                  <i className="mdi mdi-download dowload-icon" />
                </a>
                <img src={PostImages[0]?.imageURL} alt="pic" />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div style={{ marginBottom: '2rem' }}>
        {contentFile?.length && contentFile.length < 2 ? (
          <div className="img-div">
            <a href={contentFile[0]} rel="noopener noreferrer">
              <i className="mdi mdi-download dowload-icon" alt="uploaded-pix" />
            </a>
            {contentFile[0]?.includes('mp4') ||
            contentFile[0]?.includes('webm') ||
            contentFile[0]?.includes('wmv') ? (
              <div
                style={{
                  height: '100%',
                  maxWidth: '32rem',
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <video
                  controls
                  autoPlay
                  muted
                  loop
                  width="80%"
                  height="auto"
                  className="video-post"
                  playsInline
                  style={{
                    objectFit: 'fill',
                  }}
                >
                  <source src={contentFile[0]} type="video/webm" />
                  <source src={contentFile[0]} type="video/mp4" />
                  <source src={contentFile[0]} type="video/wmv" />
                  Your browser does not support the video tag.
                  <track
                    // src="captions_en.vtt"
                    kind="captions"
                    srcLang="en"
                    label="english_captions"
                  />
                </video>
              </div>
            ) : (
              <img src={contentFile[0]} alt="pic" />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      {PostImages?.length > 1 && (
        <div style={{ marginBottom: '2rem' }}>
          <PostImageCard
            PostImages={PostImages}
            paidPostProfile={paidPostProfile}
          />
        </div>
      )}
      {(isPaid || kind === 'product') && (
        <PaidCard
          PostImages={Product?.ProductImages || PostImages}
          data={Poll || Donation || data || Product}
          Product={Product}
          handleBuyNow={handleBuyNow}
          handleAddToCart={handleAddProductToCart}
        />
      )}

      {/* {showAddToCart ? (
        <AddProductToCart
          hideModal={setShowAddToCart}
          showModal={showAddToCart}
          Product={Product}
          handleAddProductToCart={handleAddProductToCart}
        />
      ) : (
        ''
      )} */}

      {(isPoll || fromLocation === 'profilePoll') && (
        <>
          <PollOptionCard
            Poll={Poll || data}
            handleClickVote={handleClickVote}
            wayagramProfileId={wayagramProfileId}
            type="Post"
          />
        </>
      )}
      {showPayProduct ? (
        <PayForProduct
          handleSubmit={handleSubmit}
          values={isPoll ? data : Product}
          showModal={showPayProduct}
          hideModal={setShowPayProduct}
          type={productType?.type}
          trxStatusModal={trxStatusModal}
          setTrxStatusModal={setTrxStatusModal}
          isPaid
        />
      ) : (
        ''
      )}
      {trxStatusModal?.status ? (
        <TransactionStatusModal
          showModal={trxStatusModal}
          hideModal={setTrxStatusModal}
        />
      ) : (
        ''
      )}
      {showSharePeople ? (
        <Share
          showModal={showSharePeople}
          hideModal={setShowSharePeople}
          title="Share"
          productType={productType}
          description={`Share this ${
            productType?.type === 'vote' ? 'polls' : 'products'
          } with your friends`}
          url={`${process.env.REACT_APP_WEBSITE_APP}/post/${
            Product?.id || Poll?.id
          }`}
          text={
            productType?.type === 'vote'
              ? `${wayagramProfile?.username} voted on a poll: ${description}, and he recommends you take part. Please click the link below for more information about this poll.`
              : description
          }
        />
      ) : (
        ''
      )}
      {openPaymentOptionModal.status && (
        <PaymentOptionModal
          openPaymentOptionModal={openPaymentOptionModal}
          setOpenPaymentOptionModal={setOpenPaymentOptionModal}
          handlePaymentWithOption={handleCheckOut}
        />
      )}
    </div>
  );
};

export default CardMiddleContent;
