import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import mentionSearch, { HashSearch } from './mentionSearch';
import defaultStyle from './style';

export default function MentionInput(props) {
  const { text = '', setText, placeholder, setHashTagList } = props;
  // const [searchResult, setSearchResult] = useState('');

  return (
    <div>
      <MentionsInput
        value={text}
        onChange={async (e, newValue, newPlainTextValue) => {
          // console.log(newPlainTextValue, newValue);
          setText(newPlainTextValue);
          const regexp = /\B#\w\w+\b/g;
          setHashTagList(newPlainTextValue?.match(regexp));
        }}
        style={defaultStyle}
        placeholder={placeholder}
      >
        <Mention
          trigger="@"
          displayTransform={(id, display) => `@${display}`}
          data={mentionSearch}
        />
        <Mention
          trigger="#"
          //  displayTransform={(id, display) => `${display}`}
          renderSuggestion={(display, focused) => (
            <div className={`user ${focused ? 'focused' : ''}`}>
              {display.display}
              <br />
              {display?.count} Conversations
            </div>
          )}
          data={HashSearch}
        />
      </MentionsInput>
    </div>
  );
}
