import React from 'react';
import moment from 'moment';

const VendorBidTable = ({ bids }) => {
  // const {bids} = bids.data
  if (bids.length === 0) {
    return <div>No open bids yet.</div>;
  }
  return (
    <table className="bidding-list-table">
      <thead className="bidding-list-thead">
        <tr className="bidding-list-header-row">
          <th className="bidding-list-header-cell">Buyer Name</th>
          <th className="bidding-list-header-cell">
            Item Description / Quantity
          </th>
          <th className="bidding-list-header-cell">Delivery Location</th>
          <th className="bidding-list-header-cell">Date Opened</th>
        </tr>
      </thead>
      <tbody className="bidding-list-tbody">
        {bids.map((item) => (
          <tr key={item.bidID} className="bidding-list-row">
            <td className="bidding-list-cell">{item.vendorName}</td>
            <td className="bidding-list-cell">
              {item.quote.description} / {item.quote.quantity}
            </td>
            <td className="bidding-list-cell">
              {item.quote.deliveryAddress.number}{' '}
              {item.quote.deliveryAddress.street}{' '}
              {item.quote.deliveryAddress.town}{' '}
              {item.quote.deliveryAddress.state}
            </td>
            <td className="bidding-list-cell">
              {moment(item.biddingStart).format('MMMM Do YYYY, h:mm:ss a')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VendorBidTable;
