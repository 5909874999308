import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from 'reactstrap';
import { Add } from '@material-ui/icons';
import axios from 'axios';
import {
  customBaseUrl,
  httpPost,
  httpPostFormData,
  httpPut,
} from '../../../../action/http';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import { userTheme } from '../../../../store/initialState';
import Button from '../../Button';
import MentionInput from '../../CustomInput/MentionInput';
import { GifIcon, JpgIcon, VideoIcon } from '../../Icons';
import '../FundWallet/index.scss';
import TermsAndCondition from '../Terms/TermsAndCondition';
import './imgGrid.scss';
import { PaidPostPreview } from './postUtils';
import { LayoutContext } from '../../../../store/context/LayoutContext';

const CreatePost = (props) => {
  const { showModal, hideModal, id, mode, selectedData, type, PostTags } =
    props;
  const { id: pid, contestantId: cid } = useParams();
  const theme = useRecoilValue(userTheme);
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltype, setTooltype] = useState('');
  const {
    wayagramProfile: profile,
    reload,
    setReload,
  } = useContext(WayagramContext);
  const { setProgress, setShowProgress } = useContext(LayoutContext);
  const selImg = selectedData?.postImages ? selectedData?.postImages : [];
  const [preview, setPreview] = useState(selImg);
  const [images, setImages] = useState([]);
  const [showPreview, setShowPreview] = useState(mode === 'edit');
  const [mentionList, setMentionList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);
  const [showEmo, setShowEmo] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('1f60a');

  const handleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handlePreview = (e) => {
    const { files } = e.target;
    const imgFiles = [...images, ...files];
    if (imgFiles.length > 10) {
      swal('Oops!', `Upload exceeded limit of 10`, 'error');
      return;
    }
    if (imgFiles.length) {
      setShowPreview(true);
      setImages(imgFiles);
      const imgArr = [];
      /* eslint-disable no-plusplus */
      for (let i = 0; i < imgFiles.length; i++) {
        const reader = new FileReader();
        /* eslint-disable  no-loop-func */
        // reader.readAsDataURL(imgFiles[i]);
        reader.onloadend = () => {
          imgArr.push({ type: imgFiles[0]?.type, imageUrl: reader.result });
          if (imgFiles.length - 1 === i) {
            setPreview(imgArr);
          }
        };
        reader.readAsDataURL(imgFiles[i]);
      }
    } else {
      console.log('no files');
    }
  };

  const addImagesToPosts = async (newFormOpt) => {
    //  await httpPut('/all-posts/update', newFormOpt, customBaseUrl.wayagramUrl);
    setShowProgress(true);
    axios
      .put(`${customBaseUrl.wayagramUrl}/all-posts/update`, newFormOpt, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
        headers: {
          Authorization: `${localStorage.token}`,
          'Content-Type': 'multipart/form-data',
          'CLIENT-TYPE': localStorage.getItem('TYPE') ?? 'PERSONAL',
          'CLIENT-ID': 'WAYAGRAM',
        },
      })
      .then(async (fRes) => {
        if (fRes?.data?.status) {
          setShowProgress(false);
          setReload(!reload);
        } else {
          setShowProgress(false);
          NotificationManager.error(
            fRes?.data?.message ?? 'Something went wrong with media upload',
            'Error!',
            3000
          );
          // swal(
          //   'Oops!',
          //   `${
          //     fRes?.data?.message ?? 'Something went wrong with media upload'
          //   }`,
          //   'error'
          // );
        }
      })
      .catch((err) => {
        setShowProgress(false);
        NotificationManager.error(
          err?.message ?? 'Something went wrong with media upload',
          'Error!',
          3000
        );
      })
      .finally(() => {
        setShowProgress(false);
      });
    // setInterval(() => {
    //   setReload(!reload);
    // }, 3000);
    setTimeout(() => setReload(!reload), 10000);
  };

  const createWayagramPost = async () => {
    setLoading(true);
    const deletedTags = [];
    PostTags?.forEach((tag) => {
      if (!hashTagList?.includes(tag?.HashTag?.hashtag))
        deletedTags.push(tag?.HashTag?.hashtag);
    });
    let isPaid = false;
    /* eslint-disable no-unused-expressions */
    postData.isPaid ? (isPaid = true) : (isPaid = false);
    const url = `/all-posts/${mode === 'create' ? 'create' : 'update'}`;
    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    const file = Object.values(images);
    newForm.append('profile_id', profile.id);
    file.length && newForm.append('media_type', mediaType);
    pid && type === 'page' && newForm.append('page_id', pid);
    pid && type === 'group' && newForm.append('group_id', pid);
    mode === 'create' && newForm.append('type', type);
    mode === 'edit' && newForm.append('post_id', postData?.postId);
    newForm.append('description', postData.description ?? ' ');
    isPaid === 'paidPost' &&
      newForm.append('amount', postData?.amount?.replace(',', ''));
    mentionList?.length && newForm.append('mentions', mentionList);
    if (mode === 'create' && hashTagList?.length)
      newForm.append('hashtags', hashTagList);
    if (mode === 'edit') newForm.append('deletedHashtags', deletedTags);
    if (mode === 'edit') newForm.append('addedHashtags', hashTagList);
    let res = {};
    if (mode === 'create') {
      res = await httpPost(url, newForm, customBaseUrl.wayagramUrl);
    } else {
      res = await httpPut(url, newForm, customBaseUrl.wayagramUrl);
    }
    if (res?.status) {
      if (file.length > 0) {
        file?.forEach(async (img) => {
          const newFormOpt = new FormData();
          newFormOpt.append('images', img);
          newFormOpt.append('profile_id', profile.id);
          newFormOpt.append('post_id', res?.data?.id);
          await addImagesToPosts(newFormOpt);
        });
      }
      setLoading(false);
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideModal(false);
      setReload(!reload);
    } else {
      setLoading(false);
      NotificationManager.error(res?.message, 'Oops!', 3000);
    }
  };

  const createContestPost = async () => {
    setLoading(true);
    /* eslint-disable no-unused-expressions */
    const url = `/contests/upload-content`;
    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    const file = Object.values(images);
    newForm.append('contentFile', file[0]);
    newForm.append('contestantId', cid);
    newForm.append('profileId', profile.id);
    postData.description && newForm.append('description', postData.description);
    let res = {};
    res = await httpPostFormData(url, newForm, customBaseUrl.contestUrl);
    if (res?.status) {
      // if (file.length > 0) {
      //   file?.forEach(async (img) => {
      //     const newFormOpt = new FormData();
      //     newFormOpt.append('images', img);
      //     newFormOpt.append('profile_id', profile.id);
      //     newFormOpt.append('post_id', res?.data?.id);
      //     await addImagesToPosts(newFormOpt);
      //   });
      // }
      setLoading(false);
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideModal(false);
      setReload(!reload);
    } else {
      setLoading(false);
      NotificationManager.error(res?.message, 'Oops!', 3000);
    }
  };

  const handleChange = (query) => {
    setPostData({ ...postData, description: query });
  };
  const addEmo = (emojiData) => {
    setPostData({
      ...postData,
      description: `${postData.description ?? ''}  ${
        emojiData.isCustom ? emojiData.unified : emojiData.emoji
      }`,
    });
    setShowEmo(false);
    setSelectedEmoji(emojiData.unified);
    console.log(selectedEmoji);
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader
        style={theme.modalStyle}
        className="text-left"
        toggle={() => hideModal(false)}
      >
        <span style={theme.modalStyle}>
          {type === 'contest'
            ? 'Upload Contest Video'
            : mode === 'create'
            ? 'Create Post'
            : 'Edit Post'}
        </span>
      </ModalHeader>
      <ModalBody style={theme.modalStyle}>
        <Modal isOpen={showEmo} toggle={() => setShowEmo(false)} centered>
          <EmojiPicker
            onEmojiClick={addEmo}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
            width={600}
            searchPlaceHolder=""
            searchDisabled
          />
        </Modal>
        <form className="mx-auto">
          <div className="d-flex">
            <div className="image-div">
              <img src={profile?.avatar || imgPlaceholder} alt="placeholder" />
            </div>
            <MentionInput
              setText={handleChange}
              text={postData.description}
              setMentionList={setMentionList}
              setHashTagList={setHashTagList}
              placeholder="What's on your mind?"
            />
          </div>
          {showPreview ? (
            <PaidPostPreview
              preview={preview}
              setShowPreview={setShowPreview}
              setPreview={setPreview}
              images={images}
              setImages={setImages}
              showPreview={showPreview}
              theme={theme}
            />
          ) : (
            ''
          )}
        </form>
      </ModalBody>
      <ModalFooter style={theme.modalStyle}>
        <div
          className="mb-3 mt-2"
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              id="imagePost"
              onMouseEnter={() => {
                setTooltype('image');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              <input
                type="file"
                style={{
                  width: '20px',
                  opacity: '0',
                  position: 'absolute',
                  color: 'transparent',
                }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => {
                  handlePreview(e);
                  setMediaType('IMAGE');
                }}
                title=""
              />
              {type !== 'contest' ? (
                images?.length < 1 ? (
                  <JpgIcon color={theme?.modalStyle?.color} />
                ) : images?.length < 4 ? (
                  type !== 'contest' ? (
                    <>
                      <Add />
                      Add Photo
                    </>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {tooltype === 'image' && (
                <Tooltip
                  key="image"
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target="imagePost"
                  toggle={handleTooltip}
                >
                  image
                </Tooltip>
              )}
            </div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              onClick={() => setShowEmo(true)}
            >
              <Emoji unified="1f60a" size={30} />
            </div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              id="gifPost"
              onMouseEnter={() => {
                setTooltype('gif');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              <input
                type="file"
                style={{
                  width: '20px',
                  opacity: '0',
                  position: 'absolute',
                  color: 'transparent',
                }}
                className="image-div-input"
                accept="image/gif"
                multiple="multiple"
                // onClick={() => setPostData({ ...postData, isPaid: true })}
                onChange={(e) => {
                  handlePreview(e);
                  setMediaType('VIDEO');
                }}
                title=""
              />
              <GifIcon color={theme?.modalStyle?.color} />
              {tooltype === 'gif' && (
                <Tooltip
                  key="gif"
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target="gifPost"
                  toggle={handleTooltip}
                >
                  gif
                </Tooltip>
              )}
            </div>
          </div>
          <div
            className="add-cursor me-3"
            style={{ position: 'relative', display: 'inline-block' }}
            id="videoPost"
            onMouseEnter={() => {
              setTooltype('video');
            }}
            onMouseLeave={() => {
              setTooltype('');
            }}
          >
            <input
              type="file"
              style={{
                width: '20px',
                opacity: '0',
                position: 'absolute',
                color: 'transparent',
              }}
              className="image-div-input"
              accept="video/*"
              multiple="multiple"
              title=""
              onChange={(e) => {
                setMediaType('VIDEO');
                handlePreview(e);
              }}
            />
            <VideoIcon color={theme?.modalStyle?.color} />
            {tooltype === 'video' && (
              <Tooltip
                key="video"
                placement="bottom"
                isOpen={tooltipOpen}
                target="videoPost"
                toggle={handleTooltip}
              >
                video
              </Tooltip>
            )}
          </div>
        </div>
        <Button
          loading={loading}
          disabled={
            loading || type === 'contest'
              ? isEmpty(postData?.description) || !images.length
              : (isEmpty(postData?.description) && !images.length) || false
          }
          style={{ width: 100 }}
          className="btn btn-primary"
          color="primary"
          content="Post"
          onClick={type === 'contest' ? createContestPost : createWayagramPost}
        >
          Next
        </Button>
      </ModalFooter>
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

CreatePost.defaultProps = {
  id: '',
  mode: 'create',
  // selectedData: {},
};

CreatePost.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  mode: PropTypes.string,
  // handleSubmit: PropTypes.func.isRequired,
  // selectedData: PropTypes.node,
};

export default CreatePost;
