import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import Layout from '../../../shared/Layout';
import '../index';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import {
  customBaseUrl,
  httpGet,
  httpPatch,
  httpPost,
} from '../../../../action/http';
import AntDropdown from '../../../shared/Dropdown/antDropdown';
import ContestantDetails from './contestantDetails';
import { hideLoader, showLoader } from '../../../../utils/loader';

const ManageContestants = (props) => {
  const history = useHistory();
  const { profileId, contestId } = useParams();
  //    const [showSharePost, setShowSharePost] = useState(false);
  const [candidates, setCandidtes] = useState([]);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [reason, setReason] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showMore, setShowMore] = useState(false);

  const handleGetContestDetails = async () => {
    // const search = `all-posts/search-products?query=${value}`;
    const uri = `contests/contestants?contestId=${contestId}`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      const contest = res?.data;
      const rank = contest?.sort((a, b) => {
        const fa = a?.rank?.position;
        const fb = b?.rank?.position;

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setCandidtes(rank);
      //  setLoading(false);
    }
  };

  const handleAcceptReject = async (action, d, r) => {
    showLoader();
    const dat = {
      action,
      profileId,
      contestantIds: [d?.id],
      reason: r,
    };
    console.log({ dat });
    const res = await httpPost(
      `/contests/decide-contestants`,
      dat,
      customBaseUrl.contestUrl
    );
    if (res?.status) {
      hideLoader();
      swal('Successful', res?.message);
      console.log({ res });
      setReason('');
      setShowDeclineModal(false);
      setShowMore(false);
      handleGetContestDetails();
    } else {
      hideLoader();
      swal('Oops!', res?.message);
      console.log('erroe', res);
      setReason('');
    }
  };
  const handleDelEnab = async (action, d) => {
    showLoader();
    const dat = {
      participationStatus: action,
      profileId,
      contestId: d.contestId,
      contestantId: d?.id,
    };
    console.log({ dat });
    const res = await httpPatch(
      `/contests/update-contestant-participation`,
      dat,
      customBaseUrl.contestUrl
    );
    if (res?.status) {
      hideLoader();
      swal('Successful', res?.message);
      console.log({ res });
      setShowDeclineModal(false);
      setShowMore(false);
      handleGetContestDetails();
    } else {
      hideLoader();
      swal('Oops!', res?.message);
      console.log('erroe', res);
      setReason('');
    }
  };
  const handleDecline = async () => {
    if (!reason) return swal('Oops!', 'Reason for can not be blank');
    handleAcceptReject('DECLINE', selectedData, reason);
    setShowDeclineModal(false);
    return true;
  };
  const handleDropdownAction = (item, row) => {
    if (item === 'View Contestant') {
      history.push(
        `/wayagram-profile/contest/${row?.email}/${row?.contestId}/${row?.id}`
      );
      console.log({ item, row });
    } else if (item === 'Accept') {
      handleAcceptReject('APPROVE', row);
    } else if (item === 'Decline') {
      setSelectedData(row);
      setShowDeclineModal(true);
    } else if (item === 'View More') {
      setSelectedData(row);
      setShowMore(true);
    } else if (item === 'Enable') {
      handleDelEnab('ENABLED', row);
    } else if (item === 'Disable') {
      handleDelEnab('DISABLED', row);
    } else if (item === 'Delete') {
      handleDelEnab('DELETED', row);
    }
  };
  const columns = React.useMemo(() => [
    {
      field: 'rank',
      headerName: 'Vote Position',
      width: 100,
      editable: false,
      //  headerClassName: styles.tableHeader,
      renderCell: ({ row }) => row?.rank?.position ?? 'N/A',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'affiliationName',
      headerName: 'Institution',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'voteCounts',
      headerName: 'No of Vote',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'createdAt',
      headerName: 'Date Joined',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
      renderCell: ({ row }) => moment(row?.createdAt).format('Do MMMM YYYY'),
    },
    {
      field: 'participationStatus',
      headerName: 'Participation Status',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
    },
    {
      field: 'status',
      headerName: 'Contest Status',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <span style={{ color: row?.status === 'ACTIVE' ? 'green' : 'red' }}>
          {row.status}
        </span>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      editable: false,
      //  headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={[
            'View Contestant',
            'View More',
            row?.status === 'PENDING' && 'Accept',
            row?.status === 'PENDING' && 'Decline',
            'Enable',
            'Disable',
            'Delete',
          ]}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          isArrow
        />
      ),
    },
  ]);
  useEffect(() => {
    console.log({ profileId, contestId });
    handleGetContestDetails();
  }, []);
  return (
    <Layout route={props} currentPage="contest" pageName="" showName>
      {!showMore ? (
        <>
          <div
            className="mt-4 mb-3 mx-auto search-container d-flex justify-content-between"
            style={{ width: '90%' }}
          >
            <input
              className="w-50 form-control search-input"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <Modal
            isOpen={showDeclineModal}
            toggle={() => {
              setShowDeclineModal(false);
              // setPreview([]);
            }}
            centered
          >
            <ModalHeader
              className="text-center"
              toggle={() => setShowDeclineModal(false)}
            >
              <span>Reason for Declining</span>
            </ModalHeader>
            <div style={{ margin: 10, width: '100%', textAlign: 'center' }}>
              <textarea
                onChange={(e) => setReason(e.target.value)}
                className="px-3 border w-75"
                value={reason}
              />
            </div>
            <button
              className="btn btn-primary px-2 py-3"
              type="button"
              onClick={handleDecline}
            >
              Continue
            </button>
          </Modal>
          {candidates.length > 0 ? (
            <DataGrid
              //  className={styles.table}
              rows={
                searchText
                  ? candidates?.filter(
                      (e) =>
                        e?.firstName
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase()) ||
                        e?.lastName
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase()) ||
                        e?.email
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase())
                    )
                  : candidates
              }
              density="comfortable"
              columns={columns}
              // page={pagination.current}
              // paginationMode="server"
              // pageSize={pagination.pageSize}
              // onPageSizeChange={(newPageSize) =>
              //   handleChangeRowsPerPage(newPageSize)
              // }
              // onPageChange={(newPage) => handleChangePage(newPage)}
              // rowsPerPageOptions={[5, 10, 20, 50, 100]}
              // pagination
              //  components={{ Toolbar: GridToolbar }}
              //  rowCount={metaData}
            />
          ) : (
            <div>No Record Found</div>
          )}
        </>
      ) : (
        <ContestantDetails
          setShowMore={setShowMore}
          data={selectedData}
          setShowDeclineModal={setShowDeclineModal}
          reason={reason}
          setReason={setReason}
          handleDecline={handleDecline}
          handleAcceptReject={handleAcceptReject}
          isAdmin
        />
      )}
    </Layout>
  );
};

export default ManageContestants;
