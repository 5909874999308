// import { Form, Formik } from 'formik';
// import { isEmpty } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
// import {
//   getCountryCallingCode,
//   parsePhoneNumber,
// } from 'react-phone-number-input';
//  import { Form } from 'formik';
//  import { Select } from 'antd';
import 'react-phone-number-input/style.css';
import 'react-responsive-modal/styles.css';
import NaijaStates from 'naija-state-local-government';
//  import nguniversities from 'ng_universities';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
//  import { Link } from 'react-router-dom';
//  import { toast } from 'react-toastify';
import { Progress, notification } from 'antd';
//  import DatePicker from 'react-datepicker';
import { Cancel } from '@mui/icons-material';
import { Close } from '@material-ui/icons';
// import moment from 'moment';
//  import { Upload } from '@mui/icons-material';
//  import { Container } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Modal } from 'reactstrap';
// import Swal from 'sweetalert2';
//  import validator from 'validator';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  //  httpPostFormData,
} from '../../../action/http';
import Bg from '../../../assets/image/BG.png';
import Logo from '../../../assets/logo.svg';
//  import { getBusinessTypes } from '../../../store/calls';
// import { handleSendOpenOtp } from '../../../utils/helper';
import ResendOtp from '../../shared/Modals/Otp/ResendOtpOutside';
import VerifyOtp from '../../shared/Modals/Otp/VerifyOtp';
// import FormInput from '../../shared/CustomInput/FormInput';
import './index.scss';
import { hideLoader, showLoader } from '../../../utils/loader';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { capFirstWord } from '../../../utils/helper';
import { fileValidation, videoValidation } from '../../../utils/validateImage';

const initial = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  state: '',
  city: '',
  lga: '',
  affiliationAddress: '',
  affiliationName: '',
};
const ContestSignUp = () => {
  const history = useHistory();
  const { id } = useParams();
  //  const hiddenFileInput = useRef(null);
  const {
    wayagramProfile: { email, isOrganiser, id: profileid },
  } = useContext(WayagramContext);
  //  const { Option } = Select;
  //  const states = NaijaStates.states();
  // const unis = nguniversities.getUniversities();
  const [businessTypes, setBusinessTypes] = useState([]);
  const [phone, setPhone] = useState('');
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const [data, setData] = useState(initial);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state1, setStates1] = useState([]);
  //  const [showPassword, setShowPassword] = useState(true);
  const [preview, setPreview] = useState('');
  const [preview1, setPreview1] = useState('');
  const [preview2, setPreview2] = useState('');
  const [preview3, setPreview3] = useState('');
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  // const [unis, setUnis] = useState([]);
  const [contestData, setContestData] = useState([]);

  const handleVideoUpload = (event) => {
    const valid = true;
    const file = event.target.files[0];
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      console.log({ vv: Number(video.duration) > 600 });
      if (Number(video.duration) > 300) {
        swal('Oops! file should not exceed 5 mins');
      } else {
        setData({ ...data, coverVideo: file });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setPreview2(reader?.result);
        };
      }
      window.URL.revokeObjectURL(video.src);
    };

    video.src = window.URL.createObjectURL(file);
    return valid;
  };
  const resendOtp = async (phoneNumber) => {
    setLoading(true);
    setPhone(phoneNumber);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${phoneNumber}`
    );
    // await handleSendOpenOtp(data.email, 'email', 'JOINT_VERIFICATION');
    if (res?.status) {
      setLoading(false);

      swal('Done', res.message, 'success').then(() => {
        setShowResendOtp(false);
        setShowVerifyOtp(true);
      });
    } else {
      setLoading(false);
      swal('Wrong', `${res.message}`, 'error', {
        button: {
          text: 'Resend OTP',
          className: 'button-warning',
        },
      }).then(() => {
        setPhone('');
      });
    }
  };
  const getContest = async () => {
    await httpGet(
      `${customBaseUrl.contestUrl}/contests/me?email=${data?.email}`
    );
  };
  // const getUnis = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.contestUrl}/contests/universities`
  //   );
  //   if (res?.universities) {
  //     setUnis(res?.universities);
  //   } else {
  //     console.log(res);
  //   }
  // };
  const getContestData = async () => {
    const res = await httpGet(
      `${customBaseUrl.contestUrl}/contests?profileId=${profileid}&id=${id}`
    );
    if (res?.status) {
      setContestData(res?.data[0].contest);
    } else {
      console.log(res);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data?.contestPhoto)
      return swal('Error', 'Kindly upload contest photo');
    if (!data?.coverPhoto) return swal('Error', 'Kindly upload Cover photo');
    if (!data?.coverVideo) return swal('Error', 'Kindly upload cover video');
    if (!data?.idCard) return swal('Error', 'Kindly upload ID card');
    setLoading(true);
    //  showLoader();
    // const profileId = localStorage.getItem('profileId');
    const formData = new FormData();
    formData.append('contestId', id);
    formData.append('email', data?.email);
    formData.append('firstName', data?.firstName);
    formData.append('lastName', data?.lastName);
    formData.append('phoneNumber', data?.phoneNumber);
    formData.append('city', data?.city);
    formData.append('lga', data?.lga);
    formData.append('state', data?.state);
    formData.append('stateLogo', data?.stateLogo);
    formData.append('maritalStatus', data?.maritalStatus);
    formData.append('bio', data?.bio);
    formData.append('affiliationName', contestData?.university);
    formData.append('university', contestData?.university);
    formData.append('affiliationAddress', data?.affiliationAddress);
    formData.append('dob', data?.dob);
    formData.append('talent', data?.talent);
    formData.append('faculty', data?.faculty);
    formData.append('department', data?.department);
    formData.append('level', data?.level);
    formData.append('contestPhoto', data?.contestPhoto);
    formData.append('coverPhoto', data?.coverPhoto);
    formData.append('coverVideo', data?.coverVideo);
    formData.append('idCard', data?.idCard);
    formData.append('profileId', profileid);

    //  const fData = await objectToFormData(postData);
    // await httpPostFormData(
    //   '/contests/register-contestants',
    //   formData,
    //   customBaseUrl.contestUrl
    // )
    console.log('form', formData);
    setShowProgress(true);
    axios
      .post(
        `${customBaseUrl.contestUrl}/contests/register-contestant`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
            if (percentCompleted === 100) showLoader();
          },
          headers: {
            Authorization: `${localStorage.token}`,
            'Content-Type': 'multipart/form-data',
            'CLIENT-TYPE': localStorage.getItem('TYPE') ?? 'PERSONAL',
            'CLIENT-ID': 'WAYAGRAM',
          },
        }
      )
      .then(async (firstRes) => {
        console.log({ firstRes });
        if (firstRes?.status) {
          setShowProgress(false);
          hideLoader();
          // toast.success(firstRes?.data);
          swal('Done', `${firstRes?.data?.message}`, 'success');
          setLoading(false);
          getContest();
          history.push(`/contest-dashboard/${profileid}/${id}`);
          // });
        } else {
          hideLoader();
          setShowProgress(false);
          swal(
            'Oops!',
            `${firstRes?.message ?? 'Something went wrong'}`,
            'error'
          );
        }
      })
      .catch((err) => {
        setShowProgress(false);
        hideLoader();
        swal(
          'Oops!',
          `${err?.message || 'Something went wrong'}`,
          'Error creating user'
        );
      })
      .finally(() => {
        setShowProgress(false);
        hideLoader();
        setLoading(false);
      });
    return true;
  };

  // const setCountryAndInitialPhone = (handler, setFieldValue) => (e) => {
  //   const { target } = e;
  //   const { value } = target;x`x
  //   setFieldValue('phoneNumber', `+${getCountryCallingCode(value)}`);
  //   handler({ target: { name: 'country', value } });
  // };
  const handleClose = () => {
    history.push(`/contest-dashboard/${profileid}/${id}`);
  };
  const validateUser = async () => {
    setLoading(true);
    showLoader();
    const res = JSON.parse(localStorage?.wayaUserData);
    if (res?.user?.email) {
      setData({
        ...data,
        firstName: res?.user?.firstName,
        email: res?.user?.email,
        lastName: res?.user?.lastName,
        phoneNumber: res?.user?.phoneNumber,
        dob: res?.user?.dateOfBirth,
      });
    } else {
      notification.error('Log in to apply for this contest');
      history.push(`/contest-dashboard/${profileid}/${id}`);
    }
    hideLoader();
    // const res = await httpGet(
    //   `${customBaseUrl.authUrl}/api/v1/user/email/${mail}/extended`
    // );
    // if (res?.status) {
    //   hideLoader();
    //   setData({
    //     ...data,
    //     firstName: res?.data?.firstName,
    //     email: res?.data?.email,
    //     lastName: res?.data?.lastName,
    //     phoneNumber: res?.data?.phoneNumber,
    //     dob: res?.data?.dateOfBirth,
    //   });
    //   setShowPassword(false);
    //   delete data?.password;
    // } else {
    //   hideLoader();
    //   setData(initial);
    //   setShowPassword(true);
    //   //  swal('Oops!', res?.message);
    //   notification.error(res?.message);
    // }
  };
  const getStates = async () => {
    setLoading(true);
    const res = await httpGet(`${customBaseUrl.contestUrl}/users/states`);
    if (res?.status) {
      //  homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      const state = res?.data?.sort((a, b) => {
        const fa = a.state.toLowerCase();
        const fb = b.state.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setStates1(state);
    } else {
      console.log(res);
    }
  };
  const completeSignup = async ({ setState, otp, setOtp }) => {
    setState((s) => ({
      ...s,
      submit: true,
    }));
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    await httpPost(
      '/api/v1/auth/verify-otp',
      postData,
      customBaseUrl.authUrl,
      true
    )
      .then((res) => {
        if (res.status) {
          setPhone('');
          setShowVerifyOtp(false);
          setData(initial);
          swal('Done', res.message, 'success').then(() => {
            history.push('/login');
          });
        } else {
          swal('Oops!', res?.message);
        }
      })
      .finally(() => {
        setState((s) => ({
          ...s,
          submit: false,
        }));
        setOtp({});
      });
  };

  //   const handleSignup = async () => {
  //     if (!validator.equals(data.password, data.confirmPassword)) {
  //       swal('Oops!', 'Password do not match', 'error');
  //       setLoading(false);
  //       return;
  //     }
  //     setLoading(true);
  //     const formattedPhone = `${
  //       parsePhoneNumber(data.phoneNumber).countryCallingCode
  //     }${parsePhoneNumber(data.phoneNumber).nationalNumber}`;

  //     const formattedOrgPhone = `${
  //       parsePhoneNumber(data.orgPhone).countryCallingCode
  //     }${parsePhoneNumber(data.orgPhone).nationalNumber}`;

  //     const postData = {
  //       admin: false,
  //       corporate: true,
  //       ...data,
  //       orgPhone: formattedOrgPhone,
  //       phoneNumber: formattedPhone,
  //       userId: 0,
  //       wayaBankPayPosGram: 'WAYAGRAM',
  //     };

  //     const hiddenPhone = `*********${data.phoneNumber.substr(-4, 4)}`;

  //     const res = await httpPost(
  //       '/api/v1/auth/create-corporate',
  //       postData,
  //       customBaseUrl.authUrl,
  //       true
  //     );
  //     if (res.status) {
  //       // await handleSendOpenOtp(data.email, 'email', 'JOINT_VERIFICATION');
  //       setPhone(data.phoneNumber);
  //       setLoading(false);
  //       swal(
  //         'Done',
  //         `Account created! and a verification OTP has been sent to ${hiddenPhone}`,
  //         'success',
  //         {
  //           button: {
  //             text: 'Continue to OTP',
  //             className: 'button-success',
  //             value: true,
  //           },
  //           closeOnClickOutside: false,
  //         }
  //       ).then(() => {
  //         setShowVerifyOtp(true);
  //         swal.close();
  //       });
  //     } else {
  //       setLoading(false);
  //       swal('Oops!', res.message || 'Something went wrong', 'error');
  //     }
  //   };
  console.log(businessTypes);
  useEffect(() => {
    getContestData();
    getStates();
    //  getUnis();
    // console.log({ unis });
    const bid = localStorage.getItem('bid');
    if (!isOrganiser && bid) {
      validateUser(bid);
    } else {
      notification.error('Log in to apply for this contest');
      history.push(`/contest-dashboard/${profileid}/${id}`);
    }
    return () => {
      setBusinessTypes([]);
    };
  }, [email]);

  useEffect(() => {
    if (data?.state) {
      console.log(NaijaStates.all());
      setLgaList(NaijaStates.lgas(data?.state).lgas);
    } else {
      setLgaList([]);
    }
  }, [data?.state, id]);

  const marital = ['single', 'married', 'divorced'];
  return (
    <div
      id="login-bg"
      style={{
        backgroundImage: `url(${Bg})`,
      }}
    >
      <Modal
        isOpen={showProgress}
        toggle={() => setShowProgress(false)}
        centered
      >
        <div className="modal-body-rs p-2 pb-3">
          <div className="waya-modal-body">
            <div className="inputbox-with-one-input-log mt-5">
              <div className="mb-5">
                {progress < 100 ? 'Upload' : 'Registration'} in progress
              </div>
              <Progress percent={progress} />
            </div>
          </div>
        </div>
      </Modal>
      <div className="modal-body-rs-log col-8 p-10">
        <div className="flex flex-col items-center shadow-2xl p-5 bg-white">
          <div className="text-end">
            <Close onClick={handleClose} />
          </div>
          <a href={process.env.REACT_APP_HOME_URL}>
            <div className="header-sec-modal-log">
              <img
                src={Logo}
                alt="Logo"
                className="header-sec-logo-log mx-auto"
              />
            </div>
          </a>
          <>
            <h2 className="modal-header-data-log">
              REGISTER {isOrganiser ? '' : 'AS'} A CONTESTANT
            </h2>
          </>
          <form className="row g-3 p-3" onSubmit={handleSubmit}>
            <div>
              <label>Email*</label>
              <div style={{ position: 'relative' }}>
                <input
                  type="email"
                  disabled={!isOrganiser}
                  className="form-control"
                  placeholder="Email"
                  required
                  value={data?.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
                {isOrganiser && (
                  <button
                    type="button"
                    onClick={() => validateUser(data?.email)}
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: '0px',
                      height: '100%',
                      border: 'none',
                      backgroundColor: 'transparent',
                    }}
                  >
                    Verify
                  </button>
                )}
              </div>
            </div>
            <div className="mt-3 col-md-6">
              <label>First Name *</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  required
                  disabled
                  value={data?.firstName}
                  onChange={(e) =>
                    setData({ ...data, firstName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Last Name *</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  disabled
                  required
                  value={data?.lastName}
                  onChange={(e) =>
                    setData({ ...data, lastName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>PhoneNumber*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0801234567"
                  disabled
                  required
                  value={data?.phoneNumber}
                  onChange={(e) =>
                    setData({ ...data, phoneNumber: e.target.value })
                  }
                />
              </div>
            </div>
            {/* <div>
              <label className="mt-3">Logo</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0801234567"
                  required
                  value={data?.stateLogo}
                  onChange={(e) =>
                    setData({ ...data, stateLogo: e.target.value })
                  }
                />
              </div>
            </div> */}
            <div className="col-md-6">
              <label>Name of School*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name of School"
                  disabled
                  required
                  value={contestData?.university}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>State of Origin*</label>
              <select
                required
                className="form-select border rounded"
                onChange={(e) => {
                  const { state, logo } = JSON.parse(e.target.value);
                  setData({
                    ...data,
                    state: state === 'nasarawa' ? 'nassarawa' : state,
                    stateLogo: logo,
                  });
                }}
              >
                <option>Select State</option>
                {state1?.map((e) => (
                  <option key={e} value={JSON.stringify(e)}>
                    {e?.state?.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label>LGA*</label>
              <select
                required
                className="form-select border rounded"
                onChange={(e) => setData({ ...data, lga: e.target.value })}
              >
                <option>Select LGA</option>
                {lgaList?.map((e) => (
                  <option key={e}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label>City*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  required
                  value={data?.city}
                  onChange={(e) => setData({ ...data, city: e.target.value })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Marital Status*</label>
              <select
                className="form-select border rounded"
                onChange={(e) =>
                  setData({ ...data, maritalStatus: e.target.value })
                }
              >
                <option>Select Marital</option>
                {marital?.map((e) => (
                  <option key={e}>{capFirstWord(e)}</option>
                ))}
              </select>
            </div>
            {/* <div className="col-md-6">
              <label>Name of School*</label>
              <select
                required
                className="form-select border rounded"
                value={data.university}
                onChange={(e) => {
                  if (contestData?.university !== e.target.value)
                    return swal('invalid school selected');
                  setData({
                    ...data,
                    university: e.target.value,
                    affiliationName: e.target.value,
                  });
                  return e;
                }}
              >
                <option>Select school</option>
                {unis?.map((e) => (
                  <option key={e}>{e}</option>
                ))}
              </select>
            </div> */}
            <div className="col-md-6">
              <label>School Address*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data?.affiliationAddress}
                  onChange={(e) =>
                    setData({ ...data, affiliationAddress: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Faculty*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data?.faculty}
                  onChange={(e) =>
                    setData({ ...data, faculty: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Department*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data?.department}
                  onChange={(e) =>
                    setData({ ...data, department: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Year/Level*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={data?.level ?? ''}
                  onChange={(e) => setData({ ...data, level: e.target.value })}
                />
              </div>
            </div>
            <div className="col-md-12">
              <label>DOB of Contestant*</label>
              {/* <input
                className="form-select border rounded"
                value={data?.dob}
                required
                max
                type="date"
                onChange={(e) => setData({ ...data, dob: e.target.value })}
                placeholder="First name"
                name="dob"
              /> */}
              {/* <DatePicker
                className="w-100 mt-4 py-1 rounded border"
                name="dob"
                showTimeInput={false}
                showTimeSelect
                selected={parseInt(data.dob, 10)}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 16)}
                timeIntervals={15}
                onChange={(date) => {
                  setData({
                    ...data,
                    dob: date.getTime(),
                    // startTime: date.getTime(),
                  });
                }}
              /> */}
              <input
                type="date"
                max="2013-01-01"
                className="form-control"
                onChange={(e) => {
                  setData({
                    ...data,
                    dob: e.target.value,
                    // startTime: date.getTime(),
                  });
                }}
                // max={new Date().setFullYear(new Date().getFullYear() - 16)}
              />
              {/* <ReactDatePicker
                className="w-100 mt-4 py-1 rounded border"
                name="dob"
                placeholderText="DOB"
                selected={parseInt(data.dob, 10)}
                maxDate={new Date().getDate - 1}
                //  minDate={new Date()}
                onChange={(date) => {
                  setData({
                    ...data,
                    dob: date.getTime(),
                    // startTime: date.getTime(),
                  });
                }}
              /> */}
            </div>
            {/* {showPassword && (
              <div className="col-md-6">
                <label>Password*</label>
                <input
                  type="password"
                  className="form-control"
                  required
                  value={data?.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
              </div>
            )} */}
            <div className="">
              <label>Talent*</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  maxLength={35}
                  required
                  value={data?.talent}
                  onChange={(e) => setData({ ...data, talent: e.target.value })}
                />
              </div>
              <div className="text-end mx-4">
                <label>{data?.talent?.length ?? 0}/35</label>
              </div>
            </div>
            <div className="inputbox-with-one-input-log">
              <label>Enter Page Biography*</label>
              <div>
                <textarea
                  maxLength={255}
                  type="text"
                  className="form-control"
                  required
                  value={data?.bio}
                  onChange={(e) => setData({ ...data, bio: e.target.value })}
                />
              </div>
            </div>
            <div
              className="col-12 text-center btn border"
              //  onClick={() => hiddenFileInput.current.click()}
            >
              <label
                style={{
                  backgroundImage: preview ? `url(${preview})` : '',
                  backgroundSize: 'cover',
                  height: '140px',
                  width: '100%',
                  backgroundPosition: 'left',
                  //  border: 1,
                  borderStyle: 'dotted',
                  borderTopLeftRadius: 14,
                  borderTopRightRadius: 14,
                }}
                htmlFor="contestPhoto"
              >
                Click here to upload contest photo*
              </label>
              <input
                hidden
                //  ref={hiddenFileInput}
                id="contestPhoto"
                name="contestPhoto"
                placeholder="Click here to upload"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const validFormat = fileValidation(e.target.files[0]);
                  // if (e.target.files[0].size > 100000) {
                  //   setPreview('');
                  //   return swal(
                  //     'Oops!',
                  //     'Please contest Photo should be less than 100kb',
                  //     'error'
                  //   );
                  // }
                  if (!validFormat) {
                    setPreview('');
                    return swal(
                      'Oops!',
                      'Please upload file image only.',
                      'error'
                    );
                  }
                  setData({ ...data, contestPhoto: e.target.files[0] });
                  const reader = new FileReader();
                  const url = reader.readAsDataURL(e.target.files[0]);
                  reader.onloadend = () => {
                    setPreview(reader.result);
                  };
                  console.log('img', url);
                  return url;
                }}
              />
            </div>
            <div
              className="col-12 text-center btn border"
              //  onClick={() => hiddenFileInput.current.click()}
            >
              <label
                htmlFor="coverPhoto"
                style={{
                  backgroundImage: preview1 ? `url(${preview1})` : '',
                  backgroundSize: 'cover',
                  height: '140px',
                  backgroundPosition: 'left',
                  //  border: 1,
                  width: '100%',
                  borderStyle: 'dotted',
                  borderTopLeftRadius: 14,
                  borderTopRightRadius: 14,
                }}
              >
                Click here to upload contest cover photo*
              </label>
              <input
                hidden
                //  ref={hiddenFileInput}
                id="coverPhoto"
                name="coverPhoto"
                placeholder="Click here to upload"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const validFormat = fileValidation(e.target.files[0]);
                  console.log({ validFormat });
                  // if (e.target.files[0].size > 100000) {
                  //   setPreview1('');
                  //   return swal(
                  //     'Oops!',
                  //     'Please cover photo should be less than 100kb',
                  //     'error'
                  //   );
                  // }
                  if (!validFormat) {
                    setPreview1('');
                    return swal(
                      'Oops!',
                      'Please upload file image only.',
                      'error'
                    );
                  }
                  setData({ ...data, coverPhoto: e.target.files[0] });
                  const reader = new FileReader();
                  const url = reader.readAsDataURL(e.target.files[0]);
                  reader.onloadend = () => {
                    setPreview1(reader.result);
                  };
                  console.log('cimg', url);
                  return url;
                }}
              />
            </div>
            <div
              className="col-12 text-center btn border"
              //  onClick={() => hiddenFileInput.current.click()}
            >
              <label
                htmlFor="idCard"
                style={{
                  backgroundImage: preview3 ? `url(${preview3})` : '',
                  backgroundSize: 'cover',
                  height: '140px',
                  width: '100%',
                  backgroundPosition: 'left',
                  //  border: 1,
                  borderStyle: 'dotted',
                  borderTopLeftRadius: 14,
                  borderTopRightRadius: 14,
                }}
              >
                Click here to upload your School Id Card or admission letter or
                bio data form*
              </label>
              <input
                hidden
                //  ref={hiddenFileInput}
                id="idCard"
                name="idCard"
                placeholder="Click here to upload"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const validFormat = fileValidation(e.target.files[0]);
                  console.log({ validFormat });
                  if (!validFormat) {
                    setPreview3('');
                    return swal(
                      'Oops!',
                      'Please upload file image only.',
                      'error'
                    );
                  }
                  setData({ ...data, idCard: e.target.files[0] });
                  const reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onloadend = () => {
                    setPreview3(reader.result);
                  };
                  return reader;
                }}
              />
            </div>
            <div
              className="col-12 text-center btn border"
              //  onClick={() => hiddenFileInput.current.click()}
            >
              <label htmlFor="coverVideo">
                Click here to upload a short contest cover video that describes
                you and your art/talent*
              </label>
              <input
                hidden
                //  ref={hiddenFileInput}
                id="coverVideo"
                name="coverVideo"
                placeholder="Click here to upload"
                type="file"
                accept="video/*"
                onChange={(e) => {
                  handleVideoUpload(e);
                  if (e.target.files[0].size > 500000000) {
                    setPreview2('');
                    return swal(
                      'Oops!',
                      'Please cover video should be less than 500mb',
                      'error'
                    );
                  }
                  const validFormat = videoValidation(e.target.files[0]);
                  console.log({ validFormat });
                  if (!validFormat) {
                    e.target.files = null;
                    setPreview2('');
                    return swal(
                      'Oops!',
                      'Please upload video file only.',
                      'error'
                    );
                  }
                  return true;
                }}
              />
              <Progress percent={progress} showInfo={false} />
            </div>
            <div className="flex d-flex justify-content-between">
              {/* {preview ? (
                <div className="flex-col">
                  <div>
                    <img
                      style={{ height: 150, width: 150 }}
                      src={preview}
                      alt="contest"
                    />
                  </div>
                  <label>
                    Contest photo
                    <Cancel
                      onClick={() => {
                        setData({ ...data, contestPhoto: '' });
                        setPreview('');
                      }}
                    />
                  </label>
                </div>
              ) : (
                ''
              )}
              {preview1 ? (
                <div>
                  <img
                    style={{ height: 150, width: 150 }}
                    src={preview1}
                    alt="contest"
                  />
                  <div>
                    <label>
                      Contest cover photo
                      <Cancel
                        onClick={() => {
                          setData({ ...data, coverPhoto: '' });
                          setPreview1('');
                        }}
                      />
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}
              {preview3 ? (
                <div>
                  <img
                    style={{ height: 150, width: 150 }}
                    src={preview3}
                    alt="contest"
                  />
                  <div>
                    <label>
                      School ID Card
                      <Cancel
                        onClick={() => {
                          setData({ ...data, idCard: '' });
                          setPreview3('');
                        }}
                      />
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )} */}
              {preview2 ? (
                <div>
                  <video width="200" controls>
                    <source src={preview2} />
                    <track
                      src="captions_en.vtt"
                      kind="captions"
                      label="english_captions"
                    />
                  </video>
                  <div>
                    <label>
                      Contest video
                      <Cancel
                        onClick={() => {
                          setData({ ...data, coverVideo: '' });
                          setPreview2('');
                        }}
                      />
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-primary px-5 py-2">
                Proceed
              </button>
            </div>
          </form>
        </div>
      </div>

      {showVerifyOtp && (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          buttonLabel="Verify"
          handleSubmit={completeSignup}
          resendOtp={setShowResendOtp}
        />
      )}
      {showResendOtp && (
        <ResendOtp
          center="true"
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title="Resend OTP"
          description="Please input the email and mobile number"
          handleSubmit={resendOtp}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ContestSignUp;
