import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { IoCloseCircle, IoWalletOutline } from 'react-icons/io5';
import { FcAlarmClock } from 'react-icons/fc';
import { FaCheck } from 'react-icons/fa6';
import wayagramLogo from '../../../assets/images/wayagram-short-logo.png';
import paystackLogo from '../../../assets/images/paystack.png';
import { numberFormat } from '../../../utils/helper';

const PaymentOptionModal = ({
  openPaymentOptionModal,
  setOpenPaymentOptionModal,
  handlePaymentWithOption,
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleHoverSelectOption = (id) => {
    const element = document.getElementById(id);
    element.style.border = '3px solid #ff6634';
    element.style.cursor = 'pointer';
    element.style.transition = '0.5s';
    element.style.backgroundColor = '#ffefea';
    element.style.color = '#ff6634';
  };

  const handleHoverLeaveOption = (id) => {
    const element = document.getElementById(id);
    element.style.border = '3px solid gray';
    element.style.backgroundColor = '#efefef';
    element.style.color = 'black';
  };

  const handleSelectPayOption = () => {
    const wayaquickCheck = document.getElementById('wayaquick-check');
    const paystackCheck = document.getElementById('paystack-check');
    if (selectedOption === 'wayaquick-check') {
      wayaquickCheck.style.display = 'flex';
      paystackCheck.style.display = 'none';
      handlePaymentWithOption('WAYAQUICK');
      setTimeout(() => {
        setOpenPaymentOptionModal({
          status: false,
          paymentDetails: {},
        });
      }, 500);
    } else if (selectedOption === 'paystack-check') {
      paystackCheck.style.display = 'flex';
      wayaquickCheck.style.display = 'none';
      handlePaymentWithOption('PAYSTACK');
      setTimeout(() => {
        setOpenPaymentOptionModal({
          status: false,
          paymentDetails: {},
        });
      }, 500);
    }
  };

  useEffect(() => {
    handleSelectPayOption();
  }, [selectedOption]);

  return (
    <Modal
      isOpen={openPaymentOptionModal?.status}
      toggle={() =>
        setOpenPaymentOptionModal({ status: true, paymentDetails: {} })
      }
      centered
      // className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <div
          style={{
            backgroundColor: '#ff6634',
            height: '8rem',
            width: '100%',
            position: 'relative',
            display: 'flex',
            paddingTop: '1rem',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              cursor: 'pointer',
            }}
          >
            <IoCloseCircle
              size={30}
              color="white"
              onClick={() =>
                setOpenPaymentOptionModal({
                  status: false,
                  paymentDetails: {},
                })
              }
            />
          </div>
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              color: 'white',
            }}
          >
            <h5>{openPaymentOptionModal?.paymentDetails?.type}</h5>
          </div>
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              gap: '1rem',
              color: 'white',
            }}
          >
            <div>
              <span>
                {numberFormat(
                  openPaymentOptionModal?.paymentDetails?.totalAmount
                )}
              </span>
            </div>
            <div>
              <span>{openPaymentOptionModal?.paymentDetails?.email}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#FAFAFC',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          <div>
            <h5>Select a payment option</h5>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                backgroundColor: '#efefef',
                border: '3px solid gray',
                borderRadius: '1rem',
                width: '12rem',
                height: '9rem',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '-15px',
                  top: '-12px',
                  border: '2px solid gray',
                  borderRadius: '5rem',
                  gap: '0.5rem',
                  backgroundColor: 'white',
                  padding: '0.3rem',
                }}
              >
                <div>
                  <FcAlarmClock size={20} />
                </div>
                <span
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  Coming soon...
                </span>
              </div>
              <div>
                <IoWalletOutline size={70} />
              </div>

              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                }}
              >
                PAY WITH WAYAGRAM WALLET
              </p>
            </div>

            <div
              style={{
                backgroundColor: '#efefef',
                border: '3px solid gray',
                borderRadius: '1rem',
                width: '12rem',
                height: '9rem',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '2rem',
                gap: '1rem',
              }}
              id="wayaquick"
              onMouseEnter={() => handleHoverSelectOption('wayaquick')}
              onMouseLeave={() => handleHoverLeaveOption('wayaquick')}
              onClick={() => setSelectedOption('wayaquick-check')}
            >
              <div
                style={{
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '-12px',
                  top: '-10px',
                  border: '2px solid #ff6634',
                  borderRadius: '5rem',
                  backgroundColor: 'white',
                  padding: '5px',
                  width: '2rem',
                  height: '2rem',
                }}
                id="wayaquick-check"
              >
                <div>
                  <FaCheck size={15} color="#ff6634" />
                </div>
              </div>
              <div>
                <img src={wayagramLogo} alt="wayagram-logo" />
              </div>
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                }}
              >
                PAY WITH WAYAQUICK
              </p>
            </div>
            <div
              id="paystack"
              style={{
                backgroundColor: '#efefef',
                border: '3px solid gray',
                borderRadius: '1rem',
                width: '12rem',
                height: '9rem',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '2rem',
                gap: '0.5rem',
              }}
              onMouseEnter={() => handleHoverSelectOption('paystack')}
              onMouseLeave={() => handleHoverLeaveOption('paystack')}
              onClick={() => setSelectedOption('paystack-check')}
            >
              <div
                style={{
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '-12px',
                  top: '-10px',
                  border: '2px solid #ff6634',
                  borderRadius: '5rem',
                  backgroundColor: 'white',
                  padding: '5px',
                  width: '2rem',
                  height: '2rem',
                }}
                id="paystack-check"
              >
                <div>
                  <FaCheck size={15} color="#ff6634" />
                </div>
              </div>

              <div
                style={{
                  width: '130px',
                }}
              >
                <img src={paystackLogo} alt="wayagram-logo" width="100%" />
              </div>
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                }}
              >
                PAY WITH PAYSTACK
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentOptionModal;
