import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { WalletContext } from '../../../../../store/context/WalletContext';
import { numberFormat } from '../../../../../utils/helper';
import FundWallet from '../../FundWallet';
// import PropTypes from 'prop-types';

import './makeDonation.scss';

function PinInput(props) {
  const { values, setOtp, otp, separator, handleFormInput, setTrxStatusModal } =
    props;
  const [fundWallet, setFundWallet] = useState(false);
  const { walletList } = useContext(WalletContext);

  const retryAction = () => {
    setFundWallet(false);
    setTrxStatusModal((data) => ({ ...data, status: false }));
  };

  const handleSetAmt = (e) => {
    const { value } = e.target;
    const val = value.split('-');
    if (Number(val[1]) < Number(values.amount)) {
      setTrxStatusModal({
        status: true,
        type: 'failed',
        actionName: 'Fund Wallet',
        action: () => setFundWallet(true),
        message: 'Insufficient Funds',
        retry: retryAction,
      });
    } else handleFormInput(value, 'accountNo');
  };

  return (
    <div className="pin-input-container">
      <div className="pin-input-header">Input your 4 digit pin</div>
      <div className="pin-input-description">
        Please input your 4digit pin to transfer fund
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Receipient</div>
        <div className="transaction-detail-right">Wayagram</div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Amount</div>
        <div className="transaction-detail-right">
          {numberFormat(values?.amount)}
        </div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Beneficiary Bank:</div>
        <div className="transaction-detail-right">Wayabank</div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">From</div>
        <div className="transaction-detail-right">
          <select
            className="mb-1 border-0"
            value={values?.accountNo}
            onChange={handleSetAmt}
          >
            <option>Select Account</option>
            {walletList?.map((list) => (
              <option
                key={Math.random()}
                value={`${list?.accountNo}-${list?.clr_bal_amt}`}
              >
                {`${list?.accountNo} (N${list?.clr_bal_amt})`}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <div className="transaction-detail mt-4">
        <div className="pin-input-description">Transaction fee:</div>
        <div className="transaction-detail-right">#####</div>
      </div> */}
      <OtpInput
        value={otp}
        onChange={(e) => setOtp(e)}
        separator={separator}
        containerStyle={{ justifyContent: 'center' }}
        inputStyle="otp-input otp-bottom"
        isInputSecure
      />
      {fundWallet ? (
        <FundWallet
          showModal={fundWallet}
          hideModal={setFundWallet}
          id={values.profile_id}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default PinInput;
