import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Progress, Space } from 'antd';
import { useRecoilValue } from 'recoil';
import { MenuOutlined } from '@ant-design/icons';
import swal from 'sweetalert';
import { NavbarMenu, NavbarWrapper } from './style';
import ManageBankAccountModal from '../Modals/BankAccount/manage';
import ManageCardModal from '../Modals/Card/manage';
import CreateWalletModal from '../Modals/Wallet';
import LinkCardModal from '../Modals/Card';
import BankAccountModal from '../Modals/BankAccount';
import ManageWallet from '../Modals/Wallet/ManageWallet';
import { LayoutContext } from '../../../store/context/LayoutContext';
import { ArrowBack } from '../Icons';
import WayagramNavbar from './wayagramNavbar';
import { userTheme } from '../../../store/initialState';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpPost } from '../../../action/http';
import VerifyPin from '../Modals/Otp/VerifyPin';

export default function Navbar(props) {
  const { currentPage, pageName, goBack, showName } = props;
  const { emailVerified, phoneVerified, reload, setReload } =
    useContext(ProfileContext);
  const [showManageBankAccount, setShowManageBankAccount] = useState(false);
  const [showManageCard, setShowManageCard] = useState(false);
  const [showManageWallet, setShowManageWallet] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showLinkCardModal, setShowLinkCardModal] = useState(false);
  const [showAddBankAccount, setShowAddBankAccount] = useState(false);
  const {
    setToggleMobileSidebar,
    toggleMobileSidebar,
    progress,
    showProgress,
  } = useContext(LayoutContext);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [pin, setPin] = useState(null);
  const [type, setType] = useState('email');
  const history = useHistory();
  const theme = useRecoilValue(userTheme);
  const themeColor = theme.mode === 'light' ? 'dark' : 'white';

  const validatePhone = async () => {
    const postBody = {
      //  userId: localStorage.getItem('userId'),
      phoneNumber: localStorage.getItem('phone'),
    };
    //  setLoading(true);
    setType('phone');
    const res = await httpPost(
      `/api/v1/auth/otp/phone/resend`,
      postBody,
      customBaseUrl.authUrl,
      false,
      '',
      true
    );
    console.log({ res });
    setShowVerifyOtp({ show: true, type: 'phone' });
  };
  const validateEmail = async () => {
    const postBody = {
      userId: localStorage.getItem('userId'),
      email: localStorage.getItem('email'),
    };
    //  setLoading(true);
    setType('email');
    const res = await httpPost(
      `/api/v1/auth/otp/email/resend`,
      postBody,
      customBaseUrl.authUrl,
      false,
      '',
      true
    );
    console.log({ res });
    setShowVerifyOtp({ show: true, type: 'email' });
  };
  const resendOtp = async () => {
    //  const formatedPhone = `234${phoneNumber.slice(1)}`;
    // const formatedPhone = isPersonal
    //   ? `234${phoneNumber.slice(1)}`
    //   : phoneNumber;
    const postBody = {
      userId: localStorage.getItem('userId'),
      email: localStorage.getItem('email'),
    };
    const phoneBody = {
      userId: localStorage.getItem('userId'),
      phonenumber: localStorage.getItem('phone'),
    };
    //  setLoading(true);
    await httpPost(
      `/api/v1/auth/otp/${type === 'email' ? 'email' : 'phone'}/resend`,
      type === 'email' ? postBody : phoneBody,
      customBaseUrl.authUrl,
      false,
      '',
      true
    );
    setShowVerifyOtp(true);
    setPin('');
  };
  const handleVerifyOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/otp/verify`,
      {
        email: localStorage.getItem('email'),
        otp: pin,
      },
      customBaseUrl.authUrl,
      false,
      '',
      true
    );
    if (res.status) {
      swal('Done', 'Email verified successfully.', 'success');
      //  setShowPin(false);
      setPin('');
      setReload(!reload);
      setShowVerifyOtp(false);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };
  return (
    <NavbarWrapper className="navbar-container" bgColor={theme.style}>
      <NavbarMenu>
        <Button
          shape="default"
          icon={<MenuOutlined />}
          onClick={() => setToggleMobileSidebar(!toggleMobileSidebar)}
        />
      </NavbarMenu>
      {goBack && (
        <Space direction="horizontal" onClick={() => history.push('/settings')}>
          <ArrowBack color={theme?.style?.color} />
          Back
        </Space>
      )}
      <h6 className={`nav-title text-${themeColor}`}>{pageName}</h6>
      <div
        style={{
          display: showProgress ? 'none' : 'none',
          flexDirection: 'row',
          width: '50%',
        }}
      >
        <div>
          <h6>Upload progress</h6>
        </div>
        <Progress percent={progress} />
      </div>
      {!emailVerified ? (
        <div style={{ display: 'flex', flexDirection: 'row', width: '500px' }}>
          <h6 className="nav-title text-red" style={{ color: 'red' }}>
            Email not verified
          </h6>
          <span style={{ margin: 3 }}>
            <button type="button" className="btn" onClick={validateEmail}>
              verify
            </button>
          </span>
        </div>
      ) : !phoneVerified ? (
        <div style={{ display: 'flex', flexDirection: 'row', width: '500px' }}>
          <h6 className="nav-title text-red" style={{ color: 'red' }}>
            Phone not verified
          </h6>
          <span style={{ margin: 3 }}>
            <button type="button" className="btn" onClick={validatePhone}>
              verify
            </button>
          </span>
        </div>
      ) : (
        ''
      )}
      <WayagramNavbar
        setShowManageBankAccount={setShowManageBankAccount}
        setShowCreateWallet={setShowCreateWallet}
        setShowManageCard={setShowManageCard}
        setShowManageWallet={setShowManageWallet}
        setToggleMobileSidebar={setToggleMobileSidebar}
        toggleMobileSidebar={toggleMobileSidebar}
        history={history}
        currentPage={currentPage}
        pageName={pageName}
        showName={showName}
        theme={theme}
      />
      {showManageBankAccount ? (
        <ManageBankAccountModal
          showModal={showManageBankAccount}
          hideModal={setShowManageBankAccount}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowAddBankAccount={setShowAddBankAccount}
        />
      ) : (
        ''
      )}

      {showAddBankAccount ? (
        <BankAccountModal
          showModal={showAddBankAccount}
          hideModal={setShowAddBankAccount}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}

      {showCreateWallet ? (
        <CreateWalletModal
          showModal={showCreateWallet}
          hideModal={setShowCreateWallet}
          id="account-wrapper"
        />
      ) : (
        ''
      )}

      {showManageWallet ? (
        <ManageWallet
          showModal={showManageWallet}
          hideModal={setShowManageWallet}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowCreateWallet={setShowCreateWallet}
        />
      ) : (
        ''
      )}

      {showManageCard ? (
        <ManageCardModal
          showModal={showManageCard}
          hideModal={setShowManageCard}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowLinkCardModal={setShowLinkCardModal}
        />
      ) : (
        ''
      )}

      {showLinkCardModal ? (
        <LinkCardModal
          showModal={showLinkCardModal}
          hideModal={setShowLinkCardModal}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <VerifyPin
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={pin}
          setOtp={(e) => setPin(e)}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your you"
          isResendOtp
          buttonLabel="Verify"
          handleSubmit={handleVerifyOtp}
          numInputs={6}
          resendOtp={resendOtp}
        />
      ) : (
        ''
      )}
    </NavbarWrapper>
  );
}

Navbar.defaultProps = {
  currentPage: 'others',
};

Navbar.propTypes = {
  currentPage: PropTypes.string,
};
