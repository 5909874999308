import React, { useState, useContext, useEffect } from 'react';
// import OtpInput from 'react-otp-input';
import { Modal, ModalHeader } from 'reactstrap';
import { Select } from 'antd';
import { useRecoilValue } from 'recoil';
// import swal from 'sweetalert';
// import { customBaseUrl, httpGet } from '../../../../action/http';
// import { ProfileContext } from '../../../../store/context/ProfileContext';
import { WalletContext } from '../../../../store/context/WalletContext';
// import { numberFormat } from '../../../../utils/helper';
import Button from '../../Button';
import FundWallet from '../FundWallet';
import './MakeDonation/makeDonation.scss';
import TransactionStatusModal from './MakeDonation/TransactionStatus';
import PostImageCard, {
  ProductImageCard,
} from '../../Cards/GramCards/PostImageCard';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import { userTheme } from '../../../../store/initialState';
import { hideLoader, showLoader } from '../../../../utils/loader';

function PayForProduct(props) {
  const { walletList } = useContext(WalletContext);
  // const { profile } = useContext(ProfileContext);
  const {
    values,
    hideModal,
    showModal,
    handleSubmit,
    type,
    trxStatusModal,
    setTrxStatusModal,
    isPaid,
  } = props;

  const postImages = values?.ProductImages
    ? values?.ProductImages
    : [{ imageUrl: values?.imageUrl }];
  console.log({ values });
  // const [account, setAccount] = useState('');
  const theme = useRecoilValue(userTheme);
  const [ticketNo, setTicketNo] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [trnxFee, setTrnxFee] = useState(0);
  const { states } = useContext(WayagramContext);
  const [fundWallet, setFundWallet] = useState(false);
  const { Option } = Select;
  const [filteredOptions, setFilteredOption] = useState([]);
  const [location, setLocation] = useState({});
  // const [initialize, setInitialize] = useState(null);
  // const amt =
  //   type === 'event'
  //     ? values?.amount * (ticketNo || 1)
  //     : values?.amount || values?.Poll?.amount || values?.Product?.amount;

  const handleVerifyPin = async () => {
    setLoading(true);
    showLoader();
    // if (isPaid && otp?.length < 4) {
    //   setLoading(false);
    //   swal('Oops!', 'Pin is required', 'error');
    //   return;
    // }
    // if (isPaid && account?.length <= 0) {
    //   setLoading(false);
    //   swal('Oops!', 'Please select a debit account', 'error');
    //   return;
    // }
    if (isPaid) {
      // const res = await httpGet(
      //   `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
      // );
      // if (res.status) {
      await handleSubmit(null, ticketNo, location);
      setLoading(false);
      hideLoader();
      // setOtp('');
      // } else {
      // swal('Oops!', res.message || 'Something went wrong', 'error');
      // }
    } else {
      await handleSubmit(walletList[0]?.accountNo, ticketNo, location);
      setLoading(false);
      hideLoader();
    }
  };
  useEffect(() => {
    setFilteredOption(states);
  }, [states]);

  // const handleContinuePayment = () => {
  //   setLoading(false);
  //   window.location.href = initialize?.authorization_url;
  // };

  // useEffect(async () => {
  //   const product =
  //     type === 'product' ? 'Product' : type === 'vote' ? 'Poll' : 'Event';
  //   const res = await httpGet(
  //     `${customBaseUrl.wayagramUrl}/all-posts/transaction-fee?type=${product}&amount=${amt}`
  //   );
  //   setTrnxFee(res?.data || 0);
  // }, [ticketNo]);

  // useEffect(async () => {
  //   const product =
  //     type === 'product' ? 'Product' : type === 'vote' ? 'Poll' : 'Event';
  //   const postData = {
  //     firstName: profile.firstName,
  //     lastName: profile.surname,
  //     narration: 'Payment made',
  //     email: profile.email,
  //     phoneNumber: profile.phoneNumber,
  //     no_of_ticket: `${ticketNo}`,
  //     item_type: product,
  //     item_id: values?.Product?.id || values?.Poll?.id || values?.id,
  //     amount: amt,
  //     // ...(type === 'vote' && { poll_option_id: optionId }),
  //   };
  //   const res = await httpPost(
  //     `/all-posts/initialize-wayaquick-payment`,
  //     postData,
  //     customBaseUrl.wayagramUrl,
  //     true
  //   );
  //   if (res?.status) setInitialize(res.data?.data);
  // }, []);

  // const retryAction = () => {
  //   setTrxStatusModal((data) => ({ ...data, status: false }));
  //   setFundWallet(false);
  // };
  // const handleSetAmt = (e) => {
  //   const { value } = e.target;
  //   const a = values?.Poll?.amount || values?.Product?.amount || amt;
  //   const val = value?.split('-');
  //   if (Number(val[1]) < Number(amt)) {
  //     setTrxStatusModal({
  //       status: true,
  //       type: 'failed',
  //       actionName: 'Fund Wallet',
  //       action: () => setFundWallet(true),
  //       message: 'Insufficient Funds',
  //       retry: retryAction,
  //     });
  //   } else setAccount(value);
  // };

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader className="text-left" toggle={() => hideModal(false)}>
          <div className="interest-header normal-header">
            <h3 className="interest-p1">
              {type === 'vote'
                ? 'Poll'
                : type === 'event'
                ? 'Event'
                : 'Product'}{' '}
              Details
            </h3>
          </div>
        </ModalHeader>
        <div className="pin-input-container">
          {isPaid && (
            <>
              {/* <div className="pin-input-header">Input your 4 digit pin</div>
              <div className="pin-input-description">
                Please input your 4 digit pin to transfer fund
              </div> */}
            </>
          )}
          {/* <div className="transaction-detail mt-4">
            <div className="pin-input-description">Receipient</div>
            <div className="transaction-detail-right">
              {type === 'product' ? values?.Profile?.username : 'Wayagram'}
            </div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Amount</div>
            <div className="transaction-detail-right">{numberFormat(amt)}</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Beneficiary Bank:</div>
            <div className="transaction-detail-right">Waya Bank</div>
          </div> */}
          {type === 'vote' && (
            <>
              <div className="text-center">
                You will be charged NGN{values?.Poll?.amount} for this vote
              </div>
            </>
          )}
          {type !== 'event' && type !== 'vote' && (
            <>
              <div className="transaction-detail mt-4">
                <div className="transaction-detail-right">Product Name</div>
                <div className="pin-input-description" type="text">
                  {values?.name}
                </div>
              </div>
              <div className="transaction-detail mt-4">
                <div className="transaction-detail-right">
                  Product descriptions
                </div>
                <div className="pin-input-description" type="text">
                  {values?.description}
                </div>
              </div>
              <div
                className="transaction-detail mt-4"
                hidden={type === 'productSale'}
              >
                <div className="transaction-detail-right">Seller’s Details</div>
                <div className="pin-input-description" type="text" />
                <div className="transaction-detail-right">
                  {values?.Profile?.username}
                </div>
              </div>
              <div className="my-4 w-100">
                {postImages?.length > 2 ? (
                  <ProductImageCard PostImages={postImages} />
                ) : (
                  <PostImageCard PostImages={postImages} />
                )}
              </div>
              {type !== 'productSale' && type !== 'productPurchase' && (
                <>
                  <div className="my-4 w-80">
                    <label className="form-label">State</label>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      name="locations"
                      value={location?.state}
                      onChange={(e) => setLocation({ ...location, state: e })}
                      placeholder="Lagos"
                    >
                      {/* <Option value="all states">All states</Option> */}
                      {filteredOptions
                        ? filteredOptions.map((item) => (
                            <Option value={item.name} key={item.state_code}>
                              {item.name}
                            </Option>
                          ))
                        : ''}
                    </Select>
                  </div>
                  <div className="m-3 w-80">
                    <p className="mb-2">City</p>
                    <input
                      type="text"
                      className={`w-100 border border-bottom bg-${
                        theme.mode === 'dark' ? 'secondary' : 'white'
                      }`}
                      placeholder="City"
                      value={location?.city}
                      onChange={(e) =>
                        setLocation({ ...location, city: e.target.value })
                      }
                    />
                  </div>
                  <div className="m-3 w-80">
                    <p className="mb-2">Delivery Address</p>
                    <input
                      type="text"
                      className={`w-100 border border-bottom bg-${
                        theme.mode === 'dark' ? 'secondary' : 'white'
                      }`}
                      placeholder="Enter Delivery Address"
                      value={location?.deliveryAddress}
                      onChange={(e) =>
                        setLocation({
                          ...location,
                          deliveryAddress: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
              <div className="transaction-detail mt-4 ">
                <p className="transaction-detail-right p-2">Product Quantity</p>
                {type === 'productSale' ? (
                  <div className="pin-input-description" type="text">
                    {values?.qty}
                  </div>
                ) : (
                  <input
                    className="pin-input-description"
                    type="number"
                    defaultValue="1"
                    min="1"
                    value={ticketNo}
                    onChange={(e) => setTicketNo(e.target.value)}
                  />
                )}
              </div>
              <div
                className="transaction-detail mt-4"
                hidden={type !== 'productSale'}
              >
                <div className="transaction-detail-right">Buyer’s Details</div>
                <div className="pin-input-description" type="text" />
                <div className="transaction-detail-right">
                  {values?.buyerInfo?.username}
                </div>
              </div>
              <div
                className="transaction-detail mt-4"
                hidden={type !== 'productSale'}
              >
                <div className="transaction-detail-right">
                  Order Status
                  <p
                    className={
                      values?.isDelivered ? 'text-success' : 'text-danger'
                    }
                  >
                    {values?.isDelivered ? 'Delivered' : 'Not Delivered'}
                  </p>
                </div>
                <div className="transaction-detail-right">
                  Buyer Acceptance Status
                  <p
                    className={
                      values?.isRecieved ? 'text-success' : 'text-warning'
                    }
                  >
                    {values?.isRecieved ? 'Accepted' : 'Not accepted'}
                  </p>
                </div>
                <div className="transaction-detail-right">
                  {' '}
                  <div className="transaction-detail-right">
                    Payment Status
                    <p
                      className={
                        values?.isRefunded ? 'text-success' : 'text-warning'
                      }
                    >
                      {values?.isRefunded ? 'Paid' : 'Pending'}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* {isPaid && (
            <div className="transaction-detail mt-4">
              <div className="pin-input-description">From</div>
              <div className="transaction-detail-right">
                <select
                  className="mb-1"
                  value={account}
                  onChange={handleSetAmt}
                >
                  <option>Select Account</option>
                  {walletList?.map((list) => (
                    <option
                      key={Math.random()}
                      value={`${list?.accountNo}-${list?.clr_bal_amt}`}
                    >
                      {`${list?.accountNo} (${numberFormat(
                        list?.clr_bal_amt
                      )})`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )} */}
          {/* <div className="transaction-detail mt-4">
            <div className="pin-input-description">Transaction fee:</div>
            <div className="transaction-detail-right">
              {numberFormat(trnxFee || 0)}
            </div>
          </div> */}
          {/* <div className="transaction-detail mt-4">
            <div className="pin-input-description">Total Amount</div>
            <div className="transaction-detail-right">
              {numberFormat(amt + trnxFee || 0)}
            </div>
          </div> */}
          {type === 'event' && (
            <div className="my-2">
              <input
                className="w-100 border-0 border-bottom"
                name="no_of_ticket"
                value={ticketNo}
                onChange={(e) => setTicketNo(e.target.value)}
                placeholder="No of ticket"
                type="number"
                min={1}
              />
              <span>Specify number of ticket</span>
            </div>
          )}
          {/* {isPaid && (
            <OtpInput
              value={otp}
              onChange={(e) => setOtp(e)}
              separator=" "
              containerStyle={{ justifyContent: 'center' }}
              inputStyle="otp-input otp-bottom"
              isInputSecure
            />
          )} */}
          {/* <p className="mt-3">
            Kindly visit&nbsp;
            <a href="https://wayabank.ng/" rel="noreferrer" target="_blank">
              Wayabank&nbsp;
            </a>
            to create your transaction pin
          </p> */}
          <div className="w-100 mt-5 text-center">
            <Button
              type="button"
              loading={loading}
              disabled={loading || false}
              onClick={handleVerifyPin}
              content={
                type === 'productSale'
                  ? 'Initiate Delivery'
                  : type === 'productPurchase'
                  ? 'Accept Delivery'
                  : type === 'vote'
                  ? 'Continue'
                  : 'Confirm'
              }
              className="finish-card"
            />
          </div>
        </div>
        {trxStatusModal?.status ? (
          <TransactionStatusModal
            showModal={trxStatusModal}
            hideModal={setTrxStatusModal}
          />
        ) : (
          ''
        )}
        {fundWallet ? (
          <FundWallet
            showModal={fundWallet}
            hideModal={setFundWallet}
            id={values?.profile_id}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
}

export default PayForProduct;
