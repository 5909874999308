import React, { useState } from 'react';
import BidQuestion from '../modal/BidQuestion';
import formatNaira from '../../../../../../utils/formatNaira';

const BuyerBidTable = ({ bids, history }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <table className="bidding-list-table">
        <thead className="bidding-list-thead">
          <tr className="bidding-list-header-row">
            <th className="bidding-list-header-cell">Vendor Name</th>
            <th className="bidding-list-header-cell">Vendor City</th>
            <th className="bidding-list-header-cell">Bid Amount</th>
            <th className="bidding-list-header-cell">Accept Bid</th>
            <th className="bidding-list-header-cell">View Profile</th>
            <th className="bidding-list-header-cell">Ask question</th>
            <th className="bidding-list-header-cell">Bid Details</th>
            <th className="bidding-list-header-cell">More Actions</th>
          </tr>
        </thead>
        <tbody className="bidding-list-tbody">
          {bids.map((bid) => (
            <tr key={bid.bidID} className="bidding-list-row">
              <td className="bidding-list-cell">{bid.vendorName}</td>
              <td className="bidding-list-cell">{bid.quote.state}</td>
              <td className="bidding-list-cell">{formatNaira(bid.amount)}</td>
              <td className="bidding-list-cell">
                <button type="button" className="bidding-list-accept-button">
                  Accept Bid
                </button>
              </td>
              <td className="bidding-list-cell">
                <button
                  className="bidding-list-accept-button"
                  type="button"
                  onClick={() => history.push('/bidding/vendor')}
                >
                  Vendor Profile
                </button>
              </td>
              <td
                className="bidding-list-cell ask-question"
                onClick={() => setShowModal(true)}
              >
                Ask question
              </td>
              <td className="bidding-list-cell">
                <button
                  type="button"
                  onClick={() => history.push(`/bid/item/${bid.quote.quoteID}`)}
                  className="bidding-list-accept-button bid-details"
                >
                  Bid details and comment
                </button>
              </td>
              <td className="bidding-list-cell">
                <button type="button" className="bidding-list-accept-button">
                  More Actions
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && <BidQuestion onClose={() => setShowModal(false)} />}
    </>
  );
};

export default BuyerBidTable;
