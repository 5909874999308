import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import '../FundWallet/index.scss';
import { customBaseUrl, httpPatch, httpPost } from '../../../../action/http';
import Button from '../../Button';
import './imgGrid.scss';
import { PollOptionPreview, PollPreview } from './postUtils';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import PollDisclaimer from './PollDisclaimer';
import MentionInput from '../../CustomInput/MentionInput';
// import TermsAndCondition from '../Terms/TermsAndCondition';
import { userTheme } from '../../../../store/initialState';

const CreatePoll = (props) => {
  const theme = useRecoilValue(userTheme);
  const { showModal, hideModal, mode, selectedData, type } = props;
  const { id: pid } = useParams();
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  // const postOptions = selectedData?.options
  // const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPollOption, setShowPollOption] = useState(false);
  const {
    wayagramProfile: profile,
    reload,
    setReload,
  } = useContext(WayagramContext);
  const [showPoll, setShowPoll] = useState(true);
  const [showPollDisclaimer, setShowPollDisclaimer] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);
  const [errors, setErrors] = useState(null);

  const handlePollOption = () => {
    setShowPoll(false);
    setShowPollOption(true);
  };

  const createPollOptions = async (newFormOpt) => {
    await httpPost(
      '/all-posts/poll-option-create',
      newFormOpt,
      customBaseUrl.wayagramUrl
    );
    setReload(!reload);
  };

  const createWayagramPostCont = async () => {
    setLoading(true);
    let isPaid = false;
    /* eslint-disable no-unused-expressions */
    postData.isPaid ? (isPaid = true) : (isPaid = false);
    if (postData.description === '') {
      swal('Error', 'Poll Description cannot be Empty', 'error');
      setLoading(false);
      return;
    }
    if (postData.voteLimit === undefined) {
      swal('Error', 'Please input poll vote user limit', 'error');
      setLoading(false);
      return;
    }
    if (mode === 'create' && postData?.options.length < 2) {
      swal('Error', 'Please poll options has to be two or more', 'error');
      setLoading(false);
      return;
    }
    const newForm = new FormData();
    // newForm.append('profile_id', profile.id);
    // const updateOption = [];
    // postData?.options?.forEach((tag) => {
    //   postOptions.filter(tag)
    //   updateOption.push(tag);
    // });
    pid && type === 'page' && newForm.append('page_id', pid);
    pid && type === 'group' && newForm.append('group_id', pid);
    newForm.append(
      'pollCategory',
      postData.pollCategory ? postData.pollCategory : 'text'
    );
    mode === 'create' && newForm.append('type', type);
    mode === 'edit' && newForm.append('poll_id', postData?.poll_id);
    postData.description && newForm.append('description', postData.description);
    postData.voteLimit && newForm.append('voteLimit', postData.voteLimit);
    newForm.append('amount', postData?.amount || 0);
    newForm.append('isPaid', isPaid);
    newForm.append('showResult', postData?.showResult === 'true');
    mentionList?.length && newForm.append('mentions', mentionList);
    hashTagList?.length && newForm.append('hashtags', hashTagList);
    newForm.append(
      'startDate',
      moment(postData?.startDate).format('L')
      // new Date(postData?.startDate)?.toLocaleDateString()
    );
    newForm.append(
      'endDate',
      moment(postData?.endDate).format('L')
      // new Date(postData?.endDate)?.toLocaleDateString()
    );
    newForm.append('startTime', postData?.startTime);
    newForm.append('endTime', postData?.endTime);

    const url = `/all-posts/${
      mode === 'edit' ? 'update-poll-post' : 'poll-create'
    }`;
    let res = null;
    if (mode === 'edit') {
      res = await httpPatch(url, newForm, customBaseUrl.wayagramUrl);
    } else {
      res = await httpPost(url, newForm, customBaseUrl.wayagramUrl);
    }
    if (res?.status) {
      console.log('options', postData.option);
      postData.options &&
        postData?.options?.forEach(async (option, i) => {
          const newFormOpt = new FormData();
          newFormOpt.append('pollId', res.data.id);
          newFormOpt.append('option', option.option);
          newFormOpt.append('position', i + 1);
          option.description &&
            newFormOpt.append('short_desc', option.description);
          option.file && newFormOpt.append('file', option.file);
          await createPollOptions(newFormOpt);
        });
      setReload(!reload);
      setLoading(false);
      hideModal(false);
      NotificationManager.success(res?.message, 'Done!', 3000);
      // apiModalResponse(res, setTrxStatusModal, setShowSharePeople);
    } else {
      setLoading(false);
      NotificationManager.error(res?.message, 'Oops!', 3000);
    }
  };

  const createWayagramPost = async () => {
    if (postData.isPaid) {
      await swal({
        title: 'Payment Info',
        text: 'All paid votes will be credited into your Wayabank (default wallet) at the end of the post duration. A commision of total payment will be paid to Waya.',
        buttons: true,
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          createWayagramPostCont();
        } else {
          setLoading(false);
        }
      });
    } else {
      createWayagramPostCont();
    }
  };

  // const handleSubmitEdit = () => {
  //   setLoading(true);
  //   const d = {
  //     amount: postData?.amount,
  //     description: postData?.description,
  //     endDate: postData?.endDate,
  //     startTime: postData?.startTime,
  //     isPaid: postData?.isPaid,
  //     pollCategory: postData?.pollCategory,
  //     poll_id: postData?.poll_id,
  //     // showOptionResult: false
  //     showResult: postData?.showResult,
  //     startDate: postData?.startDate,
  //     endTime: postData?.endTime,
  //     voteLimit: postData?.voteLimit,
  //   };
  //   handleSubmit(d, 'poll');
  //   setLoading(false);
  // };
  // console.log(postData);

  const handleChange = (query) => {
    setPostData({ ...postData, description: query });
  };

  const handleGoBack = () => {
    setShowPollOption(false);
    setShowPoll(true);
  };

  const handleNext = () => {
    if (
      loading ||
      !postData.description ||
      !postData.accept ||
      !postData.startDate ||
      !postData.endDate ||
      postData.showResult === '' ||
      !postData.showOptionResult === '' ||
      !postData.pollCategory ||
      (postData.isPaid && !postData.amount) ||
      !postData.voteLimit
    ) {
      setErrors('All input fields marked with an asterisk (*) are mandatory');
      return;
    }
    // if (mode === 'edit') handleSubmitEdit();
    handlePollOption();
    setErrors('');
  };

  return (
    <Modal
      isOpen={showModal}
      id="donation-modal"
      toggle={() => hideModal(false)}
      centered
      size="lg"
    >
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <div className="interest-header normal-header" style={theme.modalStyle}>
          <h3 className="interest-p1" style={theme.modalStyle}>
            {mode === 'create' ? 'Create' : 'Edit'} Poll
          </h3>
        </div>
      </ModalHeader>
      <ModalBody style={theme.modalStyle}>
        <form className="mx-auto mt-3">
          <div className="d-flex" style={{ width: '90%' }}>
            <div className="image-div">
              <img src={profile?.avatar || imgPlaceholder} alt="placeholder" />
            </div>
            <div
              className="poll-container my-3 border mx-2 text center"
              style={{ width: '90%' }}
            >
              <MentionInput
                setText={handleChange}
                text={postData.description}
                setMentionList={setMentionList}
                setHashTagList={setHashTagList}
                placeholder="Describe your poll?"
              />
            </div>
          </div>
          {showPoll && (
            <PollPreview
              setPostData={setPostData}
              postData={postData}
              options={postData.options}
              // setShowTermsModal={setShowTermsModal}
              mode={mode}
              theme={theme}
            />
          )}
          {showPollOption && (
            <div className="my-3 mx-5">
              <PollOptionPreview
                setPostData={setPostData}
                postData={postData}
                handleGoBack={handleGoBack}
                options={postData.options}
                // setShowTermsModal={setShowTermsModal}
                theme={theme}
              />
            </div>
          )}
          {showPollDisclaimer && (
            <PollDisclaimer
              showModal={showPollDisclaimer}
              setShowPoll={setShowPoll}
              hideModal={setShowPollDisclaimer}
            />
          )}
          {postData?.isPaid === true && (
            <div className="payment-info my-3 mx-auto">
              <div className="mb-1">Payment Info</div>
              <div>
                Waya will take a commission from the payment and the rest of the
                money will be paid into your account at the end of post duration
                or its expirey date.
              </div>
            </div>
          )}
        </form>
        <p className="text-primary text-center">{errors}</p>
      </ModalBody>
      <ModalFooter style={theme.modalStyle}>
        <div className="w-100 text-center">
          <Button
            loading={loading}
            className="btn btn-primary mb-3 mt-2 w-50"
            color="primary"
            content={
              showPoll ? 'Continue' : mode === 'edit' ? 'Update' : 'Create'
            }
            onClick={showPoll ? handleNext : createWayagramPost}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

CreatePoll.defaultProps = {
  // id: '',
  mode: 'create',
  // selectedData: {},
};

CreatePoll.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  // id: PropTypes.string,
  type: PropTypes.string.isRequired,
  mode: PropTypes.string,
  // handleSubmit: PropTypes.func.isRequired,
  // selectedData: PropTypes.node,
};

export default CreatePoll;
