import React, { createContext, useState } from 'react';

export const HeaderContext = createContext();

export const HeaderContextProvider = (props) => {
  const { children } = props;
  const [headers, setHeaders] = useState({
    image:
      'https://drive.google.com/file/d/1imiq20Vq3RMzb-ohusYImVu_3DJny7XI/view?usp=sharing',
    title: 'Wayagram - World is yours to explore',
    url: process.env.REACT_APP_WEBSITE_APP,
  });

  return (
    <HeaderContext.Provider
      value={{
        headers,
        setHeaders,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
