import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import successIcon from '../../../assets/image/success.png';
import './FundWallet/index.scss';

const SuccessModal = (props) => {
  const history = useHistory();
  const { showModal, hideModal, id, message, hideBankAccountModal } = props;
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalBody>
        <div>
          <div className="text-center mt-4">
            <img src={successIcon} alt="success" />
          </div>
          <h4 className="mt-5 mb-3 text-center">Successful</h4>
          <p className="text-secondary mb-3 text-center">{message}</p>
        </div>
      </ModalBody>
      <ModalFooter className="p-3" style={{ background: '#F2F2F2' }}>
        <div
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className="w-100 text-center text-primary"
          onClick={() => {
            hideModal(false);
            hideBankAccountModal(false);
            history.goBack();
          }}
        >
          Go back to Home
        </div>
      </ModalFooter>
    </Modal>
  );
};

SuccessModal.defaultProps = {
  id: '',
};

SuccessModal.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  message: PropTypes.string.isRequired,
  hideBankAccountModal: PropTypes.func.isRequired,
};

export default SuccessModal;
