import React from 'react';
import PropTypes from 'prop-types';

import './eventModal.scss';

const EventTicket = (props) => {
  const { handleFormInput, values, theme } = props;
  return (
    <div className="event-inner-content">
      <div className="input-container mt-3">
        <select
          name="isPaid"
          onChange={(e) => {
            handleFormInput(e.target.value, 'isPaid');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          value={values?.isPaid}
        >
          <option value="true">Paid to attend</option>
          <option value="false">Free to attend</option>
        </select>
        <span>
          Free to attend or paid event?
          <span className="text-primary"> *</span>
        </span>
      </div>
      {values.isPaid === 'true' && (
        <div className="input-container">
          <input
            name="amount"
            value={values.amount}
            type="number"
            onChange={(e) => {
              handleFormInput(e.target.value, 'amount');
            }}
            className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            placeholder="Amount"
            min={1}
          />
          <span>
            Specify event fee
            <span className="text-primary"> *</span>
          </span>
        </div>
      )}
      <div className="input-container">
        <input
          name="no_of_ticket"
          value={values.no_of_ticket}
          onChange={(e) => {
            handleFormInput(e.target.value, 'no_of_ticket');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Capacity"
          type="number"
          min={1}
        />
        <span>
          Specify max capacity
          <span className="text-primary"> *</span>
        </span>
      </div>
    </div>
  );
};

EventTicket.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventTicket;
