import { ArrowForwardIos } from '@material-ui/icons';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { BankOutlined } from '@ant-design/icons';
import { Wallet } from '@mui/icons-material';
import { Modal, ModalBody } from 'reactstrap';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import Quick from '../../../../assets/wayaquick.png';
// import Bank from '../../../../assets/newlog.png';
import Paystack from '../../../../assets/paystackpng.png';

const Funding = (props) => {
  const {
    showModal,
    hideModal,
    // title,
    description,
    loggedInUser,
    setSHowBanks,
    // relooad,
    // setReload,
  } = props;
  const [showOption, setShowOption] = useState(false);
  const [data, setData] = useState({});
  const [paymentGateway, setPaymentGateway] = useState('WAYAQUICK');

  const handleFunding = async (e) => {
    e.preventDefault();
    const postData = {
      ...data,
      firstName: loggedInUser?.displayName.split(' ')[0],
      lastName: loggedInUser?.displayName.split(' ')[1],
      email: loggedInUser?.email,
      phoneNumber: loggedInUser?.phone,
      currency: 'NGN',
      userId: loggedInUser.UserId,
      paymentGateway,
    };
    showLoader();
    const res = await httpPost(
      '/api/v1/wallet/init-transaction',
      postData,
      customBaseUrl.walletUrl
    );
    if (res?.status) {
      hideLoader();
      window.open(`${res?.data?.authorization_url}`, '_self');
    } else {
      hideLoader();
      Swal.fire('Oops!', res?.message);
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <Modal isOpen={showOption} toggle={() => setShowOption(false)} centered>
        <ModalBody>
          <div className="mx-4">
            <form onSubmit={handleFunding}>
              <input
                type="number"
                min={1}
                required
                placeholder="amount"
                className="my-2 border rounded w-full"
                onChange={(e) => setData({ ...data, amount: e.target.value })}
              />
              <input
                placeholder="narration"
                className="my-2 border rounded w-full"
                required
                onChange={(e) =>
                  setData({ ...data, narration: e.target.value })
                }
              />
              <button
                // role="button"
                type="submit"
                // tabIndex={0}
                aria-hidden="true"
                className="col-12 text-center text-primary pt-3 pb-3"
                // onClick={handleFunding}
              >
                CONTINUE
              </button>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ModalBody>
        <div>
          <h3 className="mt-5 mb-3 text-center fw-bold text-[#fe6533]">
            Fund Wallet <Wallet />
          </h3>
          <p className="text-secondary mb-3 text-center">{description}</p>
          <div className="col border rounded px-2">
            <div
              role="button"
              className="flex flex-row mx-10 my-3"
              onClick={() => {
                setSHowBanks(true);
                hideModal(false);
              }}
              tabIndex={0}
            >
              <div className="col-sm-2">
                {/* <img src={BankLogo} alt="bnk logo" /> */}
                <BankOutlined style={{ fontSize: 40 }} />
              </div>
              <div className="col-sm-8 px-2 mx-2">
                <div>
                  <h5 className="fw-bold">Bank account</h5>
                  <h6 className="text-sm">Fund you account from bank</h6>
                </div>
              </div>
              <div className="justify-end">
                <ArrowForwardIos />
              </div>
            </div>
            <div
              role="button"
              className="flex flex-row mx-10 my-5"
              tabIndex={0}
              onClick={() => {
                setShowOption(true);
                setPaymentGateway('WAYAQUICK');
              }}
            >
              <div className="col-sm-2">
                <img src={Quick} alt="bnk logo" />
              </div>
              <div className="col-sm-8 px-2 mx-2">
                <div>
                  <h5 className="fw-bold">Wayaquick</h5>
                  <h6 className="text-sm">Fund your account from wayaquick</h6>
                </div>
              </div>
              <div className="justify-end">
                <ArrowForwardIos />
              </div>
            </div>
            <div
              role="button"
              className="flex flex-row mx-10 my-5"
              tabIndex={0}
              onClick={() => {
                setShowOption(true);
                setPaymentGateway('PAYSTACK');
              }}
            >
              <div className="col-sm-2">
                <img src={Paystack} alt="bnk logo" />
              </div>
              <div className="col-sm-8 px-2 mx-2">
                <div>
                  <h5 className="fw-bold">Paystack</h5>
                  <h6 className="text-sm">Fund your account from paystack</h6>
                </div>
              </div>
              <div className="justify-end">
                <ArrowForwardIos />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* <ModalFooter className="p-0" style={{ border: '1px solid  #F2F2F2' }}>
        <div className="col-12 m-0">
          <div className="row">
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              style={{ borderRight: '1px solid  #F2F2F2' }}
              onClick={() => hideModal(false)}
            >
              CANCEL
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              onClick={handleSubmit}
            >
              CONTINUE
            </div>
          </div>
        </div>
      </ModalFooter> */}
    </Modal>
  );
};
export default Funding;
