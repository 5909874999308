import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { dateToNow } from '../../../utils/formatDate';
import {
  addBookmark,
  blockUser,
  handleFollowUser,
  handleMuteUser,
  postProductToCart,
} from '../../../utils/helper';
import CreateComment from '../Modals/Post/CreateComment';
import CreatePoll from '../Modals/Post/CreatePoll';
import CreatePostModal from '../Modals/Post/CreatePost';
import CreateProductModal from '../Modals/Post/CreateProduct';
import DonationModal from '../Modals/Post/DonationModal';
import MakeDonationModal from '../Modals/Post/MakeDonation';
import Report from '../Modals/ReportAndDispute';
import CardBottomContent from './GramCards/CardBottomContent';
import CardMiddleContent from './GramCards/CardMiddleContent';
import TopCardContent from './GramCards/TopCardContent';
import { GramCard } from './style';
// import { customBaseUrl, httpPatch } from '../../../action/http';
import { inviteToAPage } from '../../pages/Wayagram/container';
import { PromotedIcon } from '../Icons';
import Invite from '../Modals/Invite';
import DonationView from '../Modals/Post/DonationModal/DonationView';
import CreateContest from '../Modals/Post/CreateContest';
import useStore from '../../../store/zustandStore/store';
import { OrderContext } from '../../../store/context/OrderContext';

export default function Gram(props) {
  const {
    wayagramProfile: userProfile,
    reload,
    setReload,
    loggedInUser,
  } = useContext(WayagramContext);
  const { orders } = useContext(OrderContext);

  const { match } = props;
  const profileId = userProfile.id;
  const authId = userProfile.UserId;
  const [btnLoading, setBtnLoading] = useState(false);
  const [sel, setSel] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [payDonation, setPayDonation] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showCreateContest, setShowCreateContest] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showInvitePeople, setShowInvitePeople] = useState(false);
  const [showCreateComment, setShowCreateComment] = useState(false);
  const [inviteMembers, setInviteMemembers] = useState([]);
  const { data, handleDeletePost, fromLocation, pid, isOption, kind } = props;

  const profile = data?.profile || data?.Profile || data?.DonatorProfile;
  const history = useHistory();
  const {
    description,
    likeCount,
    id: postId,
    Donation,
    Product,
    Poll,
    createdAt,
    isPoll,
    repostedBy,
    repostCommentDesc,
    isRepostWithComment,
    OriginalOwner,
    isPromoted,
    PostTags,
    PostImages,
    isPagePost,
    Page,
    isBookmarked,
  } = data;

  const OriginalProfile =
    OriginalOwner?.profile ||
    OriginalOwner?.Profile ||
    OriginalOwner?.DonatorProfile;
  const { sendProductToCart, setOpenCart } = useStore((state) => state);
  const [showDonationDetails, setShowDonationDetails] = useState(false);
  const [likes, setlikes] = useState(likeCount);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [showPayProduct, setShowPayProduct] = useState(false);
  const [productType, setProductType] = useState(null);
  const [typeReport, setTypeReport] = useState('');
  const myRef = React.useRef(null);
  const userId =
    kind === 'contest' ? data?.Contestant?.profileId : data?.ProfileId;

  const handleShowPostModal = async (selected, type) => {
    if (type === 'donation' || fromLocation === 'profileDonation')
      setShowDonationModal(true);
    else if (type === 'product') setShowCreateProduct(true);
    else if (type === 'contest') setShowCreateContest(true);
    else if (selected.isPoll || fromLocation === 'profilePoll')
      setShowCreatePoll(true);
    else setShowCreatePost(true);
    setSelectedData(selected);
  };

  const handleOption = async (type, id) => {
    console.log({ type });
    if (type === 'editPost') {
      const selectedD = {
        postId: id,
        poll_id: Poll?.id || data?.id,
        voteLimit: Poll?.voteLimit || data?.voteLimit,
        description,
        options: Poll?.PollOptions || data?.PollOptions,
        amount: Poll?.amount || Product?.amount || data.amount,
        showResult: Poll?.showResult || data?.showResult,
        showOptionResult: Poll?.showOptionResult || data?.showOptionResult,
        pollCategory: Poll?.pollCategory || data?.pollCategory,
        isPaid: Poll?.isPaid || data?.isPaid,
        isPoll,
        donation: Donation || data,
        ProductImages: Product?.ProductImages,
        postImages: PostImages,
        productId: Product?.id,
        name: Product?.name,
        startDate: Poll?.startDate || data?.startDate,
        startTime: Poll?.startTime || data?.startTime,
        endDate: Poll?.endDate || data?.endDate,
        endTime: Poll?.endTime || data?.endTime,
      };
      handleShowPostModal(selectedD, kind);
    } else if (type === 'bookmark') {
      addBookmark({ postId }, profileId, () => setReload(!reload));
    } else if (type === 'deletePost') {
      handleDeletePost(id);
    } else if (type === 'reportUser') {
      // handleDeletePost(id);
      const t = isPoll ? 'OTHERS' : kind || 'Post';
      setTypeReport(t.toUpperCase());
      setShowReportModal(true);
    } else if (type === 'blockUser') {
      blockUser(profile.username, authId, () => setReload(!reload));
    } else if (type === 'followUser') {
      handleFollowUser(profile.username, authId, () => setReload(!reload));
    } else if (type === 'muteUser') {
      handleMuteUser(profile.username, authId, () => setReload(!reload));
    } else if (type === 'donation') {
      setShowDonationDetails(true);
    } else if (type === 'invite') {
      setShowInvitePeople(true);
    }
  };

  // const handleEditPost = async (selected, type) => {
  //   const isPol = type === 'poll';
  //   const url = `/all-posts/${isPol ? 'update-poll-post' : 'update'}`;
  //   const res = await httpPatch(url, selected, customBaseUrl.wayagramUrl);
  //   if (res?.status) {
  //     swal('Done', res?.message, 'success').then(() => {
  //       setShowCreatePost(false);
  //       setShowCreatePoll(false);
  //       setShowDonationModal(false);
  //       setReload(!reload);
  //     });
  //   } else {
  //     swal('Oops!', res?.message, 'error');
  //   }
  // };

  const handleInvite = async (invitee) => {
    setBtnLoading(true);
    const invite = await inviteToAPage(
      loggedInUser?.id,
      invitee,
      match?.params?.id
    );
    setBtnLoading(false);
    if (invite?.status) {
      swal('success', invite?.message, 'success');
    } else {
      swal('error', invite?.message, 'error');
    }
  };

  const handleAddProductToCart = async (qty = 1) => {
    const isUserLoggedIn = localStorage.getItem('wayaProfileId');
    if (!isUserLoggedIn) {
      NotificationManager.error(
        'You need to login to add to cart',
        'Error',
        3000
      );

      return;
    }

    const pendingOrderExist = orders.find(
      (order) => order?.sellerProfileId === data?.Product?.ProfileId
    );
    if (pendingOrderExist && pendingOrderExist.paymentStatus === 'PENDING') {
      swal(
        'Info',
        'You have a pending order with this seller. Kindly delete or pay for the order before adding new product from this seller to cart'
      );
      return;
    }
    const d = {
      profileId,
      productId: data.Product.id,
      quantity: qty,
    };

    const res = await postProductToCart(d, profileId);
    if (res?.status) {
      // sendProductToCart({ ...res?.data, Product: data.Product });
      const updatedCart = {
        CartProduct: {
          cartId: res?.data?.cart?.id,
          productId: res?.data?.cart?.productDetails?.id,
          quantity: 1,
        },
        ...res?.data?.cart?.productDetails,
        sellerDetails: res?.data?.cart?.sellerDetails,
      };
      sendProductToCart(updatedCart);

      NotificationManager.success(
        'Product added to cart successfully',
        'Success',
        3000
      );
    } else {
      NotificationManager.error(res?.message, 'Error', 3000);
    }
  };

  const handleBuyNow = () => {
    handleAddProductToCart(1);
    setOpenCart(true);
    history.push('/shops');
  };

  return (
    <>
      {/* <HeaderHelmet data={data} /> */}
      {repostCommentDesc ? (
        <div style={{ margin: 15 }}>
          <TopCardContent
            profile={profile}
            handleOption={handleOption}
            fromLocation={fromLocation}
            postId={postId}
            userId={userId}
            profileId={profileId}
            showMenu={false}
            kind={kind || 'Post'}
            isPagePost={isPagePost}
            page={Page}
            id={pid}
            isBookmarked={isBookmarked}
            type={kind}
            isPromo={data?.Product?.isPromo}
            promoFlyer={data?.Product?.promoFlyer}
          />
          <div>{repostCommentDesc}</div>
        </div>
      ) : (
        ''
      )}
      <GramCard ref={myRef} className="p-3">
        {OriginalOwner && !isRepostWithComment && (
          <span className="mb-5 pl-1 pb-5">
            <i className="mdi mdi-twitter-retweet text-success" />
            {repostedBy}
          </span>
        )}
        {isPromoted && (
          <span className="mb-5 pl-1 pb-5">
            <PromotedIcon /> Promoted
          </span>
        )}
        <TopCardContent
          profile={
            OriginalOwner && !isRepostWithComment ? OriginalProfile : profile
          }
          handleOption={handleOption}
          fromLocation={fromLocation}
          postId={postId}
          userId={userId}
          profileId={profileId}
          showMenu={!isOption}
          kind={kind || 'Post'}
          isPagePost={isPagePost}
          page={Page}
          id={pid}
          isBookmarked={isBookmarked}
          type={kind}
          isPromo={data?.Product?.isPromo}
          promoFlyer={data?.Product?.promoFlyer}
        />
        <CardMiddleContent
          data={OriginalOwner && !isRepostWithComment ? OriginalOwner : data}
          setShowPayProduct={setShowPayProduct}
          showPayProduct={showPayProduct}
          setProductType={setProductType}
          productType={productType}
          fromLocation={fromLocation}
        />
        <span className="text-muted">
          {/* {moment(createdAt).format('dddd, MM DD YYYY')} */}
          {/* {dateToFromNowDaily(createdAt)} */}
          {!repostCommentDesc ? `${dateToNow(createdAt)} ago` : ''}
        </span>
        <CardBottomContent
          setlikes={setlikes}
          likes={likes}
          profileId={profileId}
          reload={reload}
          setReload={setReload}
          setShowCreateComment={setShowCreateComment}
          setPayDonation={setPayDonation}
          userId={userId}
          setShowPayProduct={setShowPayProduct}
          setProductType={setProductType}
          data={data}
          handleBuyNow={handleBuyNow}
          handlePostComment={() => {
            if (fromLocation === 'profileDonation') {
              history.push(`/post/${data?.PostId}`);
            } else {
              history.push(`/post/${postId}`);
            }
          }}
          fromLocation={fromLocation}
          type={kind}
        />
        {repostCommentDesc ? `${dateToNow(createdAt)} ago` : ''}
      </GramCard>
      {payDonation && (
        <MakeDonationModal
          showModal={payDonation}
          hideModal={setPayDonation}
          userData={userProfile}
          donationId={Donation?.id || data?.id}
          data={data}
        />
      )}

      {showReportModal && (
        <Report
          showModal={showReportModal}
          hideModal={setShowReportModal}
          title="Report User"
          reported={profile}
          type={typeReport}
          reporter={userProfile}
          itemId={postId}
        />
      )}
      {showCreateComment && (
        <CreateComment
          showModal={showCreateComment}
          hideModal={setShowCreateComment}
          mode="repost"
          post={data}
          profile={profile}
          reload={reload}
          setReload={setReload}
        />
      )}
      {showDonationModal && (
        <DonationModal
          showModal={showDonationModal}
          hideModal={setShowDonationModal}
          selectedData={selectedData}
          edit
        />
      )}
      {showDonationDetails && (
        <DonationView
          showModal={showDonationDetails}
          hideModal={setShowDonationDetails}
          data={data}
        />
      )}
      {showCreatePost && (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="edit"
          PostTags={PostTags}
          type="user"
          selectedData={selectedData}
        />
      )}
      {showCreateProduct && (
        <CreateProductModal
          showModal={showCreateProduct}
          hideModal={setShowCreateProduct}
          PostTags={PostTags}
          selectedData={selectedData}
          id="create-post-modal"
          mode="edit"
        />
      )}
      {showCreateContest && (
        <CreateContest
          showModal={showCreateContest}
          hideModal={setShowCreateComment}
          PostTags={PostTags}
          selectedData={selectedData}
          id="create-post-modal"
          mode="Create"
        />
      )}
      {showCreatePoll && (
        <CreatePoll
          showModal={showCreatePoll}
          hideModal={setShowCreatePoll}
          selectedData={selectedData}
          id="create-post-modal"
          mode="edit"
          type="user"
        />
      )}
      {showInvitePeople && (
        <Invite
          showModal={showInvitePeople}
          hideModal={setShowInvitePeople}
          btnLoading={btnLoading}
          setInviteMembers={setInviteMemembers}
          inviteMembers={inviteMembers}
          handleNewChat={handleInvite}
          selected={sel}
          setSelected={setSel}
        />
      )}
    </>
  );
}

Gram.propTypes = {
  // data: PropTypes.shape.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  // showPostModal: PropTypes.func.isRequired,
  // handleLikePost: PropTypes.func.isRequired,
};
