import React, { useState, useContext, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import swal from 'sweetalert';
import ball from '../../../../assets/images/ball.svg';
import imgPlaceholder from '../../../../assets/images/plusCircle.png';
import food from '../../../../assets/images/plate.svg';
import nature from '../../../../assets/images/hill.png';
import rabbit from '../../../../assets/images/rabbit.png';
import star from '../../../../assets/images/star.svg';
import checked from '../../../../assets/images/check_circle.png';
import './index.scss';
import {
  addSelectedInterest,
  removeSelectedInterest,
} from '../../../pages/Wayagram/container';
import { LayoutContext } from '../../../../store/context/LayoutContext';

const recommendlist = [
  {
    name: 'Legacy Kitchen',
    address: 'Ikoyi, Lagos',
    image: ball,
    id: 1,
  },
  {
    name: 'Pancake hub',
    address: 'Yaba, Lagos',
    image: food,
    id: 2,
  },
  {
    name: 'Quid by Debrit',
    address: 'Lekki phase 1, Lagos',
    image: nature,
    id: 3,
  },
  {
    name: 'Onyeabor Prince ',
    address: 'Ikoyi, Lagos',
    image: rabbit,
    id: 4,
  },
];

function InterestModal(props) {
  const { unAddedInterest } = useContext(LayoutContext);
  const {
    showModal,
    hideModal,
    step,
    selectedInterest,
    profileId,
    reload,
    setReload,
  } = props;
  const headers = ['Select your interest', 'Recommended for you'];
  const description = [
    'What items are you interested in',
    'Here are some people, pages and groups to follow based on your interest',
  ];

  const handleSubmitInterest = (selectedInteres) => {
    if (selectedInteres.length) {
      setReload(!reload);
      // localStorage.setItem('gramHandleSetup', true);
      hideModal(false);
    } else {
      swal('Wait', 'Select an Interest', 'error');
    }
  };

  const addInterest = async (interestId) => {
    const data = { profileId, interestId };
    await addSelectedInterest(data);
  };

  const removeInterest = async (userInterestId) => {
    const data = { profileId, userInterestId };
    await removeSelectedInterest(data);
  };

  const handleSubmitRecommend = () => {
    hideModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      id="interest-modal"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <div className="interest-header normal-header">
          <h3 className="interest-p1">{headers[step]}</h3>
          <div className="description-header">
            <p className={step === 2 ? 'description-text' : ''}>
              {description[step]}
            </p>
          </div>
        </div>
        <div className="interest-header mobile-header">
          <h3 className="interest-p1">{headers[step]}</h3>
          <p className="interest-p2">{description[step]}</p>
        </div>
        <InterestCard
          step={step}
          submitInterest={handleSubmitInterest}
          submitRecommend={handleSubmitRecommend}
          removeInterest={removeInterest}
          addInterest={addInterest}
          unAddedInterest={unAddedInterest}
          selectedInterests={selectedInterest}
          reload={reload}
          setReload={setReload}
        />
      </ModalBody>
    </Modal>
  );
}

function InterestCard(props) {
  const {
    step,
    submitInterest,
    submitRecommend,
    unAddedInterest,
    addInterest,
    removeInterest,
    selectedInterests,
    reload,
    setReload,
  } = props;
  const buttonTitle = ['Continue', 'Continue', 'Finish'];
  let selectedInterest = selectedInterests.length
    ? selectedInterests.map((i) => i.id)
    : [];
  const [randomSelected, setRandomSelected] = useState(selectedInterest);
  let selectedRecommend = [];

  const interestAction = () => {
    submitInterest(randomSelected);
  };
  const handleInterest = (interesdtId) => {
    if (!selectedInterest.includes(interesdtId)) {
      selectedInterest = selectedInterest.concat(interesdtId);
      setRandomSelected(selectedInterest);
      addInterest(interesdtId);
    } else {
      removeInterest(interesdtId);
      selectedInterest.splice(selectedInterest.indexOf(interesdtId), 1);
      setRandomSelected(selectedInterest);
    }
  };

  const handleRecommend = (recommendId) => {
    if (!selectedRecommend.includes(recommendId)) {
      selectedRecommend = selectedRecommend.concat(recommendId);
    } else {
      // Implement remove the recommedId
    }
  };

  const recommendAction = () => {
    submitRecommend(selectedRecommend);
  };

  const handleSurprise = () => {
    const numInterests = Math.ceil(unAddedInterest.length / 5);
    const randoms = [];

    for (let i = 0; i < numInterests; i++) {
      const randomIndex = Math.floor(Math.random() * unAddedInterest.length);
      const randomInterest = unAddedInterest[randomIndex];
      handleInterest(randomInterest?.id);
      randoms.push(randomInterest?.title);
    }
    setRandomSelected(randoms);
  };

  const onAction = [interestAction, recommendAction];
  return (
    <div className="interest-container">
      {step === 0 && unAddedInterest.length > 0 ? (
        <>
          <div
            className="float-suprise"
            onClick={handleSurprise}
            hidden={selectedInterests.length}
          >
            <Surprise icon={star} title="Surprise me" />
          </div>
          <InterestList
            list={[...selectedInterests, ...unAddedInterest]}
            selectedInterests={selectedInterests || []}
            handleClick={handleInterest}
            randomSelected={randomSelected}
            reload={reload}
            setReload={setReload}
          />
        </>
      ) : (
        <p className="no-inte">Interest is not available</p>
      )}
      {step === 1 && (
        <RecommendList list={recommendlist} handleRecommend={handleRecommend} />
      )}
      <FinishButton title={buttonTitle[step]} onClick={onAction[step]} />
    </div>
  );
}

function Card(props) {
  const { icon, title, handleClick, selectCard } = props;

  return (
    <div className="interest-card" onClick={() => handleClick()}>
      <img
        className="interest-card-img"
        src={icon || imgPlaceholder}
        alt="interest"
      />
      <p className="interest-card-p1">{title}</p>
      {selectCard.includes(title) ? (
        <img className="interest-status-icon" src={checked} alt="status" />
      ) : (
        ''
      )}
    </div>
  );
}

function InterestList(props) {
  const {
    list,
    handleClick,
    selectedInterests,
    randomSelected,
    reload,
    setReload,
  } = props;
  const onlyTitles = selectedInterests.map((item) => item.title);
  const [selectCard, setSelectCard] = useState([...onlyTitles]);

  const handleSelectedCard = (title) => {
    if (selectCard.includes(title)) {
      setSelectCard([...selectCard.filter((item) => item !== title)]);
    } else setSelectCard([...selectCard, title]);
  };

  useEffect(() => {
    setReload(!reload);
    if (randomSelected.length) {
      setSelectCard([...selectCard, ...randomSelected]);
    }
  }, [randomSelected]);

  return (
    <div className="card-container">
      {list?.map((item) => (
        <Card
          key={item.id}
          title={item.title}
          icon={item.image}
          selectCard={selectCard}
          setSelectCard={setSelectCard}
          handleClick={() => {
            handleClick(item.id);
            handleSelectedCard(item.title);
          }}
        />
      ))}
    </div>
  );
}

function RecommendList(props) {
  const { list, handleRecommend } = props;
  return (
    <div className="recommend-container">
      {list.map((item) => (
        <RecommendCard
          key={item.id}
          name={item.name}
          address={item.address}
          image={item.image}
          handleRecommend={handleRecommend}
          id={item.id}
        />
      ))}
    </div>
  );
}

function RecommendCard(props) {
  const { name, address, image, handleRecommend, id } = props;
  return (
    <div className="recommend-card">
      <div className="recommend-group">
        <div className="recommend-image">
          <img src={image} alt="avatar" />
        </div>
        <div>
          <p className="recomment-card-p1">{name}</p>
          <p className="recomment-card-p2">{address}</p>
        </div>
      </div>
      <FollowButton title="Folow" handleRecommend={handleRecommend} id={id} />
    </div>
  );
}

function FollowButton({ title, handleRecommend, id }) {
  return (
    <div className="follow-button" onClick={handleRecommend(id)}>
      <span className="finish-card-p1">{title}</span>
    </div>
  );
}

function Surprise({ icon, title, onClick = () => {} }) {
  return (
    <div className="surprise-card" onClick={onClick}>
      <img className="surprise-card-img" src={icon} alt="interest icon" />
      <span className="surprise-card-p1">{title}</span>
    </div>
  );
}

// function WayaHandleSetup({ handleInput }) {
//   const [values, setValues] = useState('');
//   const onChange = (input) => {
//     setValues(input);
//     handleInput(input);
//   };
//   return (
//     <div className="wayagram-handle">
//       <input
//         placeholder="Wayagram handle"
//         value={values.handleName}
//         type="text"
//         onChange={(e) => onChange(e.target.value)}
//         name="handleName"
//       />
//       <hr />
//       <p>e.g @John doe</p>
//     </div>
//   );
// }

function FinishButton({ title, onClick }) {
  return (
    <div className="finish-card" onClick={onClick}>
      <span className="finish-card-p1">{title}</span>
    </div>
  );
}

export default InterestModal;
