import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { CusModalHeader } from '../../Components';
// import Button from '../../Button';

export default function ExistingAccount(props) {
  const { showModal, hideModal, id, availableBank } = props;

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <CusModalHeader
        hideModal={() => hideModal(false)}
        title="Available banks to fund wallet"
      />
      <ModalBody>
        {availableBank.data.length
          ? availableBank.data.map((item) => (
              <p key={Math.random()}>{item.name}</p>
            ))
          : ''}
      </ModalBody>
      {/* <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              value={data.walletAccount}
              onChange={(e) => {
                const [
                  accountNumber,
                  accountName,
                  bankCode,
                  bankName,
                ] = e.target.value.split(',');
                setData({
                  ...data,
                  crAccount: accountNumber,
                  crAccountName: accountName,
                  bankCode,
                  bankName,
                });
              }}
            >
              <option value="Select" selected disabled>
                Select Bank Account
              </option>
              {myAccountList.length
                ? myAccountList.map((item) => (
                    <option
                      value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
                      key={item.accountNo}
                    >
                      {`${item.accountName}-${item.bankName}`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">Select saved bank</span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Amount"
              type="text"
              value={data.amount}
              onChange={(e) => setData({ ...data, amount: e.target.value })}
            />
            <span className="input-detail">How much do you want to Send?</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3"
          color="primary"
          onClick={() => setShowPin(true)}
          content="Next"
        />
      </ModalFooter> */}
    </Modal>
  );
}
