import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

function PersonalInfo(props) {
  const { handleFormInput, values, userData } = props;

  return (
    <div className="w-75">
      <div className="input-container mt-5">
        <input name="username" value={userData.username} disabled />
      </div>
      <div className="input-container mt-5">
        <Tooltip
          title={`N${values.minimumAmount} is the minimum you can donate`}
        >
          <input
            min={1}
            type="number"
            name="amount"
            value={values.amount}
            onChange={(e) => handleFormInput(e.target.value, 'amount')}
            placeholder="Amount"
          />
        </Tooltip>
      </div>
      <div className="input-container mt-5">
        <input
          name="description"
          value={values.description}
          onChange={(e) => handleFormInput(e.target.value, 'description')}
          placeholder="Add a Note (optional)"
        />
      </div>
    </div>
  );
}

PersonalInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default PersonalInfo;
