import { ArrowRightAlt } from '@mui/icons-material';
import React from 'react';
import { Spinner } from 'reactstrap';

const AuthButton = ({ loading, content }) => {
  // disabled={disabled || (loading && false)}

  return (
    <button type="submit">
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
          variant="light"
        />
      ) : (
        <p>
          {content} <ArrowRightAlt />
        </p>
      )}
    </button>
  );
};

export default AuthButton;
