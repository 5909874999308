import styled from 'styled-components';

export const previewDiv = {
  // background: '#FFFFFF',
  boxShadow: '0px 4px 10px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '14px',
};

export const PriceDiv = styled.div`
  // padding-left: 20px;
  margin: 15px;
  p {
    font-size: 14px;
  }
  input {
    border: none;
  }
`;

export const RemoveDiv = styled.div`
  width: 100%;
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb5757;
  border-top: 1px solid #edeaea;
  padding: 0px 1.3em;
  p {
    cursor: pointer;
  }
`;

export const PollContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.3em;
  p {
    margin-bottom: 0px;
  }
  .poll-container {
    background: #fff;
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    width: 80%;
    margin: auto;
  }
  .poll-footer {
    width: 21em;
    font-size: 0.8em;
    color: #828282;
    text-align: center;
    margin-top: 0.8em;
  }
  .choice-container {
    min-height: 9.5em;
    padding: 2.5em 1.3em 2em 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    img {
      width: 2em;
    }
    .choice-box {
      input {
        border: none;
        border-bottom: 1px solid #c4c4c4;
        height: 2.4em;
      }
    }
  }
  .poll-duration {
    height: 6.25em;
    width: 100%;
    // border-bottom: 1px solid #c4c4c4;
    p {
      margin: 1.25em 0px 0.5em 1.25em;
    }
    .period-container {
      width: 100%;
      padding: 0 1.2em;
      display: flex;
      justify-content: space-between;
    }
    .period-div {
      width: 20%;
      select {
        border: none;
        width: 100%;
      }
    }
  }
  .third-session {
    width: 100%;
    height: 4.9em;
    padding: 1em 1.2em;
    display: flex;
    justify-content: space-between;
    select {
      border: none;
      width: 100%;
    }
  }
`;
