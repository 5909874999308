import React from 'react';
// import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './eventModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

const EventTime = (props) => {
  const { handleFormInput, values, theme } = props;

  return (
    <div className="event-inner-content">
      <div className="event-time-container mt-5 w-100">
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="event-period">
              <div>
                <label>
                  Event Registration starts
                  <span className="text-primary"> *</span>
                </label>
              </div>
              <div className="">
                <DatePicker
                  name="regStartDate"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.regStartDate, 10)}
                  onChange={(date) => {
                    handleFormInput(date.getTime(), 'regStartDate');
                  }}
                  className={`w-100 border-0 bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="event-period">
              <div>
                <label>Registration Start Time</label>
              </div>
              <div>
                <DatePicker
                  className="w-100 border-0"
                  name="regStartDate"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.regStartDate, 10)}
                  dateFormat="h:mm aa"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="event-time-container mt-5 w-100">
            <div className="event-period">
              <div>
                <label>
                  Event Registration End
                  <span className="text-primary"> *</span>
                </label>
              </div>
              <div className="">
                <DatePicker
                  name="regEndDate"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.regEndDate, 10)}
                  onChange={(date) => {
                    handleFormInput(date.getTime(), 'regEndDate');
                  }}
                  className={`w-100 border-0 bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="event-period">
              <div>
                <label>Registration End Time</label>
              </div>
              <div>
                <DatePicker
                  className="w-100 border-0"
                  name="regEndDate"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.regEndDate, 10)}
                  dateFormat="h:mm aa"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="event-time-container mt-5 w-100">
            <div className="event-period">
              <div>
                <label>
                  Event starts
                  <span className="text-primary"> *</span>
                </label>
              </div>
              <div className="">
                <DatePicker
                  name="eventStart"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventStart, 10)}
                  onChange={(date) => {
                    handleFormInput(date.getTime(), 'eventStart');
                  }}
                  className={`w-100 border-0 bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="event-period">
              <div>
                <label>Start Time</label>
              </div>
              <div>
                <DatePicker
                  className="w-100 border-0"
                  name="eventStart"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventStart, 10)}
                  dateFormat="h:mm aa"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="event-time-container mt-5 w-100">
            <div className="event-period">
              <div>
                <label>
                  Event ends
                  <span className="text-primary"> *</span>
                </label>
              </div>
              <div>
                <DatePicker
                  name="eventEnd"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventEnd, 10)}
                  onChange={(date) => {
                    handleFormInput(date.getTime(), 'eventEnd');
                  }}
                  className={`w-100 border-0 bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="event-period">
              <div>
                <label>End Time</label>
              </div>
              <div>
                <DatePicker
                  className="w-100 border-0"
                  name="eventEnd"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventEnd, 10)}
                  dateFormat="h:mm aa"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="event-time-container mt-5 w-100">
            <div className="event-period">
              <div>
                <label>
                  Event Ticket ends
                  <span className="text-primary"> *</span>
                </label>
              </div>
              <div>
                <DatePicker
                  name="eventTicketEnd"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventTicketEnd, 10)}
                  onChange={(date) => {
                    handleFormInput(date.getTime(), 'eventTicketEnd');
                  }}
                  className={`w-100 border-0 bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className="event-period">
              <div>
                <label>Event Ticket End Time</label>
              </div>
              <div>
                <DatePicker
                  className="w-100 border-0"
                  name="eventTicketEnd"
                  minDate={new Date()}
                  showTimeSelect
                  selected={parseInt(values?.eventTicketEnd, 10)}
                  dateFormat="h:mm aa"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventTime.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventTime;
