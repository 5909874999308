import React from 'react';
import cancelIcon from '../../../assets/images/cancelIcon.png';
import leftArrow from '../../../assets/image/left-arrow.png';

export const CusModalHeader = ({ hideModal, title, showBackBtn }) => {
  return (
    <div className="header">
      <div className="row">
        <div className="col-3 h-left">
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              hideModal(false);
            }}
          >
            {showBackBtn ? <img src={leftArrow} alt="left" /> : <p>{'  '}</p>}
          </a>
        </div>
        <div className="col-6 h-center text-center">{title}</div>
        <div className="col-3 h-right text-center">
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              hideModal(false);
            }}
          >
            <img src={cancelIcon} alt="plus" />
          </a>
        </div>
      </div>
    </div>
  );
};

export const example = () => {};
