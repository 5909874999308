import { Select } from 'antd';
import React from 'react';
import { capFirstWord } from '../../../../../utils/helper';

function GroupInfo(props) {
  const { handleChange, values, setValues, members, categories, theme } = props;
  return (
    <div className="event-inner-content">
      <div className="input-container">
        <input
          name="title"
          values={values.title}
          onChange={handleChange}
          placeholder="Group name"
          required
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          What is the name of your group
          <span className="text-primary fs-6"> *</span>
        </span>
      </div>
      <div className="input-container">
        <input
          name="description"
          values={values.description}
          onChange={handleChange}
          placeholder="Description"
          required
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          What is the description of your group
          <span className="text-primary fs-6"> *</span>
        </span>
      </div>
      <div className="input-container">
        <select
          onChange={handleChange}
          defaultValue={values.privacyStatus}
          name="privacyStatus"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        >
          <option value="false">Private</option>
          <option value="true">Public</option>
        </select>
        <span>Is the group private or public?</span>
      </div>
      <div className="input-container">
        <select
          value={values.category}
          defaultValue={values.category}
          onChange={handleChange}
          name="category"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        >
          <option value="">Select Category</option>
          {categories
            ? categories.map((item) => (
                <option value={item.title} key={item.id}>
                  {capFirstWord(item.title)}
                </option>
              ))
            : ''}
        </select>
        <span>
          Select Category
          <span className="text-primary fs-6"> *</span>
        </span>
      </div>
      {values.category === 'Others' && (
        <div className="input-container">
          <input
            name="customCategory"
            values={values.customCategory}
            onChange={handleChange}
            placeholder="Type custom category"
            required
            className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          />
          <span>
            Type your custom category here
            <span className="text-primary fs-6"> *</span>
          </span>
        </div>
      )}
      <div className="input-container">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={values.invitee}
          onChange={(e) => setValues({ ...values, invitee: e })}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        >
          {members?.map((member) => (
            <Select.Option key={member.id}>{member.displayName}</Select.Option>
          ))}
        </Select>
        <span>Who do you want to invite</span>
      </div>
    </div>
  );
}

export default GroupInfo;
