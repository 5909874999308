import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import './index.scss';
import Container from '../../Container';
import {
  BankTransfer,
  USSDCode,
  MainContent,
  BankAccount,
} from './OtherTransfer';
import { FundTab } from './style';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPostUnreloaded,
} from '../../../../action/http';
import PinModal from '../PinModal';
import UssdModal from '../UssdModal';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { WalletContext } from '../../../../store/context/WalletContext';
import { PaymentContext } from '../../../../store/context/PaymentContext';
import ExistingAccount from './ExistingAccount';
import { CardContext } from '../../../../store/context/CardContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { ModalContext } from '../../../../store/context/ModalContext';

const Index = (props) => {
  const { showModal, hideModal, id } = props;
  const [mode, setMode] = useState('bankCard');
  const [data, setData] = useState({});
  const [showPin, setShowPin] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showUssdModal, setShowUssdModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const { walletList, setReload, reload } = useContext(WalletContext);
  const { ussdCode, virtualAccount } = useContext(PaymentContext);
  const {
    cardList: myCardList,
    bankAccountList,
    availableBank,
  } = useContext(CardContext);
  const { profile: user } = useContext(ProfileContext);
  const { setShowResendOtp, setModalAction } = useContext(ModalContext);

  const handleFundByCard = async () => {
    showLoader();
    const ref = `${Math.floor(100000 + Math.random() * 900000)}`;
    const res = await httpPostUnreloaded(
      `/card/charge?amount=${data.amount}&cardNumber=${data.cardNumber}&userId=${user.userId}&walletAccountNo=${data.walletAccountNo}&ref=${ref}`,
      {},
      customBaseUrl.cardUrl
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success').then(() => {
        hideLoader();
        hideModal(false);
        setData({});
        setReload(!reload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleFundByBankAccount = async () => {
    showLoader();
    const postData = {
      amount: data.amount,
      app_ref: `${Math.floor(100000 + Math.random() * 900000)}`,
      email: user.email,
      walletAcctNo: data.walletAccountNo,
    };
    const res = await httpPost(
      `/bankAccount/payWithCheckOut/${user.userId}`,
      postData,
      customBaseUrl.cardUrl
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      hideLoader();
      hideModal(false);
      window.location.href = res.data.authorization_url;
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      setShowPin(false);
      if (mode === 'bankAccount') {
        handleFundByBankAccount();
        return;
      }
      handleFundByCard();
      // swal('Done', `Pin verified`, 'success').then(() => {
      //   setShowPin(false);
      //   if (mode === 'bankAccount') {
      //     handleFundByBankAccount();
      //     return;
      //   }
      //   handleFundByCard();
      // });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleFundByUssdTopUp = async () => {
    setLoading(true);
    const res = await httpPost(
      `/generate-ussd-topup`,
      data,
      customBaseUrl.ussdTopUp
    );
    if (res.status) {
      setModalData(res);
      setShowUssdModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      // setShowUssdModal(true);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const handleFundUssdByWithdraw = async () => {
    setLoading(true);
    const res = await httpPost(
      `/generate-ussd-withdraw`,
      data,
      customBaseUrl.ussdTopUp
    );
    if (res.status) {
      setModalData(res);
      setShowUssdModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleFundUssdByTransferToWallet = async () => {
    setLoading(true);
    const res = await httpPost(
      `/generate-ussd-transfer-to-wallet`,
      data,
      customBaseUrl.ussdTopUp
    );
    if (res.status) {
      setModalData(res);
      setShowUssdModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id={id}
      centered
      size="md"
    >
      <ModalHeader>Fund Wallet Account</ModalHeader>
      <div className="text-center fund-tab-wrap pb-2">
        <FundTab className="row m-0 fund-tab">
          <Container
            className={mode === 'bankCard' ? 'col fund-tab-active' : 'col'}
            onClick={() => {
              setMode('bankCard');
              setData({});
            }}
          >
            Bank Card
          </Container>
          <Container
            className={mode === 'bankAccount' ? 'col fund-tab-active' : 'col'}
            onClick={() => {
              setMode('bankAccount');
              setData({});
              // setShowExistingModal(true);
            }}
          >
            Bank Account
          </Container>
          <Container
            className={mode === 'bankTransfer' ? 'col fund-tab-active' : 'col'}
            onClick={() => {
              setMode('bankTransfer');
              setData({});
            }}
          >
            Bank Transfer
          </Container>
          <Container
            className={mode === 'ussdCode' ? 'col fund-tab-active' : 'col'}
            onClick={() => {
              setMode('ussdCode');
              setData({});
            }}
          >
            USSD Code
          </Container>
        </FundTab>
      </div>
      {mode === 'bankTransfer' ? (
        <BankTransfer virtualAccount={virtualAccount} />
      ) : mode === 'ussdCode' ? (
        <USSDCode
          ussdCode={ussdCode}
          setData={setData}
          setModalData={setModalData}
          modalData={modalData}
          data={data}
          loading={loading}
          handleFundByUssdTopUp={handleFundByUssdTopUp}
          handleFundUssdByWithdraw={handleFundUssdByWithdraw}
          handleFundUssdByTransferToWallet={handleFundUssdByTransferToWallet}
        />
      ) : mode === 'bankAccount' ? (
        <BankAccount
          hideModal={hideModal}
          setShowPin={setShowPin}
          walletList={walletList}
          bankAccountList={bankAccountList}
          availableBank={availableBank}
          setData={setData}
          data={data}
          setShowExistingModal={setShowExistingModal}
        />
      ) : (
        <MainContent
          hideModal={hideModal}
          setShowPin={setShowPin}
          walletList={walletList}
          myCardList={myCardList}
          setData={setData}
          data={data}
        />
      )}
      {showUssdModal ? (
        <UssdModal
          center
          showModal={showUssdModal}
          hideModal={setShowUssdModal}
          modalData={modalData}
        />
      ) : (
        ''
      )}
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit pin"
          description="Please input your 4 digit pin to confirm transaction"
          handleSubmit={handleVerifyPin}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {showExistingModal ? (
        <ExistingAccount
          showModal={showExistingModal}
          hideModal={setShowExistingModal}
          setShowPin={setShowPin}
          walletList={walletList}
          setData={setData}
          data={data}
          myAccountList={bankAccountList}
          availableBank={availableBank}
          id="available-bank-modal"
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

MainContent.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default Index;
