// import { useParams } from 'react-router';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './index.scss';

const DonationView = (props) => {
  const { showModal, hideModal, data } = props;
  console.log({ donData: data });
  return (
    <Modal
      isOpen={showModal}
      id="donation-modal"
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalBody>
        <div className="p-5 text-center">
          <h6 className="fw-bold">Description of cause:</h6>
          <p>{data?.amountJustification}</p>
          <h6 className="fw-bold">Description of solution:</h6>
          <p>{data?.impact}</p>
          {/* <h6 className="fw-bold">More about donation organizer:</h6>
          <p>{data?.ownerDescription}</p> */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DonationView;
