import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import ChildComment from '../../Cards/CommendCard';
import { customBaseUrl, httpPost, httpGet } from '../../../../action/http';
import Button from '../../Button';
import { repostPost } from '../../../../utils/helper';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import { hideLoader, showLoader } from '../../../../utils/loader';

const CreateComment = (props) => {
  const {
    showModal,
    hideModal,
    post,
    data,
    profile,
    reload,
    setReload,
    mode,
    type,
  } = props;
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const params = useParams();
  const history = useHistory();
  const createComment = async () => {
    setLoading(true);
    const p = type === 'sub-comment' && { parent_id: post.id };
    const d = {
      ...postData,
      type: 'user',
      profile_id: profile.id,
      post_id: data.id,
      ...p,
    };
    const contestData = {
      contestId: data?.id,
      profileId: profile?.id,
      comment: postData?.comment,
      firstName: profile.displayName.split(' ')[0],
      lastName: profile.displayName.split(' ')[1],
      username: profile?.username,
      // photo: profile?.avatar,
      parentId: data?.parentId,
    };
    let res;
    showLoader();
    if (type === 'cComment') {
      res = await httpPost(
        `/comments/add`,
        contestData,
        customBaseUrl.contestUrl
      );
    } else {
      res = await httpPost(
        `/all-posts/comment-create`,
        d,
        customBaseUrl.wayagramUrl
      );
    }
    if (res.status) {
      hideLoader();
      NotificationManager.success(res?.message, 'Success!', 3000);
      setLoading(false);
      hideModal(false);
      setReload(!reload);
    } else {
      hideLoader();
      setLoading(false);
      NotificationManager.error(res?.message, 'Oops!', 3000);
    }
  };

  const onChangeHandler = (e) => {
    setPostData({ ...postData, comment: e.target.value });
  };

  const handleSubmit = () => {
    if (mode === 'repost') {
      repostPost(
        post.id,
        profile.id,
        postData.comment,
        () => setReload(!reload),
        params?.id
      );
    } else {
      createComment();
    }
    hideModal(false);
  };

  const handleViewChildComments = async () => {
    try {
      setLoading(true);
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/child-comments?comment_id=${post.id}`
      );
      if (res.status) {
        setComments(res.data.data);
      }
      setLoading(false);
      return {};
    } catch (error) {
      return error.message;
    }
  };

  useEffect(() => {
    if (type === 'sub-comment') {
      handleViewChildComments();
    }
    console.log({ data, profile });
  }, []);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="wayagram-create-group"
      centered
    >
      <ModalHeader>
        <div className="header-wrapper">
          <h5 className="">{mode === 'repost' ? 'Repost' : 'Comment'}</h5>
          <button
            className="header-close"
            type="button"
            onClick={() => hideModal(false)}
          >
            x
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="inner-wrapper">
          {mode === 'repost' ? (
            <div>
              <div className="wrapper second-wrapper col">
                <div className="left-gram">
                  <img
                    src={profile?.avatar || imgPlaceholder}
                    alt="Profile"
                    className="profile-image"
                  />
                </div>
                <div className="right-gram">
                  <input
                    type="text"
                    placeholder={type !== 'sub-comment' ? 'Comment' : 'Reply'}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  />
                </div>
                <div>
                  <Button
                    loading={loading}
                    disabled={loading || false}
                    className="btn btn-primary ml-auto me-3"
                    color="primary"
                    content={mode === 'repost' ? 'Share' : 'Post'}
                    type="button"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
              <div className="wrapper border mx-3 my-3">
                <div className="left-gram">
                  <img
                    src={post?.Profile?.avatar || imgPlaceholder}
                    alt="Profile"
                    className="profile-image"
                    onClick={() =>
                      history.push(`/wayagram-profile/${post?.Profile?.id}`)
                    }
                  />
                </div>
                <div className="right-gram">
                  <div className="inner-right">
                    {/* <h6 className="fullname">{post.Profile?.displayName}</h6> */}
                    <h6
                      className="handle"
                      onClick={() =>
                        history.push(`/wayagram-profile/${post?.Profile?.id}`)
                      }
                    >
                      {`@${post?.Profile?.username}`}
                    </h6>
                    <h6 className="hour">
                      {moment(post?.createdAt).fromNow()}
                    </h6>
                  </div>
                  <h6 className="gram-content">
                    {post?.description || post?.comment}
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="wrapper">
                <div className="left-gram">
                  <img
                    src={post?.Profile?.avatar || imgPlaceholder}
                    alt="Profile"
                    className="profile-image"
                    onClick={() =>
                      history.push(`/wayagram-profile/${post?.Profile?.id}`)
                    }
                  />
                </div>
                <div className="right-gram">
                  <div className="inner-right">
                    {/* <h6 className="fullname">{post.Profile?.displayName}</h6> */}
                    <h6
                      className="handle"
                      onClick={() =>
                        history.push(`/wayagram-profile/${post?.Profile?.id}`)
                      }
                    >
                      {`@${post?.Profile?.username}`}
                    </h6>
                    <h6 className="hour">
                      {moment(post?.createdAt).fromNow()}
                    </h6>
                  </div>
                  <h6 className="gram-content">
                    {post?.description || post?.comment}
                  </h6>
                </div>
              </div>
            </>
          )}
          {type === 'sub-comment' && (
            <div
              style={{
                maxHeight: '60vh',
                overflowY: 'scroll',
                padding: '0 20px',
              }}
            >
              <h6>Replies</h6>
              {comments.map((item) => (
                <div
                  className="ml-5"
                  key={Math.random()}
                  style={{ fontSize: 12, marginLeft: 20 }}
                >
                  <ChildComment
                    data={item}
                    comment={item.comment}
                    commentType="child"
                  />
                </div>
              ))}
            </div>
          )}
          {mode !== 'repost' && (
            <div className="wrapper second-wrapper">
              <div className="left-gram">
                <img
                  src={profile?.avatar || imgPlaceholder}
                  alt="Profile"
                  className="profile-image"
                />
              </div>
              <div className="right-gram">
                <input
                  type="text"
                  placeholder={type !== 'sub-comment' ? 'Comment' : 'Reply'}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
              </div>
              <div>
                <Button
                  loading={loading}
                  disabled={loading || false}
                  className="btn btn-primary ml-auto me-3"
                  color="primary"
                  content={mode === 'repost' ? 'Share' : 'Post'}
                  type="button"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

CreateComment.defaultProps = {
  // showModal: false,
};

CreateComment.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CreateComment;
