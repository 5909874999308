import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { customBaseUrl, httpGet } from '../../../../action/http';
import StepButton from '../Post/DonationModal/StepButton';
// import createMarkup from '../../../../utils/htmlEncode';

export default function TermsAndCondition(props) {
  const { showModal, hideModal } = props;
  const [terms, setTerms] = useState(null);
  const [loading, setLoading] = useState(false);
  const text = `Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss. Making your own terms and conditions for your website is hard, not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out. All you need to do is fill up the blank spaces and then you will receive an email with your personalized terms and conditions.`;

  const getTerms = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.contentManagementUrl}/terms-and-condition?page=0`
    );
    if (res?.data) {
      setTerms(
        res?.data.termsAndConditionResponses.find((item) => item.active)
          ?.content
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getTerms();
    return setTerms(null);
  }, []);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      id="Terms-modal"
    >
      <ModalHeader className="" toggle={() => hideModal(false)}>
        <div className="custome-header">
          <div>Terms & Conditions </div>
        </div>
      </ModalHeader>

      <ModalBody className="modal-body-rs col-sm-12 col-md-10 pt-4">
        {loading ? (
          <div className="">
            <Skeleton height={500} width={400} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              maxHeight: '620px',
              overflowY: 'auto',
              padding: '2px 0px 32px',
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '100%',
                maxHeight: '620px',
                overflowY: 'auto',
                padding: '2px 0px 32px',
                position: 'relative',
              }}
            >
              {/* eslint-disable react/no-danger */}
              <div
                className="html-div p-0"
                // dangerouslySetInnerHTML={createMarkup(terms)}
              >
                {terms || text}
              </div>
            </div>
          </div>
        )}
        {terms ? (
          <StepButton
            title="Confirm"
            onClick={() => hideModal(false)}
            className="border-primary"
          />
        ) : (
          ''
        )}
      </ModalBody>
    </Modal>
  );
}
