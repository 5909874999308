import { Formik, Form } from 'formik';
import { useRecoilValue } from 'recoil';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import * as Yup from 'yup';
//  import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import FormInput from '../../CustomInput/FormInput';
import { userTheme } from '../../../../store/initialState';
//  import { WayagramContext } from '../../../../store/context/WayagramContext';
import {
  //  httpPost,
  customBaseUrl,
  httpGet,
  httpPatch,
  httpPostFormData,
} from '../../../../action/http';
import AuthButton from '../../AuthButton';
import { hideLoader, showLoader } from '../../../../utils/loader';
import {
  fileValidation,
  videoValidation,
} from '../../../../utils/validateImage';

const CreateContest = (props) => {
  const theme = useRecoilValue(userTheme);
  const { showModal, hideModal, id, mode, data, handleGetContest } = props;
  const { Option } = Select;
  const [filteredOptions, setFilteredOption] = useState([]);
  const [location, setLocation] = useState({});
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);
  const [coverPhoto, setCoverPhot] = useState('');
  const [coverVideo, setCoverVideo] = useState('');
  const [preview1, setPreview1] = useState('');
  const [unis, setUnis] = useState([]);
  // const [errors, setErrors] = useState(null);
  // const { wayagramProfile: profile } = useContext(WayagramContext);
  // const router = useRouter();
  // const handleProceed = () => {
  //   router.push(`${id}/password`);
  // };

  const getStates = async () => {
    const res = await httpGet(`${customBaseUrl.contestUrl}/users/states`);
    if (res?.status) {
      //  homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      const state = res?.data?.sort((a, b) => {
        const fa = a.state.toLowerCase();
        const fb = b.state.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setFilteredOption(state);
    } else {
      console.log(res);
    }
  };
  const getUnis = async () => {
    const res = await httpGet(
      `${customBaseUrl.contestUrl}/contests/universities`
    );
    if (res?.universities) {
      setUnis(res?.universities);
    } else {
      console.log(res);
    }
  };
  const handleSubmit = async (values) => {
    if (!postData?.stageOneStartDate)
      return swal('Oops!', 'Contest start date can not be null');
    if (!postData?.stageOneEndDate)
      return swal('Oops!', 'Contest end date can not be null');
    if (!postData?.registrationEndDate)
      return swal('Oops!', 'Contest registration start date can not be null');
    if (!postData?.registrationEndDate)
      return swal('Oops!', 'Contest registration end date can not be null');
    if (!coverPhoto)
      return swal('Oops!', 'Contest cover photo can not be null');
    if (!coverVideo)
      return swal('Oops!', 'Contest cover photo can not be null');

    setLoading(true);
    const newForm = new FormData();
    newForm.append('profileId', id);
    newForm.append('title', values?.title);
    newForm.append('category', values?.category);
    newForm.append('state', location?.state);
    newForm.append('winnerPrize', values?.winnerPrize);
    newForm.append('maxContestants', values?.maxContestants);
    newForm.append('description', values?.description);
    newForm.append('numberOfWeeks', values?.numberOfWeeks);
    newForm.append('voteBenchmark', values?.voteBenchmark);
    newForm.append('stageOneVoteCount', values?.stageOneVoteCount);
    newForm.append('stageTwoVoteCount', values?.stageTwoVoteCount);
    newForm.append('stageThreeVoteCount', values?.stageThreeVoteCount);
    newForm.append(
      'amountPerVote',
      postData?.type === 'PAID' ? values?.amountPerVote : 0
    );
    newForm.append('type', postData?.type);
    newForm.append('overallResult', values?.overallResult);
    newForm.append('university', values?.university);
    newForm.append('publicity', values?.publicity);
    newForm.append('startDate', postData?.startDate);
    newForm.append('endDate', postData?.endDate);
    newForm.append('stageOneStartDate', postData?.stageOneStartDate);
    newForm.append('stageOneEndDate', postData?.stageOneEndDate);
    newForm.append('stageTwoStartDate', postData?.stageTwoStartDate);
    newForm.append('stageTwoEndDate', postData?.stageTwoEndDate);
    newForm.append('stageThreeStartDate', postData?.stageThreeStartDate);
    newForm.append('stageThreeEndDate', postData?.stageThreeEndDate);
    newForm.append('registrationStartDate', postData?.registrationStartDate);
    newForm.append('registrationEndDate', postData?.registrationEndDate);
    newForm.append('coverPhoto', coverPhoto);
    newForm.append('coverVideo', coverVideo);
    console.log({ newForm: coverPhoto });
    // const res = await httpPost(
    //   `/contests/create`,
    //   { ...postData, ...values, ...location },
    //   customBaseUrl.contestUrl,
    //   true
    // );
    const res = await httpPostFormData(
      `/contests/create`,
      newForm,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      NotificationManager.success(res?.message, 'Done!', 3000);
      setLoading(false);
      hideModal(false);
      handleGetContest();
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
    return true;
  };

  const handleUpdate = async (values) => {
    // const updateData = {
    //   id: data?.id,
    //   profileId: data?.profileId,
    //   title: values.title,
    //   state: location?.state,
    //   winnerPrize: values.winnerPrize,
    //   description: values.description,
    //   numberOfWeeks: values.numberOfWeeks,
    //   maxContestants: values.maxContestants,
    //   amountPerVote: values.amountPerVote,
    //   //  type: values.type,
    //   overallResult: values.overallResult,
    //   publicity: values.publicity,
    //   status: values.status,
    //   startDate: postData?.startDate,
    //   endDate: postData?.endDate,
    //   registrationStartDate: postData?.registrationStartDate,
    //   registrationEndDate: postData?.registrationEndDate,
    // };
    const newForm = new FormData();
    newForm.append('id', data?.id);
    newForm.append('profileId', data?.profileId);
    newForm.append('title', values?.title);
    //  newForm.append('category', values?.category);
    newForm.append('state', location?.state);
    newForm.append('winnerPrize', values?.winnerPrize);
    newForm.append('maxContestants', values?.maxContestants);
    newForm.append('description', values?.description);
    newForm.append('numberOfWeeks', values?.numberOfWeeks);
    newForm.append('voteBenchmark', values?.voteBenchmark);
    newForm.append('stageOneVoteCount', values?.stageOneVoteCount);
    newForm.append('stageTwoVoteCount', values?.stageTwoVoteCount);
    newForm.append('stageThreeVoteCount', values?.stageThreeVoteCount);
    // newForm.append('amountPerVote', values?.amountPerVote);
    newForm.append('type', postData?.type);
    newForm.append(
      'amountPerVote',
      postData?.type === 'PAID' ? values?.amountPerVote : 0
    );
    newForm.append('overallResult', values?.overallResult);
    newForm.append('university', values?.university);
    newForm.append('status', values?.status);
    newForm.append('publicity', values?.publicity);
    newForm.append('startDate', postData?.startDate);
    newForm.append('endDate', postData?.endDate);
    newForm.append('stageOneStartDate', postData?.stageOneStartDate);
    newForm.append('stageOneEndDate', postData?.stageOneEndDate);
    newForm.append('stageTwoStartDate', postData?.stageTwoStartDate);
    newForm.append('stageTwoEndDate', postData?.stageTwoEndDate);
    newForm.append('stageThreeStartDate', postData?.stageThreeStartDate);
    newForm.append('stageThreeEndDate', postData?.stageThreeEndDate);
    newForm.append('registrationStartDate', postData?.registrationStartDate);
    newForm.append('registrationEndDate', postData?.registrationEndDate);
    if (coverPhoto) newForm.append('coverPhoto', coverPhoto);
    if (coverVideo) newForm.append('coverVideo', coverVideo);
    //  if (!values.amountPerVote) delete updateData?.amountPerVote;
    showLoader();
    const res = await httpPatch(
      `/contests/update`,
      newForm,
      customBaseUrl.contestUrl
    );
    if (res.status) {
      NotificationManager.success(res?.message, 'Done!', 3000);
      hideLoader();
      setLoading(false);
      hideModal(false);
      handleGetContest();
    } else {
      hideLoader();
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  const CreateSchema = Yup.object().shape({
    // type: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    //  state: Yup.string().required('Required'),
    winnerPrize: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    numberOfWeeks: Yup.string().required('Required'),
    voteBenchmark: Yup.string().required('Required'),
    stageOneVoteCount: Yup.string().required('Required'),
    stageTwoVoteCount: Yup.string().required('Required'),
    stageThreeVoteCount: Yup.string().required('Required'),
    maxContestants: Yup.string().required('Required'),
    //  amountPerVote: Yup.string().required('Required'),
    overallResult: Yup.string().required('Required'),
    university: Yup.string().required('Required'),
    publicity: Yup.string().required('Required'),
  });
  useEffect(() => {
    console.log({ data });
    getStates();
    getUnis();
    if (mode === 'edit') {
      setPostData({
        ...postData,
        stageOneStartDate: new Date(data?.stageOneStartDate).getTime(),
        stageOneEndDate: new Date(data?.stageOneEndDate).getTime(),
        stageTwoStartDate: new Date(data?.stageTwoStartDate).getTime(),
        stageTwoEndDate: new Date(data?.stageTwoEndDate).getTime(),
        stageThreeStartDate: new Date(data?.stageThreeStartDate).getTime(),
        stageThreeEndDate: new Date(data?.stageThreeEndDate).getTime(),
        startDate: new Date(data?.startDate).getTime(),
        endDate: new Date(data?.endDate).getTime(),
        registrationStartDate: new Date(data?.registrationStartDate).getTime(),
        registrationEndDate: new Date(data?.registrationEndDate).getTime(),
        type: data?.type,
        coverImg: data?.miscellaneous?.coverPhoto,
      });
    }
    //  setFilteredOption(states);
  }, []);
  const initialValues1 = {
    profileId: id,
    category: 'church',
    type: 'FREE',
    description: '',
    title: '',
    maxContestants: '',
    winnerPrize: '',
    amountPerVote: '',
    voteBenchmark: '',
    stageOneVoteCount: '',
    stageTwoVoteCount: '',
    stageThreeVoteCount: '',
    overallResult: 'YES',
    university: '',
    numberOfWeeks: '',
    publicity: 'PUBLIC',
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        hideModal(false);
        // setPreview([]);
      }}
      id={id}
      centered
    >
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <span style={theme.modalStyle}>
          {mode === 'create' ? 'Create New' : 'Edit'} Contest
        </span>
      </ModalHeader>
      <ModalBody className="">
        <section className="flex flex-col items-center shadow-2xl p-10 bg-white">
          <Formik
            onSubmit={mode === 'edit' ? handleUpdate : handleSubmit}
            initialValues={mode === 'edit' ? data : initialValues1}
            validationSchema={CreateSchema}
          >
            {({ errors }) => (
              <Form class="row g-3">
                {mode === 'create' && (
                  <div className="col-12">
                    <label htmlFor="category" className="form-label">
                      Contest Type*
                    </label>
                    <FormInput
                      as="select"
                      type="select"
                      id="category"
                      className="form-select"
                      name="category"
                    >
                      <option value="church">Church</option>
                      <option value="school">School</option>
                    </FormInput>
                    <div>{errors.category}</div>
                  </div>
                )}
                <div className="col-12">
                  <label htmlFor="state" className="form-label">
                    Select State
                  </label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    name="state"
                    value={location?.state ?? data?.state}
                    onChange={(e) => setLocation({ ...location, state: e })}
                    placeholder="Lagos"
                  >
                    {filteredOptions
                      ? filteredOptions.map((item) => (
                          <Option value={item.state} key={item.state}>
                            {item?.state?.toUpperCase()}
                          </Option>
                        ))
                      : ''}
                  </Select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="university" className="form-label">
                    Select School*
                  </label>
                  <FormInput
                    as="select"
                    type="select"
                    id="result"
                    className="form-select"
                    name="university"
                  >
                    <option>select school</option>
                    {unis
                      ? unis.map((item) => (
                          <option value={item} key={item}>
                            {item?.toUpperCase()}
                          </option>
                        ))
                      : ''}
                  </FormInput>
                  <div style={{ color: 'red' }}>{errors.university}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Enter Contest Title*
                  </label>
                  <FormInput id="" name="title" placeholder="Title" />
                  <div style={{ color: 'red' }}>{errors.title}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="description" className="form-label">
                    Enter Contest Description*
                  </label>
                  <FormInput id="description" name="description" />
                  <div style={{ color: 'red' }}>{errors.description}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Vote Count*
                  </label>
                  <FormInput id="number" name="voteBenchmark" />
                  <div style={{ color: 'red' }}>{errors.voteBenchmark}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Stage 1 Vote Count*
                  </label>
                  <FormInput id="number" name="stageOneVoteCount" />
                  <div style={{ color: 'red' }}>{errors.stageOneVoteCount}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Stage 2 Vote Count*
                  </label>
                  <FormInput id="number" name="stageTwoVoteCount" />
                  <div style={{ color: 'red' }}>{errors.stageTwoVoteCount}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Stage 3 Vote Count*
                  </label>
                  <FormInput id="number" name="stageThreeVoteCount" />
                  <div style={{ color: 'red' }}>
                    {errors.stageThreeVoteCount}
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Maximum Contestants*
                  </label>
                  <FormInput id="number" name="maxContestants" />
                  <div style={{ color: 'red' }}>{errors.maxContestants}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="winnerPrize" className="form-label">
                    Winner’s Award #*
                  </label>
                  <FormInput id="winnerPrize" name="winnerPrize" />
                  <div style={{ color: 'red' }}>{errors.winnerPrize}</div>
                </div>
                {/* <div className="col-12">
                  <label htmlFor="maxContestants" className="form-label">
                    Maximum Contestants
                  </label>
                  <FormInput id="maxContestants" name="maxContestants" />
                </div> */}
                <div className="col-md-6">
                  <label htmlFor="overallResult" className="form-label">
                    Hide/Show Contest overall result to audience*
                  </label>
                  <FormInput
                    as="select"
                    type="select"
                    id="result"
                    className="form-select"
                    name="overallResult"
                  >
                    <option selected value="YES">
                      Show
                    </option>
                    <option value="NO">Hide</option>
                  </FormInput>
                  <div style={{ color: 'red' }}>{errors.overallResult}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="publicity" className="form-label mb-4">
                    Privacy*
                  </label>
                  <FormInput
                    as="select"
                    type="select"
                    id="inputState"
                    className="form-select"
                    name="publicity"
                  >
                    <option selected value="PUBLIC">
                      Public
                    </option>
                    <option value="PRIVATE">Private</option>
                  </FormInput>
                  <div style={{ color: 'red' }}>{errors.publicity}</div>
                </div>
                {/* <div className="col-md-6">
                  <label htmlFor="State" className="form-label">
                    Hide/Show Contest candidate result to audience
                  </label>
                  <FormInput as="select"
                  type="select" id="inputState" className="form-select" name="">
                    <option selected value="YES">
                      Show
                    </option>
                    <option value="NO">Hide</option>
                  </FormInput>
                </div> */}
                <div className="col-md-12">
                  <label className="text-danger">
                    Note: Contest start date must be after registration has
                    ended
                  </label>
                </div>
                <div className="col-md-6">
                  <label htmlFor="registrationStartDate" className="form-label">
                    Registration Start Date*
                  </label>
                  <DatePicker
                    className={`w-100 mt-4 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="registrationStartDate"
                    //  showTimeSelect
                    selected={parseInt(
                      postData.registrationStartDate ?? new Date(),
                      10
                    )}
                    //  minDate={new Date()}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        registrationStartDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="registrationEndDate" className="form-label">
                    Registration End Date*
                  </label>
                  <DatePicker
                    className={`w-100 mt-4 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="registrationEndDate"
                    //  showTimeSelect
                    selected={parseInt(postData.registrationEndDate, 10)}
                    //  minDate={new Date(postData?.registrationStartDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        registrationEndDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="startDate" className="form-label">
                    Contest Start Date*
                  </label>
                  <DatePicker
                    className={`w-100 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="startDate"
                    //  showTimeSelect
                    selected={parseInt(postData?.startDate, 10)}
                    // minDate={new Date(postData?.startDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        startDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="endDate" className="form-label">
                    Contest End Date*
                  </label>
                  <DatePicker
                    className={`w-100 border py-1 rounded bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="endDate"
                    // showTimeSelect
                    selected={parseInt(postData?.endDate, 10)}
                    //  minDate={new Date(postData?.endDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        endDate: date.getTime(),
                        // endTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="startDate" className="form-label">
                    Stage 1 Start Date*
                  </label>
                  <DatePicker
                    className={`w-100 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="startDate"
                    //  showTimeSelect
                    selected={parseInt(postData?.stageOneStartDate, 10)}
                    // minDate={new Date(postData?.stageOneStartDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageOneStartDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="endDate" className="form-label">
                    Stage 1 End Date*
                  </label>
                  <DatePicker
                    className={`w-100 border py-1 rounded bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="endDate"
                    // showTimeSelect
                    selected={parseInt(postData?.stageOneEndDate, 10)}
                    //  minDate={new Date(postData?.stageOneEndDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageOneEndDate: date.getTime(),
                        // endTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="startDate" className="form-label">
                    Stage 2 Start Date*
                  </label>
                  <DatePicker
                    className={`w-100 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="startDate"
                    //  showTimeSelect
                    selected={parseInt(postData?.stageTwoStartDate, 10)}
                    //  minDate={new Date(postData?.stageTwoStartDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageTwoStartDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="endDate" className="form-label">
                    Stage 2 End Date*
                  </label>
                  <DatePicker
                    className={`w-100 border py-1 rounded bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="endDate"
                    // showTimeSelect
                    selected={parseInt(postData?.stageTwoEndDate, 10)}
                    // minDate={new Date(postData?.stageTwoEndDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageTwoEndDate: date.getTime(),
                        // endTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="startDate" className="form-label">
                    Stage 3 Start Date*
                  </label>
                  <DatePicker
                    className={`w-100 py-1 rounded border bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="startDate"
                    //  showTimeSelect
                    selected={parseInt(postData?.stageThreeStartDate, 10)}
                    // minDate={new Date(postData?.stageThreeStartDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageThreeStartDate: date.getTime(),
                        // startTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="endDate" className="form-label">
                    Stage 3 End Date*
                  </label>
                  <DatePicker
                    className={`w-100 border py-1 rounded bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    name="endDate"
                    // showTimeSelect
                    selected={parseInt(postData?.stageThreeEndDate, 10)}
                    //  minDate={new Date(postData?.stageThreeEndDate)}
                    timeIntervals={15}
                    onChange={(date) => {
                      setPostData({
                        ...postData,
                        stageThreeEndDate: date.getTime(),
                        // endTime: date.getTime(),
                      });
                    }}
                  />
                </div>
                {/* {mode === 'create' && (
                  <div className="col-md-6">
                    <label htmlFor="type" className="form-label">
                      Free or paid vote
                    </label>
                    <FormInput
                      as="select"
                      type="select"
                      id="inputState"
                      className="form-select"
                      name="type"
                      onChange={(e) =>
                        setPostData({ ...postData, type: e.target.value })
                      }
                    >
                      <option selected value="FREE">
                        Free
                      </option>
                      <option value="PAID">Paid</option>
                    </FormInput>
                  </div>
                )} */}
                <div className="col-md-6">
                  <label htmlFor="type" className="form-label">
                    Free or paid vote*
                  </label>
                  <select
                    id="inputState"
                    className="form-select border rounded"
                    name="type"
                    value={postData.type}
                    onChange={(e) =>
                      setPostData({ ...postData, type: e.target.value })
                    }
                  >
                    <option> Select contest type </option>
                    <option value="FREE">Free</option>
                    <option value="PAID">Paid</option>
                  </select>
                  <div style={{ color: 'red' }}>{errors.type}</div>
                </div>
                {postData?.type === 'PAID' && (
                  <div className="col-md-6">
                    <label htmlFor="amountPerVote" className="form-label">
                      Amount per vote*
                    </label>
                    <FormInput id="amountPerVote" name="amountPerVote" />
                  </div>
                )}
                {/* <div className="col-md-6">
                  <label htmlFor="voteBenchmark" className="form-label">
                    Vote per User
                  </label>
                  <FormInput id="voteBenchmark" name="voteBenchmark" />
                </div> */}
                <div className="col-md-6">
                  <label htmlFor="numberOfWeeks" className="form-label">
                    Number of Weeks*
                  </label>
                  <FormInput id="Weeks" name="numberOfWeeks" />
                </div>
                {mode === 'edit' && (
                  <div className="col-md-6">
                    <label htmlFor="status" className="form-label">
                      Status*
                    </label>
                    <FormInput
                      as="select"
                      type="select"
                      id="inputState"
                      className="form-select"
                      name="status"
                    >
                      <option selected value="ACTIVE">
                        Active
                      </option>
                      <option value="CLOSED">Closed</option>
                    </FormInput>
                  </div>
                )}
                <div className="col-12">
                  {/* <label htmlFor="coverPhoto">Upload Contest Image*</label> */}
                  <label
                    htmlFor="coverPhoto"
                    style={{
                      backgroundImage: preview1
                        ? `url(${preview1})`
                        : `url(${postData?.coverImg})` ?? '',
                      backgroundSize: 'cover',
                      height: '140px',
                      width: '100%',
                      backgroundPosition: 'left',
                      //  border: 1,
                      borderStyle: 'dotted',
                      borderTopLeftRadius: 14,
                      borderTopRightRadius: 14,
                    }}
                  >
                    Upload Contest Image*
                  </label>
                  <input
                    hidden
                    id="coverPhoto"
                    name="coverPhoto"
                    placeholder="Click here to upload"
                    type="file"
                    // onChange={(e) => {
                    //   const validFormat = fileValidation(e.target.files[0]);
                    // console.log({ size: e.target.files[0].size });
                    // if (e.target.files[0].size > 100000) {
                    //   setCoverPhot('');
                    //   return swal(
                    //     'Oops!',
                    //     'Please contest image should be less than 100kb',
                    //     'error'
                    //   );
                    // }
                    //   if (!validFormat) {
                    //     setCoverPhot('');
                    //     e.target.value = null;
                    //     return swal(
                    //       'Oops!',
                    //       'Please upload file image only.',
                    //       'error'
                    //     );
                    //   }
                    //   setCoverPhot(e.target.files[0]);
                    //   return true;
                    // }}
                    onChange={(e) => {
                      const validFormat = fileValidation(e.target.files[0]);
                      if (e.target.files[0].size > 5000000) {
                        setCoverPhot('');
                        return swal(
                          'Oops!',
                          'Please contest image should be less than 5MB',
                          'error'
                        );
                      }
                      if (!validFormat) {
                        setPreview1('');
                        return swal(
                          'Oops!',
                          'Please upload file image only.',
                          'error'
                        );
                      }
                      setCoverPhot(e.target.files[0]);
                      const reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = () => {
                        setPreview1(reader.result);
                      };
                      return reader;
                    }}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="coverVideo">
                    {mode === 'create'
                      ? 'Upload Contest Video*'
                      : 'Update Contest Video'}
                  </label>
                  <input
                    id="coverVideo"
                    name="coverVideo"
                    placeholder="Click here to upload"
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                      const validFormat = videoValidation(e.target.files[0]);
                      console.log({ validFormat });
                      if (!validFormat) {
                        setCoverVideo('');
                        e.target.value = null;
                        return swal(
                          'Oops!',
                          'Please upload video file only.',
                          'error'
                        );
                      }
                      setCoverVideo(e.target.files[0]);
                      return true;
                    }}
                  />
                </div>
                {/* <div className="col-md-6">
                  <label htmlFor="RegistrationPrivacy" className="form-label">
                    Contest Registration Privacy
                  </label>
                  <FormInput id="RegistrationPrivacy" name="" />
                </div> */}
                <AuthButton
                  loading={loading}
                  disabled={loading || false}
                  className="btn btn-primary ml-auto mb-3 mt-2"
                  color="primary"
                  content={`Proceed to ${
                    mode === 'create' ? 'Create' : 'Update'
                  } Contest`}
                  // onClick={}
                >
                  Next
                </AuthButton>
              </Form>
            )}
          </Formik>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default CreateContest;
