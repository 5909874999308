import React, { useContext } from 'react';
// import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router';
import ReactHashtag from 'react-hashtag';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import DonationCard from './DonationCard';
import PaidCard from './PaidCard';
import PollOptionCard from './PollOptionCard';
import PostImageCard from './PostImageCard';

const RepostCardMiddleContent = ({ data, fromLocation }) => {
  const {
    paidPostProfile,
    Poll,
    PostImages,
    Donation,
    isPaid,
    isPoll,
    description,
    kind,
    Product,
    id,
  } = data;
  const profile = data?.profile || data?.Profile || data.DonatorProfile;
  // const userId = data.ProfileId;
  const { wayagramProfileId } = useContext(WayagramContext);
  const history = useHistory();

  const handleNavigate = () => {
    history.push(`/post/${id}`);
  };

  return (
    <div className="middle-content">
      <div className="middle-content-description">
        {kind === 'donation' || fromLocation === 'profileDonation' ? (
          <DonationCard
            Donation={Donation || data}
            handleNavigate={handleNavigate}
            description={description}
            profile={profile}
            page={fromLocation}
          />
        ) : (
          <div className="mb-3 mt-3 add-cursor hashtag">
            {isPoll || fromLocation === 'profilePoll' ? (
              <p onClick={() => handleNavigate('polls')}>
                <ReactHashtag
                  onHashtagClick={(v) => {
                    history.push({
                      pathname: '/wayagram-discover',
                      from: 'wayagram',
                      data: v,
                    });
                  }}
                >
                  {description || ''}
                </ReactHashtag>
              </p>
            ) : (
              <ReactHashtag
                onHashtagClick={(v) => {
                  history.push({
                    pathname: '/wayagram-discover',
                    from: 'wayagram',
                    data: v,
                  });
                }}
              >
                {description || ''}
              </ReactHashtag>
            )}
          </div>
        )}
      </div>
      {PostImages?.length && PostImages.length < 2 ? (
        <div className="img-div">
          <img src={PostImages[0]?.imageURL} alt="pic" />
        </div>
      ) : (
        ''
      )}
      {PostImages?.length > 1 && (
        <PostImageCard
          PostImages={PostImages}
          paidPostProfile={paidPostProfile}
        />
      )}
      {(isPaid || kind === 'product') && (
        <PaidCard
          PostImages={Product?.ProductImages || PostImages}
          Poll={Poll}
          Donation={Donation}
          Product={Product}
        />
      )}
      {(isPoll || fromLocation === 'profilePoll') && (
        <PollOptionCard
          Poll={Poll || data}
          wayagramProfileId={wayagramProfileId}
          type="Repost"
        />
      )}
    </div>
  );
};

export default RepostCardMiddleContent;
