import React from 'react';
import PropTypes from 'prop-types';

function DonationInfo(props) {
  const { handleFormInput, values, theme } = props;
  return (
    <div>
      <div className="input-container">
        <input
          name="title"
          value={values.title}
          onChange={(e) => handleFormInput(e.target.value, 'title')}
          placeholder="Title of Cause"
          required
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          What is the cause you are fundraising for?
          <span className="text-primary"> *</span>
        </span>
      </div>
      {/* <div className="input-container">
        <input
          name="orgName"
          value={values.orgName}
          onChange={(e) => handleFormInput(e.target.value, 'orgName')}
          placeholder="Organizer’s name"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          Who is organizing this donation?
          <span className="text-primary"> *</span>
        </span>
      </div> */}
      <div className="input-container">
        <input
          name="description"
          value={values.description}
          onChange={(e) => handleFormInput(e.target.value, 'description')}
          placeholder="Description"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          Tell people why they should donate to this cause
          <span className="text-primary"> *</span>
        </span>
      </div>
      {/* <div className="input-container">
        <select
          name="isPublic"
          value={values.isPublic}
          onChange={(e) => {
            handleFormInput(e.target.checked, 'isPublic');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Select visibility access"
        >
          <option value={0}>Public</option>
          <option value={1}>Private</option>
        </select>
        <span>Set visibility to public or private</span>
      </div> */}
    </div>
  );
}

DonationInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default DonationInfo;

export function UserInfo(props) {
  const { handleFormInput, values, theme } = props;
  return (
    <div>
      <div className="input-container">
        <input
          name="amountJustification"
          value={values.amountJustification}
          onChange={(e) =>
            handleFormInput(e.target.value, 'amountJustification')
          }
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Description of cause"
          required
        />
        <span>
          What is the reason behind this donation?
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <input
          name="impact"
          value={values.impact}
          onChange={(e) => handleFormInput(e.target.value, 'impact')}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Description of solution"
          required
        />
        <span>
          How will this donation solve the above problem
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <input
          name="ownerDescription"
          value={values.ownerDescription}
          onChange={(e) => handleFormInput(e.target.value, 'ownerDescription')}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Tell us more about yourself"
          required
        />
        <span>
          Why should user donate to you and for this cause
          <span className="text-primary"> *</span>
        </span>
      </div>
    </div>
  );
}
