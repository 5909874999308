import React, { useState, useContext, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { FcAlarmClock } from 'react-icons/fc';
import { IoCloseCircle, IoWalletOutline } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import swal from 'sweetalert';
import PersonalInfo from './Personalnfo';
import PinInput from './PinIput';
import TransactionStatusModal from './TransactionStatus';
import FinishButton from '../DonationModal/StepButton';
import { headers, donationButtonTitle } from './data';
import { customBaseUrl, httpPost } from '../../../../../action/http';
// import { WalletContext } from '../../../../../store/context/WalletContext';
import TermsAndCondition from '../../Terms/TermsAndCondition';
// import Share from '../../share';
import { apiModalResponse, numberFormat } from '../../../../../utils/helper';
import { ProfileContext } from '../../../../../store/context/ProfileContext';
import useStore from '../../../../../store/zustandStore/store';
import { handleVerifyPaymentOnWayaquick } from '../../../../pages/Wayagram/container';
import wayagramLogo from '../../../../../assets/images/wayagram-short-logo.png';
import paystackLogo from '../../../../../assets/images/paystack.png';
// import ButtonComponent from '../../../Button';

const MakeDonationModal = (props) => {
  const userId = localStorage.getItem('userId');
  const { setShowShareDonationModal, setDonationDataForShare } = useStore(
    (state) => state
  );
  const [trxStatusModal, setTrxStatusModal] = useState({
    status: false,
    type: '',
    message: '',
    actionName: '',
    action: null,
  });
  const [loading, setLoading] = useState(false);
  const { showModal, hideModal, userData, donationId, data } = props;
  // const [showSharePeople, setShowSharePeople] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [otp, setOtp] = useState('');
  const [openPayModal, setOpenPayModal] = useState(true);

  const [values, setValues] = useState({
    step: 0,
    totalSteps: 2,
    userName: userData?.username,
    amount: data?.Donation?.minimumAmount || data?.minimumAmount,
    description: '',
    donationId,
    profile_id: userData?.id,
    accountNo: '',
    minimumAmount: data?.Donation?.minimumAmount || data?.minimumAmount,
  });

  const [openPaymentOptionModal, setOpenPaymentOptionModal] = useState({
    status: false,
    paymentDetails: {},
  });

  // useEffect(async () => {
  //   const postData = {
  //     firstName: profile.firstName,
  //     lastName: profile.surname,
  //     narration: 'Payment made',
  //     email: profile.email,
  //     phoneNumber: profile.phoneNumber,
  //     item_type: 'Donation',
  //     item_id: donationId,
  //     amount: values.amount,
  //   };
  //   const res = await httpPost(
  //     `/all-posts/initialize-wayaquick-payment`,
  //     postData,
  //     customBaseUrl.wayagramUrl,
  //     true
  //   );
  //   // console.log(res);
  //   if (res.status) {
  //     setInitialize(res?.data?.data);
  //   }
  // }, []);

  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  const handleOpenPaymentOptionModal = () => {
    setOpenPaymentOptionModal({
      status: true,
      paymentDetails: {
        totalAmount: values.amount,
        type: 'DONATION',
        email: profile.email,
      },
    });
    setOpenPayModal(false);
  };

  const handleDonation = async (paymentGateway) => {
    const isUserLoggedIn = localStorage.getItem('wayaProfileId');
    if (!isUserLoggedIn) {
      NotificationManager.error('You need to login to donate', 'Error', 3000);
      return;
    }
    setLoading(true);
    const postData = {
      authUserId: userId,
      profile_id: userData.id,
      itemId: values.donationId,
      amount: values.amount,
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.surname,
      phoneNumber: profile.phoneNumber,
      paymentType: 'DONATION',
      paymentGateway,
    };
    const res = await httpPost(
      `/all-posts/init-payment`,
      postData,
      customBaseUrl.wayagramUrl
    );

    if (res?.status) {
      setDonationDataForShare(data);
      hideModal(false);
      window.open(
        res?.data?.authorization_url,
        '_blank',
        'resizable=yes, scrollbars=yes, titlebar=yes, width=600, height=700, top=10, left=10'
      );

      const transDetails = {
        tranId: res?.data?.tranId,
        paymentMethod: paymentGateway,
      };

      localStorage.setItem('tranId', JSON.stringify(transDetails));
      // swal to perform a fuction when clicked ok
      swal({
        title: 'Payment',
        text: 'You will be redirected to payment page',
        icon: 'success',
        buttons: 'Ok',
        closeOnClickOutside: false,
      }).then((Ok) => {
        if (Ok) {
          setShowShareDonationModal(true);
          const trnxId = JSON.parse(localStorage.getItem('tranId'));
          if (trnxId) {
            handleVerifyPaymentOnWayaquick({
              tranId: trnxId.tranId,
              paymentMethod: trnxId.paymentMethod,
            });
            localStorage.removeItem('tranId');
          }
        }
      });
    } else {
      apiModalResponse(res, setTrxStatusModal, setShowShareDonationModal);
    }
    setLoading(false);
  };

  // const handleVerifyPin = async () => {
  //   setLoading(true);
  //   if (otp?.length < 4) {
  //     setLoading(false);
  //     swal('Oops!', 'Pin is required', 'error');
  //     return;
  //   }
  //   if (values?.accountNo?.length <= 0) {
  //     setLoading(false);
  //     swal('Oops!', 'Please select a debit account', 'error');
  //     return;
  //   }
  //   const res = await httpGet(
  //     `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
  //   );
  //   if (res.status) {
  //     handleDonation();
  //     setOtp('');
  //   } else {
  //     setLoading(false);
  //     swal('Oops!', res.message || 'Something went wrong', 'error');
  //   }
  // };

  // const handleContinuePayment = () => {
  //   setLoading(false);
  //   window.location.href = initialize.authorization_url;
  // };

  const [selectedOption, setSelectedOption] = useState('');

  const handleHoverSelectOption = (id) => {
    const element = document.getElementById(id);
    element.style.border = '3px solid #ff6634';
    element.style.cursor = 'pointer';
    element.style.transition = '0.5s';
    element.style.backgroundColor = '#ffefea';
    element.style.color = '#ff6634';
  };

  const handleHoverLeaveOption = (id) => {
    const element = document.getElementById(id);
    element.style.border = '3px solid gray';
    element.style.backgroundColor = '#efefef';
    element.style.color = 'black';
  };

  const handleSelectPayOption = () => {
    const wayaquickCheck = document.getElementById('wayaquick-check');
    const paystackCheck = document.getElementById('paystack-check');
    if (selectedOption === 'wayaquick-check') {
      wayaquickCheck.style.display = 'flex';
      paystackCheck.style.display = 'none';
      handleDonation('WAYAQUICK');
      setTimeout(() => {
        setOpenPaymentOptionModal({
          status: false,
          paymentDetails: {},
        });
      }, 500);
    } else if (selectedOption === 'paystack-check') {
      paystackCheck.style.display = 'flex';
      wayaquickCheck.style.display = 'none';
      handleDonation('PAYSTACK');
      setTimeout(() => {
        setOpenPaymentOptionModal({
          status: false,
          paymentDetails: {},
        });
      }, 500);
    }
  };

  useEffect(() => {
    handleSelectPayOption();
  }, [selectedOption]);

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        {openPayModal && (
          <ModalHeader className="text-left" toggle={() => hideModal(false)}>
            <div className="interest-header normal-header">
              <h3 className="interest-p1">{headers[values.step].header}</h3>
              <div className="description-header mt-3">
                <p className="description-text">
                  {headers[values.step].description}
                </p>
              </div>
            </div>
          </ModalHeader>
        )}
        {openPayModal && (
          <ModalBody>
            <PageSwitch
              step={values.step}
              totalSteps={values.totalSteps}
              handleSteps={handleSteps}
              stepBackward={stepBackward}
              submitDonation={handleOpenPaymentOptionModal}
              handleFormInput={handleFormInput}
              userData={userData}
              values={values}
              data={data}
              setOtp={setOtp}
              otp={otp}
              loading={loading}
              setTrxStatusModal={setTrxStatusModal}
              // hideModal={hideModal}
            />
          </ModalBody>
        )}

        {openPaymentOptionModal.status && (
          <ModalBody>
            <div
              style={{
                backgroundColor: '#ff6634',
                height: '8rem',
                width: '100%',
                position: 'relative',
                display: 'flex',
                paddingTop: '1rem',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  cursor: 'pointer',
                }}
              >
                <IoCloseCircle
                  size={30}
                  color="white"
                  onClick={() => hideModal(false)}
                />
              </div>
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  color: 'white',
                }}
              >
                <h5>{openPaymentOptionModal?.paymentDetails?.type}</h5>
              </div>
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                  gap: '1rem',
                  color: 'white',
                }}
              >
                <div>
                  <span>
                    {numberFormat(
                      openPaymentOptionModal?.paymentDetails?.totalAmount
                    )}
                  </span>
                </div>
                <div>
                  <span>{openPaymentOptionModal?.paymentDetails?.email}</span>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: '#FAFAFC',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
              }}
            >
              <div>
                <h5>Select a payment option</h5>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '2rem',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#efefef',
                    border: '3px solid gray',
                    borderRadius: '1rem',
                    width: '12rem',
                    height: '9rem',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      right: '-15px',
                      top: '-12px',
                      border: '2px solid gray',
                      borderRadius: '5rem',
                      gap: '0.5rem',
                      backgroundColor: 'white',
                      padding: '0.3rem',
                    }}
                  >
                    <div>
                      <FcAlarmClock size={20} />
                    </div>
                    <span
                      style={{
                        fontSize: '0.8rem',
                      }}
                    >
                      Coming soon...
                    </span>
                  </div>
                  <div>
                    <IoWalletOutline size={70} />
                  </div>

                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    PAY WITH WAYAGRAM WALLET
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: '#efefef',
                    border: '3px solid gray',
                    borderRadius: '1rem',
                    width: '12rem',
                    height: '9rem',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    gap: '1rem',
                  }}
                  id="wayaquick"
                  onMouseEnter={() => handleHoverSelectOption('wayaquick')}
                  onMouseLeave={() => handleHoverLeaveOption('wayaquick')}
                  onClick={() => setSelectedOption('wayaquick-check')}
                >
                  <div
                    style={{
                      display: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      right: '-12px',
                      top: '-10px',
                      border: '2px solid #ff6634',
                      borderRadius: '5rem',
                      backgroundColor: 'white',
                      padding: '5px',
                      width: '2rem',
                      height: '2rem',
                    }}
                    id="wayaquick-check"
                  >
                    <div>
                      <FaCheck size={15} color="#ff6634" />
                    </div>
                  </div>
                  <div>
                    <img src={wayagramLogo} alt="wayagram-logo" />
                  </div>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    PAY WITH WAYAQUICK
                  </p>
                </div>
                <div
                  id="paystack"
                  style={{
                    backgroundColor: '#efefef',
                    border: '3px solid gray',
                    borderRadius: '1rem',
                    width: '12rem',
                    height: '9rem',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    gap: '0.5rem',
                  }}
                  onMouseEnter={() => handleHoverSelectOption('paystack')}
                  onMouseLeave={() => handleHoverLeaveOption('paystack')}
                  onClick={() => setSelectedOption('paystack-check')}
                >
                  <div
                    style={{
                      display: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      right: '-12px',
                      top: '-10px',
                      border: '2px solid #ff6634',
                      borderRadius: '5rem',
                      backgroundColor: 'white',
                      padding: '5px',
                      width: '2rem',
                      height: '2rem',
                    }}
                    id="paystack-check"
                  >
                    <div>
                      <FaCheck size={15} color="#ff6634" />
                    </div>
                  </div>

                  <div
                    style={{
                      width: '130px',
                    }}
                  >
                    <img src={paystackLogo} alt="wayagram-logo" width="100%" />
                  </div>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    PAY WITH PAYSTACK
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
      {trxStatusModal?.status ? (
        <TransactionStatusModal
          showModal={trxStatusModal}
          hideModal={setTrxStatusModal}
        />
      ) : (
        ''
      )}

      {/* {showShareDonationModal ? (
        <Share
          showModal={setShowShareDonationModal}
          hideModal={showShareDonationModal}
          title="Share"
          description="Share this donation with your friends"
          url={`${process.env.REACT_APP_WEBSITE_APP}/post/${data?.id}`}
          text={`${userData?.username} has made a donation for: ${data?.Donation?.title}, to support ${data?.Donation?.orgName}. He recommends you take part. Click the link below for more information about this donation.`}
        />
      ) : (
        ''
      )} */}
    </>
  );
};

function PageSwitch(props) {
  const {
    step,
    // totalSteps,
    handleSteps,
    submitDonation,
    handleFormInput,
    userData,
    values,
    data,
    setOtp,
    otp,
    loading,
    setTrxStatusModal,
    // hideModal,
    // stepBackward,
  } = props;
  const [showTermsModal, setShowTermsModal] = useState(false);

  const onAction = [submitDonation, handleSteps];

  return (
    <div className="donation-container">
      <div className="info-container">
        {step === 0 && (
          <PersonalInfo
            handleFormInput={handleFormInput}
            userData={userData}
            values={values}
          />
        )}
        {step === 1 && (
          <PinInput
            handleFormInput={handleFormInput}
            userData={userData}
            values={values}
            data={data}
            setOtp={setOtp}
            otp={otp}
            separator=" "
            setTrxStatusModal={setTrxStatusModal}
            // hideModal={hideModal}
          />
        )}
        <div className="termsnconditionText my-3">
          By sending, you agree with our{' '}
          <a href="/terms.html" rel="noreferrer" target="_blank">
            terms and conditions{' '}
          </a>
          on Donation creation
        </div>
        {/* <p>
          Kindly visit
          <a href="https://wayabank.ng/" rel="noreferrer" target="_blank">
            Wayabank
          </a>
          to create your transaction pin
        </p> */}
      </div>
      <FinishButton
        className="mb-3 mt-2"
        title={donationButtonTitle[step]}
        onClick={onAction[step]}
        loading={loading}
      />
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default MakeDonationModal;
