import { useRecoilValue } from 'recoil';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import NaijaStates from 'naija-state-local-government';
// import { NotificationManager } from 'react-notifications';
import { Select, InputNumber, Radio } from 'antd';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
// import imgPlaceholder from '../../../../assets/image/default-dp.svg';
// import cameraIcon from '../../../../assets/images/cameraIcon.png';
import '../FundWallet/index.scss';
import { customBaseUrl } from '../../../../action/http';
import Button from '../../Button';
import './imgGrid.scss';
import { PaidPostPreview } from './postUtils';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import MentionInput from '../../CustomInput/MentionInput';
// import TermsAndCondition from '../Terms/TermsAndCondition';
import { userTheme } from '../../../../store/initialState';
import { JpgIcon } from '../../Icons';
import { PriceDiv, RemoveDiv } from './style';
import MoneyInput from '../../MoneyInput';
import ShopTermsModal from './ShopTermsModal';
import useStore from '../../../../store/zustandStore/store';

const CreateProduct = ({
  showModal,
  hideModal,
  id,
  mode,
  selectedData,
  PostTags,
}) => {
  const queryClient = useQueryClient();
  const {
    categories,
    flyers,
    addNewProductToAllProduct,
    addNewProductToMyProduct,
    percentagePricing,
  } = useStore((state) => state);
  const theme = useRecoilValue(userTheme);
  const [postData, setPostData] = useState({ ...selectedData });
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);
  const [selectedNaijaState, setSelectedNaijaState] = useState('Lagos');
  const allNaijaState = NaijaStates.states();
  const [lgaPerState, setLgaPerState] = useState(
    NaijaStates.lgas(selectedNaijaState).lgas
  );
  const {
    wayagramProfile: profile,
    // reload,
    // setReload,
  } = useContext(WayagramContext);
  const selImg = selectedData?.ProductImages ? selectedData?.ProductImages : [];
  const [preview, setPreview] = useState(selImg);
  const [showPreview, setShowPreview] = useState(mode === 'edit');
  const [productPercentage, setProductPercentage] = useState(0);

  useEffect(() => {
    const productPricing = percentagePricing?.find(
      (p) => p?.product_type === 'Product'
    );
    setProductPercentage(productPricing?.percent);
  }, [percentagePricing]);

  useEffect(() => {
    setLgaPerState(NaijaStates.lgas(selectedNaijaState).lgas);
  }, [selectedNaijaState]);

  const handlePreview = (e) => {
    const { files } = e.target;
    const imgFiles = [...images, ...files];
    if (imgFiles.length > 10) {
      swal('Oops!', `Upload exceeded limit of 10`, 'error');
      return;
    }
    if (files.length) {
      setShowPreview(true);
      setImages(imgFiles);
      const imgArr = [];
      /* eslint-disable no-plusplus */
      for (let i = 0; i < imgFiles.length; i++) {
        const reader = new FileReader();
        /* eslint-disable no-loop-func */
        reader.onloadend = () => {
          imgArr.push({ type: imgFiles[0]?.type, imageUrl: reader.result });
          if (imgFiles.length - 1 === i) {
            setPreview(imgArr);
          }
        };
        reader.readAsDataURL(imgFiles[i]);
      }
    }
  };
  // const updateProductImage = async (newFormOpt) => {
  //   await httpPut(
  //     '/all-posts/update-product',
  //     newFormOpt,
  //     customBaseUrl.wayagramUrl
  //   );
  //   setReload(!reload);
  // // };

  // useEffect(() => {
  //   console.log('wayagram-product-new', postData);
  // }, [postData]);

  const handleShowTerm = async (t) => {
    if (t === 'product') setShowTermsModal(true);
  };

  const createWayagramProduct = async () => {
    setLoading(true);
    const deletedTags = [];
    if (!postData.description || postData.description === '') {
      swal('Error', 'Description cannot be Empty', 'error');
      setLoading(false);
      return;
    }
    if (!postData.amount || postData.amount === '0') {
      swal('Error', 'Amount cannot be Empty', 'error');
      setLoading(false);
      return;
    }

    if (!postData.deliveryType || postData.deliveryType === '') {
      swal('Error', 'Delivery type cannot be Empty', 'error');
      setLoading(false);
      return;
    }

    if (!postData.city || postData.city === '') {
      swal(
        'Error',
        'City where the product is delivered from cannot be Empty',
        'error'
      );
      setLoading(false);
      return;
    }

    if (!postData.state || postData.state === '') {
      swal(
        'Error',
        'State where the product is delivered from cannot be Empty',
        'error'
      );
      setLoading(false);
      return;
    }

    if (!postData.lga || postData.lga === '') {
      swal(
        'Error',
        'LGA where the product is delivered from cannot be Empty',
        'error'
      );
      setLoading(false);
      return;
    }

    if (!postData.location || postData.location === '') {
      swal(
        'Error',
        'Location/Description where the product is delivered from cannot be Empty',
        'error'
      );
      setLoading(false);
      return;
    }

    if (!postData.category || postData.category === '') {
      swal('Error', 'Product category cannot be Empty', 'error');
      setLoading(false);
      return;
    }
    if (images.length === 0 && preview.length === 0) {
      swal('Error', 'Please add product image', 'error');
      setLoading(false);
      return;
    }
    const file = Object.values(images);
    const isEdit = mode === 'edit';
    const newForm = new FormData();
    PostTags?.forEach((tag) => {
      if (!hashTagList?.includes(tag?.HashTag?.hashtag))
        deletedTags.push(tag?.HashTag?.hashtag);
    });
    const amt =
      String(postData?.amount)?.search(',') === -1
        ? postData?.amount
        : postData?.amount?.replaceAll(',', '');
    if (isEdit) newForm.append('product_id', postData?.productId);
    newForm.append('profile_id', profile.id);
    newForm.append('description', postData.description);
    newForm.append('name', postData.name);
    newForm.append('amount', amt);
    newForm.append('category', postData.category);
    newForm.append('deliveryType', postData.deliveryType || 'DELIVERY');
    newForm.append('city', postData.city);
    newForm.append('state', postData.state);
    newForm.append('lga', postData.lga);
    newForm.append('location', postData.location);
    // newForm.append('quantity', postData.quantity);
    if (!isEdit) newForm.append('delivery', postData.delivery);
    if (postData.isPromo === true || postData.isPromo === 'true') {
      newForm.append('isPromo', postData.isPromo);
      newForm.append('promoFlyer', postData.promoFlyer);
      newForm.append('percentage', postData.percentage);
    }

    file?.forEach((img) => {
      newForm.append('image', img);
    });

    const token = localStorage.getItem('token');

    if (mentionList?.length) newForm.append('mentions', mentionList);
    if (hashTagList?.length && !isEdit) newForm.append('hashtags', hashTagList);
    // if (isEdit) newForm.append('deletedHashtags', deletedTags);
    // if (isEdit) newForm.append('addedHashtags', hashTagList);
    const url = `/all-posts/${isEdit ? 'update' : 'create'}-product`;

    const res = await axios.post(
      `${customBaseUrl.wayagramUrl}${url}`,
      newForm,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
          'client-Id': 'WAYAGRAM',
          'client-Type': 'PERSONAL',
        },
      }
    );

    // eslint-disable-next-line consistent-return
    return res?.data;

    // const url = `/all-posts/${isEdit ? 'update' : 'create'}-product`;
    // let res;
    // if (isEdit) {
    //   res = await httpPut(url, newForm, customBaseUrl.wayagramUrl);
    // } else {
    //   try {
    //     res = await axios.post(`${customBaseUrl.wayagramUrl}${url}`, newForm, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: `${token}`,
    //         'client-id': 'WAYAGRAM',
    //         'client-type': 'PERSONAL',
    //       },
    //     });
    //   } catch (error) {
    //     NotificationManager.error(error?.message, 'Oops!', 3000);
    //   }
    // }
    // if (res?.status) {
    //   const pid = isEdit ? postData?.productId : res?.data?.id;
    //   if (file.length > 0) {
    //     file?.forEach(async (img) => {
    //       const newFormOpt = new FormData();
    //       newFormOpt.append('product_id', pid);
    //       newFormOpt.append('profile_id', profile.id);
    //       newFormOpt.append('image', img);
    //       await updateProductImage(newFormOpt);
    //     });
    //   }
    //   setLoading(false);
    //   hideModal(false);
    //   NotificationManager.success(res?.message, 'Done!', 3000);
    // } else {
    //   setLoading(false);
    //   NotificationManager.error(res?.message, 'Oops!', 3000);
    // }
  };

  // using react query
  const createProduct = useMutation(createWayagramProduct, {
    onSuccess: (data) => {
      if (data?.status) {
        addNewProductToAllProduct(data?.data);
        addNewProductToMyProduct(data?.data);
        setLoading(false);
        hideModal(false);
        swal('Success', data.message, 'success');
        queryClient.invalidateQueries('allProducts');
        queryClient.invalidateQueries('myProducts');
      } else {
        swal('Error', data?.message, 'error');
        setLoading(false);
      }
      // swal('Error', data?.message, 'error');
    },
    onError: (error) => {
      console.log('wayagram-create-product-error', error);
      setLoading(false);
      swal('Error', error?.message, 'error');
    },
  });

  const createWayagramPostCont = async () => {
    createProduct.mutate();
  };

  const createWayagramPost = () => {
    swal({
      title: 'Payment Info',
      text: `All products payment will be credited into your Wayabank (default wallet) at the end of the transaction with the buyer.`,
      buttons: true,
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        createWayagramPostCont();
      } else {
        setLoading(false);
      }
    });
  };

  const handleChange = (query) => {
    setPostData({ ...postData, description: query });
  };
  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          hideModal(false);
          setPreview([]);
        }}
        id={id}
        centered
      >
        <ModalHeader
          className="text-left"
          toggle={() => hideModal(false)}
          style={theme.modalStyle}
        >
          <span style={theme.modalStyle}>
            {mode === 'create' ? 'Create' : 'Edit'} Product
          </span>
        </ModalHeader>
        <ModalBody style={theme.modalStyle}>
          <form className="mx-auto mt-3">
            <div className="m-3">
              <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                Product Name
              </p>
              <input
                type="text"
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                placeholder="sneakers"
                value={postData?.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </div>

            {/* <div>
              <p className="mb-2">Product Category</p>
              <SelectInput
                options={categories}
                selectedOption={selectedCategory}
                handleSelectOption={handleSelectCategory}
              />
            </div> */}

            <div className="d-flex w-100 border-0 border-bottom">
              <div className="image-div">
                {/* <img
                  src={profile?.avatar || imgPlaceholder}
                  alt="placeholder"
                /> */}
              </div>
              <MentionInput
                setText={handleChange}
                text={postData.description}
                setMentionList={setMentionList}
                setHashTagList={setHashTagList}
                placeholder="Describe your product"
              />
            </div>
            <PriceDiv>
              <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                Product Price
              </p>
              <MoneyInput
                placeholder="0.00"
                value={postData.amount}
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                onChange={(e) => {
                  setPostData({
                    ...postData,
                    amount: e.target.defaultValue,
                    // isPaid: true,
                  });
                }}
              />
            </PriceDiv>

            {/* <div className="m-3">
              <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                Available quantity(Stock)
              </p>
              <InputNumber
                min={0}
                max={9}
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                placeholder="5"
                value={postData?.quantity}
                onChange={(value) =>
                  setPostData({ ...postData, quantity: value })
                }
              />
            </div> */}

            {mode !== 'edit' && (
              <div className="m-3">
                <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                  Delivery
                </p>
                <Select
                  defaultValue="Select Delivery"
                  className={`w-100 border-0 border-bottom bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  value={postData?.delivery}
                  onChange={(value) =>
                    setPostData({ ...postData, delivery: value })
                  }
                  // defaultValue="Select Delivery"
                >
                  <Select.Option value="" disabled>
                    Select Delivery
                  </Select.Option>
                  <Select.Option value="PAID">PAID</Select.Option>
                  <Select.Option value="FREE">FREE</Select.Option>
                </Select>
              </div>
            )}

            <div className="m-3">
              <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                Category
              </p>
              <Select
                defaultValue="Select category"
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                onChange={(value) =>
                  setPostData({ ...postData, category: value })
                }
                value={postData?.category}
              >
                <Select.Option value="" disabled>
                  Select category
                </Select.Option>
                {categories.map((cat) => {
                  return (
                    <Select.Option key={cat.id} value={cat.name}>
                      {cat.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>

            <div className="m-3">
              <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                Is this product on promo?
              </p>
              <Select
                defaultValue="Select Yes or No"
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                onChange={(value) =>
                  setPostData({ ...postData, isPromo: value })
                }
                value={postData?.isPromo}
              >
                <Select.Option value="" disabled>
                  Select Yes or No
                </Select.Option>
                <Select.Option value="false">No</Select.Option>
                <Select.Option value="true">Yes</Select.Option>
              </Select>
            </div>

            {postData?.isPromo === 'true' && (
              <div>
                <div className="m-3">
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Percentage Discount
                  </p>
                  <InputNumber
                    min={0}
                    // max={9}
                    className={`w-100 border-0 border-bottom bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    placeholder="5"
                    value={postData?.percentage}
                    onChange={(value) =>
                      setPostData({ ...postData, percentage: value })
                    }
                  />
                </div>

                <div className="m-3">
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Select Flyer if on promo
                  </p>
                  <div
                    className="mb-2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      gap: '1rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    {flyers?.length > 0 &&
                      flyers?.map((flyer) => {
                        return (
                          <div
                            key={flyer.id}
                            className="mb-2"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              gap: '1rem',
                              flexWrap: 'wrap',
                            }}
                          >
                            <input
                              type="radio"
                              name="flyer"
                              value={flyer.design}
                              onChange={(e) =>
                                setPostData({
                                  ...postData,
                                  promoFlyer: e.target.value,
                                })
                              }
                            />
                            <img
                              src={flyer?.design}
                              alt="flyer"
                              width="150rem"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}

            {/* <div className="m-3">
              <p className="mb-2">Product Quantity</p>
              <input
                type="text"
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                placeholder="QTY"
                value={postData?.qty}
                onChange={(e) =>
                  setPostData({ ...postData, qty: e.target.value })
                }
              />
            </div> */}
            <div>
              <div className="mx-3 mt-5">
                <h4>Delivery Details</h4>
              </div>

              <div className="m-3">
                <Radio.Group
                  onChange={(e) =>
                    setPostData({ ...postData, deliveryType: e.target.value })
                  }
                  value={postData?.deliveryType}
                  // defaultValue="DELIVERY"
                >
                  <Radio value="DELIVERY">Delivery only</Radio>
                  <Radio value="PICKUP">Pickup only</Radio>
                  <Radio value="BOTH">Both</Radio>
                </Radio.Group>
              </div>
              <div className="m-3">
                {postData?.deliveryType === 'DELIVERY' ? (
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Deliver from
                  </p>
                ) : postData?.deliveryType === 'PICKUP' ? (
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Pickup from
                  </p>
                ) : postData?.deliveryType === 'BOTH' ? (
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Pickup or Deliver from
                  </p>
                ) : (
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Deliver from
                  </p>
                )}

                <input
                  type="text"
                  className={`w-100 border-0 border-bottom bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  placeholder="Where the goods will be delivered from"
                  value={postData?.city}
                  onChange={(e) =>
                    setPostData({ ...postData, city: e.target.value })
                  }
                />
              </div>

              <div className="m-3">
                <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                  State
                </p>

                {/* <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Is this product on promo?
                  </p> */}
                <Select
                  defaultValue="Choose the state you will be delivering from"
                  className={`w-100 border-0 border-bottom bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  onChange={(value) => {
                    setPostData({ ...postData, state: value });
                    setSelectedNaijaState(value);
                  }}
                  value={postData?.state}
                >
                  <Select.Option
                    value="Choose the state you will be delivering from"
                    disabled
                  >
                    Choose State
                  </Select.Option>
                  {allNaijaState?.map((state) => (
                    <Select.Option key={state} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="m-3">
                <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                  Local Government Area
                </p>
                <Select
                  defaultValue="Choose the LGA you will be delivering from"
                  className={`w-100 border-0 border-bottom bg-${
                    theme.mode === 'dark' ? 'secondary' : 'white'
                  }`}
                  onChange={(value) => {
                    setPostData({ ...postData, lga: value });
                  }}
                  value={postData?.lga}
                >
                  <Select.Option
                    value="Choose the LGA you will be delivering from"
                    disabled
                  >
                    Choose LGA
                  </Select.Option>
                  {lgaPerState.length &&
                    lgaPerState.map((lga) => (
                      <Select.Option key={lga} value={lga}>
                        {lga}
                      </Select.Option>
                    ))}
                </Select>

                <div className="mt-3">
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Description
                  </p>
                  <textarea
                    // type="text"
                    className={`w-100 border-0 border-bottom bg-${
                      theme.mode === 'dark' ? 'secondary' : 'white'
                    }`}
                    placeholder="Write down the town and then the street and the number and any landmark"
                    value={postData?.location}
                    onChange={(e) =>
                      setPostData({ ...postData, location: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            {showPreview && (
              <PaidPostPreview
                preview={preview}
                setShowPreview={setShowPreview}
                setPreview={setPreview}
                showPreview={showPreview}
                images={images}
                setImages={setImages}
                theme={theme}
              />
            )}
            <RemoveDiv className="mt-2" hidden={!preview.length}>
              <p
                onClick={() => {
                  setShowPreview(false);
                  setPreview([]);
                }}
              >
                Remove Products
              </p>
            </RemoveDiv>
            {/* <div className="termsnconditionText my-3">
            By sending, you agree with our{' '}
            <a
              href="/terms.html"
              rel="noreferrer"
              target="_blank"
            >
              terms and conditions
            </a>{' '}
            of product creation
          </div> */}
          </form>
        </ModalBody>
        <ModalFooter style={theme.modalStyle}>
          <div
            className="mb-3 mt-2"
            style={{
              display: 'flex',
              width: '70%',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="add-cursor"
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <input
                type="file"
                style={{
                  height: '100%',
                  width: '100%',
                  opacity: '0',
                  position: 'absolute',
                  cursor: 'pointer',
                }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => handlePreview(e)}
              />
              {/* <img src={cameraIcon} alt="camera" /> */}
              Upload photos <JpgIcon color={theme?.modalStyle?.color} />
            </div>
          </div>
          <Button
            loading={loading}
            disabled={loading || false}
            className="btn btn-primary mb-3 mt-2"
            color="primary"
            content="Continue"
            onClick={() => setShowTermsModal(true)}
          />
          {/* <Button
            loading={loading}
            disabled={loading || false}
            className="btn btn-primary mb-3 mt-2"
            color="primary"
            content={mode === 'create' ? 'Create' : 'Update'}
            onClick={createWayagramPost}
          /> */}
        </ModalFooter>
      </Modal>

      {showTermsModal && (
        <ShopTermsModal
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
          handleShowTerm={handleShowTerm}
          handleSubmit={createWayagramPost}
          productPercentage={productPercentage}
        />
      )}
    </>
  );
};

CreateProduct.defaultProps = {
  id: '',
  mode: 'create',
  // selectedData: {},
};

CreateProduct.propTypes = {
  // hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  // type: PropTypes.string.isRequired,
  mode: PropTypes.string,
  // handleSubmit: PropTypes.func.isRequired,
  // selectedData: PropTypes.node,
};

export default CreateProduct;
