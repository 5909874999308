const validateImage = (file) => {
  let valid = true;
  let message = 'uploading...';
  /* eslint-disable no-plusplus */
  for (let i = 0; i < file.length; i++) {
    if (file[i] && !/\/(jpg|jpeg|tiff|png|mp4|webm|wmv)$/i.test(file[i].type)) {
      valid = false;
      message = 'Invalid picture format';
    }
    if (i >= 2) {
      valid = false;
      message = 'Donation image must not be more than 2';
    }
    if (file[i] && parseFloat(file[i].size) > 10097152) {
      valid = false;
      message = 'File size exceeds 10MB';
    }
  }

  return { valid, message };
};

export default validateImage;

export const checkExtension = (file) => {
  const reg = /\.([0-9a-z]+)(?:[?#]|$)/i;
  const r = file?.match(reg);
  return r;
};

export const fileValidation = (fileInput) => {
  const filePath = fileInput?.type;
  console.log({ filePath });
  //  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  if (!filePath.includes('image/')) {
    // console.log('Please upload file .jpeg/.jpg/.png/.gif only.');
    // fileInput.value = '';
    return false;
  }
  return true;
};
export const videoValidation = (fileInput) => {
  const filePath = fileInput?.type;
  console.log({ filePath });
  //  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  if (!filePath?.includes('video/')) {
    // console.log('Please upload file .jpeg/.jpg/.png/.gif only.');
    // fileInput.value = '';
    return false;
  }
  return true;
};
