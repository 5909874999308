import React, { useState, useEffect } from 'react';
import { format, differenceInDays, differenceInHours } from 'date-fns';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../../../../shared/Layout';
import BidModal from '../modal/BidModal';
// import './bidCard.css';

const BidCardDetails = (props) => {
  const { quoteID } = useParams();
  const [bidDetail, setBidDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const formatNaira = (amount) => {
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  const formatDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const daysDifference = differenceInDays(end, start);
    const hoursDifference = differenceInHours(end, start) % 24;

    const formattedDaysHours = `${daysDifference} days ${hoursDifference} hours`;
    const formattedEndDate = format(end, "EEEE do 'of' MMMM yyyy");

    return `${formattedDaysHours} | ${formattedEndDate}`;
  };

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      try {
        const response = await axios.get(
          `https://services.staging.wayagram.ng/wayagram-products/quotes/${quoteID}`
        );
        const { data } = response.data;
        setBidDetails(data);
      } catch (error) {
        console.error('Error fetching bid details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuoteDetails();
  }, [quoteID]);

  const handleReadMoreToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Layout route={props} currentPage="bid" pageName="Bidding" showName>
      {loading ? (
        <div>Loading...</div>
      ) : !bidDetail ? (
        <div>Bid details not found.</div>
      ) : (
        <div className="product-card">
          <p className="product-card__category">{bidDetail.category}</p>
          <h1 className="product-card__title">{bidDetail.itemName}</h1>
          <hr className="product-card__divider" />

          <div className="product-flex-card-container">
            <div className="product-description-detail">
              <div className="product-card__info-row">
                <p className="product-card__label">Condition:</p>
                <p className="product-card__value">{bidDetail.condition}</p>
              </div>

              <div className="product-card__info-row product-card__description">
                <p className="product-card__label">Description:</p>
                <p className="product-card__value">
                  {isExpanded
                    ? bidDetail.description
                    : `${bidDetail.description.substring(0, 200)}...`}{' '}
                  <a
                    href="#readmore"
                    className="product-card__readmore"
                    onClick={(e) => {
                      e.preventDefault();
                      handleReadMoreToggle();
                    }}
                  >
                    {isExpanded ? 'Show less' : 'Read more'}
                  </a>
                </p>
              </div>

              <div className="product-card__info-row">
                <p className="product-card__label">Time duration:</p>
                <p className="product-card__value">
                  {formatDateRange(
                    bidDetail.biddingStart,
                    bidDetail.biddingEndTime
                  )}
                </p>
              </div>

              <div className="product-card__info-row">
                <p className="product-card__label">Bid amount:</p>
                <p className="product-card__value product-card__bid-amount-value">
                  {formatNaira(bidDetail.startingAmount)}
                </p>
              </div>

              <div className="product-card__info-row info-row-btn">
                <p className="product-card__label" />
                <button
                  type="button"
                  className="product-card__bid-button"
                  onClick={() => setShowModal(true)}
                >
                  Place bid
                </button>
              </div>
            </div>
          </div>

          {showModal && (
            <BidModal onClose={() => setShowModal(false)} quoteID={quoteID} />
          )}
        </div>
      )}
    </Layout>
  );
};

export default BidCardDetails;
