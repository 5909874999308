import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberInput = (props) => {
  const { value, onChange, className, placeholder, format, mask, type } = props;
  return (
    <NumberFormat
      format={format}
      mask={mask}
      type={type}
      className={className}
      placeholder={placeholder}
      value={value}
      onValueChange={onChange}
    />
  );
};

NumberInput.defaultProps = {
  placeholder: '0.00',
  className: '',
  // format: '#### #### #### #### ####',
  mask: '_',
  type: 'text',
  value: '',
};

NumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  // format: PropTypes.string,
  mask: PropTypes.string,
  type: PropTypes.string,
};

export default NumberInput;
