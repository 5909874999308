import axios from 'axios';

// Base URLs for your APIs
const DEVELOPMENT_BASE_API_URL =
  'https://services.staging.wayagram.ng/wayagram-products';
const LOCAL_BASE_API_URL = 'http://localhost:80';

// Function to check if the local API is up
const checkLocalAPI = async () => {
  try {
    await axios.get(`${LOCAL_BASE_API_URL}/quotes`);
    return true;
  } catch (error) {
    return false;
  }
};

// Function to get the appropriate base URL
const getBaseURL = async () => {
  const isLocalAPIUp = await checkLocalAPI();
  return isLocalAPIUp ? LOCAL_BASE_API_URL : DEVELOPMENT_BASE_API_URL;
};

export const fetchData = async () => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.get(baseURL);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async (data) => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.post(`${baseURL}/quotes`, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchBidStats = async () => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.get(`${baseURL}/bidStats`);
    const { data } = response;
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const bidStats = async (userID) => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.get(`${baseURL}/${userID}/bids-and-counts`);

    const { data } = response;

    // Initialize default values for counts
    const defaultCounts = {
      pendingCount: '0',
      closedCount: '0',
      acceptedCount: '0',
      rejectedCount: '0',
      cancelledCount: '0',
    };

    const counts = {
      ...defaultCounts,
      ...data.counts,
    };

    return {
      ...data,
      counts,
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const bidBuyerStats = async (userID) => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.get(`${baseURL}/${userID}/bids-and-counts`);
    const { data } = response;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchBidQuotes = async () => {
  try {
    const baseURL = await getBaseURL();
    const response = await axios.get(`${baseURL}/quotes`);
    const { data } = response;
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchUserBidsAndCounts = async (userDetails) => {
  try {
    const baseURL = await getBaseURL();
    const endpoint = `${baseURL}/${userDetails}/bids-and-counts`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching user bids and counts:', error);
    throw error;
  }
};
