import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import swal from 'sweetalert';
import StickyBox from 'react-sticky-box';
import { useHistory } from 'react-router';
import RightSidebarWrapper from './style';
import List from '../../shared/Cards/List';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { handleJoinGroup, followOrUnfollowPage } from './container';
import { handleFollowUser } from '../../../utils/helper';

export default function RightSidebar(props) {
  const { isSearch } = props;
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const {
    groupSuggestion,
    pageSuggestion,
    peopleSuggestion,
    reload,
    setReload,
    loading,
    setLoading,
  } = useContext(WayagramContext);

  const followPeople = async (authId, username) => {
    setLoading(true);
    await handleFollowUser(username, authId, () => setReload(!reload));
    setLoading(false);
  };

  const onAction = async (userId, groupId) => {
    setLoading(true);
    const res = await handleJoinGroup(userId, groupId);
    if (res.status) {
      swal('Done', res.message, 'success');
      setReload(!reload);
    } else {
      swal('Oops!', res.message, 'error');
    }
    setLoading(false);
  };

  const followAction = async (userId, pageId) => {
    setLoading(true);
    const res = await followOrUnfollowPage(userId, pageId);
    if (res.status) {
      swal('Done', res.message, 'success');
      setReload(!reload);
    } else {
      swal('Oops!', res.message, 'error');
    }
    setLoading(false);
  };

  return (
    <RightSidebarWrapper className="col-4">
      <StickyBox offsetTop={10} offsetBottom={20}>
        <div
          className="column-right-container mb-3 ps-4"
          style={{ width: 'max-content' }}
        >
          <div className="row" style={{ position: 'relative' }}>
            <div
              className="search-container mb-3 input-group mx-auto"
              style={!isSearch ? { display: 'none' } : {}}
            >
              <input
                className="w-100 form-control ps-3 search-input"
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    history.push({
                      pathname: '/wayagram-discover',
                      from: 'wayagram',
                      data: e.target.value,
                      state: { activeTab: 'People' },
                    });
                  }
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '25px',
                  height: '100%',
                  border: 'none',
                }}
                className="input-group-append"
              >
                <i className="fa fa-search" />
              </span>
            </div>
          </div>
          <List
            headerText="People"
            list={peopleSuggestion?.slice(0, 3)}
            onAction={followPeople}
            loading={loading}
            buttonTitle="Follow"
          />
          <List
            headerText="Groups"
            list={groupSuggestion.slice(0, 3)}
            onAction={onAction}
            loading={loading}
            buttonTitle="Join"
          />
          <List
            headerText="Pages"
            list={pageSuggestion.slice(0, 3)}
            onAction={followAction}
            loading={loading}
            buttonTitle="Follow"
          />
        </div>
      </StickyBox>
    </RightSidebarWrapper>
  );
}

// RightSidebar.defaultProps = {
//   isSearch: true,
// };

// RightSidebar.propTypes = {
//   isSearch: PropTypes.string,
// };
