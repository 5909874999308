import styled from 'styled-components';
import device from '../../../utils/device';

// export const mobileSideBarWrapper = {
//   position: 'fixed',
//   bottom: 0,
//   width: 'inherit',
//   textAlign: 'center',
//   width: '100%',
//   height: '70px',
//   backgroundColor: 'black',
//   padding: '10px',
//   display: device.mobileM ? 'none' : '',
// };

export const createPostStyle = {
  background: '#064A72',
  borderRadius: '999px',
  padding: '12px',
  textAlign: 'center',
  color: '#fff',
  width: '205px',
  marginTop: '20px',
};

export const logoutStyle = { marginTop: '115px', color: '#EB5757' };

export const MobileSideBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: inherit;
  text-align: center;
  width: 100%;
  height: 70px;
  background: black;
  padding: 10px;
  .mobilenav-text {
    font-size: 12px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const LaptopSidebar = styled.div`
  @media ${device.maxtablet} {
    transform: ${(props) => (!props.toggle ? 'translateX(-305px)' : '')};
    transition: all 0.15s ease;
    background-color: ${(props) => props?.bgColor?.background} !important;
    color: ${(props) => props?.bgColor?.color} !important;
  }
`;

export const NavbarAvatar = styled.div`
  margin-right: 15px;
  background: #ffffff;
  width: 200px;
  display: flex;
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #000000;
    margin: 0px;
    margin-left: 5px;
  }
  span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #000000;
    margin-left: 5px;
  }
  img {
    height: 35px;
    width: 35px;
    border-radius: 17px;
  }
`;

export const NavbarMenu = styled.div`
  margin: 15px;
  img {
    height: 35px;
    width: 35px;
    border-radius: 17px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const NotificationWrapper = styled.div`
  background-color: #ffffff;
  .ant-drawer-content {
    background-color: #ffffff;
  }
  .ant-drawer-header {
    background-color: #ffffff;
  }
`;

export const NavbarWrapper = styled.div`
  // width: 100% !important;
  background-color: ${(props) => props.bgColor.background} !important;
  color: ${(props) => props.bgColor.color} !important;
  .nav-mid-left {
    display: flex;
    align-items: center;
  }
  .ant-drawer-content {
    background-color: #ffffff;
  }
  .ant-drawer-header {
    background-color: #ffffff;
  }
  @media ${device.maxtablet} {
    width: 100% !important;
    left: 0;
    .navbar-right {
      display: none !important;
    }
    .navbar-mid {
      width: 100% !important;
      padding-right: 24px;
    }
    .nav-mid-right {
      width: 100%;
      justify-content: flex-end;
      .toggle-switch {
        display: none !important;
      }
      .switch-label {
        font-size: 10px;
      }
    }
  }
  .custom-nav {
    width: 100%;
    display: flex;
    .navbar-right {
      width: 60% !important;
    }
    .navbar-mid {
      width: 31%;
      border-right: #e5e5e5 1px solid;
    }
  }
  .wayagram-nav {
    width: 100%;
    display: flex;
    .navbar-mid {
      width: 60%;
    }
  }
`;

export const ChildWrapper = styled.div`
  width: calc(100vw - 305px);
  position: relative;
  left: 305px;
  top: 70px !important;
  // overflow-y: scroll;
  height: calc(100vh - 70px);
  .layout-container {
    background-color: ${(props) => props.bgColor.background} !important;
    color: ${(props) => props.bgColor.color} !important;
    padding: 0;
  }
  @media ${device.maxtablet} {
    left: 0;
    width: 100% !important;
    .layout-container {
      .wayapay-div .notification-div {
        width: 100%;
      }
    }
  }
  @media ${device.maxmobileL} {
    bottom: 70px !important;
    height: calc(100vh - 140px);
    .layout-container {
      .wayapay-div .notification-div {
        width: 100%;
      }
    }
  }
  @media ${device.maxlaptop} {
    .layout-container {
      .mid-bottom-content .card-content {
        width: 100%;
      }
    }
  }
  @media ${device.maxtablet} {
    .dasboard-col-one {
      width: 100% !important;
    }
    .miditems-top-tabs {
      height: 80px !important;
    }
    .mid-bottom-content {
      height: 185px !important;
      padding: 10px;
    }
  }
  // @media ${device.tablet} {
  //   .layout-container {
  //     min-height: 100vh;
  //     padding: 0 !important;
  //     overflow: hidden;
  //   }
  // }
`;
