import React from 'react';
import { useLocation } from 'react-router-dom';
import paidPostIcon from '../../../../assets/images/paidPostIcon.png';
import { numberFormat } from '../../../../utils/helper';
import PostImageCard, { ProductImageCard } from './PostImageCard';
import ButtonComponent from '../../Button';

const PaidCard = ({
  PostImages,
  data,
  Product,
  handleBuyNow,
  handleAddToCart,
  wayagramProfileId,
}) => {
  const mainPath = useLocation();
  const path = mainPath.pathname;
  const amt = data?.amount || Product?.amount;
  const discountAmt =
    (Product?.discountAmount !== Product?.amount && Product?.discountAmount) ||
    (data?.discountAmount !== data?.amount && data?.discountAmount) ||
    null;

  return (
    <div>
      {Product && PostImages?.length > 3 ? (
        <ProductImageCard PostImages={PostImages} />
      ) : (
        <PostImageCard PostImages={PostImages} />
      )}
      {Product && <span className="my-2 mx-4">{Product?.name}</span>}

      <div className="d-flex mt-1 justify-content-between align-items-baseline">
        <img
          src={paidPostIcon}
          alt="paid post"
          style={{ width: '2.2em', height: '1.7em' }}
        />

        <div>
          <p
            className={`mx-1 ${
              discountAmt && discountAmt !== 0
                ? 'text-decoration-line-through text-secondary'
                : ''
            }`}
          >
            {numberFormat(amt)}
          </p>
        </div>

        {discountAmt && discountAmt !== 0 ? (
          <p className="mx-2">{numberFormat(discountAmt)}</p>
        ) : (
          ''
        )}

        {discountAmt ? (
          <p className="mx-2 text-secondary">{Product?.percentage}% Discount</p>
        ) : (
          ''
        )}

        {Product && Product?.delivery && (
          <p className="mx-2">Delivery: {Product?.delivery}</p>
        )}
        {path === '/shops' && wayagramProfileId !== Product.ProfileId ? (
          <div className="d-flex mt-1 justify-content-end">
            <ButtonComponent
              content="Buy Now"
              className="btn btn-primary donation-button px-2 mx-2"
              onClick={() => handleBuyNow()}
              role="button"
            />

            <ButtonComponent
              content="Add to Cart"
              className="btn btn-primary donation-button px-2 mr-2"
              onClick={() => handleAddToCart()}
              role="button"
            />
          </div>
        ) : data?.kind === 'product' &&
          !wayagramProfileId &&
          path !== '/wayagram' ? (
          <div className="d-flex mt-1 justify-content-end">
            <ButtonComponent
              content="Add to Cart"
              className="btn btn-primary donation-button px-2 mr-2"
              onClick={() => handleAddToCart()}
              role="button"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

// PaidCard.prototype = {
//   handleBuyNow: PropTypes.func.isRequired,
// };

export default PaidCard;
