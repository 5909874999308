import React, { useState } from 'react';
import VendorStat from './Bid/Vendor/VendorStat';
import BidFilterTab from './Bid/pages/BidFilterTab';
import VendorCloseBids from './Bid/Vendor/VendorCloseBids';
import VendorDeliveryStatus from './Bid/Vendor/VendorDeliveryStatus';
import VendorTransactions from './Bid/Vendor/VendorTransactions';
import OpenBidTable from './Bid/pages/BidTable';

const VendorContent = ({ stats, userDetails, transactionData = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const totalBids =
    stats.totalOpenBids + stats.totalClosedBids + stats.totalWonBids;

  const calculatePercentage = (value) => ((value / totalBids) * 100).toFixed(1);

  const bidStats = [
    {
      title: 'Open Bids',
      value: stats.totalOpenBids,
      percentage: calculatePercentage(stats.totalOpenBids),
    },
    {
      title: 'Closed Bids',
      value: stats.totalClosedBids,
      percentage: calculatePercentage(stats.totalClosedBids),
    },
    {
      title: 'Won Bids',
      value: stats.totalWonBids,
      percentage: calculatePercentage(stats.totalWonBids),
    },
  ];

  return (
    <>
      <div className="bidding__stats">
        {bidStats.map(({ title, value, percentage }) => (
          <VendorStat
            key={title}
            title={title}
            value={value}
            percentage={`${percentage}%`}
          />
        ))}
      </div>
      <BidFilterTab
        tabs={['Open Bids', 'Closed Bids', 'Delivery Status', 'Transaction']}
        activeIndex={activeIndex}
        onClick={setActiveIndex}
        counts={[...Object.values(stats), transactionData.length]}
      />
      {activeIndex === 0 && <OpenBidTable userType={userDetails.id} />}
      {activeIndex === 1 && <VendorCloseBids userType={userDetails.id} />}
      {activeIndex === 2 && <VendorDeliveryStatus userType={userDetails.id} />}
      {activeIndex === 3 && (
        <VendorTransactions userType={userDetails.id} data={transactionData} />
      )}
    </>
  );
};

export default VendorContent;
