import { Tag } from 'antd';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import sharePostIcon from '../../../../assets/image/share-post.svg';
import shareMomentIcon from '../../../../assets/image/share-moment.svg';
import createPageIcon from '../../../../assets/image/create-page.svg';
import pollIcon from '../../../../assets/image/polls.svg';
import donationIcon from '../../../../assets/image/donation.svg';
import groupIcon from '../../../../assets/image/group.svg';
import calendarIcon from '../../../../assets/image/calendar.svg';
import cartIcon from '../../../../assets/image/cart.svg';
import LiveStreaming from '../../../../assets/image/live-streaming.svg';
import CreatePostModal from './CreatePost';
import CreateProductModal from './CreateProduct';
import CreateGroupModal from './CreateGroupModal';
import CreateMomentModal from './CreateMoment';
import CreatePageModal from './CreatePage';
// import CreateEventModal from './CreateEvent';
import CreateAuctionModal from './CreateAuction';
import DonationModal from './DonationModal';
import CreateEventModal from './CreateEventModal/index';
import PublishEventModal from './CreateEventModal/PublishEventModal';
import './index.scss';
import Container from '../../Container';
import CreatePoll from './CreatePoll';
import { userTheme } from '../../../../store/initialState';
import CreateContest from './CreateContest';

export default function PostModal(props) {
  const { showModal, hideModal, set, type } = props;
  const theme = useRecoilValue(userTheme);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showCreateContest, setShowCreateContest] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showCreateMoment, setShowCreateMoment] = useState(false);
  const [showCreatePage, setShowCreatePage] = useState(false);
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showCreateAuction, setShowCreateAuction] = useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [showPusblishModal, setShowPublishModal] = useState(false);
  // const [showPoll, setShowPoll] = useState(false);
  const myRef = React.useRef(null);

  const handleHideModal = () => {
    hideModal();
    setShowCreatePost(false);
    setShowCreatePoll(false);
    setShowCreateMoment(false);
    setShowCreateProduct(false);
    setShowCreateContest(false);
    setShowCreatePage(false);
    setShowCreateGroup(false);
    setShowDonation(false);
    setShowPublishModal(false);
    setShowCreateEvent(false);
  };
  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="post-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div style={theme.modalStyle} className="p-3 rounded-3">
          <div className="header-box">
            <h4 style={theme.modalStyle}>Create</h4>
          </div>
          <ModalBody className="">
            <Container
              className="d-flex"
              onClick={() => setShowCreatePost(true)}
            >
              <div className="icon me-3">
                <img src={sharePostIcon} alt="post" />
              </div>
              <div className="text-div">
                <span>Post</span>
                <span className="s-text d-block">
                  Share a post or write what is on your mind
                </span>
              </div>
            </Container>
            <Container
              className="d-flex mt-3 mb-3"
              onClick={() => setShowCreatePoll(true)}
            >
              <div className="icon me-3">
                <img src={pollIcon} alt="post" />
              </div>
              <div className="text-div">
                <span>Polls</span>
                <span className="s-text d-block">
                  Create a Polls for your favs
                </span>
              </div>
            </Container>
            {type === 'user' && (
              <>
                <Container
                  className="d-flex mt-3"
                  onClick={() => {
                    // hideModal();
                    setShowDonation(true);
                  }}
                >
                  <div className="icon me-3">
                    <img src={donationIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Donation</span>
                    <span className="s-text d-block">Create Donation</span>
                  </div>
                </Container>
                <hr />
                <Container
                  className="d-flex mt-3"
                  onClick={() => setShowCreateMoment(true)}
                >
                  <div className="icon me-3">
                    <img src={shareMomentIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Moment</span>
                    <span className="s-text d-block">
                      Share a photo or write something
                    </span>
                  </div>
                </Container>
                <Container
                  className="d-flex mt-3 mb-5"
                  onClick={() => {
                    // hideModal();
                    setShowCreatePage(true);
                  }}
                >
                  <div className="icon me-3">
                    <img src={createPageIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Page</span>
                    <span className="s-text d-block">
                      Create a page for your business or fans
                    </span>
                  </div>
                </Container>

                <Container
                  className="d-flex mt-5"
                  onClick={() => {
                    // hideModal();
                    setShowCreateGroup(true);
                  }}
                >
                  <div className="icon me-3">
                    <img src={groupIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Group</span>
                    <span className="s-text d-block">
                      Create a private or public group of interests
                    </span>
                  </div>
                </Container>
                <Container
                  className="d-flex mt-3"
                  onClick={() => {
                    // hideModal();
                    setShowCreateEvent(true);
                  }}
                >
                  <div className="icon me-3">
                    <img src={calendarIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Event</span>
                    <span className="s-text d-block">
                      Bring people together with public or private events
                    </span>
                  </div>
                </Container>
                <Container
                  className="d-flex mt-3"
                  onClick={() => setShowCreateProduct(true)}
                >
                  <div className="icon me-3">
                    <img src={cartIcon} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Products</span>
                    <span className="s-text d-block">
                      Create a product of your new collections
                    </span>
                  </div>
                </Container>
                <Container className="d-flex mt-3">
                  <div className="icon me-3">
                    <img src={LiveStreaming} alt="post" />
                  </div>
                  <div className="text-div">
                    <span>Go Live</span>{' '}
                    <Tag color="processing">Coming soon</Tag>
                    <span className="s-text d-block">Go live on Wayagram</span>
                  </div>
                </Container>
              </>
            )}
          </ModalBody>
        </div>
      </Modal>
      {showCreatePost && (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={handleHideModal}
          id="create-post-modal"
          mode="create"
          type={type}
        />
      )}
      {showCreatePoll && (
        <CreatePoll
          showModal={showCreatePoll}
          hideModal={handleHideModal}
          id="create-post-modal"
          mode="create"
          type={type}
        />
      )}
      {showCreateProduct && (
        <CreateProductModal
          showModal={showCreateProduct}
          hideModal={handleHideModal}
          id="create-post-modal"
          mode="create"
        />
      )}
      {showCreateContest && (
        <CreateContest
          showModal={showCreateContest}
          hideModal={handleHideModal}
          id="create-post-modal"
          mode="create"
        />
      )}
      {showCreateGroup && (
        <CreateGroupModal
          showModal={showCreateGroup}
          hideModal={handleHideModal}
          mode="create"
        />
      )}
      {showCreateMoment && (
        <CreateMomentModal
          showModal={showCreateMoment}
          hideModal={handleHideModal}
          set={set}
          id="create-moment-modal"
          mode="create"
        />
      )}
      {showCreatePage && (
        <CreatePageModal
          showModal={showCreatePage}
          hideModal={handleHideModal}
          mode="create"
        />
      )}
      {showCreateEvent ? (
        <CreateEventModal
          showModal={showCreateEvent}
          hideModal={handleHideModal}
          setShowPublishModal={setShowPublishModal}
          mode="create"
        />
      ) : (
        ''
      )}
      {showCreateAuction ? (
        <CreateAuctionModal
          showModal={showCreateAuction}
          hideModal={setShowCreateAuction}
          mode="create"
        />
      ) : (
        ''
      )}
      {showDonation && (
        <DonationModal
          showModal={showDonation}
          hideModal={handleHideModal}
          edit={false}
          type={type}
        />
      )}
      {showPusblishModal ? (
        <PublishEventModal
          showModal={showPusblishModal}
          hideModal={handleHideModal}
        />
      ) : (
        ''
      )}
    </div>
  );
}

PostModal.defaultProps = {
  showModal: false,
};

PostModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
};
