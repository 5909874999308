import React from 'react';
import './backdrop.css';

const Backdrop = ({ children, onClose }) => {
  return (
    <div className="overlay" onClick={onClose}>
      <div className="content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Backdrop;
