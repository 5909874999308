import React, { useState, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { CommentCard } from './style';
import commentIcon from '../../../assets/image/message-icon.png';
import imgPlaceholder from '../../../assets/image/default-dp.svg';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { handleLikeComment } from '../../../utils/helper';
import CustomDropdown from '../Dropdown';
import { customBaseUrl, httpDelete } from '../../../action/http';

export default function Gram(props) {
  const {
    wayagramProfileId: profileId,
    reload,
    setReload,
  } = useContext(WayagramContext);
  const { data, handleComment, commentType } = props;
  const { username, avatar, displayName, id } = data?.Profile || data || {};
  const {
    likeCount,
    id: postId,
    isLiked,
    comment,
    updatedAt,
    commentCount,
  } = data;
  const [likes, setlikes] = useState(likeCount);
  const history = useHistory();
  const myRef = React.useRef(null);
  const options = [{ name: 'Delete Comment', type: 'delete', id: postId }];

  const handleDeletePost = async () => {
    const d = {
      comment_id: postId,
      profile_id: id,
    };
    const res = await httpDelete(
      `/all-posts/comment-delete-permanently`,
      d,
      customBaseUrl.wayagramUrl
    );
    if (res?.status) setReload(!reload);
  };

  const handleOption = async (type) => {
    if (type === 'delete') {
      handleDeletePost();
    }
  };

  return (
    <CommentCard ref={myRef} className="p-3">
      <div className="top-content d-flex">
        <div className="d-flex">
          <img
            src={avatar || imgPlaceholder}
            alt="gram avatar"
            className="me-3"
            style={{ height: '30px', width: '30px', borderRadius: ' 50%' }}
            onClick={() => history.push(`/wayagram-profile/${id}`)}
          />
          <div className="me-3 mb-2">
            <span
              className="mb-0 add-cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              aria-hidden="true"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              onClick={() => history.push(`/wayagram-profile/${id}`)}
            >
              {`${displayName || ''}`}
              {'  '}
              {commentType === 'child' && (
                <span className="text-muted">@{username}</span>
              )}
            </span>
            {commentType !== 'child' && (
              <p className="mb-0 text-muted">@{username}</p>
            )}
          </div>
        </div>
        {id === profileId && (
          <div className="option-div ml-auto my-auto">
            <div className="dot-3x" style={{ position: 'relative' }}>
              <CustomDropdown
                id={12}
                direction="horizontal"
                handleOption={handleOption}
                options={options}
              />
            </div>
          </div>
        )}
      </div>
      <div className={commentType === 'child' ? 'ms-5' : 'middle-content'}>
        <p>{comment}</p>
      </div>
      {commentType !== 'child' && (
        <span className="text-muted">{moment(updatedAt).fromNow()}</span>
      )}
      {commentType !== 'contest' ? (
        <div className="bottom-content">
          <div className="bottom-content-container mt-3">
            <div className="comment-div" hidden={commentType === 'child'}>
              <img
                src={commentIcon}
                alt="comment"
                style={{
                  width: '1em',
                  height: '1em',
                  cursor: 'pointer',
                }}
                onClick={() => handleComment()}
              />
              <span className="ms-2">{commentCount}</span>
            </div>
            <div className="comment-div" hidden={commentType !== 'child'}>
              <span className="text-muted">{moment(updatedAt).fromNow()}</span>
            </div>
            <div className="comment-div">
              <i
                className={
                  isLiked
                    ? 'mdi mdi-heart text-danger'
                    : 'mdi mdi-heart-outline text_text add-cursor'
                }
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={() => {
                  if (isLiked) setlikes(Number(likes) - 1);
                  if (!isLiked) setlikes(Number(likes) + 1);
                  handleLikeComment(
                    { postId, isLiked, profileId, type: 'user' },
                    () => setReload(!reload)
                  );
                }}
              />
              <span className="ms-2">{likes}</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </CommentCard>
  );
}

// function ChildComment({ comment, updatedAt }) {
//   const myRef = React.useRef(null);
//   return (
//     <CommentCard ref={myRef} className="p-3">
//       <div className="middle-content">
//         <p>{comment}</p>
//       </div>
//       <span className="text-muted">{moment(updatedAt).fromNow()}</span>
//     </CommentCard>
//   );
// }

Gram.propTypes = {
  // data: PropTypes.shape.isRequired,
  // handleLikePost: PropTypes.func.isRequired,
  handleComment: PropTypes.func.isRequired,
};
