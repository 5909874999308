import React from 'react';
import Backdrop from '../../../../../shared/backdrop/Backdrop';
import '../css/bidQuestion.css';

const BidQuestion = ({ onClose }) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Backdrop onClose={onClose}>
      <div onClick={onClose}>
        <div onClick={handleStopPropagation}>
          <section className="bid__question">
            <div className="level__one__bx__question">
              <div className="svg__confirm__icon" />
              <div className="bid__question__header">Ask a question</div>
              <div className="bid__question__placeholder">
                ask the vendor a question you have for them
              </div>
              <div>
                <input
                  className="bid__question__input"
                  placeholder="Ask your question"
                  type="text"
                />
              </div>
            </div>

            <div className="level__two__bx__question">
              <div className="level__two__bx__question__container">
                <button type="button" className="quote__discard__btn">
                  <div className="quote__discard__btn__text">Discard</div>
                </button>

                <button type="button" className="quote__send__btn">
                  <div className="quote__discard__btn__text">Send</div>
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Backdrop>
  );
};

export default BidQuestion;
