// import { useParams } from 'react-router';
import React, { useState, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert';
// import { NotificationManager } from 'react-notifications';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ProgressBar from './ProgressBar';
// import DonationPrivacy from './DonationPrivacy';
import DonationTarget from './DonationTarget';
import DonationInfo, { UserInfo } from './DonationInfo';
import PublishDonation from './PublishDonation';
import PageCount from './Pagecount';
import StepButton from './StepButton';
import { headers, donationButtonTitle } from './data';
import './index.scss';
import { WayagramContext } from '../../../../../store/context/WayagramContext';
// import { hideLoader, showLoader } from '../../../../../utils/loader';
// import { customBaseUrl, httpPost, httpPut } from '../../../../../action/http';
// import TermsAndCondition from '../../Terms/TermsAndCondition';
import { userTheme } from '../../../../../store/initialState';
import {
  createDonationHelper,
  updateDonationHelper,
} from '../../../../../utils/helper';

const DonationModal = (props) => {
  const theme = useRecoilValue(userTheme);
  const {
    wayagramProfile: profile,
    reload,
    setReload,
  } = useContext(WayagramContext);
  // const { id: pid } = useParams();
  const { showModal, hideModal, selectedData, edit } = props;
  const { donation } = selectedData || {};
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 4,
    estimatedAmount: '',
    displayTotalDonation: true,
    minimumAmount: '',
    donationImage: '',
    displayNumberOfDonors: true,
    donationExpiresAt: new Date(),
    ...donation,
  });
  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  // const addImagesToPosts = async (newFormOpt) => {
  //   await httpPut(
  //     '/all-posts/update-donation',
  //     newFormOpt,
  //     customBaseUrl.wayagramUrl
  //   );
  //   setReload(!reload);
  // };

  const submitDonation = async () => {
    setLoading(true);
    // showLoader();
    if (values?.donationImage.length <= 0 && !values?.imageUrl) {
      swal('error', 'Donation image can not be empty');
      // hideLoader();
      return;
    }

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    // const file = Object?.values(values?.donationImage);
    newForm.append('profile_id', profile.id);
    newForm.append('title', values.title);
    newForm.append('ownerDescription', values.ownerDescription);
    newForm.append('impact', values.impact);
    newForm.append('amountJustification', values.amountJustification);
    newForm.append('description', values.description);
    newForm.append(
      'displayTotalDonation',
      values.displayTotalDonation || false
    );
    newForm.append('estimatedAmount', values.estimatedAmount);
    newForm.append(
      'displayNumberOfDonors',
      values.displayNumberOfDonors || false
    );
    newForm.append('donationExpiresAt', values.donationExpiresAt);
    newForm.append('minimumAmount', values.minimumAmount);
    values.id && newForm.append('donation_id', values.id);
    values.isClosed && newForm.append('isClosed ', values.isClosed);
    // newForm.append('orgName', values.orgName);
    if (values?.donationImage?.length > 0) {
      values?.donationImage?.forEach((img) => {
        newForm.append('donationImage', img);
      });
    }
    let res;
    if (edit) {
      res = await updateDonationHelper(newForm);
    } else {
      res = await createDonationHelper(newForm);
    }
    if (res?.status) {
      // if (file.length > 0) {
      //   file?.forEach(async (img) => {
      //     const newFormOpt = new FormData();
      //     newFormOpt.append('donationImage', img);
      //     newFormOpt.append('profile_id', profile.id);
      //     newFormOpt.append('donation_id', res?.data?.id);
      //     newFormOpt.append('donationExpiresAt', values.donationExpiresAt);
      //     await addImagesToPosts(newFormOpt);
      //   });
      // }
      // NotificationManager.success(res.message, 'Success', 3000);
      swal('Success!', res?.message, 'success');
      // hideLoader();
      setLoading(false);
      hideModal(false);
      setReload(!reload);
    } else {
      // hideLoader();
      swal('Oops!', res?.message, 'error');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      id="donation-modal"
      toggle={() => hideModal(false)}
      centered
      size="lg"
    >
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <div className="interest-header normal-header" style={theme.modalStyle}>
          <h3 className="interest-p1" style={theme.modalStyle}>
            {headers[values.step].header}
          </h3>
          <div className="description-header mt-3">
            <p className="description-text" style={theme.modalStyle}>
              {headers[values.step].description}
            </p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={theme.modalStyle}>
        <PageSwitch
          loading={loading}
          step={values.step}
          totalSteps={values.totalSteps}
          handleSteps={handleSteps}
          stepBackward={stepBackward}
          submitDonation={submitDonation}
          handleFormInput={handleFormInput}
          values={values}
          edit={edit}
          theme={theme}
        />
      </ModalBody>
    </Modal>
  );
};

function PageSwitch(props) {
  const [errors, setErrors] = useState(null);
  const {
    step,
    totalSteps,
    handleSteps,
    submitDonation,
    handleFormInput,
    stepBackward,
    values,
    edit,
    theme,
    loading,
  } = props;
  // const [showTermsModal, setShowTermsModal] = useState(false);

  const onAction = [handleSteps, handleSteps, handleSteps, submitDonation];

  const handleSubmit = () => {
    if (
      !values.title ||
      // !values.orgName ||
      !values.description ||
      (step === 1 && !values.amountJustification) ||
      (step === 1 && !values.impact) ||
      (step === 1 && !values.ownerDescription) ||
      (step === 1 && !values.amountJustification) ||
      (step === 2 && (values.minimumAmount === '0' || !values.minimumAmount)) ||
      (step === 2 &&
        (values.estimatedAmount === '0' || !values.estimatedAmount)) ||
      (step === 2 && !values.donationExpiresAt)
    ) {
      setErrors('All input fields marked with an asterisk (*) are mandatory');
      return;
    }
    setErrors('');
    const nextAction = onAction[step];
    nextAction();
  };

  return (
    <div className="donation-container pb-5 mb-3">
      <ProgressBar totalSteps={totalSteps} step={step} />
      <div className="info-container">
        {step === 0 && (
          <DonationInfo
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        {step === 1 && (
          <UserInfo
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}
        {step === 2 && (
          <DonationTarget
            handleFormInput={handleFormInput}
            values={values}
            theme={theme}
          />
        )}

        {step === 3 && (
          <PublishDonation
            handleFormInput={handleFormInput}
            values={values}
            edit={edit}
            theme={theme}
          />
        )}
        <p className="text-primary">{errors}</p>
        <PageCount
          currentPage={step + 1}
          totalPage={totalSteps}
          stepBackward={stepBackward}
          color={theme?.modalStyle?.color}
        />
        {step < 3 && (
          <div className="termsnconditionText my-3">
            By sending, you agree with our{' '}
            <a href="/terms.html" rel="noreferrer" target="_blank">
              terms and conditions
            </a>{' '}
            of donation creation
          </div>
        )}
      </div>

      <StepButton
        disabled={loading}
        className="mb-3"
        title={donationButtonTitle[step]}
        onClick={handleSubmit}
      />
      {/* {showTermsModal && (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      )} */}
    </div>
  );
}

export default DonationModal;
