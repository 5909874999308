import React from 'react';
import Loader from 'react-loader-spinner';

export default function index() {
  return (
    <div className="row h-100 d-grid justify-content-center align-items-center app-spinner">
      <Loader type="TailSpin" color="#EF6C00" height={40} width={40} />
    </div>
  );
}
