import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../../../../../shared/Layout';
import QuoteConfirmation from '../modal/AuthConfirmationNotification';
import statesData from '../data/dataState';
import FormInput from './FormInput';
import { postData } from '../../apiStore/apiService';
import { customBaseUrl } from '../../../../../../action/http';
import 'react-toastify/dist/ReactToastify.css';
import './Quotes.css';

// import { getRequestNotifications } from '../../../container';

const GetQuote = (props) => {
  const history = useHistory();

  const userDetails = JSON.parse(localStorage.getItem('wayaUserData')) || {};
  if (!userDetails.user) {
    toast.error('User details not found. Please log in again.');
    history.push('/login');
    return null;
  }

  // const profileId = userDetails.profile.id;

  const { firstName, id } = userDetails.user;

  const initialFormData = {
    userID: `${id}`,
    categoryID: '',
    condition: '',
    itemName: '',
    brandName: '',
    description: '',
    quantity: '',
    price: '',
    state: '',
    lga: '',
    distance: '',
    maximumAmount: '',
    biddingEndTime: Date.now() + 2 * 24 * 60 * 60 * 1000, // Example: 2 days from now
    startingAmount: '',
    userName: `${firstName}`,
    deliveryMethod: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [localGovernments, setLocalGovernments] = useState([]);

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommas = (number) => {
    return number.toString().replace(/,/g, '');
  };

  const handleStateChange = (e) => {
    const selectedStateValue = e.target.value;
    setSelectedState(selectedStateValue);

    setFormData({ ...formData, state: selectedStateValue, lga: '' });

    const stateData = statesData.find(
      (state) => state.name === selectedStateValue
    );
    setLocalGovernments(stateData ? stateData.lgas : []);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const valueWithoutCommas = removeCommas(value);
    setFormData({ ...formData, [name]: valueWithoutCommas });
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;

    const valueWithoutCommas = removeCommas(value);

    // Update the formData with the value without commas
    setFormData({ ...formData, [name]: valueWithoutCommas });
    e.target.value = formatNumberWithCommas(valueWithoutCommas);
  };

  const closeConfirmationModal = () => {
    setIsModalVisible(false);
    history.replace('/bid');
  };

  const fetchCorporateAccounts = async () => {
    const re2s = await axios.get(
      `${customBaseUrl.roleUrl}/user/get-corporate-accounts`
    );
    console.log(re2s.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await postData(formData);
      if (response.statusCode === 201) {
        setIsModalVisible(true);

        setTimeout(() => setIsModalVisible(false), 5000);
        setFormData(initialFormData);
        setSelectedState('');
        setLocalGovernments([]);
      } else {
        toast.error('Failed to submit the form. Please try again.');
      }
    } catch (error) {
      await fetchCorporateAccounts();

      // const data = await axios.get(
      //   'https://services.staging.wayagram.ng/wayagram-service/accounts/user/get-corporate-accounts'
      // );
      // console.log(data);
      // const r = await getRequestNotifications('bidding', profileId, 'id', 1);
      // console.log(r);

      toast.error('Error posting data');
    }
  };

  const handleClick = () => {
    history.push('/bid');
  };

  return (
    <Layout route={props} currentPage="bid" pageName="Get quote" showName>
      <div className="quote__container">
        <ToastContainer />
        <div className="back__btn">
          <div className="back__btn-text" onClick={handleClick}>
            <u style={{ borderBottom: '5px' }}>Go Back</u>
          </div>
        </div>

        <div className="quote__description">
          <div className="quote__header">Get a Quote</div>
          <div>
            Allows you to request pricing and availability information for a
            specific item or service. Its your first step towards receiving
            personalized offers tailored to your needs.
          </div>
        </div>

        <div className="quote__raisedQuoteForm">
          <form onSubmit={handleSubmit} className="quote__bid-form">
            <div className="quote__form-group">
              <label htmlFor="categoryId">Category</label>
              <select
                id="categoryId"
                name="categoryID"
                onChange={handleInputChange}
              >
                <option value="">Select a Category</option>
                <option value="1">Skin Care</option>
                <option value="2">Clothing, Shoes & Jewelry</option>
                <option value="3">Home & Kitchen</option>
                <option value="4">Toys & Games</option>
                <option value="5">Pharmaceuticals</option>
                <option value="6">Beauty and Cosmetics</option>
              </select>
            </div>

            <FormInput
              label="Condition"
              type="select"
              id="condition"
              name="condition"
              onChange={handleInputChange}
              value={formData.condition}
              options={['Select a condition for the bid item', 'New', 'Used']}
            />

            <FormInput
              label="Item name"
              type="text"
              id="item-name"
              name="itemName"
              onChange={handleInputChange}
              value={formData.itemName}
              placeholder="Enter the specific item name"
            />

            <FormInput
              label="Brand name (Optional)"
              type="text"
              id="brand-name"
              name="brandName"
              onChange={handleInputChange}
              value={formData.brandName}
              placeholder="Enter the brand name"
            />

            <FormInput
              label="Description"
              type="textarea"
              id="description"
              name="description"
              onChange={handleInputChange}
              value={formData.description}
              placeholder="Give a detailed description of the item like size, type, color"
            />

            <FormInput
              label="Delivery Method"
              type="select"
              id="deliveryMethod"
              name="deliveryMethod"
              onChange={handleInputChange}
              value={formData.deliveryMethod}
              options={[
                'Select a method for your delivery',
                'Delivery',
                'Pickup',
              ]}
            />

            <FormInput
              label="Quantity"
              type="number"
              id="quantity"
              min={0}
              name="quantity"
              onChange={handleInputChange}
              value={formData.quantity.toString()}
              placeholder="Enter the quantity of the item"
            />

            <FormInput
              label="Bid Starting Amount"
              type="text"
              id="startingAmount"
              name="startingAmount"
              onChange={handleAmountChange}
              value={formatNumberWithCommas(formData.startingAmount)}
              placeholder="Expected bid starting amount"
            />

            <FormInput
              label="Bid Maximal Amount"
              type="text"
              id="maximumAmount"
              name="maximumAmount"
              onChange={handleAmountChange}
              value={formatNumberWithCommas(formData.maximumAmount)}
              placeholder="Enter bid maximal amount"
            />

            <div className="quote__form-group">
              <label htmlFor="state">State</label>
              <select
                id="state"
                name="state"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select a state</option>
                {statesData.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="quote__form-group">
              <label htmlFor="local-government">Local Government Area</label>
              <select
                id="local-government"
                name="lga"
                disabled={!selectedState}
                onChange={handleInputChange}
                value={formData.lga}
              >
                <option value="">Select a local government area</option>
                {localGovernments.map((lga) => (
                  <option key={lga} value={lga}>
                    {lga}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              className="quote__submit-button"
              style={{ position: 'sticky', bottom: '10px' }}
            >
              Submit
            </button>
          </form>

          {isModalVisible && (
            <QuoteConfirmation closeModal={closeConfirmationModal} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default GetQuote;
