import AccessLogo from '../assets/image/bank/access.png';
import ZenithLogo from '../assets/image/bank/zenith.png';
import GtbLogo from '../assets/image/bank/gtb.png';
import FbLogo from '../assets/image/bank/first-bank.png';
import WemaLogo from '../assets/image/bank/wema.png';
import UnityLogo from '../assets/image/bank/unity.png';
import UbaLogo from '../assets/image/bank/uba.png';
import SterlingLogo from '../assets/image/bank/sterling.png';
import FidelityLogo from '../assets/image/bank/fidelity.png';
import FcmbLogo from '../assets/image/bank/fcmb.png';

const BankLogo = [
  { name: 'Abbey Mortgage Bank', code: '801', imgPath: '' },
  { name: 'Access Bank', code: '044', imgPath: '' },
  { name: 'Access Bank (Diamond)', code: '063', imgPath: AccessLogo },
  { name: 'ALAT by WEMA', code: '035A', imgPath: '' },
  { name: 'Amju Unique MFB', code: '50926', imgPath: '' },
  { name: 'ASO Savings and Loans', code: '401', imgPath: '' },
  { name: 'Bainescredit MFB', code: '51229', imgPath: '' },
  { name: 'Bowen Microfinance Bank', code: '50931', imgPath: '' },
  { name: 'CEMCS Microfinance Bank', code: '50823', imgPath: '' },
  { name: 'Citibank Nigeria', code: '023', imgPath: '' },
  { name: 'Coronation Merchant Bank', code: '559', imgPath: '' },
  { name: 'Ecobank Nigeria', code: '050', imgPath: '' },
  { name: 'Ekondo Microfinance Bank', code: '562', imgPath: '' },
  { name: 'Eyowo', code: '50126', imgPath: '' },
  { name: 'Fidelity Bank', code: '070', imgPath: FidelityLogo },
  { name: 'Firmus MFB', code: '51314', imgPath: '' },
  { name: 'First Bank of Nigeria', code: '011', imgPath: FbLogo },
  { name: 'First City Monument Bank', code: '214', imgPath: FcmbLogo },
  { name: 'FSDH Merchant Bank Limited', code: '501', imgPath: '' },
  { name: 'Globus Bank', code: '00103', imgPath: '' },
  { name: 'Guaranty Trust Bank', code: '058', imgPath: GtbLogo },
  { name: 'Hackman Microfinance Bank', code: '51251', imgPath: '' },
  { name: 'Hasal Microfinance Bank', code: '50383', imgPath: '' },
  { name: 'Heritage Bank', code: '030', imgPath: '' },
  { name: 'Ibile Microfinance Bank', code: '51244', imgPath: '' },
  { name: 'Infinity MFB', code: '50457', imgPath: '' },
  { name: 'Jaiz Bank', code: '301', imgPath: '' },
  { name: 'Keystone Bank', code: '082', imgPath: '' },
  { name: 'Kuda Bank', code: '50211', imgPath: '' },
  {
    name: 'Lagos Building Investment Company Plc.',
    code: '90052',
    imgPath: '',
  },
  { name: 'Links MFB', code: '50549', imgPath: '' },
  { name: 'Mayfair MFB', code: '50563', imgPath: '' },
  { name: 'Mint MFB', code: '50304', imgPath: '' },
  { name: 'One Finance', code: '565', imgPath: '' },
  { name: 'PalmPay', code: '999991', imgPath: '' },
  { name: 'Parallex Bank', code: '526', imgPath: '' },
  { name: 'Parkway - ReadyCash', code: '311', imgPath: '' },
  { name: 'Paycom', code: '999992', imgPath: '' },
  { name: 'Petra Mircofinance Bank Plc', code: '50746', imgPath: '' },
  { name: 'Polaris Bank', code: '076', imgPath: '' },
  { name: 'Providus Bank', code: '101', imgPath: '' },
  { name: 'Rand Merchant Bank', code: '502', imgPath: '' },
  { name: 'Rubies MFB', code: '125', imgPath: '' },
  { name: 'Sparkle Microfinance Bank', code: '51310', imgPath: '' },
  { name: 'Stanbic IBTC Bank', code: '221', imgPath: '' },
  { name: 'Standard Chartered Bank', code: '068', imgPath: '' },
  { name: 'Sterling Bank', code: '232', imgPath: SterlingLogo },
  { name: 'Suntrust Bank', code: '100', imgPath: '' },
  { name: 'TAJ Bank', code: '302', imgPath: '' },
  { name: 'TCF MFB', code: '51211', imgPath: '' },
  { name: 'Titan Bank', code: '102', imgPath: '' },
  { name: 'Union Bank of Nigeria', code: '032', imgPath: '' },
  { name: 'United Bank For Africa', code: '033', imgPath: UbaLogo },
  { name: 'Unity Bank', code: '215', imgPath: UnityLogo },
  { name: 'VFD Microfinance Bank Limited', code: '566', imgPath: '' },
  { name: 'Wema Bank', code: '035', imgPath: WemaLogo },
  { name: 'Zenith Bank', code: '057', imgPath: ZenithLogo },
];

export default BankLogo;
