/* eslint-disable no-param-reassign */
import React, { createContext, useState, useEffect, useRef } from 'react';
import { decodeToken } from 'react-jwt';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { notification } from 'antd';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { messaging, publicKey } from '../../firebase';
import sound from '../../assets/audio/call.wav';

export const LayoutContext = createContext();

export const LayoutContextProvider = (props) => {
  const history = useHistory();
  const { children } = props;
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callData, setCallData] = useState({});
  const [showPostModal, setShowPostModal] = useState(false);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [toggleMobileSidebar, setToggleMobileSidebar] = useState(false);
  const [unAddedInterest, setUnAddedInterest] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  async function firebaseThings() {
    try {
      const tokenInLocalForage = await localStorage.getItem('deviceToken');

      if (tokenInLocalForage !== null) {
        return tokenInLocalForage;
      }
      const status = await Notification.requestPermission();
      if (status && status === 'granted') {
        const msg = messaging();
        const token = await getToken(msg, {
          vapidKey: publicKey,
        });
        if (token) {
          localStorage.setItem('deviceToken', token);
          return token;
        }
        return null;
      }
      return null;
    } catch (err) {
      // console.info(err.message);
      notification.error({
        message: 'failed',
        description: err.message,
      });
      return null;
    }
  }

  const tokenRef = useRef(null);
  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((permissionStatus) => {
          // console.info(
          //   `[NOTIFICATIONS] permission state is ${permissionStatus.state}`
          // );

          permissionStatus.onchange = async () => {
            await localStorage.clear();
            console.info(
              `[NOTIFICATIONS] permission state has changed to ${permissionStatus.state}`
            );
          };
        });
    }
    if (!tokenRef.current) {
      tokenRef.current = firebaseThings();
    }
    try {
      const messagingn = getMessaging();

      onMessage(messagingn, (payload) => {
        if (payload?.data?.type) {
          new Audio(sound).play();
          setCallData(payload.data);
          if (payload?.data?.type === 'VOICE_CALL') {
            notification.success({
              message: payload?.data?.message,
              description: payload?.data?.displayName,
            });
          } else {
            notification.success({
              message: payload?.data?.message,
              description: payload?.data?.displayName,
              duration: 0,
            });
          }
        } else {
          notification.success({
            message: payload?.notification?.body || 'notification',
            description: payload?.notification?.body,
            duration: 5,
          });
        }
      });
    } catch (err) {
      notification.error({
        message: 'failed',
        description: err.message,
      });
    }
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    //  const shared = new URLSearchParams(location.search).get('from');
    const shared = window.location.href.includes('from');
    // console.log(new URLSearchParams());
    // console.log('shared', shared);
    //  localStorage.setItem('deviceToken', token);
    const decodedToken = decodeToken(token);
    const expDate = new Date(decodedToken?.exp * 1000);
    const newDate = new Date();
    // console.log('expire', expDate);
    if (newDate > expDate && !shared) {
      localStorage.clear();
      history.push('/');
    }
    // console.log({ decodeToken, history });
  });
  return (
    <LayoutContext.Provider
      value={{
        setReload,
        reload,
        loading,
        setLoading,
        toggleMobileSidebar,
        setToggleMobileSidebar,
        showPostModal,
        setShowPostModal,
        setShowInterestModal,
        showInterestModal,
        unAddedInterest,
        setUnAddedInterest,
        setCallData,
        callData,
        setProgress,
        progress,
        setShowProgress,
        showProgress,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
