import styled from 'styled-components';
import device from '../../../utils/device';

export const TableWrapper = styled.div`
  .ant-table {
    background-color: ${(props) => props?.style?.background} !important;
    color: ${(props) => props?.style?.color} !important;
  }
  .ant-table-cell-row-hover {
    background-color: ${(props) => props?.style?.background} !important;
    color: '#000000 !important;
  }
`;

export const MomentWrapper = styled.div`
  border: none;
  margin-bottom: 40px;
  .moment-header {
    margin: 18px 0;
    width: 100%;
    font-weight: bold;
  }
  .carousel-root {
    padding: 0 !important;
  }
`;

export const PostWrapper = styled.div`
  background-color: ${(props) => props?.style?.background} !important;
  color: ${(props) => props?.style?.color} !important;
  width: 100%;
  height: 120px;
  // background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  .line {
    width: 90%;
    height: 0px;
    left: 452px;
    top: 102px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  .post-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    // color: #484848;
    margin-right: 10px;
    margin-left: 5px;
  }
  .post-container {
    ::placeholder,
    ::-webkit-input-placeholder {
      text-align: left;
      color: #828282;
    }
    .search-input {
      border-radius: 1.25rem !important;
      background-color: #f2f2f2 !important;
      padding-left: 10px !important;
    }
  }
`;

export const moment = styled.div``;

export const UploadPostButton = styled.div`
  // position: fixed;
  // bottom: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: ${(props) => props.theme.orange};
  }
  background: #ff6634;
  right: 25px;
  color: white;
  // @media (max-width: 768px) {
  //   bottom: 75px;
  // }
`;

export const GroupAndPageButton = styled.div`
  position: fixed;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: ${(props) => props.theme.orange};
  }
  background: #ff6634;
  right: 350px;
  color: white;
  @media (max-width: 768px) {
    bottom: 75px;
  }
`;

const RightSidebarWrapper = styled.div`
  @media ${device.maxtablet} {
    display: none;
  }
`;

export const TransferWrapper = styled.div`
  padding: 15px 15px;
  background: #e5e5e5;
`;

export const TransferBody = styled.div`
  width: 80%;
  .dial-card {
    border: 1px solid #f2f2f2;
  }
  .section-a {
    height: 100vh;
  }
  @media ${device.maxmobileL} {
    width: 100% !important;
  }
`;

export const FundButton = styled.div`
  position: absolute;
  right: 30px;
  color: #fff;
  border: 1px solid #4f4f4f;
  top: 14px;
  padding: 3px;
  cursor: pointer;
  z-index: 2;
  @media (max-width: 376px) {
    right: 19px !important;
  }
`;

export const CardDetails = styled.div`
  position: absolute;
  left: 30px;
  top: 14px;
  padding: 3px;
  img {
    width: auto !important;
  }
  @media (max-width: 376px) {
    left: 18px !important;
  }
`;

export const LeftArrow = styled.div`
  position: absolute;
  left: -21px;
  top: 53px;
`;

export const RightArrow = styled.div`
  position: absolute;
  right: -21px;
  top: 53px;
`;

export const IntersectLine = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  img {
    height: 156px;
    width: auto !important;
  }
`;

export default RightSidebarWrapper;

export const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 27px)',
  width: 50,
  height: 50,
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
};

export const rightArrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 29px)',
  width: 50,
  height: 50,
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
};
