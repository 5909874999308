import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import Button from '../../Button';
import Error from '../../Error';

export default function ResendOtp(props) {
  const {
    showModal,
    hideModal,
    title,
    description,
    buttonLabel,
    handleSubmit,
    setIsPhone,
    loading,
  } = props;
  const [data, setData] = useState({});
  const [pWarning, setPwarnin] = useState('');

  const validateInput = (val) => {
    setPwarnin('');
    const phoneValidation =
      localStorage?.getItem('TYPE') === 'PERSONAL'
        ? /^([\s()-]*\d[\s()-]*){11}$/
        : /^([\s()-]*\d[\s()-]*){12}$/;
    const mailValidation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (val.match(phoneValidation)) {
      // correct phone structure
      setPwarnin('');
      setIsPhone(true);
      return true;
    }
    if (val.match(mailValidation)) {
      // correct mail format
      setPwarnin('');
      setIsPhone(false);
      return true;
    }
    if (val?.length > 0)
      setPwarnin(
        localStorage?.getItem('TYPE') === 'PERSONAL'
          ? 'Please enter a valid email address in the following format: yourname@example.com or valid phone number'
          : 'Please enter a valid business Id'
      );
    // incorrect structure
    return false;
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      id="pin-modal"
    >
      <div className="modal-body-rs">
        <div className="header-sec-modal">
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/back.png"
            alt=""
          />
          <img className="header-sec-logo" src="./assets/appLogo.png" alt="" />
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/x.png"
            alt=""
          />
        </div>

        <div className="waya-modal-body">
          <h1 className="modal-header-data">{title}</h1>

          <p className="modal-header-sub-data text-secondary">{description}</p>
          <div
            className="inputbox-with-one-input"
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            <input
              placeholder={
                localStorage?.getItem('TYPE') === 'PERSONAL'
                  ? 'Email or Phone'
                  : 'BusinessId'
              }
              type="text"
              onPointerOut={(e) => validateInput(e.target.value)}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <p className="px-20">
              <Error error={pWarning} />
            </p>
          </div>
          <div className="w-100 mt-5 text-center">
            <Button
              type="button"
              className="btn btn-primary btn-lg w-50"
              loading={loading}
              onClick={() => {
                if (!validateInput(data?.email)) return;
                handleSubmit(data.email);
              }}
              content={buttonLabel}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

ResendOtp.defaultProps = {
  title: '',
  description: '',
  buttonLabel: 'Send',
};

ResendOtp.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
