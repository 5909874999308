import React from 'react';
import { useHistory } from 'react-router';
import { HomeIcon, CompassIcon, ChatIcon, BellIcon, ShopIcon } from '../Icons';
import { MobileSideBarWrapper } from './style';

export default function MobileSidebar(props) {
  const { currentPage } = props;
  const history = useHistory();
  return (
    <MobileSideBarWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4F4F',
        }}
      >
        <div className="">
          <div onClick={() => history.push('/wayagram')}>
            <HomeIcon active={currentPage === 'wayagram' && true} />
          </div>
          <div className="mobilenav-text">Wayagram</div>
        </div>
        <div className="">
          <div onClick={() => history.push('/wayagram-discover')}>
            <CompassIcon active={currentPage === 'discover' && true} />
          </div>
          <div className="mobilenav-text">Discover</div>
        </div>
        <div className="">
          <div onClick={() => history.push('/shops')}>
            <ShopIcon active={currentPage === 'shops' && true} />
          </div>
          <div className="mobilenav-text">Payment</div>
        </div>
        <div className="">
          <div onClick={() => history.push('/notifications')}>
            <BellIcon active={currentPage === 'notifications' && true} />
          </div>
          <div className="mobilenav-text">Notifications</div>
        </div>
        <div className="">
          <div onClick={() => history.push('/chat')}>
            <ChatIcon active={currentPage === 'chat' && true} />
          </div>
          <div className="mobilenav-text">Chat</div>
        </div>
      </div>
    </MobileSideBarWrapper>
  );
}
