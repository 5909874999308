// import axios from 'axios';
import React, { useRef, useState } from 'react';
import { toast, Slide } from 'react-toastify';
import { Modal, Spinner } from 'reactstrap';
import Wrapper from './styles';

export default function VerifyOtp(props) {
  const {
    showModal,
    title,
    description,
    buttonLabel,
    handleSubmit,
    resendOtp,
  } = props;
  const [state, setState] = useState({
    submit: false,
    verified: false,
  });
  const [otp, setOtp] = useState({});
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const { submit } = state;

  const SubmitFn = () => {
    const otpVal = Object.values(otp).join('');
    if (otpVal.length > 5) {
      handleSubmit({ state, setState, otp: otpVal });
    } else {
      setOtp('');
      toast.error(`Please, check fill any missing field`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
    ref1.current.value = '';
    ref2.current.value = '';
    ref3.current.value = '';
    ref4.current.value = '';
    ref5.current.value = '';
    ref6.current.value = '';
  };
  // const onResend = () => {
  //   axios({
  //     method: 'get',
  //     url: `/${props.phoneOrEmail}`,
  //   })
  //     .then(() => {
  //       toast.success(
  //         `OTP Sent, kindly check the email or phone number provided`,
  //         { transition: Slide, hideProgressBar: true, autoClose: 3000 }
  //       );
  //     })
  //     .catch((err) => {
  //       toast.error(`${err.response.data.message}`, {
  //         transition: Slide,
  //         hideProgressBar: true,
  //         autoClose: 3000,
  //       });
  //     });
  // };
  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setOtp((s) => ({
      ...s,
      [name]: value.length > 1 ? value.slice(0, 1) : value,
    }));
  };
  return (
    <Modal isOpen={showModal} centered>
      <Wrapper>
        <h1 className="modal-header-data">{title}</h1>
        <p className="modal-header-sub-data text-secondary sub-header">
          {description}
        </p>
        <div className=" full-width">
          <form>
            <div className="inputsHolder">
              <input
                className="otp-input"
                type="password"
                ref={ref1}
                value={otp[0]}
                name="0"
                maxLength={1}
                pattern="[0-9]"
                max={1}
                onKeyUp={() => ref2.current.focus()}
                onChange={handleOtpChange}
              />
              <input
                type="password"
                className="otp-input"
                ref={ref2}
                maxLength={1}
                pattern="[0-9]"
                name="1"
                value={otp[1]}
                onChange={handleOtpChange}
                max={1}
                onKeyUp={() => ref3.current.focus()}
              />
              <input
                type="password"
                className="otp-input"
                ref={ref3}
                pattern="[0-9]"
                maxLength={1}
                max={1}
                name="2"
                value={otp[2]}
                onChange={handleOtpChange}
                onKeyUp={() => ref4.current.focus()}
              />
              <input
                type="password"
                className="otp-input"
                ref={ref4}
                pattern="[0-9]"
                maxLength={1}
                max={1}
                name="3"
                value={otp[3]}
                onChange={handleOtpChange}
                onKeyUp={() => ref5.current.focus()}
              />
              <input
                type="password"
                className="otp-input"
                maxLength={1}
                max={1}
                pattern="[0-9]"
                ref={ref5}
                name="4"
                value={otp[4]}
                onKeyUp={() => ref6.current.focus()}
                onChange={handleOtpChange}
              />
              <input
                type="password"
                className="otp-input"
                ref={ref6}
                maxLength={1}
                max={1}
                pattern="[0-9]"
                name="5"
                value={otp[5]}
                onKeyUp={SubmitFn}
                onChange={handleOtpChange}
              />
            </div>
            <div className="sum-bot">
              <button className="submitBtn" type="button" onClick={SubmitFn}>
                {submit ? <Spinner animation="border" /> : buttonLabel}
              </button>
            </div>
            <div className="text-center mt-20">
              <p className="mt-20 fs-16 fw-400">
                Did not get OTP?{' '}
                <span
                  className="fw-700 text-orange cursor-pointer"
                  onClick={resendOtp}
                >
                  Resend
                </span>
              </p>
            </div>
          </form>
        </div>
      </Wrapper>
    </Modal>
  );
}
