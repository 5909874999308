import React from 'react';
import { useRecoilValue } from 'recoil';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { userTheme } from '../../../../store/initialState';
import Button from '../../Button';

function ShopTermsModal({
  hideModal,
  showModal,
  handleSubmit,
  // productPercentage,
}) {
  const dataInfo = [
    '1.4.1. Confirm that you have obtained the necessary authority to agree to these general terms and conditions;',
    '1.4.2. bind both yourself and the person company or other legal entity that operates that business or organizational project to these general terms and conditions; and',
    '1.4.3. Agree that you in these general terms and conditions shall reference both the individual user and the relevant person company or legal entity unless the context requires otherwise.',
    'Protected against any unauthorized or illegal access by internal or external parties',
  ];

  const notDataInfo = [
    '2.1. You may not register with our marketplace if you are under 18 years of age (by using our marketplace or agreeing to these general terms and conditions you warrant and represent to us that you are at least 18 years of age).To Empower the individual: WayaQuick wants you to be in charge of your personal information and to make your own voluntary choices about your Personal Information;',
    '2.2. If you register for an account with our marketplace you will be asked to provide an email address/user ID and password and you agree to:',
    '2.2.1. keep your password confidential;',
    '2.2.2. Notify us in writing immediately (using our contact details provided at section 26) if you become aware of any disclosure of your password; and',
    '2.2.3. be responsible for any activity on our marketplace arising out of any failure to keep your password confidential and that you may be held liable for any losses arising out of such a failure.',
    '2.2.4. Your account shall be used exclusively by you and you shall not transfer your account to any third party. If you authorize any third party to manage your account on your behalf this shall be at your own risk.',
    '2.2.5. We may suspend or cancel your account and/or edit your account details at any time in our sole discretion and without notice or explanation providing that if we cancel any products or services you have paid for but not received and you have not breached these general terms and conditions we will refund you in respect of the same.',
    'You may cancel your account on our marketplace by contacting us',
  ];

  const obligations = [
    '3.1.1. the marketplace provides an online location for sellers to sell and buyers to purchase products;',
    '3.1.2. we shall accept binding sales on behalf of sellers but (unless Wayagram is indicated as the seller) Wayagram is not a party to the transaction between the seller and the buyer; and',
    '3.1.3. a contract for the sale and purchase of a product or products will come into force between the buyer and seller and accordingly you commit to buying or selling the relevant product or products upon the buyer’s confirmation of purchase via the marketplace.',
    '3.2. Subject to these general terms and conditions the seller’s terms of business shall govern the contract for sale and purchase between the buyer and the seller. Notwithstanding this the following provisions will be incorporated into the contract of sale and purchase between the buyer and the seller:',
    '3.2.1. the price for a product will be as stated in the relevant product listing;',
    '3.2.2. the price for the product must include all taxes and comply with applicable laws in force from time to time;',
    '3.2.3. delivery charges packaging charges handling charges administrative charges insurance costs other ancillary costs and charges where applicable will only be payable by the buyer if this is expressly and clearly stated in the product listing; and delivery of digital products may be made electronically;',
    '3.2.4. products must be of satisfactory quality fit and safe for any purpose specified in and conform in all material respects to the product listing and any other description of the products supplied or made available by the seller to the buyer; and',
    '3.2.5. in respect of physical products sold the seller warrants that the seller has good title to and is the sole legal and beneficial owner of the products and/or has the right to supply the products pursuant to this agreement and that the products are not subject to any third party rights or restrictions including in respect of third party intellectual property rights and/or any criminal insolvency or tax investigation or proceedings; and in respect of digital products the seller warrants that the seller has the right to supply the digital products to the buyer.',
    '3.2.6. The Seller agrees to login to wayagram and initiate product delivery after confirming buyer’s payment.',
    '3.2.7 The buyer agrees to login to wayagram and acknowledged that product was delivered.',
    '3.2.8 The buyer agrees that Wayagram will hold the payment until buyer login to the app and acknowledge that product is received.',
  ];

  const commitment = [
    '4.2.1. in respect of the product price;',
    '4.2.2. local and/or international shipping fees (as stated on the refunds page); and',
    'by way of store credits vouchers mobile money transfer bank transfers or such other methods as we may determine from time to time.',
    '4.3. Returned products shall be accepted and refunds issued by Wayagram acting for and on behalf of the seller. Notwithstanding paragraphs',
    '4.1 and 4.2 above in respect of digital products or services and fresh food Wayagram shall issue refunds in respect of failures in delivery only. Refunds of payment for such products for any other reasons shall be subject to the seller’s terms and conditions of sale.',
    '4.4. Changes to our returns page or refunds page shall be effective in respect of all purchases made from the date of publication of the change on our website.',
  ];
  const information = [
    '8.1.1. all works and materials (including without limitation text graphics images audio material video material audio-visual material scripts software and files) that you submit to us or our marketplace for storage or publication processing by or onward transmission; and',
    '8.1.2. all communications on the marketplace including product reviews feedback and comments.',
    '8.2. Your content and the use of your content by us in accordance with these general terms and conditions must be accurate complete and truthful. 8.3. Your content must be appropriate civil and tasteful and accord with generally accepted standards of etiquette and behaviour on the internet and must not:',
    '8.3.1. be offensive obscene indecent pornographic lewd suggestive or sexually explicit;',
    '8.3.2. depict violence in an explicit graphic or gratuitous manner; or',
    '8.3.3. be blasphemous in breach of racial or religious hatred or discrimination legislation;',
    '8.3.4. be deceptive fraudulent threatening abusive harassing anti-social menacing hateful discriminatory or inflammatory;',
    '8.3.5. cause annoyance inconvenience or needless anxiety to any person; or',
    '8.3.6. constitute spam.',
    '8.5. You must not use our marketplace to link to any website or web page consisting of or containing material that would were it posted on our marketplace breach the provisions of these general terms and conditions. 8.6. You must not submit to our marketplace any material that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint. 8.7. The review function on the marketplace may be used to facilitate buyer reviews on products. You shall not use the review function or any other form of communication to provide inaccurate inauthentic or fake reviews. 8.8. You must not interfere with a transaction by',
    '8.8.1. contacting another user to buy or sell an item listed on the marketplace outside of the marketplace; or',
    '8.8.2. communicating with a user involved in an active or completed transaction to warn them away from a particular buyer seller or item; or',
    '8.8.3. contacting another user with the intent to collect any payments.',
    '8.9. You acknowledge that all users of the marketplace are solely responsible for interactions with other users and you shall exercise caution and good judgment in your communication with users. You shall not send them personal information including credit card details.',
    '8.10. We may periodically review your content and we reserve the right to remove any content at our discretion for any reason whatsoever.',
    '8.11. If you learn of any unlawful material or activity on our marketplace or any material or activity that breaches these general terms and conditions you may inform us by contacting us as provided at section',
  ];
  const personnel = [
    '9.1 - You grant to us a worldwide irrevocable non-exclusive royalty-free license to use reproduce store adapt publish translate and distribute your content on our marketplace and across our marketing channels and any existing or future media.',
    '9.2 - You grant to us the right to sub-license the rights licensed under section 9.1',
    '9.3 - You grant to us the right to bring an action for infringement of the rights licensed under section 9.1',
    '9.4 - You hereby waive all your moral rights in your content to the maximum extent permitted by applicable law; and you warrant and represent that all other moral rights in your content have been waived to the maximum extent permitted by applicable law.',
    '9.5 - Without prejudice to our other rights under these general terms and conditions if you breach our rules on content in any way or if we reasonably suspect that you have breached our rules on content we may delete unpublish or edit any or all of your content.',
  ];
  const site = [
    '10.2. You may:',
    '10.2.1. view pages from our website in a web browser;',
    '10.2.2. download pages from our website for caching in a web browser;',
    '10.2.3. print pages from our website for your own personal and non-commercial use providing that such printing is not systematic or excessive;',
    '10.2.4. stream audio and video files from our website using the media player on our website; and',
    '10.2.5. use our marketplace services by means of a web browser subject to the other provisions of these general terms and conditions.',
    '10.3. Except as expressly permitted by section 10.2 or the other provisions of these general terms and conditions you must not download any material from our website or save any such material to your computer. 10.4. You may only use our website for your own personal and business purposes in respect of selling or purchasing products on the marketplace. 10.5. Except as expressly permitted by these general terms and conditions you must not edit or otherwise modify any material on our website. 10.6. Unless you own or control the relevant rights in the material you must not:',
    '10.6.1 republish material from our website (including republication on another website);',
    '10.6.2. sell rent or sub-license material from our website;',
    '10.6.3. show any material from our website in public;',
    '10.6.4. exploit material from our website for a commercial purpose; or',
    '10.6.5. redistribute material from our website.',
    '10.7. Notwithstanding section 10.6 you may forward links to products on our website and redistribute our newsletter and promotional materials in print and electronic form to any person.',
    '10.8. We reserve the right to suspend or restrict access to our website to areas of our website and/or to functionality upon our website. We may for example suspend access to the website during server maintenance or when we update the website. You must not circumvent or bypass or attempt to circumvent or bypass any access restriction measures on the website.',
    '10.9. You must not:',
    '10.9.1. use our website in any way or take any action that causes or may cause damage to the website or impairment of the performance availability accessibility integrity or security of the website;',
    '10.9.2. use our website in any way that is unethical unlawful illegal fraudulent or harmful or in connection with any unlawful illegal fraudulent or harmful purpose or activity;',
    '10.9.3. hack or otherwise tamper with our website;',
    '10.9.4. probe scan or test the vulnerability of our website without our permission;',
    '10.9.5. circumvent any authentication or security systems or processes on or relating to our website;',
    '10.9.6. use our website to copy store host transmit send use publish or distribute any material which consists of (or is linked to) any spyware computer virus Trojan horse worm keystroke logger rootkit or other malicious computer software;',
    '10.9.7. impose an unreasonably large load on our website resources (including bandwidth storage capacity and processing capacity);',
    '10.9.8. decrypt or decipher any communications sent by or to our website without our permission;',
    '10.9.9. conduct any systematic or automated data collection activities (including without limitation scraping data mining data extraction and data harvesting) on or in relation to our website without our express written consent;',
    '10.9.10. access or otherwise interact with our website using any robot spider or other automated means except for the purpose of search engine indexing;',
    '10.9.11. use our website except by means of our public interfaces;',
    '10.9.12. violate the directives set out in the robots.txt file for our website;',
    '10.9.13. use data collected from our website for any direct marketing activity (including without limitation email marketing SMS marketing telemarketing and direct mailing); or',
    '10.9.14. do anything that interferes with the normal use of our website.',
  ];
  const copy = [
    '11.1.1. we together with our licensors own and control all the copyright and other intellectual property rights in our website and the material on our website; and',
    '11.1.2. all the copyright and other intellectual property rights in our website and the material on our website are reserved.',
    '11.2. Wayagram’s logos and our other registered and unregistered trademarks are trademarks belonging to us; we give no permission for the use of these trademarks and such use may constitute an infringement of our rights.',
    '11.3. The third party registered and unregistered trademarks or service marks on our website are the property of their respective owners and we do not endorse and are not affiliated with any of the holders of any such rights and as such we cannot grant any license to exercise such rights.',
  ];
  const data = [
    '12.1. Buyers agree to processing of their personal data in accordance with the terms of Wayagram’s Privacy and Cookie Notice.',
    '12.2. Wayagram shall process all personal data obtained through the marketplace and related services in accordance with the terms of our Privacy and Cookie Notice and Privacy Policy.',
    '12.3. Sellers shall be directly responsible to buyers for any misuse of their personal data and Wayagram shall bear no liability to buyers in respect of any misuse by sellers of their personal data.',
  ];
  const intro = [
    '13.2.1. in order to verify your adherence to and performance of your obligations under these terms and conditions;',
    '13.2.2. for the purpose of disclosures pursuant to a valid order by a court or other governmental body; or',
    '13.2.3. as otherwise required by law or applicable regulation.',
  ];
  const market = [
    '14.1.1. Wayagram facilitates a marketplace for buyers and third party sellers or Wayagram where Wayagram is the seller of a product;',
    '14.1.2. the relevant seller of the product (whether Wayagram is the seller or whether it is a third party seller) shall at all times remain exclusively liable for the products they sell on the marketplace; and',
    '14.1.3. in the event that there is an issue arising from the purchase of a product on the marketplace the buyer should seek recourse from the relevant seller of the product by contact us via +23413300359 or via the ticket platform Support : Wayalinks freshdesk.com)',
    '14.2. We commit to ensure that Wayagram or third party sellers as applicable submit information relating to their products on the marketplace that is complete accurate and up to date and pursuant thereto:',
    '14.2.1. the relevant seller warrants and represents the completeness and accuracy of their information published on our marketplace relating to their products;',
    '14.2.2. the relevant seller warrants and represents that the material on the marketplace is up to date; and',
    '14.2.3. if a buyer has a complaint relating to the accuracy or completeness of the product information received from a seller (including where Wayagram is the seller) the buyer can seek recourse from the relevant seller by following the process set out in the Wayagram Dispute Resolution Policy.',
    '14.3. We do not warrant or represent that the marketplace will operate without fault; or that the marketplace or any service on the marketplace will remain available during the occurrence of events beyond Wayagram’s control (force majeure events) which include but are not limited to; flood drought earthquake or other natural disasters; hacking viruses malware or other malicious software attacks on the marketplace; terrorist attacks civil war civil commotion or riots; war threat of or preparation for war; epidemics or pandemics; or extra-constitutional events or circumstances which materially and adversely affect the political or macro-economic stability of the territory as a whole.',
    '14.4. We reserve the right to discontinue or alter any or all of our marketplace services and to stop publishing our marketplace at any time in our sole discretion without notice or explanation; and you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any marketplace services or if we stop publishing the marketplace. This is without prejudice to your rights in respect of any unfulfilled orders or other existing liabilities of Wayagram.',
    '14.5. If we discontinue or alter any or all of our marketplace in circumstances not relating to force majeure we will provide prior notice to the buyers and sellers of not less than fifteen (15) days with clear guidance on the way forward for the pending transactions or other existing liabilities of Wayagram. 14.6. We do not guarantee any commercial results concerning the use of the marketplace. To the maximum extent permitted by applicable law and subject to section 15.1 below we exclude all representations and warranties relating to the subject matter of these general terms and conditions our marketplace and the use of our marketplace.',
  ];
  const Audit = [
    '15.1.1. limit any liabilities in any way that is not permitted under applicable law; or',
    '15.1.2. exclude any liabilities or statutory rights that may not be excluded under applicable law.',
    '15.2. The limitations and exclusions of liability set out in this section 15 and elsewhere in these general terms and conditions:',
    '15.2.1. are subject to section 15.1; and',
    '15.2.2. govern all liabilities arising under these general terms and conditions or relating to the subject matter of these general terms and conditions including liabilities arising in contract in tort (including negligence) and for breach of statutory duty except to the extent expressly provided otherwise in these general terms and conditions.',
    '15.3. In respect of the services offered to you free of charge we will not be liable to you for any loss or damage of any nature whatsoever. 15.4. Our aggregate liability to you in respect of any contract to provide services to you under these general terms and conditions shall not exceed the total amount paid and payable to us under the contract. Each separate transaction on the marketplace shall constitute a separate contract for the purpose of this section 15.',
    '15.5. Notwithstanding section 15.4 above we will not be liable to you for any loss or damage of any nature including in respect of:',
    '15.5.1. any losses occasioned by any interruption or dysfunction to the website;',
    '15.5.2. any losses arising out of any event or events beyond our reasonable control;',
    '15.5.3. any business losses including (without limitation) loss of or damage to profits income revenue use production anticipated savings business contracts commercial opportunities or goodwill;',
    '15.5.4. any loss or corruption of any data database or software; or',
    '15.5.5. any special indirect or consequential loss or damage.',
    '15.6. We accept that we have an interest in limiting the personal liability of our officers and employees and having regard to that interest you acknowledge that we are a limited liability entity; you agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the marketplace or these general terms and conditions (this will not limit or exclude the liability of the limited liability entity itself for the acts and omissions of our officers and employees).',
    '15.7. Our marketplace includes hyperlinks to other websites owned and operated by third parties; such hyperlinks are not recommendations. We have no control over third party websites and their contents and we accept no responsibility for them or for any loss or damage that may arise from your use of them.',
  ];
  const Aud = [
    '16.1.1. any and all losses damages costs liabilities and expenses (including without limitation legal expenses and any amounts paid by us to any third party in settlement of a claim or dispute) incurred or suffered by us and arising directly or indirectly out of your use of our marketplace or any breach by you of any provision of these general terms and conditions or the Wayagram codes policies or guidelines; and',
    '16.1.2. any VAT liability or other tax liability that we may incur in relation to any sale supply or purchase made through our marketplace where that liability arises out of your failure to pay withhold declare or register to pay any VAT or other tax properly due in any jurisdiction.',
  ];
  const brech = [
    '17.2.1. temporarily suspend your access to our marketplace;',
    '17.2.2. permanently prohibit you from accessing our marketplace;',
    '17.2.3. block computers using your IP address from accessing our marketplace;',
    '17.2.4. contact any or all of your internet service providers and request that they block your access to our marketplace;',
    '17.2.5. suspend or delete your account on our marketplace; and/or',
    '17.2.6. commence legal action against you whether for breach of contract or otherwise.',
    '17.3. Where we suspend prohibit or block your access to our marketplace or a part of our marketplace you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).',
  ];

  const theme = useRecoilValue(userTheme);

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader
        className="text-left"
        toggle={() => hideModal(false)}
        style={theme.modalStyle}
      >
        <h2 className="title text-3xl font-semibold px-8 text-center md:px-0 my-16">
          General Terms and Conditions of Use of the Wayagram Marketplace for
          Buyers and Sellers
        </h2>
      </ModalHeader>
      <ModalBody
        style={{ ...theme.modalStyle, height: '60vh', overflowY: 'scroll' }}
      >
        <div className="px-8 lg:px-40 pb-20">
          <div>
            <h3 className="font-semibold"> Introduction</h3>
            <p>
              1.1. “Wayagram” powered by Wayabank, operates an e-commerce and
              messaging platform consisting of a website and mobile application
              (“marketplace”) together with supporting IT logistics and payment
              infrastructure for the sale and purchase of consumer products and
              services (“products”) in its allocated territory as defined at
              Appendix 1 (“territory”). 1.2. These general terms and conditions
              shall apply to buyers and sellers on the marketplace and shall
              govern your use of the marketplace and related services. 1.3. By
              using our marketplace you accept these general terms and
              conditions in full. If you disagree with these general terms and
              conditions or any part of these general terms and conditions you
              must not use our marketplace. 1.4. If you use our marketplace in
              the course of a business or other organizational project then by
              so doing you:
            </p>
            <ul>
              {dataInfo.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>
          <>
            <h4 className="font-bold"> 1. Registration and account</h4>
            <ul>
              {notDataInfo.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>

          <>
            <h4 className="font-bold mt-5">2. Terms and conditions of sale </h4>
            3.1. You acknowledge and agree that:
            <ul>
              {obligations.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>

          <>
            <h4 className="font-bold mt-5"> 3. Returns and refunds </h4>
            4.1. Returns of products by buyers and acceptance of returned
            products by sellers shall be managed by us in accordance with the
            returns page on the marketplace as may be amended from time to time.
            Acceptance of returns shall be in our discretion subject to
            compliance with applicable laws of the territory. 4.2. Refunds in
            respect of returned products shall be managed in accordance with the
            refunds page on the marketplace as may be amended from time to time.
            Our rules on refunds shall be exercised in our discretion subject to
            applicable laws of the territory. We may offer refunds in our
            discretion:
            <ul>
              {commitment.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>

          <>
            <h4 className="font-bold mt-5"> 4.Payments</h4>
            5.1. You must make payments due under these general terms and
            conditions in accordance with the Payments Information and
            Guidelines on the marketplace.
          </>
          <>
            <h4 className="font-bold mt-5"> 5. Rules about your content</h4>
            6.1. In these general terms and conditions your content means:
            <ul>
              {information.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5">
              {' '}
              6. Our rights to use your content
            </h4>
            <ul>
              {personnel.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5">
              {' '}
              7. Use of website and mobile applications{' '}
            </h4>
            8.1. In this section 10 words “marketplace” and website” shall be
            used interchangeably to refer to Wayagram’s websites and mobile
            applications.
            <ul>
              {site.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5"> 8. Copyright and trademarks </h4>
            9.1. Subject to the express provisions of these general terms and
            conditions:
            <ul>
              {copy.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5"> 9. Data privacy</h4>
            <ul>
              {data.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5">
              {' '}
              10. Due diligence and audit rights{' '}
            </h4>
            11.1. We operate an anti-fraud and anti-money laundering compliance
            program and reserve the right to perform due diligence checks on all
            users of the marketplace. 13.2. You agree to provide to us all such
            information documentation and access to your business premises as we
            may require:
            <ul>
              {intro.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5">
              11. Wayagram’s role as a marketplace{' '}
            </h4>
            12.1 You acknowledge that:
            <ul>
              {market.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
          <>
            <h4 className="font-bold mt-5">
              {' '}
              12. Limitations and exclusions of liability{' '}
            </h4>
            13.1 Nothing in these general terms and conditions will:
            <ol>
              {Audit.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ol>
          </>
          <>
            <h4 className="font-bold mt-5"> 13. Indemnification </h4>
            You hereby indemnify us and undertake to keep us indemnified
            against:
            <ol>
              {Aud.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ol>
          </>
          <>
            <h4 className="font-bold mt-5">
              {' '}
              14. Breaches of these general terms and conditions
            </h4>
            15.1 If we permit the registration of an account on our marketplace
            it will remain open indefinitely subject to these general terms and
            conditions. 15.2. If you breach these general terms and conditions
            or if we reasonably suspect that you have breached these general
            terms and conditions or any Wayagram codes policies or guidelines in
            any way we may:
            <ol>
              {brech.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ol>
          </>
          <>
            <h4 className="font-bold mt-5"> 15. Entire agreement</h4>
            16.1These general terms and conditions and the Wayagram codes
            policies and guidelines (and in respect of sellers the seller terms
            and conditions) shall constitute the entire agreement between you
            and us in relation to your use of our marketplace and shall
            supersede all previous agreements between you and us in relation to
            your use of our marketplace.{' '}
          </>
          <>
            <h4 className="font-bold mt-5"> 16. Hierarchy </h4>
            17.1 Should these general terms and conditions the seller terms and
            conditions and the Wayagram codes policies and guidelines be in
            conflict these terms and conditions the seller terms and conditions
            and the Wayagram codes policies and guidelines shall prevail in the
            order here stated.{' '}
          </>
          <>
            <h4 className="font-bold mt-5"> 17. Variation </h4>
            18.1. We may revise these general terms and conditions the seller
            terms and conditions and the Wayagram codes policies and guidelines
            from time to time. 18.2. The revised general terms and conditions
            shall apply from the date of publication on the marketplace.
          </>
          <>
            <h4 className="font-bold mt-5"> 18. No waiver </h4>
            19.1. No waiver of any breach of any provision of these general
            terms and conditions shall be construed as a further or continuing
            waiver of any other breach of that provision or any breach of any
            other provision of these general terms and conditions.
          </>
          <>
            <h4 className="font-bold mt-5"> 19. Severability </h4>
            20.1. If a provision of these general terms and conditions is
            determined by any court or other competent authority to be unlawful
            and/or unenforceable the other provisions will continue in effect.
            22.2. If any unlawful and/or unenforceable provision of these
            general terms and conditions would be lawful or enforceable if part
            of it were deleted that part will be deemed to be deleted and the
            rest of the provision will continue in effect.
          </>
          <>
            <h4 className="font-bold mt-5"> 20. Assignment </h4>
            You hereby agree that we may assign transfer sub-contract or
            otherwise deal with our rights and/or obligations under these
            general terms and conditions. 23.2. You may not without our prior
            written consent assign transfer sub-contract or otherwise deal with
            any of your rights and/or obligations under these general terms and
            conditions.{' '}
          </>
          <>
            <h4 className="font-bold mt-5"> 21. Third party rights </h4>A
            contract under these general terms and conditions is for our benefit
            and your benefit and is not intended to benefit or be enforceable by
            any third party.24.2. The exercise of the parties rights under a
            contract under these general terms and conditions is not subject to
            the consent of any third party.{' '}
          </>
          <>
            <h4 className="font-bold mt-5"> 22. Law and jurisdiction </h4>
            These general terms and conditions shall be governed by and
            construed in accordance with the laws of the territory. 25.2. Any
            disputes relating to these general terms and conditions shall be
            subject to the exclusive jurisdiction of the courts of the
            territory.
          </>
        </div>
      </ModalBody>
      <ModalFooter style={theme.modalStyle}>
        <Button
          className="btn btn-primary mb-3 mt-2"
          color="primary"
          content="Accept"
          onClick={() => {
            handleSubmit();
            hideModal(false);
          }}
        />
        <Button
          className="btn btn-primary mb-3 mt-2"
          color="primary"
          content="Decline"
          onClick={() => hideModal(false)}
        />
      </ModalFooter>
    </Modal>
  );
}

export default ShopTermsModal;
