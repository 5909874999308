import { NotificationManager } from 'react-notifications';
import React, { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../index.scss';
import { customBaseUrl, httpPostFormData } from '../../../../action/http';

import Button from '../../Button';
import { WayagramContext } from '../../../../store/context/WayagramContext';
// import bannerImage from '../../../../assets/image/banner.jpg';
import avatarImg from '../../../../assets/image/default-dp.svg';
import cameraImg from '../../../../assets/image/camera_alt.png';
import validateImage from '../../../../utils/validateImage';
// import { handleGetPageCategories } from '../../../pages/Wayagram/container';
import { userTheme } from '../../../../store/initialState';
import { CameraIcon } from '../../Icons';
import { capFirstWord } from '../../../../utils/helper';

const CreatePage = (props) => {
  const theme = useRecoilValue(userTheme);
  const history = useHistory();
  const { showModal, hideModal, mode, selectedData } = props;
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);
  const {
    wayagramProfile: profile,
    categories,
    reload,
    setReload,
  } = useContext(WayagramContext);
  // const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState(null);

  // useEffect(() => {
  //   (async () => {
  //     const res = await handleGetPageCategories();
  //     setCategories(res);
  //   })();
  // }, []);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'image') {
        setPostData({ ...postData, image: imageData });
      } else {
        setPostData({ ...postData, headerImage: imageData });
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'image') {
          setPreviewImage(reader.result);
        } else {
          setPreviewHeaderImage(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const handleSubmit = async () => {
    if (
      loading ||
      !postData.description ||
      !postData.name ||
      !postData.categoryId ||
      !postData.category ||
      (postData.category === 'Others' && !postData?.customCategory)
    ) {
      setErrors('All input fields marked with an asterisk (*) are mandatory');
      return;
    }
    setErrors('');
    setLoading(true);
    const newForm = new FormData();
    if (postData.image) newForm.append('image', postData.image);
    if (postData?.headerImage)
      newForm.append('headerImage', postData.headerImage);
    newForm.append('userId', profile.id);
    newForm.append('title', postData.name);
    newForm.append('description', postData.description);
    newForm.append('categoryId', postData.categoryId);
    newForm.append('category', postData.category);
    newForm.append('customCategory', postData.customCategory);
    newForm.append('username', postData.name);
    newForm.append('isPublic', postData.isPublic || false);
    if (postData.websiteUrl) newForm.append('websiteUrl', postData.websiteUrl);

    const res = await httpPostFormData(
      `/main/page/create-page`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setReload(!reload);
      NotificationManager.success(res?.message, 'Done!', 3000);
      history.push('/manage-pages');
      setLoading(false);
      hideModal(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="wayagram-create-group"
      centered
    >
      <ModalHeader style={theme.modalStyle}>
        <h5 style={theme.modalStyle}>
          {mode === 'create' ? 'Create' : 'Edit'} Page
        </h5>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary ml-auto"
          color="primary"
          onClick={handleSubmit}
          content="Create"
        />
      </ModalHeader>
      <ModalBody style={theme.modalStyle}>
        <p className="text-primary text-center">{errors}</p>
        <div
          className="banner-div"
          style={{
            backgroundImage: `url(${previewHeaderImage})`,
            backgroundSize: 'cover',
            height: '140px',
            backgroundPosition: 'center',
            border: 1,
            borderStyle: 'dotted',
            borderTopLeftRadius: 14,
            borderTopRightRadius: 14,
            margin: '0px 2px',
          }}
        >
          <span className="upload-banner-input">
            <CameraIcon color={theme?.modalStyle?.color} />
          </span>
          <input
            type="file"
            className="upload-banner-input2"
            onChange={(e) => handlePreview(e, 'headerImage')}
          />
          {/* <p>Click here to upload cover photo</p> */}
        </div>
        <div className="avatar-section d-flex mb-4">
          <img src={previewImage || avatarImg} alt="avatar" />
          <img src={cameraImg} className="upload-input" alt="camera" />
          <input
            type="file"
            className="upload-input2"
            onChange={(e) => handlePreview(e, 'image')}
          />
        </div>
        <div className="form-div p-3">
          <div className="inputbox-with-one-input">
            <input
              placeholder="Page title"
              value={postData.name}
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, name: e.target.value });
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
              required
            />
            <span className="s-text">
              What is the title of your page
              <span className="text-primary fs-6"> *</span>
            </span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              value={postData.description}
              placeholder="Description"
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            />
            <span className="s-text">
              Short description
              <span className="text-primary fs-6"> *</span>
            </span>
          </div>
          <div className="inputbox-with-one-input">
            <select
              onChange={(e) => {
                const [id, name] = e.target.value.split(',');
                setPostData({
                  ...postData,
                  categoryId: id,
                  category: name,
                });
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            >
              <option value="">Select category</option>
              {categories
                ? categories.map((item) => (
                    <option value={`${item.id},${item.title}`} key={item.id}>
                      {capFirstWord(item.title)}
                    </option>
                  ))
                : ''}
            </select>
            <span className="s-text">
              Select category
              <span className="text-primary fs-6"> *</span>
            </span>
          </div>
          {postData.category === 'Others' && (
            <div className="inputbox-with-one-input">
              <input
                value={postData.customCategory}
                placeholder="Custom Category"
                type="text"
                onChange={(e) => {
                  setPostData({ ...postData, customCategory: e.target.value });
                }}
                className={`bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
              />
              <span className="s-text">
                Input custom category
                <span className="text-primary fs-6"> *</span>
              </span>
            </div>
          )}
          <div className="inputbox-with-one-input">
            <select
              value={postData.isPublic}
              onChange={(e) => {
                setPostData({ ...postData, isPublic: e.target.value });
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            >
              <option value="true">Public</option>
              <option value="false">Private</option>
            </select>
            <span className="s-text">Choose privacy</span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              value={postData.websiteUrl}
              placeholder="Website Url"
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, websiteUrl: e.target.value });
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
            />
            <span className="s-text">Website url</span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

CreatePage.defaultProps = {
  mode: 'create',
  showModal: false,
};

CreatePage.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
  // selectedData: PropTypes.node,
};

export default CreatePage;
