import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../../../shared/Layout/index';
import BuyerContent from '../../BuyerContent';
import VendorContent from '../../VendorContent';
import { bidStats } from '../../apiStore/apiService';
import '../css/bid.css';

const BiddingPage = (props) => {
  const [userType, setUserType] = useState(null);
  const [stats, setStats] = useState({
    totalOpenBids: 0,
    totalClosedBids: 0,
    totalWonBids: 0,
  });

  const fetchBidStatistics = async (userID) => {
    try {
      const response = await bidStats(userID);
      const {
        pendingCount = 0,
        closedCount = 0,
        acceptedCount = 0,
      } = response.data.counts || {};

      setStats({
        totalOpenBids: parseInt(pendingCount, 10),
        totalClosedBids: parseInt(closedCount, 10),
        totalWonBids: parseInt(acceptedCount, 10),
      });
    } catch (error) {
      console.error('Failed to fetch bid statistics', error);
      toast.error('Failed to fetch bid statistics. Please try again later.');
    }
  };

  useEffect(() => {
    try {
      const userData = localStorage.getItem('wayaUserData');
      if (userData) {
        const { user } = JSON.parse(userData);
        if (user && user.id) {
          setUserType(user);
          fetchBidStatistics(user.id);
        } else {
          throw new Error('Invalid user data');
        }
      } else {
        throw new Error('No user data found in local storage');
      }
    } catch (error) {
      console.error('Error in loading user data', error);
      toast.error('Error in loading user data. Please login again.');
    }
  }, []);

  const renderContent = () => {
    if (!userType) {
      return <div>Loading...</div>;
    }

    switch (userType.type) {
      case 'PERSONAL':
        return (
          <BuyerContent
            stats={stats}
            userDetails={userType}
            transactionData={props.transactionData}
          />
        );
      case 'CORPORATE':
        return (
          <VendorContent
            stats={stats}
            userDetails={userType}
            transactionData={props.transactionData}
          />
        );
      default:
        toast.error('User type is not recognized.');
        return <div>No user type detected</div>;
    }
  };

  return (
    <Layout route={props} currentPage="bid" pageName="Bidding" showName>
      <ToastContainer />
      <div className="bidding__dashboard__items">{renderContent()}</div>
    </Layout>
  );
};

export default BiddingPage;
