import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { Progress } from 'antd';
import { Delete } from '@material-ui/icons';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { EyeIcon } from '../../Icons';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
//  import dateToFromNowDaily from '../../../../utils/formatDate';
import { customBaseUrl, httpGet, httpDelete } from '../../../../action/http';
import MomentViewers from './MomentViewer';
//  import CustomDropdown from '../../Dropdown';

const ImageViewerModal = ({
  showModal,
  id,
  allMoments,
  hideModal,
  pid,
  setReload,
  reload,
  setVisible,
  type,
}) => {
  const history = useHistory();
  const flattened = allMoments?.flat(2) ?? {};
  const [data, setData] = useState({});
  const [currentIndex, setCurrentIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [showViewer, setShowViewer] = useState(false);
  const [momentView, setMomentViewers] = useState([]);
  const [percent, setPercent] = useState(20);
  const [c, setC] = useState(0);

  const handleViewCount = async (d) => {
    // await httpGet(
    //   `${customBaseUrl.wayagramUrl}/all-posts/moment-viewers?page=1&limit=100&profile_id=${pid}&moment_id=${d?.id}`
    // );
    await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/view-moment?profile_id=${pid}&moment_id=${d?.id}`
    );
  };

  useEffect(() => {
    const d = flattened.find((f) => f.ProfileId === id);
    setData(d);
    setCurrentIndex(flattened.findIndex((f) => f.ProfileId === id));
    handleViewCount(d);
  }, []);

  const handleAction = async (action) => {
    if (action === 'next') {
      setPercent(0);
      if (flattened?.length <= currentIndex + 1) hideModal(false);
      const v = flattened.find((r, i) => i === currentIndex + 1);
      setData(v);
      setCurrentIndex(currentIndex + 1);
      handleViewCount(v);
    } else {
      setPercent(0);
      if (currentIndex === 0) hideModal(false);
      const v = flattened.find((r, i) => i === currentIndex - 1);
      setData(v);
      setCurrentIndex(currentIndex - 1);
      handleViewCount(v);
    }
  };

  const handleGetMomentViewer = async (mid) => {
    setLoading(true);
    setShowViewer(true);
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/moment-viewers?page=1&limit=100&profile_id=${pid}&moment_id=${mid}`
    );
    if (res.status) {
      setMomentViewers(res?.data);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const res = await httpDelete(
      `/all-posts/delete-moment`,
      {
        moment_id: data?.id,
        profile_id: pid,
      },
      customBaseUrl.wayagramUrl
    );
    if (res?.status) {
      setReload(!reload);
      NotificationManager.success(res?.message);
    }
    setVisible(false);
    if (res?.status) setReload(!reload);
  };

  // const handleOption = (e) => {
  //   if (e === 'delete') {
  //     handleDelete();
  //   }
  // };

  // const userOption = [
  //   {
  //     name: 'Delete',
  //     type: 'delete',
  //     id: 'mid',
  //   },
  // ];
  const increase = (c1) => {
    // setPercent((prevPercent) => {
    //   const newPercent = prevPercent + 1;
    //   if (newPercent > 100) {
    //     return 100;
    //   }
    //   return newPercent;
    if (c1 < 101) {
      setPercent(c1);
    } else {
      setPercent(100);
    }
  };
  useEffect(() => {
    //  setC(0);
    const interval = setInterval(() => {
      setC(c + 1);
    }, 100);
    increase(c);
    return () => clearInterval(interval);
  }, [c, currentIndex]);
  useEffect(() => {
    if (currentIndex || currentIndex === 0) {
      setPercent(0);
      const interval = setInterval(() => {
        if (showViewer) return;
        if (flattened?.length <= currentIndex + 1) hideModal(false);
        setPercent(0);
        setC(0);
        const v = flattened.find((r, i) => i === currentIndex + 1);
        setData(v);
        setCurrentIndex(currentIndex + 1);
        handleViewCount(v);
      }, 13 * 1000);
      return () => clearInterval(interval);
    }
    return {};
  }, [currentIndex, showViewer]);
  return (
    <Modal
      isOpen={showModal}
      id="viewer-modal"
      toggle={() => hideModal(false)}
      centered
    >
      {pid === data?.ProfileId && (
        <div
          className="dot-3x z-3 text-gray mx-5"
          style={{
            position: 'absolute',
            top: 3,
            right: -10,
            width: '1px',
            zIndex: 3,
          }}
        >
          <Delete onClick={handleDelete} />
          {/* <CustomDropdown
            style={{ zIndex: 1 }}
            id={12}
            direction="horizontal"
            handleOption={(e) => {
              handleOption(e);
            }}
            options={userOption}
          /> */}
        </div>
      )}
      <div className="profile z-3" style={{ zIndex: 1 }}>
        {type === 'product' ? (
          <div className="d-flex">
            <img
              src={data?.Profile?.avatar || imgPlaceholder}
              alt="gram avatar"
              className="me-3 "
              style={{ height: '42px', width: '42px', borderRadius: ' 50%' }}
              onClick={() =>
                history.push(`/wayagram-profile/${data?.Profile?.id}`)
              }
            />
            <div className="text-gray">
              <p>{data?.Profile?.username}</p>
              {/* <p>{data?.createdAt}</p>
              <p>{new Date(data?.createdAt).getDate()}</p>
              <p>{moment(data?.createdAt).format('ll')}</p> */}
              <p>Products</p>
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <img
              src={data?.userAvatar || imgPlaceholder}
              alt="gram avatar"
              className="me-3 "
              style={{ height: '42px', width: '42px', borderRadius: ' 50%' }}
              onClick={() =>
                history.push(`/wayagram-profile/${data?.ProfileId}`)
              }
            />
            <div className="text-gray">
              <p>{data?.userName}</p>
              {/* <p>{data?.createdAt}</p>
              <p>{new Date(data?.createdAt).getDate()}</p>
              <p>{moment(data?.createdAt).format('ll')}</p> */}
              <p>{moment(data?.createdAt).fromNow()}</p>
            </div>
          </div>
        )}
      </div>
      <ModalBody>
        <div className="z-1 mx-5" style={{ zIndex: 1 }}>
          <Progress percent={showViewer ? 50 : percent} showInfo={false} />
        </div>
        {type === 'product' ? (
          <img
            alt="modal viewer"
            src={
              data?.ProductImages?.length > 0 && type === 'product'
                ? data?.ProductImages[0]?.imageUrl
                : ''
            }
          />
        ) : (
          <img
            alt="modal viewer"
            src={
              data?.image ||
              data?.content ||
              data?.imageUrl ||
              data?.fileUrl ||
              data?.imageURL
            }
          />
        )}
        <div className="overlay-img-text add-cursor">
          <p>{data?.text ?? data?.description}</p>
          {type === 'product' && (
            <p>
              <Link to={`/post/${data?.PostId}`}>View more...</Link>
            </p>
          )}
          {pid === data?.ProfileId && (
            <div onClick={() => handleGetMomentViewer(data?.id)}>
              <EyeIcon color="white" /> {data?.viewCount} view
            </div>
          )}
        </div>
      </ModalBody>
      <span className="prev" onClick={() => handleAction('prev')}>
        {'<'}
      </span>
      <span className="next" onClick={() => handleAction('next')}>
        {'>'}
      </span>

      {showViewer ? (
        <MomentViewers
          showModal={showViewer}
          hideModal={setShowViewer}
          members={momentView}
          profileData={data}
          loading={loading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

export default ImageViewerModal;
