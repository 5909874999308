import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from '../../../../utils/device';
import ImagePreview from '../../Modals/ImagePreview';
import VideoPlayerModal from '../../Modals/VideoPlayerModal';

const PostImageCard = ({ PostImages }) => {
  const [visible, setVisible] = useState(false);
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const [videoToPlay, setVideoToPlay] = useState(null);

  const handleOpenVideoPlayer = (data) => {
    setOpenVideoPlayer(true);
    setVideoToPlay(data?.imageURL || data?.imageUrl);
  };
  return (
    <div
      className={`image-grid-div-${
        PostImages?.length >= 4 ? '4' : PostImages?.length
      }`}
    >
      {PostImages?.length > 0 &&
        PostImages.map((item, index) => {
          const idx = index + 1;
          if (index <= 3) {
            if (
              item?.imageURL?.includes('mp4') ||
              item?.imageUrl?.includes('mp4') ||
              item?.imageURL?.includes('webm') ||
              item?.imageUrl?.includes('webm') ||
              item?.imageURL?.includes('wmv') ||
              item?.imageUrl?.includes('wmv')
            ) {
              return (
                <div key={Math.random()}>
                  <div
                    className="image-each"
                    style={{
                      gridArea: `grid${index + 1}`,
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOpenVideoPlayer(item)}
                    >
                      <video
                        playsInline
                        // controls
                        autoPlay
                        muted
                        loop
                        width={180}
                        height={150}
                        className="video-post"
                        style={{
                          objectFit: 'contain',
                        }}
                      >
                        <source
                          src={item.imageURL || item.imageUrl}
                          type="video/webm"
                        />
                        <source
                          src={item.imageURL || item.imageUrl}
                          type="video/mp4"
                        />
                        <source
                          src={item.imageURL || item.imageUrl}
                          type="video/wmv"
                        />
                        Your browser does not support the video tag.
                        <track
                          // src="captions_en.vtt"
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                      </video>
                    </div>
                  </div>
                  {openVideoPlayer && (
                    <VideoPlayerModal
                      openVideoModal={openVideoPlayer}
                      setOpenVideoModal={setOpenVideoPlayer}
                      videoToPlay={videoToPlay}
                    />
                  )}
                </div>
              );
            }
            if (
              !item?.imageURL?.includes('mp4' || 'webm') ||
              !item?.imageUrl?.includes('mp4' || 'webm')
            ) {
              return (
                <div
                  className="image-each"
                  key={Math.random()}
                  style={{
                    backgroundImage: `url(${item.imageURL || item.imageUrl})`,
                    gridArea: `grid${index + 1}`,
                    position: 'relative',
                  }}
                  onClick={() => setVisible(true)}
                >
                  <div className="img-div">
                    <a href={item.imageURL || item.imageUrl}>
                      <i className="mdi mdi-download dowload-icon" alt="icon" />
                    </a>
                    {/* <img src={item.imageURL || item.imageUrl} alt="pic" /> */}
                  </div>
                  {index === 3 && PostImages.length > 4 && (
                    <div className="overlay-img-text">
                      +{PostImages.length - idx}
                    </div>
                  )}
                </div>
              );
            }
          }
          return null;
        })}
      <ImagePreview
        data={PostImages}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};

export const ProductImageCard = ({ PostImages }) => {
  return (
    <Carousel
      responsive={responsive}
      ssr
      autoPlay
      infinite={false}
      autoPlaySpeed={10000}
      keyBoardControl
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container h-100 w-100"
    >
      {(PostImages || []).map((item) => (
        <div key={Math.random()}>
          <div
            className="moment-card"
            style={{
              backgroundImage: `url(${item.imageURL || item.imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              marginRight: 10,
            }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default PostImageCard;
