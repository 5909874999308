import React, { lazy, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { NotificationContainer } from 'react-notifications';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { decodeToken } from 'react-jwt';
// import 'antd/dist/antd.min.css';
import './assets/stylesheets/index.scss';
import closeStore from './assets/image/empty-contact.svg';
import './App.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-notifications/lib/notifications.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
//  import { BillerContextProvider } from './store/context/BillerContext';
import { CardContextProvider } from './store/context/CardContext';
import { ChatContextProvider } from './store/context/ChatContext';
import { HeaderContextProvider } from './store/context/HeaderContext';
import { LayoutContextProvider } from './store/context/LayoutContext';
import { ModalContextProvider } from './store/context/ModalContext';
import { PaymentContextProvider } from './store/context/PaymentContext';
import { ProfileContextProvider } from './store/context/ProfileContext';
import { WalletContextProvider } from './store/context/WalletContext';
import { WayagramContextProvider } from './store/context/WayagramContext';
import { OrderContextProvider } from './store/context/OrderContext';
import ProtectedRoute from './utils/ProtectedRoute';
import ContestDashboard from './components/pages/Wayagram/Contest/ContestDashboard';
import ContestDetails from './components/pages/Wayagram/Contest/ContestDetails';
import ContestSignUp from './components/pages/Auth/contestSignUp';
import Contestants from './components/pages/Wayagram/Contest/contestants';
import ManageStates from './components/pages/Wayagram/Contest/manageState';
import ManageContestants from './components/pages/Wayagram/Contest/managecontestants';
import Bid from './components/pages/Wayagram/Bidding/Bid/index';
import ContestSettings from './components/pages/Wayagram/Contest/contestSettings';
import Wallet from './components/pages/Wayagram/Wallet/Index';
//  import { getUserToken } from './components/pages/Wayagram/container';

const Notifications = lazy(() => import('./components/pages/Notification'));
const Wayagram = React.lazy(() => import('./components/pages/Wayagram'));
const Interest = React.lazy(() =>
  import('./components/pages/Wayagram/Interest')
);
const Dispute = React.lazy(() => import('./components/pages/Wayagram/Dispute'));
const WayagramFollowing = React.lazy(() =>
  import('./components/pages/Wayagram/Following')
);
const WayagramProfile = React.lazy(() =>
  import('./components/pages/Wayagram/Profile')
);
const PromotionPage = lazy(() =>
  import('./components/pages/Wayagram/Promotion')
);

const CrowdfundingPage = lazy(() =>
  import('./components/pages/Wayagram/Crowdfunding')
);

const PromotionAnalytics = lazy(() =>
  import('./components/pages/Wayagram/PromotionAnalytics')
);
const CreatePromotionPage = lazy(() =>
  import('./components/pages/Wayagram/Promotion/createPromotion')
);
const WayagramGroup = lazy(() => import('./components/pages/Wayagram/Group'));
const WayagramPage = lazy(() => import('./components/pages/Wayagram/Page'));
const VerifyEmail = lazy(() => import('./components/pages/VerifyEmail'));
const sendEmail = lazy(() => import('./components/pages/Wayagram/sendEmail'));
const Setting = lazy(() => import('./components/pages/Setting'));
const Logout = lazy(() => import('./components/pages/Logout'));
const Login = lazy(() => import('./components/pages/Auth/login'));
const CorpLogin = lazy(() => import('./components/pages/Auth/corpAuth'));
const Signup = lazy(() => import('./components/pages/Auth/signup'));
const Activity = lazy(() => import('./components/pages/Activity'));
const Chat = lazy(() => import('./components/pages/Chat'));
const Contact = lazy(() => import('./components/pages/Contact'));
const Support = lazy(() => import('./components/pages/Support'));
const Bookmarks = lazy(() => import('./components/pages/Wayagram/Bookmarks'));
const Discover = lazy(() => import('./components/pages/Wayagram/Discover'));
const DonationDetails = lazy(() =>
  import('./components/pages/Wayagram/Donation/DonationDetails')
);
const DonationTable = lazy(() =>
  import('./components/pages/Wayagram/Donation/DonationTable')
);
const ManagePages = lazy(() =>
  import('./components/pages/Wayagram/ManagePages')
);
const ManageShops = lazy(() =>
  import('./components/pages/Wayagram/ManageShops')
);

const EditProduct = lazy(() =>
  import('./components/pages/Wayagram/Shops/EditProductModal')
);
const ManageContest = lazy(() =>
  import('./components/pages/Wayagram/ManageContest')
);
const MyGroups = lazy(() => import('./components/pages/Wayagram/MyGroups'));
const PollsDetails = lazy(() =>
  import('./components/pages/Wayagram/Polls/PollsDetails')
);
const PollsTable = lazy(() =>
  import('./components/pages/Wayagram/Polls/PollsTable')
);
const TopClips = lazy(() => import('./components/pages/Wayagram/TopClips'));
const CommentView = lazy(() => import('./components/shared/Cards/CommentView'));
const ContestComment = lazy(() =>
  import('./components/shared/Cards/ContestComment')
);

const EventDetails = lazy(() =>
  import('./components/pages/Wayagram/Event/EventDetails')
);
const MyEvents = lazy(() => import('./components/pages/Wayagram/MyEvents'));
const EventAnalytics = lazy(() =>
  import('./components/pages/Wayagram/Event/EventAnalytics')
);
const HerosPage = lazy(() => import('./components/pages/Wayagram/HerosPage'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}

const queryClient = new QueryClient();

const App = () => {
  // const [token, setToken] = useState();
  const history = useHistory();
  const helmetContext = {};
  useEffect(async () => {
    const token = localStorage.getItem('token');
    //  localStorage.setItem('deviceToken', token);
    if (token) {
      const decodedToken = decodeToken(token);
      const expDate = new Date(decodedToken?.exp * 1000);
      const newDate = new Date();
      if (newDate > expDate) {
        localStorage.clear();
        history.push('/');
      }
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div style={{ position: 'absolute', top: '25%', left: '40%' }}>
          <div style={{ width: '200px', height: '200px' }}>
            <img
              style={{ width: '100%', height: '100%' }}
              src={closeStore}
              alt="suspense"
            />
          </div>
        </div>
      }
    >
      <NotificationContainer />
      <HeaderContextProvider>
        <HelmetProvider context={helmetContext}>
          <Router>
            <Switch>
              {/* <Route exact path="/" component={Login} /> */}
              <Route path="/login" component={Login} />
              <Route path="/corp-login" component={CorpLogin} />
              <Route path="/signup" component={Signup} />
              <Route path="/support" component={Support} />
              <PaymentContextProvider>
                <CardContextProvider>
                  <WalletContextProvider>
                    <ProfileContextProvider>
                      <ModalContextProvider>
                        <WayagramContextProvider>
                          <ChatContextProvider>
                            <OrderContextProvider>
                              <LayoutContextProvider>
                                <ProtectedRoute
                                  exact
                                  path="/notifications"
                                  component={Notifications}
                                />

                                <ProtectedRoute
                                  exact
                                  path="/sendemail"
                                  component={sendEmail}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/register-contest/:id"
                                  component={ContestSignUp}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/events"
                                  component={MyEvents}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/event/:id"
                                  component={EventDetails}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/event/analytics/:id"
                                  component={EventAnalytics}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/dispute"
                                  component={Dispute}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/chat"
                                  component={Chat}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wayagram"
                                  component={Wayagram}
                                />

                                <ProtectedRoute path="/bid" component={Bid} />

                                <Route exact path="/" component={HerosPage} />

                                <ProtectedRoute
                                  exact
                                  path="/settings"
                                  component={Setting}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/interest"
                                  component={Interest}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/post/:id"
                                  component={CommentView}
                                /> */}
                                <Route
                                  exact
                                  path="/post/:id"
                                  component={CommentView}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/contest/post/:id"
                                  component={ContestComment}
                                /> */}
                                <Route
                                  exact
                                  path="/contest/post/:id"
                                  component={ContestComment}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/following/:id"
                                  component={WayagramFollowing}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wayagram-profile/:id"
                                  component={WayagramProfile}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/wayagram-profile/contest/:email/:contestId/:contestantId"
                                  component={WayagramProfile}
                                /> */}
                                <Route
                                  exact
                                  path="/wayagram-profile/contest/:email/:contestId/:contestantId"
                                  component={WayagramProfile}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/contest"
                                  component={ManageContest}
                                /> */}
                                <Route
                                  exact
                                  path="/contest"
                                  component={ManageContest}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/contest/settings"
                                  component={ContestSettings}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/contest/contestants/:contestId/:profileId"
                                  component={Contestants}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/contest/contestants/states/:state/:contestId/:profileId"
                                  component={Contestants}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/manage-dashboard/:profileId/:contestId"
                                  component={ContestDashboard}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/manage-states/:profileId/:contestId"
                                  component={ManageStates}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/manage-contestants/:profileId/:contestId"
                                  component={ManageContestants}
                                />
                                <Route
                                  exact
                                  path="/contest-dashboard/:profileId/:contestId"
                                  component={ContestDetails}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/promotion/:postid"
                                  component={PromotionPage}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/promotions-analytics"
                                  component={PromotionAnalytics}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/create-promotion/:type/:id"
                                  component={CreatePromotionPage}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wayagram-group/:id"
                                  component={WayagramGroup}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wayagram-page/:id"
                                  component={WayagramPage}
                                />

                                <ProtectedRoute
                                  exact
                                  path="/shops/edit-product/:id"
                                  component={EditProduct}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/donation/:id"
                                  component={DonationDetails}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/polls/:id"
                                  component={PollsDetails}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/poll-list/:id"
                                  component={PollsTable}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/donation-list/:id"
                                  component={DonationTable}
                                />

                                {/* <ProtectedRoute
                                  exact
                                  path="/donation"
                                  component={CrowdfundingPage}
                                /> */}
                                <Route
                                  exact
                                  path="/donation"
                                  component={CrowdfundingPage}
                                />

                                <ProtectedRoute
                                  exact
                                  path="/manage-groups"
                                  component={MyGroups}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/manage-pages"
                                  component={ManagePages}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/wayagram-discover"
                                  component={Discover}
                                /> */}
                                <Route
                                  exact
                                  path="/wayagram-discover"
                                  component={Discover}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wayagram-bookmarks"
                                  component={Bookmarks}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/top-clips"
                                  component={TopClips}
                                />
                                {/* <ProtectedRoute
                                  exact
                                  path="/shops"
                                  component={ManageShops}
                                /> */}
                                <Route
                                  exact
                                  path="/shops"
                                  component={ManageShops}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/activity-log"
                                  component={Activity}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/contacts"
                                  component={Contact}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wallets"
                                  component={Wallet}
                                />
                              </LayoutContextProvider>
                            </OrderContextProvider>
                          </ChatContextProvider>
                        </WayagramContextProvider>
                        <ProtectedRoute
                          exact
                          path="/verify_email"
                          component={VerifyEmail}
                        />
                        <Route exact path="/logout" component={Logout} />
                      </ModalContextProvider>
                    </ProfileContextProvider>
                  </WalletContextProvider>
                </CardContextProvider>
              </PaymentContextProvider>
            </Switch>
          </Router>
          <ToastContainer newestOnTop />
        </HelmetProvider>
      </HeaderContextProvider>
    </Suspense>
  );
};

ReactDOM.render(
  // root recoil
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
