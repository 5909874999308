import { initializeApp, getApps } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDUKlAObw4bZiR7W5PkZZbb6sTzspJSaQs',
  authDomain: 'wayapayv2.firebaseapp.com',
  projectId: 'wayapayv2',
  storageBucket: 'wayapayv2.appspot.com',
  messagingSenderId: '581411214832',
  appId: '1:581411214832:web:270f5a42f9b8ed338a3935',
  measurementId: 'G-THVMCPE36N',
};

// Initialize Firebase
const apps = getApps();
const app = apps.length ? apps[0] : initializeApp(firebaseConfig);
export const publicKey =
  'BAKdHSXC0G18t8VyZgsfXnI3ie7SZZE5PJjnnR3YgsuoHFnLQpGwql21Wpu5N_s7ClOTy4P00wq3CYsmN8m59pY';
export const messaging = () => {
  if (app) {
    return getMessaging(app);
  }
  return {};
};

// firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();

// export const getToken = async () => {
//   let currentToken = '';
//   try {
//     currentToken = await messaging.getToken({ vapidKey: publicKey });
//     return currentToken;
//   } catch (error) {
// notification.error({
//   message: 'Please unlock permission to recieve notification',
//   description: error?.message,
// });
//   }
//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

// const messaging = getMessaging(firebaseApp);
// export const fetchToken = async (setToken) => {
//   await getToken(messaging, { vapidKey: KEY_PAIR }).then((currentToken) => {
//     if (currentToken) {
//       setToken(currentToken)
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//   });
// }
