import { Avatar, Progress } from 'antd';
import React from 'react';
import './donationProgress.scss';

// import userAvatar from '../../../../../../assets/image/avata22.png';

const DonationProgress = (props) => {
  const { description, image, current, total, title, theme } = props;
  const currentPercent = Math.round(
    (parseInt(current, 10) / parseInt(total, 10)) * 100
  );
  // const totalPercent = 100;
  return (
    <div className="dontaion-progress-container mt-2 w-80">
      {/* <div className="dontaion-progress-box w-100">
        <div className="current-progress" />
      </div> */}
      <Progress
        percent={currentPercent}
        strokeColor={
          currentPercent <= 0
            ? '#7C2B0A'
            : currentPercent <= 25 && currentPercent > 0
            ? '#38364F'
            : currentPercent <= 50 && currentPercent > 25
            ? '#993F1A'
            : currentPercent <= 75 && currentPercent > 50
            ? '#FF9858'
            : '#27AE60'
        }
        format={(percent) => (
          <p style={theme.style}>{`${title} ${percent}%`}</p>
        )}
      />
      <div className="donation-detail-group mb-3">
        {image && <Avatar className="m-1" src={image} />}
        {description && <div>{description}</div>}
      </div>
    </div>
  );
};

export default DonationProgress;
