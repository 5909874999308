const headers = [
  {
    header: 'Donation Information',
    description: 'You will receive money  donated into your defaut wallet',
  },
  {
    header: 'Donation Information',
    description: 'You will receive money  donated into your defaut wallet',
  },
  {
    header: 'Target',
    description: 'How much are you looking to raise',
  },
  {
    header: 'Summary',
    description: 'Preview details of donation information',
  },
  {
    header: '',
    description: '',
  },
];

const donationButtonTitle = ['Continue', 'Continue', 'Continue', 'Publish'];
const groupButtonTitle = ['Continue', 'Publish'];

export { headers, donationButtonTitle, groupButtonTitle };
