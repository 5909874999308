import React from 'react';
import styled from 'styled-components';

const StyledError = styled.div`
  color: red;
  font-size: 12px;
  font-style: italic;
`;

const Error = ({ error }) => {
  return <StyledError>{error}</StyledError>;
};

export default Error;
