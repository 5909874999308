import axios from 'axios';
import {
  customBaseUrl,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  httpPatch,
  httpSearch,
} from '../../../action/http';

export const getFeeds = async (profileId, page, limit) => {
  if (!profileId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/all-posts/feed?page=${page || 1}&limit=${
        limit || 10
      }`
    );
    console.log('wayagram-getfeeds', res.data);
    if (res?.status) {
      return res.data;
    }
  }

  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/feed?profile_id=${profileId}&page=${
      page || 1
    }&limit=${limit || 10}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const getNotifications = async (profileId, page) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/notification/get-notifications-user?profile_id=${profileId}&page=${
      page || 1
    }&limit=30`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const getRequestNotifications = async (type, uid, id, page) => {
  const url =
    type === 'friend'
      ? `/graph/friend/requests?user_id=${uid}&page=${page || 1}`
      : type === 'event'
      ? `/all-posts/get-a-user-events-invitations?page=${page || 1}`
      : type === 'group'
      ? `/main/group/get-all-groups-invites?pageNumber=${
          page || 1
        }&userId=${id}`
      : type === 'bidding'
      ? `/bid/item/${uid}`
      : `/main/page/get-all-pages-invitation`;
  const res = await httpGet(`${customBaseUrl.wayagramUrl}${url}`);
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const deleteNotication = async (data) => {
  const res = await httpDelete(
    `/notification/delete-notification`,
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const acceptOrRejectNotification = async (data, type, action) => {
  const url =
    type === 'friend'
      ? `/graph/friend/${action}-request`
      : type === 'event'
      ? `/all-posts/accept-or-reject-invites`
      : type === 'page'
      ? `/main/page/respond-to-page-invite`
      : `main/group/respond-to-group-invite`;
  let res;
  if (type === 'friend') {
    res = await httpPost(url, data, customBaseUrl.wayagramUrl);
  } else if (type === 'event') {
    res = await httpPatch(url, data, customBaseUrl.wayagramUrl);
  } else res = await httpPut(url, data, customBaseUrl.wayagramUrl);
  return res;
};

export const getMyInterest = async (profileId) => {
  if (!profileId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/graph/user-interest/userInterest`
    );
    console.log('wayagram-getmyinterest', res.data);
    if (res?.status) {
      return res.data;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/userInterest?profileId=${profileId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const getUnAddedInterest = async (profileId) => {
  if (!profileId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/graph/user-interest/notAdded`
    );
    console.log('wayagram-addedinterest', res.data);
    if (res?.status) {
      return res.data;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/notAdded?profileId=${profileId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const addSelectedInterest = async (data) => {
  const res = await httpPost(
    `/graph/user-interest/selectUserInterest`,
    data,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const retrieveMoney = async (id, type, profileId) => {
  const postData = {
    item_id: id,
    item_type: type,
    profile_id: profileId,
  };
  const res = await httpPost(
    `/all-posts/receive-money`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const removeSelectedInterest = async (data) => {
  const { profileId, userInterestId } = data;
  const postData = {
    profileId,
    userInterestId,
  };
  const res = await httpDelete(
    `/graph/user-interest/deleteUserInterest`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const getGroupSuggestion = async (userId) => {
  if (!userId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/main/group/get-group-suggestion`
    );
    console.log('wayagram-getgroupsuggestion', res.data);
    if (res?.status) {
      return res.suggestion;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-group-suggestion?userId=${userId}`
  );
  if (res?.status) {
    return res.suggestion;
  }
  return [];
};

export const getPageSuggestion = async (userId) => {
  if (!userId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/main/page/get-page-suggestion`
    );
    console.log('wayagram-getpagesuggestion', res.data);
    if (res?.status) {
      return res.suggestion;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-page-suggestion?userId=${userId}`
  );
  if (res?.status) {
    return res.suggestion;
  }
  return [];
};

export const getFriendSuggestion = async (userId) => {
  if (!userId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/graph/friend/suggestions`
    );
    console.log('wayagram-getfriendsuggestion', res.data);
    if (res?.status) {
      return res?.suggestion || res.Profiles;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/suggestions?user_id=${userId}`
  );
  if (res?.status) {
    return res?.suggestion || res.Profiles;
  }
  return [];
};

export const getConnectionStatus = async (loggedInUserId, friendId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/connection?profile_id=${loggedInUserId}&friend_id=${friendId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return {};
};

export const getPostsBySelectedInterest = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/recommended-posts?profile_id=${userId}`
  );
  if (res?.status) {
    return res;
  }
  return [];
};

export const getUserFeeds = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/user-posts?profile_id=${profileId}&page=1`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const getUserLikes = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-posts-user-liked?profile_id=${profileId}&page=1`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

// export const getUserMedia = async (id) => {
//   const res = await httpGet(
//     `${customBaseUrl.wayagramUrl}/accounts/profile/get-media-files-by-profile-id?profile_id=${id}`
//   );
//   if (res?.status) {
//     return res?.data;
//   }
//   return [];
// };

export const getUserMedia = async (url) => {
  const res = await httpGet(`${customBaseUrl.authUrl}/${url}`);
  if (res?.status) {
    return res?.data;
  }
  return [];
};

export const getUserDonation = async (id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-all-donations?profile_id=${id}&page=1`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const getAllDonation = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-all-donations`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const getUserPolls = async (page, limit, id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-polls?profile_id=${id}&page=${page}&limit=${limit}`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const getWayaProfile = async (userId) => {
  const id = localStorage.profileId;
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/profile/get-by-id?profile_id=${
      userId ?? id
    }`
  );
  if (res?.status) {
    return res.data;
  }
  return {};
};
export const getUserToken = async (id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/notification/get-device-token-profileId?profileId=${id}`
  );
  if (res?.status) {
    return res.data;
  }
  return {};
};
export const getEventParticipants = async (eventId, id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-all-event-participants?event_id=${eventId}&profile_id=${id}`
  );
  if (res.status) {
    return res.data.data;
  }
  return {};
};

export const getAllEvents = async (url) => {
  const res = await httpGet(`${customBaseUrl.wayagramUrl}/all-posts/${url}`);
  if (res?.status) {
    return res?.data?.data;
  }
  return {};
};

export const getUserFriends = async (userId) => {
  const res = await httpGet(`${customBaseUrl.wayagramUrl}/?user_id=${userId}`);
  if (res?.status) {
    return res?.data;
  }
  return [];
};

export const getFollowing = async (userId, page) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/following?user_id=${userId}&page=${page}`
  );
  if (res?.status) {
    return res;
  }
  return [];
};

export const getFollowers = async (userId, page) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/followers?user_id=${userId}&page=${page}`
  );
  if (res?.status) {
    return res;
  }
  return {};
};

export const getMyGroups = async (userId, pageNumber) => {
  if (!userId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/main/group/get-user-groups?pageNumber=${
        pageNumber || 1
      }`
    );
    console.log('wayagram-getmygroups', res.data);
    if (res?.status) {
      return res.data;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-user-groups?pageNumber=${
      pageNumber || 1
    }&userId=${userId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const inviteToGroup = async (requestFrom, requestTo, groupId) => {
  const postData = {
    groupId,
    requestFrom,
    requestTo,
  };
  const res = await httpPost(
    `/main/group/invite-others-to-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};
export const inviteToEvent = async (requestFrom, requestTo, eventId) => {
  const postData = {
    event_id: eventId,
    requestFrom,
    requestTo,
  };
  // const newForm = new FormData();
  // newForm.append('event_id', eventId);
  // newForm.append('requestFrom', requestFrom);
  // newForm.append('requestTo', requestTo);
  const res = await httpPost(
    `/all-posts/invite-others-to-event`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const joinGroup = async (userId, groupId) => {
  const postData = {
    userId,
    groupId,
  };
  const res = await httpPost(
    `/main/group/join-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const leaveAndDeleteGroup = async (userId, groupId) => {
  const postData = {
    userId,
    groupId,
  };
  const res = await httpPut(
    `/main/group/leave-and-delete-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const getAllGroups = async (userId, pageNumber) => {
  if (!userId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/main/group/get-all-groups?pageNumber=${
        pageNumber || 1
      }`
    );
    console.log('wayagram-getallgroups', res.data);
    if (res?.status) {
      return res.data;
    }
    return [];
  }
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-groups?pageNumber=${
      pageNumber || 1
    }&userId=${userId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const getMyPages = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-user-pages?userId=${userId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};
export const followOrUnfollowPage = async (userId, pageId) => {
  const postData = {
    userId,
    pageId,
  };
  const res = await httpPut(
    `/main/page/follow-or-un-follow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const getPageById = async (userId, pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-page-by-id?pageId=${pageId}&userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};
export const deletePage = async (data) => {
  const res = await httpDelete(
    `/main/page/delete-page`,
    data,
    customBaseUrl.wayagramUrl
  );
  if (res.status) {
    return res.data;
  }
  return {};
};

export const getPagePosts = async (userId, pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/page-posts?profile_id=${userId}&page_id=${pageId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getPageFollowers = async (pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-page-followers?pageId=${pageId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getAllPages = async (userId, pageNumber) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/main/page/get-all-pages-a-user-follows?pageNumber=${
      pageNumber || 1
    }&userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getFriends = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend?user_id=${userId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return [];
};

export const searchFriends = async (q, profileId) => {
  if (!profileId) {
    const res = await axios.get(
      `${customBaseUrl.wayagramUrl}/graph/friend/search?query=${q}`
    );
    console.log('wayagram-searchfriends', res.data);
    if (res?.status) {
      return res?.data;
    }
    return [];
  }
  const res = await httpSearch(
    `${customBaseUrl.authUrl}/profile/search?query=${q}&profile_id=${profileId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return [];
};

export const inviteToAPage = async (requestFrom, requestTo, pageId) => {
  const postData = {
    pageId,
    requestFrom,
    requestTo,
  };
  const res = await httpPost(
    `/main/page/invite-to-a-page`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const deletePost = async (data) => {
  const res = await httpDelete(`/delete`, data, 'http://157.245.84.14:3200');
  if (res.status) {
    return res.data;
  }
  return {};
};

export const other = () => {
  return [];
};

// Group calls
export const getGroupInfo = async (groupId, userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/view-group-info?groupId=${groupId}&userId=${userId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return {};
};

export const getGroupFeeds = async (groupId, userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/group-posts?group_id=${groupId}&profile_id=${userId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return {
    data: [],
  };
};

export const getPrivateGroupRequest = async (groupId, userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-private-group-requests?groupId=${groupId}&userId=${userId}`
  );
  if (res?.status) {
    return res;
  }
  return {
    data: [],
  };
};

export const getGroupMembers = async (groupId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/see-all-members-in-group?groupId=${groupId}`
  );
  if (res?.status) {
    return res;
  }
  return {
    data: [],
  };
};

export const handleFollow = async (id, username) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/follow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleUnFollow = async (id, username) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/unfollow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleBookmark = async (url, postData) => {
  const res = await httpPost(url, postData, customBaseUrl.wayagramUrl);
  return res;
};

export const handleGetBookmark = async (profileId, page, limit) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/all-posts/get-user-bookmarks?profileId=${profileId}&page=${
      page || 1
    }&limit=${limit || 30}`
  );
  return res;
};

export const handleGetTopClips = async (profileId, page, limit) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/all-posts/get-top-clips?profile_id=${profileId}&page=${page || 1}&limit=${
      limit || 20
    }`
  );
  return res;
};

export const handleGetContact = async (page) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/get-user-contact?page=${page}`
  );
  return res;
};

export const handleBlockUser = async (id, username, url) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/${url}`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleJoinGroup = async (userid, groupid) => {
  const postData = {
    groupId: groupid,
    userId: userid,
  };
  const res = await httpPost(
    `/main/group/join-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const manageProduct = async (payload) => {
  const res = await httpPost(
    `/all-posts/manage-sold-product`,
    payload,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleRepost = async (
  originalPostId,
  profileId,
  comment,
  groupId
) => {
  const commet = comment && { repost_comment_desc: comment };
  const group = groupId && { group_id: groupId };
  const postData = {
    original_post_id: originalPostId,
    reposter_profile_id: profileId,
    ...group,
    ...commet,
  };
  const res = await httpPost(
    `/all-posts/repost-post`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};
export const eventShowInterest = async (eventId, profileId, going) => {
  const postData = {
    event_id: eventId,
    profile_id: profileId,
    going,
  };
  const res = await httpPatch(
    `/all-posts/attend-event`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleToggleMute = async (authId, username) => {
  const postData = {
    user_id: authId,
    username,
  };
  const res = await httpPut(
    `/graph/friend/mute-or-unmute-friend`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleGetPageCategories = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-page-categories?page=1&limit=100`
  );
  if (res?.status) {
    return res.interests?.data;
  }
  return [];
};

export const handleGetPriceList = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-promotion-pricing-configs`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const handleGetPromotions = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-user-promotions`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const handleGetPromotionsByUser = async (id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-user-promotions?profile_id=${id}`
  );
  if (res?.status) {
    return res?.data?.data;
  }
  return [];
};

export const handleGetPromoStat = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-promotion-stats`
  );
  if (res.status) {
    return res?.data;
  }
  return [];
};

export const handleGetGroupCategories = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-groups-categories?page=1&limit=100`
  );
  if (res?.status) {
    return res.interests?.data;
  }
  return [];
};

export const handleGetUserInterest = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/userInterest?profileId=${profileId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return [];
};

export const resetNotifications = async (profileId) => {
  const postData = {
    profileId,
  };
  const res = await httpPut(
    `/profile/reset-notification-count`,
    postData,
    customBaseUrl.authUrl
  );
  return res;
};

// get postbyid

export const getPostById = async (postId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/single-post?post_id=${postId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return {};
};

export const handleVerifyPaymentOnWayaquick = async (data) => {
  const res = await httpPost(
    '/all-posts/verify-payment',
    data,
    customBaseUrl.wayagramUrl,
    false,
    false,
    true
  );
  return res;
};
