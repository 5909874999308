import React, { memo } from 'react';

const BidFilterTab = memo(({ tabs, activeIndex, onClick, counts }) => (
  <div className="bidding__bid-filter-tab">
    {tabs.map((content, index) => (
      <div
        key={content}
        className={`bidding__clickable ${
          activeIndex === index ? 'bidding__active' : ''
        }`}
        onClick={() => onClick(index)}
      >
        {content}
        <span className="bidding__badge">{counts[index]}</span>
      </div>
    ))}
  </div>
));

export default BidFilterTab;
