import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router';
import { repostContest, repostPost } from '../../../../utils/helper';

const RepostDropDown = ({
  isRepost,
  postId,
  profileId,
  setReload,
  reload,
  setShowRepost,
  commentType,
  contestId,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const params = useParams();
  const handleRepost = (e) => {
    if (!profileId) return NotificationManager.error('Sign in to repost');
    if (e.key === 'quote') setShowRepost(true);
    else {
      repostPost(postId, profileId, null, () => setReload(!reload), params?.id);
    }
    return {};
  };
  const handleRepostContest = () => {
    repostContest({ contestId, profileId }, () => setReload(!reload));
  };

  return commentType !== 'contest' ? (
    <Dropdown
      overlay={
        <Menu onClick={handleRepost}>
          <Menu.Item key="repost">Repost</Menu.Item>
          <Menu.Item key="quote">Quote</Menu.Item>
        </Menu>
      }
      onVisibleChange={(e) => setDropDown(e)}
      visible={dropDown}
    >
      <i
        className={
          isRepost
            ? 'mdi mdi-twitter-retweet text-success'
            : 'mdi mdi-twitter-retweet'
        }
      />
    </Dropdown>
  ) : (
    <>
      <i
        onClick={handleRepostContest}
        className={
          isRepost
            ? 'mdi mdi-twitter-retweet text-success'
            : 'mdi mdi-twitter-retweet'
        }
      />
    </>
  );
};

export default RepostDropDown;
