import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BuyerBidTable from '../Buyer/BuyerBidTable';
import VendorBidTable from '../Vendor/VendorBidTable';
import '../css/BidTable.css';
import '../css/bidCard.css';
import { fetchUserBidsAndCounts } from '../../apiStore/apiService';

const OpenBidTable = ({ userDetails }) => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchBids = async () => {
      try {
        console.log(userDetails.id);
        const response = await fetchUserBidsAndCounts(userDetails.id);
        console.log(response);
        setBids(response.data.data.bids); // Set the entire bids array
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBids();
  }, [userDetails]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (bids.length === 0) {
    return <div>No open bids yet.</div>;
  }

  // Determine which component to use based on user type
  const BidTableComponent =
    userDetails.type === 'PERSONAL' ? BuyerBidTable : VendorBidTable;

  return (
    <div className="bidding-list-container">
      <BidTableComponent bids={bids} history={history} />
    </div>
  );
};

export default OpenBidTable;
