import React, { useCallback, useEffect, useState } from 'react';
import swal from 'sweetalert';
// import { PhotoProvider, PhotoView } from 'react-photo-view';
// import 'react-photo-view/dist/react-photo-view.css';
import { IoMdCloseCircle } from 'react-icons/io';
import validateImage from '../../../../../utils/validateImage';
import { numberFormat } from '../../../../../utils/helper';
import { CameraIcon } from '../../../Icons';
// import useStore from '../../../../../store/zustandStore/store';

const PublishDonation = (props) => {
  const { handleFormInput, values, theme } = props;
  // const { percentagePricing } = useStore((state) => state);
  const [previeweDonationPoster, setPreviewDonationPoster] = useState([]);
  const [imageFileArr, setImageFileArr] = useState([]);
  // const [productPercentage, setProductPercentage] = useState(0);

  // useEffect(() => {
  //   const donationPricing = percentagePricing?.find(
  //     (p) => p?.product_type === 'Donation'
  //   );
  //   setProductPercentage(donationPricing?.percent);
  // }, [percentagePricing]);

  useEffect(() => {
    if (values?.donationImage?.length) {
      setImageFileArr(values.donationImage);
    }
  }, [values?.donationImage]);

  useEffect(() => {
    handleFormInput(imageFileArr, 'donationImage');
  }, [imageFileArr]);

  const handleUploadImg = useCallback(
    (e) => {
      const { files } = e.target;
      const validFormat = validateImage(files);
      // const validateVideo = videoValidation(files);

      if (validFormat.valid) {
        setImageFileArr((prev) => [...prev, ...files]);
      } else {
        swal('Oops!', `${validFormat.message}`, 'error');
      }
    },
    [imageFileArr]
  );

  const handlePreview = useCallback(
    (e) => {
      const { files } = e.target;
      const validFormat = validateImage(files);
      // const validateVideo = videoValidation(files);

      if (validFormat.valid) {
        /* eslint-disable no-plusplus */
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          /* eslint-disable no-loop-func */
          reader.onloadend = () => {
            if (files.length - 1 === i && files[i].type.includes('image')) {
              setPreviewDonationPoster([
                ...previeweDonationPoster,
                { mediaType: 'image', media: reader.result },
              ]);
            } else if (
              files.length - 1 === i &&
              files[i].type.includes('video')
            ) {
              setPreviewDonationPoster([
                ...previeweDonationPoster,
                { mediaType: 'video', media: reader.result },
              ]);
            }
          };
          reader.readAsDataURL(files[i]);
        }
      } else {
        swal('Oops!', `${validFormat.message}`, 'error');
      }
    },
    [previeweDonationPoster, imageFileArr]
  );

  const handleCloseImage = useCallback(
    (index) => {
      const imgArr = [...previeweDonationPoster];
      imgArr.splice(index, 1);
      setPreviewDonationPoster(imgArr);

      const imgUploadArr = [...imageFileArr];
      imgUploadArr.splice(index, 1);
      setImageFileArr(imgUploadArr);
    },
    [previeweDonationPoster, imageFileArr]
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '5px' }}>
        {/* <PhotoProvider> */}
        <div style={{ display: 'flex', gap: '5px' }}>
          {previeweDonationPoster.map(
            (img, index) =>
              img.mediaType === 'image' ? (
                <div
                  style={{
                    position: 'relative',
                    border: '3px solid #ef562440',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                  key={Math.random()}
                >
                  <span style={{ position: 'absolute', top: 10, right: 10 }}>
                    <IoMdCloseCircle
                      size={20}
                      color="red"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        zIndex: 10,
                      }}
                      onClick={() => handleCloseImage(index)}
                    />
                  </span>
                  <img
                    src={img.media}
                    alt="event"
                    width={120}
                    height={120}
                    style={{
                      objectFit: 'fill',
                      margin: '2px',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      border: '2px solid white',
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: 'relative',
                    border: '3px solid #ef562440',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                  key={Math.random()}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      zIndex: 30,
                    }}
                  >
                    <IoMdCloseCircle
                      size={20}
                      color="red"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                      }}
                      onClick={() => handleCloseImage(index)}
                    />
                  </span>
                  {/* <img
                src={img}
                alt="event"
                width={120}
                height={120}
                style={{
                  objectFit: 'contain',
                  margin: '2px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  border: '2px solid white',
                }}
              /> */}

                  <video
                    // className="video-upload"
                    playsInline
                    muted
                    autoPlay
                    loop
                    width={120}
                    height={120}
                    controls
                    style={{
                      objectFit: 'fill',
                      margin: '2px',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      border: '2px solid white',
                      zIndex: 10,
                      position: 'relative',
                    }}
                  >
                    <source src={img.media} type="video/mp4" />
                    <source src={img.media} type="video/webm" />
                    <source src={img.media} type="video/wmv" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )

            // </PhotoView>
          )}
        </div>
      </div>
      <div
        className="event-icon-container mt-2 d-flex justify-content-start align-items-start gap-2 fst-italic text-primary flex-column"
        style={{ fontSize: '12px', color: 'skyblue' }}
      >
        {/* <div
          className="banner-div"
          style={{
            backgroundImage: `url(${
              values?.imageUrl ||
              values?.DonationImage ||
              (values?.DonationImages &&
                values?.DonationImages.length &&
                values?.DonationImages[0]?.imageUrl) ||
              (previeweDonationPoster.length && previeweDonationPoster[0])
            })`,
            backgroundSize: 'cover',
            height: '140px',
            backgroundPosition: 'center',
            border: 1,
            borderStyle: 'dotted',
            borderTopLeftRadius: 14,
            borderTopRightRadius: 14,
            margin: '0px 2px',
            width: '100%',
          }}
        >
          <span className="upload-banner-input">
            <CameraIcon color={theme?.modalStyle?.color} />
          </span>
          <input
            type="file"
            multiple="multiple"
            accept="image/*"
            className="upload-banner-input2"
            onChange={handlePreview}
            style={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          />
          <p>Click here to upload cover photo</p>
        </div> */}
        {/* <div
          style={{
            width: '5rem',
            height: '5rem',
            border: '2px solid black',
            backgroundColor: 'red',
          }}
        >
          <span className="upload-banner-input">
            <CameraIcon color={theme?.modalStyle?.color} />
          </span>
          <input
            type="file"
            multiple="multiple"
            accept="image/*"
            className="upload-banner-input2"
            onChange={handlePreview}
            style={{
              width: '3rem',
              height: '3rem',
              top: 0,
              left: 0,
            }}
          />
          <p>Click here to upload cover photo</p>
        </div> */}

        <div
          style={{
            width: '3rem',
            height: '3rem',
            position: 'relative',
            marginLeft: '3%',
          }}
        >
          <input
            type="file"
            multiple="multiple"
            accept="video/*,image/*"
            className="upload-banner-input2"
            onChange={(e) => {
              handlePreview(e);
              handleUploadImg(e);
            }}
            style={{
              width: '100%',
              height: '100%',
              // backgroundColor: 'red',
              position: 'absolute',
              top: 0,
              left: 0,
              cursor: 'pointer',
              zIndex: 10,
            }}
          />
          <span
            className="upload-banner-input"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              // transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <CameraIcon color={theme?.modalStyle?.color} />
          </span>
        </div>
        <span>Attach photos and videos</span>
      </div>
      <div className="event-preview-container">
        <div
          className="event-details-container mb-4 pt-5"
          style={theme.modalStyle}
        >
          {/* <div className="event-title mt-3 mb-1">{values?.title}</div> */}
          <div className="event-group">Donation Name</div>
          <div className="event-group">{values?.title}</div>
          {/* <div className="event-group">Organization Name</div> */}
          {/* <div className="event-group">{values.orgName}</div> */}
          <div className="event-group">Target</div>
          <div className="event-group">
            {numberFormat(values.estimatedAmount)}
          </div>
          <div className="event-group">Minimum Amount to Donate</div>
          <div className="event-group">
            {numberFormat(values.minimumAmount)}
          </div>
          <div className="event-group">Description</div>
          <div className="event-group">{values.description}</div>
        </div>
        {/* {edit && values.isClosed === false && (
          <div
            className="d-flex justify-content-center"
            style={{ width: '48%' }}
          >
            <input
              type="checkbox"
              style={{ width: '25px', height: '25px' }}
              className="mx-1 radio radio-light"
              checked={values?.isClosed}
              onChange={(e) => {
                handleFormInput(e.target.checked, 'isClosed');
              }}
            />
            <div style={{ fontSize: '14px' }}>Close Donation</div>
          </div>
        )} */}
      </div>
      {/* <div className="payment-info my-3 mx-auto">
        <div className="mb-1">Payment Info</div>
        <div>
          Waya will take a commission of {productPercentage}% from the payment
          and the rest of the money will be paid into your account at the end of
          post duration or its expirey date.
        </div>
      </div> */}
    </div>
  );
};

export default PublishDonation;
