import socketIO from 'socket.io-client';
// import { NotificationManager } from 'react-notifications';
// import { customBaseUrl } from './http';

export const AppSocket = {};
export const notifications = [{}];

AppSocket.createConnection = async () => {
  if (AppSocket.io?.connected) return; // if there has been a connection before, return
  // open a connection
  const connectionOptions = {
    'force new connection': true,
    reconnectionAttempts: 'Infinity',
    timeout: 10000,
    transports: ['websocket'],
  };

  AppSocket.io = socketIO(
    `${process.env.REACT_APP_CHAT_BASE_URL}?token=${
      localStorage.token
    }&clientId=WAYAGRAM&clientType=${
      localStorage.getItem('TYPE') ?? 'PERSONAL'
    }`,
    connectionOptions
  );

  // console.log(localStorage?.wayaProfileId, 'TOKEN');
  AppSocket.io.emit('login', {
    profileId: localStorage?.wayaProfileId,
    clientId: 'WAYAGRAM',
    clientType: localStorage.getItem('TYPE') ?? 'PERSONAL',
  });

  AppSocket.io.on('me', (user) => {
    console.log('ME', user);
  });

  // listen for connection
  // AppSocket.io.on('connect', () => {
  //   console.log('connected to server');
  // });
  // console.log(AppSocket.io);
  // AppSocket.io.on('user_connected', () => {
  //   console.log('User connected to server');
  // });
};

AppSocket.sendRequest = async (path, payload) => {
  if (AppSocket.io && AppSocket.io.connected === true) {
    AppSocket.io.emit(path, payload);
    // NotificationManager.success(' Sent successfully.', 'Success');
  } else {
    console.log('App disconnected from server');
  }
};

AppSocket.disconnect = () => {
  if (AppSocket) AppSocket.io.disconnect();
};
