import React from 'react';
import { Image } from 'antd';
import { checkExtension } from '../../../utils/validateImage';

const ImagePreview = ({ setVisible, visible, data }) => {
  return (
    <div style={{ display: 'none' }}>
      <Image.PreviewGroup
        preview={{
          visible,
          onVisibleChange: (vis) => setVisible(vis),
        }}
      >
        {/* {data?.map((d) => {
          if (!d?.imageURL?.includes('mp4' || 'webm' || 'wmv')) {
            return (
              <Image
                key={d.id}
                src={d.imageURL || d.content || d.imageUrl || d.fileUrl}
              />
            );
          }

          return null;
        })} */}
        {data?.map((d) => {
          if (
            !checkExtension(
              d?.imageURL || d?.content || d?.imageUrl || d?.fileUrl
            )?.includes('mp4' || 'webm' || 'wmv')
          ) {
            return (
              <Image
                key={d.id}
                src={d.imageURL || d.content || d.imageUrl || d.fileUrl}
              />
            );
          }

          return null;
        })}
      </Image.PreviewGroup>
    </div>
  );
};

export default ImagePreview;
