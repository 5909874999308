import React from 'react';
import { useHistory } from 'react-router';
// import { AnimateKeyframes } from 'react-simple-animate';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import CustomDropdown from '../../Dropdown';
import promoBanner from '../../../../assets/promo-banner.png';

const TopCardContent = ({
  profile,
  handleOption,
  fromLocation,
  userId,
  postId,
  id,
  profileId,
  showMenu,
  kind,
  type,
  isPagePost,
  page,
  isBookmarked,
  username,
  displayName,
  isPromo,
  promoFlyer,
}) => {
  const history = useHistory();

  // useEffect(() => {
  //   console.log('wayagram-profile-top-card', profile);
  // }, [profile]);
  const options =
    fromLocation === 'bookmarks'
      ? [{ name: 'Remove Bookmark', type: 'deletePost', id }]
      : fromLocation === 'event' && profileId === userId
      ? [
          { name: 'Edit Post', type: 'editPost', id: postId },
          {
            name: isBookmarked ? 'Bookmarked' : 'Bookmark',
            type: 'bookmark',
            id: postId,
          },
          { name: 'Invite Members', type: 'invite', id: postId },
        ]
      : profileId === userId && type === 'donation'
      ? [
          { name: 'Edit Post', type: 'editPost', id: postId },
          {
            name: isBookmarked ? 'Bookmarked' : 'Bookmark',
            type: 'bookmark',
            id: postId,
          },
        ]
      : profileId === userId && type !== 'contest' && type !== 'donation'
      ? [
          fromLocation !== 'profileDonation' &&
            kind !== 'donation' && {
              name: 'Delete Post',
              type: 'deletePost',
              id: postId,
            },
          { name: 'Edit Post', type: 'editPost', id: postId },
          {
            name: isBookmarked ? 'Bookmarked' : 'Bookmark',
            type: 'bookmark',
            id: postId,
          },
        ]
      : profileId === userId && type === 'contest'
      ? [{ name: 'Delete Post', type: 'deletePost', id: postId }]
      : [
          {
            name: isBookmarked ? 'Bookmarked' : 'Bookmark',
            type: 'bookmark',
            id: postId,
          },
          { name: `Report ${kind || 'Post'}`, type: 'reportUser', id: postId },
          // { name: 'Block User', type: 'blockUser', id: postId },
          { name: 'Follow User', type: 'followUser', id: postId },
          // { name: 'Mute User', type: 'muteUser', id: postId },
        ];
  const donationOpt =
    kind === 'donation' || fromLocation === 'profileDonation'
      ? [
          { name: 'View Donation', type: 'donation', id: postId },
          //  { name: 'Invite Members', type: 'invite', id: postId },
        ]
      : [];

  return (
    <div className="top-content d-flex">
      <div className="d-flex">
        <img
          src={profile?.avatar || imgPlaceholder}
          alt="gram avatar"
          className="me-3"
          style={{ height: '42px', width: '42px', borderRadius: ' 50%' }}
          onClick={() =>
            userId ? history.push(`/wayagram-profile/${userId}`) : ''
          }
        />
        <div className="me-3 mb-2">
          <span
            className="mb-0 add-cursor"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            aria-hidden="true"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            onClick={() =>
              userId ? history.push(`/wayagram-profile/${userId}`) : ''
            }
          >
            {`${profile?.displayName || displayName || ''}`}
          </span>
          <span
            className="mb-0 add-cursor fw-bold"
            style={{ fontSize: '20px' }}
            aria-hidden="true"
            onClick={() => history.push(`/wayagram-page/${page?.id}`)}
          >
            {isPagePost && ` > ${page?.title}`}
          </span>
          <p className="mb-0 text-muted">
            {kind === 'contest' ? '' : '@'}
            {profile?.username || username || ''}
          </p>
        </div>
      </div>
      {isPromo && (
        // <AnimateKeyframes
        //   play
        //   iterationCount="infinite"
        //   keyframes={['opacity: 0', 'opacity: 1']}
        //   duration={3}
        //   // delay={1}
        // >
        <div
          style={{
            marginLeft: 'auto',
            backgroundImage: `url(${promoFlyer || promoBanner})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 'auto',
            width: '200px',
          }}
        />
        // </AnimateKeyframes>
      )}
      {showMenu && (
        <div className="option-div ml-auto my-auto">
          <div className="dot-3x" style={{ position: 'relative' }}>
            <CustomDropdown
              id={12}
              direction="horizontal"
              handleOption={handleOption}
              options={[...options, ...donationOpt]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopCardContent;
