import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { PostWrapper } from './style';
import sharePostIcon from '../../../assets/image/share-posts.svg';
import pollIcon from '../../../assets/image/polls-post.svg';
import donationIcon from '../../../assets/image/donation-post.svg';
import imgPlaceholder from '../../../assets/image/default-dp.svg';
import CreatePostModal from '../../shared/Modals/Post/CreatePost';
import CreatePoll from '../../shared/Modals/Post/CreatePoll';
import DonationModal from '../../shared/Modals/Post/DonationModal';
import { userTheme } from '../../../store/initialState';

const QuickPostCard = ({ profile }) => {
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [showCreatePoll, setShowCreatePoll] = useState(false);
  const theme = useRecoilValue(userTheme);
  return (
    <PostWrapper style={theme?.modalStyle}>
      <div className="d-flex">
        <img
          src={profile?.avatar || imgPlaceholder}
          alt="gram avatar"
          className="mx-2"
          style={{ height: '42px', width: '42px', borderRadius: ' 50%' }}
        />
        <div className="mb-3 mx-1 post-container" style={{ width: '80%' }}>
          <input
            className="w-100 form-control search-input"
            placeholder="What's on your mind"
            onClick={() => setShowCreatePost(true)}
          />
        </div>
      </div>
      <div className="line" style={{ width: '80%' }} />
      <div
        className="d-flex m-3 mx-5 justify-content-between text-center"
        style={{ width: '85%' }}
      >
        <div
          className="d-flex align-items-center add-cursor"
          onClick={() => setShowCreatePost(true)}
        >
          <img src={sharePostIcon} alt="post" />
          <span className="post-text">Post photo/Video</span>
        </div>
        <div
          className="d-flex align-items-center add-cursor"
          onClick={() => setShowDonation(true)}
        >
          <img src={donationIcon} alt="post" />
          <span className="post-text">Create Donation</span>
        </div>
        <div
          className="d-flex align-items-center add-cursor"
          onClick={() => setShowCreatePoll(true)}
        >
          <img src={pollIcon} alt="post" />
          <span className="post-text">Create poll</span>
        </div>
      </div>
      {showCreatePost && (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="create"
          type="user"
        />
      )}
      {showCreatePoll && (
        <CreatePoll
          showModal={showCreatePoll}
          hideModal={setShowCreatePoll}
          id="create-post-modal"
          mode="create"
          type="user"
        />
      )}
      {showDonation && (
        <DonationModal
          showModal={showDonation}
          hideModal={setShowDonation}
          edit={false}
          type="user"
        />
      )}
    </PostWrapper>
  );
};

export default QuickPostCard;
