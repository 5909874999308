import styled from 'styled-components';
import device from '../../../utils/device';

export const ListContainer = styled.div`
  background-color: ${(props) =>
    props.mode === 'light' ? '#f8f8f8' : '#1c1c1c'} !important;
  color: ${(props) => props.color} !important;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  .header {
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 15px;
    font-size: 1em;
    line-height: 24px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
  }
  .profile-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-div {
      width: 3.2em;
      height: 3.2em;
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        width: 90%;
        height: 90%;
        background-size: cover;
      }
    }
  }
  .action-btn {
    flex-basis: 20%;
    width: 5em;
    .btn {
      width: 100%;
      height: 35px;
    }
  }
  .show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #064a72;
    font-weight: bold;
    font-size: 0.85em;
    padding: 10px 20px;
    border-top: 1px solid #f2f2f2;
    cursor: pointer;
  }
`;

export const ListWrapper = styled.div`
  .header {
    border-bottom: 1px solid #f2f2f2;
    padding: 15px 10px;
    font-size: 16px;
    line-height: 24px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
  }
  .inner-p-unread {
    background: #8080801f !important;
    border-radius: 0 !important;
  }
  .profile-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-div {
      width: 50px;
      height: 50px;
      img {
        width: 90%;
        height: 90%;
        background-size: cover;
        border-radius: 50%;
      }
    }
  }
  .action-btn {
    width: 120px;
    .btn {
      width: 100%;
      height: 35px;
    }
  }
  @media ${device.maxmobileL} {
    .action-btn {
      width: 40% !important;
    }
    .profile-div {
      width: 60%;
      color: red;
      justify-content: flex-start;
    }
    .profile-c-div {
      color: black !important;
    }
  }
`;

export const GramCard = styled.div`
  width: 100%;
  border: 1px solid #d4d4d4;
  margin-bottom: 0.4em;
  a {
    text-decoration: none;
  }
  .option-div {
    img {
      height: 25px;
    }
  }

  .top-content {
    .name-div {
      padding-left: 30px;
    }
  }
  .repost {
    border: 1px solid #d4d4d4;
  }
  .middle-content {
    .donation-description-box {
      display: flex;
      flex-direction: column;
      .donation-text {
        background: #27ae60;
        box-shadow: 0px 10px 75px rgba(147, 147, 147, 0.04);
        border-radius: 0.75em;
        padding: 2em;
        height: 8em;
        font-weight: 800;
        font-size: 2.5em;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .donation-progress {
        color: #27ae60;
        font-size: 0.85em;
      }
    }
    .img-div {
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: center;
    .bottom-content-container {
      width: 90%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 12px;
      .comment-div,
      .like-div,
      .share-div,
      .repost-div {
        height: 20px;
        display: flex;
        align-items: center;
      }
      .donation-button {
        min-width: auto;
        /* height: 2.4em; */
        padding: 0.6em 1em;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 800;
        font-size: 0.85em;
        color: #fff;
        background: #ff6634;
        border-radius: 4px;
        @media ${device.maxmobileL} {
          width: 80px;
          font-size: 9px;
        }
      }
    }
  }
`;

export const CommentCard = styled.div`
  width: 100%;
  border-top: #e5e5e5 1px solid !important;
  border-bottom: #e5e5e5 1px solid !important;

  .option-div {
    img {
      height: 25px;
    }
  }

  .top-content {
    .name-div {
      padding-left: 30px;
    }
  }

  .middle-content {
    .donation-description-box {
      display: flex;
      flex-direction: column;
      .donation-text {
        background: #27ae60;
        box-shadow: 0px 10px 75px rgba(147, 147, 147, 0.04);
        border-radius: 0.75em;
        padding: 2em;
        height: 8em;
        font-weight: 800;
        font-size: 2.5em;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .donation-progress {
        color: #27ae60;
        font-size: 0.85em;
      }
    }
    .img-div {
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
    .middle-content-description {
      width: 100%;
      margin-top: 0.5em;
    }
  }

  .bottom-content {
    display: flex;
    justify-content: center;
    .bottom-content-container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .comment-div {
        height: 20px;
        display: flex;
        align-items: center;
        width: 25%;
      }
      .donation-button {
        min-width: auto;
        /* height: 2.4em; */
        padding: 0.6em 1em;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 800;
        font-size: 0.85em;
        color: #fff;
        background: #ff6634;
        border-radius: 4px;
        @media ${device.maxmobileL} {
          width: 80px;
          font-size: 9px;
        }
      }
    }
  }
`;

export const TopWrapper = styled.div`
  background-color: ${(props) => props.bgColor.background} !important;
  color: ${(props) => props.bgColor.color} !important;
  margin-bottom: 10px;
  .banner-div {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .img {
      background-size: cover;
      height: 130px;
      width: 100%;
    }
  }
  .avatar-section {
    position: relative;
    .img {
      height: 110px;
      width: 110px;
      position: absolute;
      left: 16px;
      bottom: 10px;
    }
    .btn {
      // background: transparent;
      // color: inherit !important;
      // border: 1px solid #e0e0e0 !important;
      // margin-right: 20px;
      // margin-top: 20px;
    }
  }
  .info-section {
    .bio {
      width: 50%;
      font-size: 12px;
      line-height: 14px;
    }
    .count {
      .s-text {
        font-size: 13px;
      }
    }
  }
  .option-div {
    img {
      height: 25px;
    }
  }
`;

export const DropDownWrapper = styled.div`
  background-color: ${(props) => props.bgColor.background} !important;
  color: ${(props) => props.bgColor.color} !important;
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  position: absolute;
  z-index: 5;
  top: 35px;
  width: 100%;
  .profile-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-div {
      width: 40px;
      height: 40px;
      margin-right: 15px;
      img {
        width: 90%;
        height: 90%;
        background-size: cover;
      }
    }
  }
`;

export const PostGramContainer = styled.div`
  .inner-wrapper {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* display: flex; */
    margin: 0 30px;
    background: #fff;
    border-radius: 0 0 8px 8px;
    .wrapper {
      display: flex;
      .left-gram {
        width: 20%;
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        .profile-image {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }
      .right-gram {
        padding: 10px 40px 10px 0;
        .inner-right {
          display: flex;
          font-weight: 12px;
          margin-top: 12px;
          h6 {
            margin-right: 20px;
          }
          .handle {
            font-weight: bolder;
            color: grey;
          }
        }
        .gram-content {
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: justify;
        }
        .post-image {
          width: 100%;
          border-radius: 8px;
        }
        .gram-icon {
          display: flex;
          padding: 20px 0;
          .icon-container {
            width: 25%;
            .icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .second-wrapper {
      border-top: 1px solid lightgrey;
    }
  }
`;

export const Container = styled.div`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme[props.backgroundColor] || props.backgroundColor
      : props.theme.white};
  box-shadow: ${(props) => props.boxShadow};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.white};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'wrap')};
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.content};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
`;
Container.defaultProps = {
  width: '300px',
  height: '150px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
};

export const PostBody = styled.p`
  white-space: pre-wrap;
`;
