import { useRecoilValue } from 'recoil';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown } from 'react-icons/io';
import * as DropDown from './styles';
// import verticalIcon from '../../../assets/image/vertical-icon.png';
import { userTheme } from '../../../store/initialState';

const CustomDropdown = ({
  handleOption,
  options,
  direction,
  notAbsolute,
  left,
  right = 0,
}) => {
  const theme = useRecoilValue(userTheme);
  // const time = props.createdAt ? moment(props.createdAt).fromNow() : moment(new Date().getTime()).fromNow()

  /**
   * here i am using useState to toggle the dropdown
   */
  const [dropDown, setDropDown] = useState(false);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter = (ref) => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) =>
      ref.current && !ref.current.contains(event.target)
        ? setDropDown(false)
        : null;

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const momentOptions = (
    <div>
      {options?.map((item) => (
        <DropDown.MomentOptions
          className="mb-2 d-flex justify-content-center align-items-center text-gray"
          onClick={() => handleOption(item.type, item.id)}
          key={Math.random()}
          mode={theme.mode}
        >
          {item.name === 'Cancel' ? (
            <span
              className="title ml-2 text-primary"
              onClick={() => setDropDown(!dropDown)}
              role="button"
              tabIndex={0}
              aria-hidden="true"
            >
              {item.name}
            </span>
          ) : (
            <div className="title" onClick={() => setDropDown(!dropDown)}>
              {item.name}
            </div>
          )}
        </DropDown.MomentOptions>
      ))}
    </div>
  );

  const style = notAbsolute
    ? {}
    : direction === 'drop'
    ? {
        position: 'absolute',
        right: '-5px',
        bottom: '12px',
        top: 10,
        zIndex: 60,
      }
    : { position: 'absolute', right: '-5px', bottom: '12px', zIndex: 60 };

  return (
    <DropDown.MomentDropdown ref={wrapperRef} style={style}>
      <DropDown.MomentToggle onClick={() => setDropDown(!dropDown)}>
        <span
          style={{ fontSize: 10 }}
          className={`options ml-auto ${dropDown ? 'active' : ''}`}
        >
          {direction === 'vertical' ? (
            // <i className="mdi mdi-dots-vertical" />
            <IoIosArrowDown size={20} />
          ) : direction === 'drop' ? (
            <IoIosArrowDown size={20} />
          ) : (
            // <i className="mdi mdi-menu-down" />
            // <img
            //   style={{ width: 50, height: 50 }}
            //   src={verticalIcon}
            //   alt="more"
            // />
            <IoIosArrowDown size={20} />
          )}
        </span>
      </DropDown.MomentToggle>

      {dropDown ? (
        <DropDown.MomentMenu
          mode={theme.mode}
          left={left}
          right={right}
          className="py-1"
        >
          <span style={{ textAlign: 'center' }}>{momentOptions}</span>
        </DropDown.MomentMenu>
      ) : null}
    </DropDown.MomentDropdown>
  );
};

CustomDropdown.defaultProps = {
  direction: 'vertical',
};

CustomDropdown.propTypes = {
  handleOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  direction: PropTypes.string,
};

export default CustomDropdown;
