import React from 'react';
import PropTypes from 'prop-types';
import { GroupAndPageButton, UploadPostButton } from './style';

export const UploadPost = (props) => {
  const { showPost } = props;
  return (
    <div>
      <UploadPostButton
        onClick={() => showPost(true)}
        className="d-flex justify-content-center align-items-center hover-card"
      >
        <i className="mdi mdi-plus" />
      </UploadPostButton>
    </div>
  );
};

UploadPost.propTypes = {
  showPost: PropTypes.func.isRequired,
};

export const example = () => {
  return null;
};

export const GroupAndPageUpload = (props) => {
  const { showPost } = props;
  return (
    <div>
      <GroupAndPageButton
        onClick={() => showPost(true)}
        className="d-flex justify-content-center align-items-center hover-card"
      >
        <i className="mdi mdi-plus" />
      </GroupAndPageButton>
    </div>
  );
};
