import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Navbar from './navbar';
import Sidebar from './sidebar';
import './layout.scss';
import MobileSidebar from './mobileSidebar';
import { ChildWrapper } from './style';
import { userTheme } from '../../../store/initialState';

export default function Index(props) {
  const history = useHistory();
  const { children, currentPage, pageName, goBack, showName } = props;
  const theme = useRecoilValue(userTheme);
  return (
    <div
      className={`${theme.mode === 'dark' ? 'dark-theme' : 'light-theme'}`}
      style={{ height: '100%' }}
    >
      {/* <Navbar history={routeHistory.history} currentPage={currentPage} /> */}
      <Navbar
        history={history}
        currentPage={currentPage}
        pageName={pageName}
        goBack={goBack}
        showName={showName}
      />
      <Sidebar currentPage={currentPage} history={history} theme={theme} />
      <ChildWrapper bgColor={theme.style}>
        <Scrollbars autoHide>
          <div className="layout-container px-1">{children}</div>
        </Scrollbars>
      </ChildWrapper>
      <MobileSidebar currentPage={currentPage} />
    </div>
  );
}

Index.propTypes = {
  // bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string.isRequired,
};
