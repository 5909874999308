import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
//  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
//  import PerfectScrollbar from 'react-perfect-scrollbar';
// import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
// import ReplyOutlined from '@mui/icons-material/ReplyOutlined';
// import { DateRange } from '@material-ui/icons';
// import medal from '../../../../assets/image/Medal First Place.png';
//  import candidate from '../../../../assets/image/right-user.svg';
//  import Button from '../../../shared/Button';
import Layout from '../../../shared/Layout';
import RightSidebar from '../RightSidebar';
import '../index';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import { customBaseUrl, httpGet } from '../../../../action/http';
// import church from '../../../../assets/image/church-one.svg';
import placeHolderImage from '../../../../assets/image/default-dp.svg';
//  import OtherDropdown from '../../../shared/Dropdown/OtherDropdown';
// import CandidateCard from './candidatesCards';

// import phone from '../../../../assets/image/bg-section4.png';

const Contestants = (props) => {
  const { profileId, contestId, state } = useParams();
  const [details, setDetails] = useState([]);
  //  const [showSharePost, setShowSharePost] = useState(false);
  //  const [states, setStates] = useState([]);

  const handleGetContestDetails = async () => {
    // const search = `all-posts/search-products?query=${value}`;
    const uri = state
      ? `contests/contestants?contestId=${contestId}&state=${state}&status=ACTIVE`
      : `contests/contestants?contestId=${contestId}&status=ACTIVE`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      const contest = res?.data;
      setDetails(contest);
      // const uniqueValues = contest
      //   .map((item) => item.state)
      //   .filter(
      //     (value, index, currentValue) => currentValue.indexOf(value) === index
      //   );
      //  setStates(uniqueValues);
      //  setLoading(false);
    }
  };
  //   const handleLike = async () => {
  //     const payload = {
  //       contestId,
  //       profileId,
  //     };
  //     const res = await httpPost(
  //       `/contests/like`,
  //       payload,
  //       customBaseUrl.contestUrl
  //     );
  //     if (res?.status) {
  //       handleGetContestDetails();
  //     } else {
  //       console.log(res);
  //     }
  //   };
  //   const dropDownOptions = [
  //     'Edit',
  //     'Manage Contestants',
  //     'Manage States',
  //     'Manage Dashboard',
  //     'Delete Contest',
  //     'Share Contest',
  //     'Invite Contestants',
  //   ];
  //   const handleDropdownAction = (item, row) => {
  //     console.log({ item, row });
  //   };
  useEffect(() => {
    console.log({ profileId, contestId });
    handleGetContestDetails();
  }, []);
  return (
    <Layout route={props} currentPage="contest" pageName="" showName>
      {state ? (
        <div className="card mb-3 col-md-8 bg-white border-primary px-4 py-4">
          <div className="row">
            <div className="col-md-4 ml-3 py-2 text-center">
              <img
                src={`https://social-waya-bucket.s3.eu-west-1.amazonaws.com/${state}`}
                className="w-10 h-10"
                width={100}
                height={100}
                alt="..."
              />
            </div>
            <div className="col-md-8">
              {state.toLowerCase() === 'abuja'
                ? state.toUpperCase()
                : `${state?.toUpperCase()} STATE`}
              {/* <p>
                <div>0 votes</div>
                <div>Qualified contestants</div>
              </p> */}
            </div>
          </div>
        </div>
      ) : (
        // <div className="card mb-3 col-md-8 bg-white border-primary px-4 py-4">
        //   <div className="fw-bold row">
        //     <div className="col-md-3">All states:</div>
        //     <div className="col-md-3 text-start">{states.length}</div>
        //   </div>
        //   <div className="row">
        //     <div className="col-md-3">All :</div>
        //     <div className="col-md-3 text-start">0</div>
        //   </div>
        //   <div className="row">
        //     <div className="col-md-3">All contestants:</div>
        //     <div className="col-md-3 text-start">{details?.length ?? 0}</div>
        //   </div>
        // </div>
        'Candidates'
      )}
      <div className="row wayagram-div">
        <div className="col-md-8 col-sm-12 col-xs-12">
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-5">
            {details?.map((e) => (
              <div key={e} className="card mb-3 max-width: 540px;">
                <div
                  className="row shadow-lg bg-white rounded mx-2"
                  style={{ fontSize: 10 }}
                >
                  <div className="col-md-4 ml-3 py-2 text-center">
                    <img
                      src={e?.miscellaneous?.contestPhoto ?? placeHolderImage}
                      className="w-10 h-10"
                      width={200}
                      height={200}
                      alt="..."
                    />
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="row mt-2">
                        <div className="col-md-8">
                          {' '}
                          <span className="fw-bold">
                            {`${e?.firstName} ${e?.lastName}`}
                          </span>
                          <p className="col">
                            <span className="mt-0">Biography: {e?.bio}</span>
                            <br />
                            <span className="mt-2">
                              State:{' '}
                              {e?.state.charAt(0).toUpperCase() +
                                e?.state.slice(1)}
                            </span>
                            <br />
                            <span className="mt-2">
                              city:{' '}
                              {e?.city.charAt(0).toUpperCase() +
                                e?.city.slice(1)}
                            </span>
                            <br />
                            <span className="mt-1">
                              Church: {e?.affiliationName}
                            </span>
                            <br />
                            <span className="mt-1">
                              No of vote: {e?.voteCounts}
                            </span>
                            <br />
                            <span className="mt-1">
                              Date joined: {moment(e?.createdAt).format('LL')}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-4 text-end py-1">
                          <label
                            className={
                              e?.status === 'ACTIVE'
                                ? `bg-success text-white px-3 py-1`
                                : `bg-danger text-white py-1`
                            }
                          >
                            {e?.status}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 text-end">
                        <Link
                          to={`/wayagram-profile/contest/${e?.email}/${e?.contestId}/${e?.id}`}
                        >
                          <button
                            className="btn round bg-primary text-white"
                            type="button"
                          >
                            View Page
                          </button>
                        </Link>
                      </div>
                      {/* <div className="col-md-4 text-center">
                        <div
                          className="share-div col"
                          style={{ position: 'relative', fontSize: 16 }}
                        >
                          <i
                            className="mdi mdi-share-variant"
                            role="button"
                            tabIndex={0}
                            aria-hidden="true"
                            // onClick={() => {
                            //   setShowSharePost(!showSharePost);
                            //   setSelectedPostId(postId);
                            // }}
                          />
                          {showSharePost ? (
                            <OtherDropdown
                              hide={setShowSharePost}
                              options={[
                                { name: 'Share to Twitter', type: 'twitter' },
                                { name: 'Share to Facebook', type: 'facebook' },
                                { name: 'Send in Whatsapp', type: 'whatsapp' },
                                { name: 'Send in Mail', type: 'mail' },
                                { name: 'Copy link', type: 'link' },
                              ]}
                              // postId={postId}
                              // handleClick={(item) => handleSharePost(item)}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <RightSidebar isSearch />
      </div>
    </Layout>
  );
};

export default Contestants;
