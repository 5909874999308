import React, { useEffect, useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { Modal, ModalHeader } from 'reactstrap';
import '../index';
import { capFirstWord } from '../../../../utils/helper';

const ContestantDetails = (props) => {
  const {
    data,
    setShowMore,
    reason,
    setReason,
    handleDecline,
    handleAcceptReject,
    isAdmin,
  } = props;
  //    const [showSharePost, setShowSharePost] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  useEffect(() => {
    console.log({ data });
  }, []);
  return (
    <div className="row wayagram-div">
      <Modal
        isOpen={showDeclineModal}
        toggle={() => {
          setShowDeclineModal(false);
          // setPreview([]);
        }}
        centered
      >
        <ModalHeader
          className="text-center"
          toggle={() => setShowDeclineModal(false)}
        >
          <span>Reason for Declining</span>
        </ModalHeader>
        <div style={{ margin: 10, width: '100%', textAlign: 'center' }}>
          <textarea
            onChange={(e) => setReason(e.target.value)}
            className="px-3 border w-75"
            value={reason}
          />
        </div>
        <button
          className="btn btn-primary px-2 py-3"
          type="button"
          onClick={handleDecline}
        >
          Continue
        </button>
      </Modal>
      <div className="col-md-9 col-sm-12 col-xs-12 px-3 mx-3">
        <div className="row">
          {isAdmin ? (
            <ArrowBack
              style={{ fontSize: 40 }}
              onClick={() => setShowMore(false)}
            />
          ) : (
            ''
          )}
          <div className=" text-center">
            <button
              type="button"
              className={`btn ${!isMedia ? 'bg-primary' : ''} border`}
              onClick={() => setIsMedia(false)}
            >
              Details
            </button>
            <button
              type="button"
              className={`btn ${isMedia ? 'bg-primary' : ''} border`}
              onClick={() => setIsMedia(true)}
            >
              Media
            </button>
          </div>
          <div className="text-center my-3">
            Application Status:
            {data?.status === 'ACTIVE' ? ' APPROVED' : data?.status}
          </div>
        </div>
        {!isMedia ? (
          <>
            <div className="mt-4 mx-auto row rounded search-container shadow-lg py-2 px-3">
              <div className="col-md-6">
                <label className="form-label">
                  <span className="fw-bold">First Name: </span>
                  {capFirstWord(data?.firstName)}
                </label>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Last Name: {capFirstWord(data?.lastName)}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">Email: {data?.email}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">
                  Phone Number: {data?.phoneNumber}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">State: {data?.state}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">Lga: {data?.lga}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">City: {data?.city}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">
                  Marital Status: {data?.maritalStatus}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">DOB: {data?.dob}</label>
              </div>
              <div className="col-md-12 mt-3">
                <label className="form-label">
                  Name of School: {data?.affiliationName}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">
                  School Address: {data?.affiliationAddress}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">Faculty: {data?.faculty}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">
                  Department: {data?.department}
                </label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">Level: {data?.level}</label>
              </div>
              <div className="col-md-6 mt-3">
                <label className="form-label">Talent: {data?.talent}</label>
              </div>
              <div className="col-md-12 mt-3">
                <label className="form-label">Biography: {data?.bio}</label>
              </div>
            </div>
          </>
        ) : (
          <div
            className={`mt-4 mx-auto ${
              isAdmin ? 'row d-flex' : 'col'
            } flex align-items-start rounded search-container shadow-lg py-2 px-3`}
          >
            {data?.miscellaneous?.contestPhoto && (
              <div
                className="my-1 col"
                style={{ width: 220, height: 400, textAlign: 'center' }}
              >
                <label className="mt-5">Contest Photo</label>
                <div
                  style={{
                    position: 'relative',
                    display: 'block',
                    maxHeight: 300,
                    maxWidth: 200,
                  }}
                  className="container"
                >
                  <img
                    src={data?.miscellaneous?.contestPhoto[0]}
                    alt="img"
                    style={{ display: 'block' }}
                    className="img-fluid"
                  />
                  <a
                    href={data?.miscellaneous?.contestPhoto[0]}
                    download
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fas fa-download"
                      alt="icon"
                      style={{ position: 'absolute', top: 10, right: 20 }}
                    />
                  </a>
                </div>
              </div>
            )}
            {data?.miscellaneous?.idCard && (
              <div
                className="my-1 col"
                style={{ width: 220, height: 400, textAlign: 'center' }}
              >
                <label className="mt-5">ID Card</label>
                <div
                  style={{
                    position: 'relative',
                    display: 'block',
                    maxHeight: 300,
                    maxWidth: 200,
                  }}
                  className="container"
                >
                  <img
                    src={data?.miscellaneous?.idCard[0]}
                    className="img-fluid"
                    alt="img"
                    style={{ display: 'block' }}
                  />

                  <a
                    href={data?.miscellaneous?.idCard[0]}
                    rel="noopener noreferrer"
                    download
                  >
                    <i
                      className="fas fa-download"
                      alt="icon"
                      style={{ position: 'absolute', top: 10, right: 20 }}
                    />
                  </a>
                </div>
              </div>
            )}
            {data?.miscellaneous?.coverPhoto && (
              <div
                className="my-1 col"
                style={{ width: 220, height: 400, textAlign: 'center' }}
              >
                <label className="mt-5 text-center">Cover Photo</label>
                <div
                  style={{
                    position: 'relative',
                    display: 'block',
                    maxHeight: 300,
                    maxWidth: 200,
                  }}
                  className="container"
                >
                  <img
                    src={data?.miscellaneous?.coverPhoto[0]}
                    className="img-fluid object-fit-none border rounded"
                    alt="img"
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                    }}
                  />
                  <a
                    href={data?.miscellaneous?.coverPhoto[0]}
                    rel="noopener noreferrer"
                    download
                  >
                    <i
                      className="fas fa-download"
                      alt="icon"
                      style={{ position: 'absolute', top: 10, right: 20 }}
                    />
                  </a>
                </div>
              </div>
            )}
            {data?.miscellaneous?.coverVideo && (
              <div
                className="my-1 col"
                style={{ width: 220, height: 300, textAlign: 'center' }}
              >
                <label className="mt-5">Contest Video</label>
                <video width="200" height="300" controls className="img-fluid">
                  <source src={data?.miscellaneous?.coverVideo[0]} />
                  <track
                    src="captions_en.vtt"
                    kind="captions"
                    label="english_captions"
                  />
                </video>
              </div>
            )}
          </div>
        )}
        {data?.status === 'PENDING' && isAdmin ? (
          <div className="text-center mt-3">
            <button
              onClick={() => handleAcceptReject('APPROVE', data)}
              className="btn-primary btn mx-2"
              type="button"
            >
              Accept
            </button>
            <button
              onClick={() => setShowDeclineModal(true)}
              className="btn-danger btn"
              type="button"
            >
              Decline
            </button>
          </div>
        ) : (
          ''
        )}
        <div style={{ height: 400 }} />
      </div>
    </div>
  );
};

export default ContestantDetails;
