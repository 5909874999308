import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'antd';
// import moment from 'moment';

function DonationTarget(props) {
  const { handleFormInput, values, theme } = props;

  return (
    <div>
      <div className="input-container">
        <input
          name="estimatedAmount"
          min={100}
          value={values.estimatedAmount}
          type="number"
          onChange={(e) => {
            handleFormInput(e.target.value, 'estimatedAmount');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="N100,000,000"
        />
        <span>
          How much are you trying to raise
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <select
          name="displayTotalDonation"
          value={values.displayTotalDonation}
          onChange={(e) => {
            handleFormInput(e.target.value ?? false, 'displayTotalDonation');
          }}
          placeholder="Hide Contributions"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        >
          <option value="">Select option</option>
          <option value={false}>Hide Contributions</option>
          <option value>Show Contributions</option>
        </select>
        <span>
          Hide or display contributions in real time
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <DatePicker
          className={`w-100 border-0 border-bottom bg-${
            theme.mode === 'dark' ? 'secondary' : 'white'
          }`}
          name="donationExpiresAt"
          placeholderText="Expiry Date"
          showTimeSelect
          selected={new Date(values.donationExpiresAt)}
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm a"
          onChange={(date) => {
            handleFormInput(date, 'donationExpiresAt');
          }}
        />
        <span>
          Date and time when donation expires
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className=" row mt-2 mb-3">
        <div className="col-6">
          <div className="input-container">
            <Tooltip title="minimum amount should not be less than N100">
              <input
                name="minimumAmount"
                min={1}
                value={values.minimumAmount}
                type="number"
                onChange={(e) => {
                  handleFormInput(e.target.value, 'minimumAmount');
                }}
                className={`bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                placeholder="N500"
              />
            </Tooltip>
            <span>
              Minimum amount a user can donate
              <span className="text-primary"> *</span>
            </span>
          </div>
        </div>
        <div className="col-6">
          <div className="input-container">
            <select
              name="displayNumberOfDonors"
              value={values.displayNumberOfDonors}
              onChange={(e) => {
                handleFormInput(e.target.checked, 'displayNumberOfDonors');
              }}
              className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
              placeholder="Hide Donors"
            >
              <option value={0}>Hide Donors</option>
              <option value={1}>Show Donors</option>
            </select>
            <span>
              Hide or display donors name in real time
              <span className="text-primary"> *</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

DonationTarget.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default DonationTarget;
