import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

// import { trxInfo } from './data';

import './makeDonation.scss';
import successIcon from '../../../../../assets/images/trxSuccess.png';
import failureIcon from '../../../../../assets/images/trxFailure.png';
import { WayagramContext } from '../../../../../store/context/WayagramContext';

function TransactionStatusModal(props) {
  const { showModal, hideModal } = props;
  // console.log({ showModal, hideModal }, 'HERE');
  const { setReload, reload } = useContext(WayagramContext);
  return (
    <Modal
      isOpen={showModal?.status}
      id="donation-status"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <img
          src={showModal?.type === 'success' ? successIcon : failureIcon}
          alt="success"
        />
        <p>{showModal?.type}</p>
        <div>
          <p className="px-5">{showModal?.message}</p>
        </div>
        <div className="modal-foot">
          {showModal?.type === 'success' ? (
            <div className="modal-foot-group">
              <div
                onClick={() => {
                  showModal?.action();
                  setReload(!reload);
                }}
                className="box"
              >
                Go back to Home
              </div>
              <div
                onClick={() => showModal?.retry()}
                className="box active-option"
              >
                Share
              </div>
            </div>
          ) : (
            <div className="modal-foot-group">
              <div onClick={showModal?.action} className="box">
                {showModal?.actionName}
              </div>
              <div
                onClick={() => showModal?.retry()}
                className="box active-option"
              >
                Try again
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default TransactionStatusModal;

export function AlertModal(props) {
  const { showModal, hideModal, action, btnTitle, title, message, id } = props;
  const handleAction = () => {
    action({ id }, 'unblock');
    hideModal(false);
  };
  return (
    <Modal
      isOpen={showModal}
      id="donation-status"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <img src={successIcon} alt="success" />
        <p>{title}</p>
        <div>
          <p className="px-5">{message}</p>
        </div>
        <div className="modal-alert-foot">
          {/* <div className="modal-foot-group"> */}
          <div onClick={handleAction} className="box">
            {btnTitle}
          </div>
          {/* </div> */}
        </div>
      </ModalBody>
    </Modal>
  );
}

export function DeleteModal(props) {
  const { showModal, hideModal, handleDelete, isOwner, title, message } = props;
  return (
    <Modal
      isOpen={showModal}
      id="donation-status"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <img src={failureIcon} alt="success" />
        <p>{title}</p>
        <div>
          <p className="px-5">{message}</p>
        </div>
        <div className="modal-foot">
          {title === 'Delete message' ? (
            <div className="modal-foot-group">
              {isOwner ? (
                <>
                  <div
                    onClick={() => handleDelete(true, 'delete-for-everyone')}
                    className="box"
                  >
                    Delete For Everyone
                  </div>
                  <div
                    onClick={() => handleDelete(true, 'delete-for-me')}
                    className="box active-option"
                  >
                    Delete For Me
                  </div>
                </>
              ) : (
                <div
                  onClick={() => handleDelete(true, 'just-delete')}
                  className="box active-option text-center mx-auto"
                >
                  Delete
                </div>
              )}
            </div>
          ) : (
            <div className="modal-foot-group">
              <div
                onClick={() => {
                  handleDelete(true, 'leave');
                  hideModal(false);
                }}
                className="box active-option"
              >
                Leave
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export function ComingSoon({ showModal, hideModal }) {
  return (
    <Modal
      isOpen={showModal}
      id="donation-status"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <img src={failureIcon} alt="success" />
        <p>This Feature is currently in development process</p>
        <div>
          <p className="px-5">Coming Soon</p>
        </div>
        <div className="modal-foot">
          <div className="modal-foot-group">
            <div
              onClick={() => {
                hideModal(false);
              }}
              className="box active-option"
            >
              Ok
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
