import React, { useState } from 'react';
import swal from 'sweetalert';
//  import validateImage from '../../../../../utils/validateImage';
import cameraImg from '../../../../../assets/image/camera_alt.png';
import avatarImg from '../../../../../assets/image/default-dp.svg';
import { CameraIcon } from '../../../Icons';

const PublishGroup = (props) => {
  const { setPostData, postData, theme } = props;
  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);

  const fileValidation = (fileInput) => {
    const filePath = fileInput?.type;
    console.log({ filePath });
    //  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!filePath.includes('image/')) {
      // console.log('Please upload file .jpeg/.jpg/.png/.gif only.');
      // fileInput.value = '';
      return false;
    }
    return true;
  };
  const handlePreview = (e, name) => {
    console.log(previewImage);
    const imageData = e.target.files[0];
    const validFormat = fileValidation(imageData);
    console.log({ validFormat });
    if (!validFormat)
      return swal('Oops!', 'Please upload file image only.', 'error');
    fileValidation(imageData);
    console.log({ validFormat });
    if (validFormat) {
      if (name === 'image') {
        setPostData({ ...postData, image: imageData });
      } else {
        setPostData({ ...postData, headerImage: imageData });
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'image') {
          setPreviewImage(reader.result);
        } else {
          setPreviewHeaderImage(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `Invalid file type`, 'error');
    }
    return name;
  };

  return (
    // <div id="wayagram-profile-modal" style={{ width: '100%' }}>
    //   <div
    //     className="banner-div"
    //     style={{
    //       backgroundImage: `url(${previewHeaderImage || postData.coverImage})`,
    //       backgroundSize: 'cover',
    //       height: '140px',
    //       backgroundPosition: 'center',
    //       border: 1,
    //       borderStyle: 'dotted',
    //       borderTopLeftRadius: 14,
    //       borderTopRightRadius: 14,
    //       margin: '0px 2px',
    //       width: '100%',
    //     }}
    //   >
    //     <span className="upload-banner-input">
    //       <CameraIcon color={theme?.modalStyle?.color} />
    //     </span>
    //     <input
    //       type="file"
    //       accept="image/*"
    //       className="upload-banner-input2"
    //       onChange={(e) => handlePreview(e, 'headerImage')}
    //     />
    //     {/* <p>Click here to upload cover photo</p> */}
    //   </div>
    //   <div className="avatar-section d-flex mb-4">
    //     <img
    //       style={{ width: 100, height: 100 }}
    //       src={previewImage || avatarImg}
    //       alt="avatar"
    //     />
    //     <img src={cameraImg} className="upload-input " alt="camera" />
    //     <input
    //       type="file"
    //       className="upload-input2"
    //       accept="image/*"
    //       onChange={(e) => handlePreview(e, 'image')}
    //     />
    //   </div>
    // </div>
    <div id="wayagram-profile-modal">
      <div
        className="banner-div"
        style={{
          backgroundImage: `url(${previewHeaderImage || postData.coverImage})`,
          backgroundSize: 'cover',
          height: '140px',
          backgroundPosition: 'center',
          border: 1,
          borderStyle: 'dotted',
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
        }}
      >
        {/* <img src={cameraImg} alt="camera" height={30} width={30} />
        <p>Click here to upload cover photo</p> */}
        <span className="upload-banner-input">
          <CameraIcon color={theme?.modalStyle?.color} />
        </span>
        <input
          type="file"
          accept="image/*"
          className="upload-banner-input2"
          onChange={(e) => {
            handlePreview(e, 'headerImage');
            e.target.value = null;
          }}
        />
      </div>
      <div className="avatar-section mt-4">
        <img
          className="rounded"
          style={{ width: 100, height: 100 }}
          src={previewImage || avatarImg}
          alt="avatar"
        />
        <img src={cameraImg} className="upload-input" alt="camera" />
        <input
          type="file"
          accept="image/*"
          className="upload-input2"
          onChange={(e) => {
            handlePreview(e, 'image');
            e.target.value = null;
          }}
        />
      </div>
    </div>
  );
};

export default PublishGroup;
