import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

export const WalletContext = createContext();

export const WalletContextProvider = (props) => {
  const { children } = props;
  const [walletList, setWalletList] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [reload, setReload] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState({});

  const getWalletList = async (userId) => {
    setWalletLoading(true);
    // const profileId = localStorage.getItem('profileId');
    const res = await httpGet(
      // `/wallet/find/by/userId/${userId}`,
      `${customBaseUrl.walletUrl}/api/v1/wallet/user/${userId}`
    );
    console.log({ wa: res });
    if (res?.status) {
      // const result = res.data.filter(
      //   (item) => Number(item?.UserId) === Number(userId)
      // );
      // console.log({ result, userId });
      setWalletList(res?.data);
      setDefaultWallet(res?.data);
      setWalletLoading(false);
    }
  };

  const getTransactionHistory = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/user-transactions/${userId}`
    );

    if (res?.status) {
      setTransactionHistory(res?.data);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getWalletList(userId);
    // getTransactionHistory();
  }, [reload]);

  useEffect(() => {
    getTransactionHistory();
  }, [pageSize]);

  return (
    <WalletContext.Provider
      value={{
        setWalletList,
        walletList,
        reload,
        setReload,
        walletLoading,
        setWalletLoading,
        transactionHistory,
        setPageSize,
        pageSize,
        setDefaultWallet,
        defaultWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
