import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export default function SEO(props) {
  const location = window?.location;
  const currentUrl = `https://app.staging.wayabank.ng${location?.pathname}`;
  const title = props.title !== undefined ? props.title : 'Wayagram Contest';
  const image =
    props.image !== undefined
      ? props.image
      : 'https://pbs.twimg.com/profile_images/1678366109060280321/llM8dUEF_400x400.jpg';
  const description =
    props.description !== undefined ? props.description : 'Wagaram Contest';
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content="Alt text for image" />
    </Helmet>
  );
}
