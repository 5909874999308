import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
//  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
//  import PerfectScrollbar from 'react-perfect-scrollbar';
// import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
// import ReplyOutlined from '@mui/icons-material/ReplyOutlined';
// import { DateRange } from '@material-ui/icons';
// import medal from '../../../../assets/image/Medal First Place.png';
//  import candidate from '../../../../assets/image/right-user.svg';
//  import Button from '../../../shared/Button';
import Layout from '../../../shared/Layout';
import RightSidebar from '../RightSidebar';
import '../index';
//  import AntDropdown from '../../../shared/Dropdown/antDropdown';
import { customBaseUrl, httpGet } from '../../../../action/http';
// import church from '../../../../assets/image/church-one.svg';
// import CandidateCard from './candidatesCards';

// import phone from '../../../../assets/image/bg-section4.png';

const ManageStates = (props) => {
  const { profileId, contestId } = useParams();
  const [details, setDetails] = useState([]);
  //    const [showSharePost, setShowSharePost] = useState(false);
  const [states, setStates] = useState([]);

  const handleGetContestDetails = async () => {
    // const search = `all-posts/search-products?query=${value}`;
    const uri = `contests/contestants?contestId=${contestId}&isLeadingContestantsQuery=true`;
    const res = await httpGet(`${customBaseUrl.contestUrl}/${uri}`);
    if (res?.status) {
      const contest = res?.data;
      setDetails(contest);
      setStates(contest?.stateAnalysis);
      //  setLoading(false);
    }
  };
  //   const handleLike = async () => {
  //     const payload = {
  //       contestId,
  //       profileId,
  //     };
  //     const res = await httpPost(
  //       `/contests/like`,
  //       payload,
  //       customBaseUrl.contestUrl
  //     );
  //     if (res?.status) {
  //       handleGetContestDetails();
  //     } else {
  //       console.log(res);
  //     }
  //   };
  //   const dropDownOptions = [
  //     'Edit',
  //     'Manage Contestants',
  //     'Manage States',
  //     'Manage Dashboard',
  //     'Delete Contest',
  //     'Share Contest',
  //     'Invite Contestants',
  //   ];
  //   const handleDropdownAction = (item, row) => {
  //     console.log({ item, row });
  //   };
  useEffect(() => {
    console.log({ profileId, contestId });
    handleGetContestDetails();
  }, []);
  return (
    <Layout route={props} currentPage="contest" pageName="" showName>
      <div className="card mb-3 col-md-8 bg-white border-primary px-4 py-4">
        <div className="fw-bold row">
          <div className="col-md-3">All states:</div>
          <div className="col-md-3 text-start">{states.length}</div>
        </div>
        <div className="row">
          <div className="col-md-3">All :</div>
          <div className="col-md-3 text-start">0</div>
        </div>
        <div className="row">
          <div className="col-md-3">All contestants:</div>
          <div className="col-md-3 text-start">{details?.length ?? 0}</div>
        </div>
      </div>
      <div className="row wayagram-div">
        <div className="col-md-8 col-sm-12 col-xs-12">
          <div className="mt-4 mx-auto rounded search-container shadow-lg py-5">
            {states?.map((e) => (
              <div key={e} className="card mb-3 max-width: 540px;">
                <div
                  className="row shadow-lg bg-white rounded mx-2"
                  style={{ fontSize: 10 }}
                >
                  <div className="col-md-2 py-2 text-start">
                    <img
                      src={`https://social-waya-bucket.s3.eu-west-1.amazonaws.com/${e?.state}`}
                      className="w-10 h-10 border rounded"
                      width={100}
                      height={100}
                      alt="..."
                    />
                  </div>
                  <div className="col-sm-8 ml-2 text-start">
                    <div className="row">
                      <div className="row mt-2">
                        <div className="col-md-8">
                          {' '}
                          <h6 className="fw-bold">
                            {e?.state?.toUpperCase()}{' '}
                            {e?.state.toLowerCase() !== 'abuja' ? 'STATE' : ''}
                          </h6>
                          <p className="col">
                            <h6 className="mt-1">
                              {e?.totalContestants} Contestants
                            </h6>
                            <h6 className="mt-1">{e?.totalVotes} Votes</h6>
                            <h6 className="mt-1">
                              {e?.activeContestants} / {e?.totalContestants}{' '}
                              Qualified Contestants
                            </h6>
                          </p>
                        </div>
                        <div className="col-md-4 text-end py-1 d-flex align-items-center">
                          <Link
                            to={`/contest/contestants/states/${e?.state}/${contestId}/${profileId}`}
                          >
                            <button
                              style={{ fontSize: 10 }}
                              className="btn text-primary border rounded-pill mt-1 h6"
                              type="button"
                            >
                              Manage Contestants
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <RightSidebar isSearch />
      </div>
    </Layout>
  );
};

export default ManageStates;
