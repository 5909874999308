import React, { createContext, useState, useEffect } from 'react';
// import { httpGet, customBaseUrl } from '../../action/http';
// import { AppSocket } from '../../action/socket';
import { handleGetOrdersByBuyerId } from '../../utils/helper';

const OrderContext = createContext();

const OrderContextProvider = (props) => {
  const { children } = props;
  const wayagramProfile = localStorage.getItem('wayaProfileId');
  const [orders, setOrders] = useState([]);
  const [newOrderNotification, setNewOrderNotification] = useState(null);
  const [deliveryFeeUpdated, setDeliveryFeeUpdated] = useState(null);
  const [deliveryFeeStatus, setDeliveryFeeStatus] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const getOrdersByBuyer = async () => {
    const res = await handleGetOrdersByBuyerId(wayagramProfile);
    // console.log('wayagram-res-order', res);
    if (res?.status) {
      setOrders(res?.data);
    }
  };

  useEffect(() => {
    getOrdersByBuyer();
  }, []);

  // useEffect(() => {
  //   if (AppSocket?.io?.disconnected) {
  //     AppSocket.createConnection();
  //   }
  //   if (AppSocket?.io?.connected) {
  //     console.log('wayagram-order socked is connected');
  //     AppSocket?.io?.on('SellerNotified', (data) => {
  //       console.log('SellerNotified', data);
  //       if (data?.sellerProfileId === wayagramProfile) {
  //         setNewOrderNotification((prev) => [...prev, data]);
  //       }
  //     });

  //     AppSocket?.io?.on('deliveryFeeUpdated', (data) => {
  //       console.log('deliveryFeeupdates from socket');
  //       setDeliveryFeeUpdated(data);
  //     });

  //     AppSocket?.io?.on('DeliveryFeeStatusAccepted', (data) => {
  //       console.log('wayagram-socket', data);
  //       setDeliveryFeeStatus(data);
  //     });

  //     AppSocket?.io?.on('DeliveryFeeStatusRejected', (data) => {
  //       setDeliveryFeeStatus(data);
  //     });

  //     AppSocket?.io?.on('DeliveryStatusUpdated', (data) => {
  //       setDeliveryStatus(data);
  //     });
  //   }
  // }, [AppSocket?.io?.connected]);
  return (
    <OrderContext.Provider
      value={{
        orders,
        setOrders,
        newOrderNotification,
        setNewOrderNotification,
        deliveryFeeUpdated,
        setDeliveryFeeUpdated,
        deliveryFeeStatus,
        setDeliveryFeeStatus,
        deliveryStatus,
        setDeliveryStatus,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderContextProvider };
