import moment from 'moment';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from '../../../../utils/device';
import { dateToNow } from '../../../../utils/formatDate';
import { numberFormat } from '../../../../utils/helper';

const PollOptionCard = ({ Poll, handleClickVote, wayagramProfileId, type }) => {
  // const votesObj = Object.values(Poll?.Votes || {});
  // const leading = Math.max(...votesObj);
  const lead = Poll?.PollOptions.sort(
    (a, b) => a?.Votes?.length - b?.Votes?.length
  );
  return (
    <div className="poll">
      <div className="d-flex flex-column my-2">
        {Poll?.pollCategory === 'text' ? (
          (Poll?.PollOptions || []).map((item, index) => {
            const { userVoted } = item;
            return (
              <PollTextOptions
                key={Math.random()}
                value={userVoted}
                item={item}
                index={index}
                handleClickVote={handleClickVote}
                type={type}
                showCount
              />
            );
          })
        ) : (
          <PollImageOptions
            data={Poll?.PollOptions}
            handleClickVote={handleClickVote}
            wayagramProfileId={wayagramProfileId}
            type={type}
          />
        )}
      </div>
      <div className="d-flex">
        {Poll?.expiresIn && (
          <div className=" me-3">
            {`${dateToNow(moment(parseFloat(Poll.expiresIn)))} left`}
          </div>
        )}
        {Poll?.amount >= 0 && (
          <div className=" me-3">{numberFormat(Poll?.amount)} per vote</div>
        )}
        <div className="me-3">
          {Poll?.totalVotesCount ||
            Poll?.totalVotesCountPoll ||
            Poll?.PollVotes?.length ||
            0}{' '}
          votes
        </div>
        {Poll?.showResult &&
        (Poll?.totalVotesCount ||
          Poll?.totalVotesCountPoll ||
          Poll?.PollVotes?.length) ? (
          <div className="me-3">
            Leading option: {lead[lead?.length - 1]?.option}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PollOptionCard;

const PollTextOptions = ({ item, index, handleClickVote, type }) => {
  const [selectedId, setSelectedId] = useState('');
  const onClick = (id) => {
    if (type === 'Post') handleClickVote(id);
  };
  return (
    <div className="form-check my-2 d-flex align-items-center">
      <input
        style={{
          width: '30px',
          height: '30px',
          marginRight: '25px',
          border: '10px solid #90DDD0',
        }}
        onMouseEnter={() => setSelectedId(item.id)}
        onMouseLeave={() => setSelectedId('')}
        className="cursor-pointer"
        type="radio"
        name={item.option}
        value={item.option}
        id={index}
        onClick={() => onClick(item.id)}
        checked={item.id === selectedId}
        readOnly
      />
      <label className="form-check-label" htmlFor={index}>
        {item.option}
        <p style={{ margin: 0 }}>{item.short_desc}</p>
      </label>
    </div>
  );
};

const PollImageOptions = ({ data, handleClickVote, type }) => {
  const onClick = (id) => {
    if (type === 'Post') handleClickVote(id);
  };
  return (
    <Carousel
      responsive={responsive}
      ssr
      autoPlay
      infinite={false}
      autoPlaySpeed={10000}
      keyBoardControl
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
    >
      {(data || []).map((item, index) => {
        return (
          <div key={Math.random()}>
            <div
              className="moment-card"
              style={{
                backgroundImage: `url(${item.media_url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                marginRight: 10,
              }}
              onClick={() => onClick(item.id)}
            >
              <div>
                <div className="moment-inner">
                  <h6 className="fs-6 text-white">
                    {item.totalVotesCountPollOption} vote(s)
                  </h6>
                </div>
              </div>
            </div>
            <PollTextOptions
              key={Math.random()}
              value={item.userVoted}
              item={item}
              index={index}
              type={type}
              handleClickVote={handleClickVote}
            />
          </div>
        );
      })}
    </Carousel>
  );
};
