import React, { useState } from 'react';
import '../css/Transaction.css';

const VendorTransactions = ({ data }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      setSelectedItems(
        newSelectAll ? data.map((item) => item.transactionId) : []
      );
      return newSelectAll;
    });
  };

  const handleItemSelect = (transactionId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(transactionId)
        ? prevSelected.filter((id) => id !== transactionId)
        : [...prevSelected, transactionId]
    );
  };

  const handleRecordsPerPageChange = (event) => {
    const newRecordsPerPage = Number(event.target.value);
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return data.slice(startIndex, startIndex + recordsPerPage);
  };

  return (
    <div className="table-container">
      <div className="table-controls">
        <div className="records-per-page">
          <label htmlFor="recordsPerPage">Show</label>
          <select
            id="recordsPerPage"
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
          >
            {[5, 10, 15, 20].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                aria-label="Select all transactions"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Transaction ID</th>
            <th>Date</th>
            <th>Vendor Name</th>
            <th>Item Description</th>
          </tr>
        </thead>
        <tbody>
          {getCurrentPageData().map((item) => (
            <tr key={item.transactionId}>
              <td>
                <input
                  type="checkbox"
                  name="transactionId"
                  aria-label="transactionId"
                  checked={selectedItems.includes(item.transactionId)}
                  onChange={() => handleItemSelect(item.transactionId)}
                />
              </td>
              <td>{item.transactionId}</td>
              <td>{item.date}</td>
              <td>{item.vendorName}</td>
              <td>{item.itemDescription}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VendorTransactions;
