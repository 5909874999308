import React from 'react';
import Backdrop from '../../../../../shared/backdrop/Backdrop';
import '../css/authConfirmationNotification.css';

const AuthConfirmationNotification = ({ closeModal }) => (
  <Backdrop onClose={closeModal}>
    <section className="confirm-delivery">
      <div className="svg__confirm__icon" />

      <div className="level__two__bx">
        <div className="confirmation__header">Quote submitted successfully</div>

        <div className="confirmation__msg">
          Your quote has been submitted successfully. You will be notified once
          you get a bid on your quote.
        </div>
      </div>

      <div className="confirm__delivery__token">
        <button className="confirmation_btn" type="button" onClick={closeModal}>
          Go to Bidding Dashboard
        </button>
      </div>
    </section>
  </Backdrop>
);

export default AuthConfirmationNotification;
