import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { useRecoilValue } from 'recoil';
import { userTheme } from '../../../store/initialState';
import { OtherDropdownWrapper } from './styles';

function OtherDropdown(props) {
  const theme = useRecoilValue(userTheme);
  const {
    options,
    handleClick,
    positionLeft,
    positionRight,
    hide,
    data,
    positionTop,
    positionBottom,
  } = props;
  const [active, setActive] = useState('');
  OtherDropdown.handleClickOutside = () => hide(false);

  return (
    <OtherDropdownWrapper
      left={positionLeft || '0'}
      right={positionRight || '0'}
      top={positionTop}
      bottom={positionBottom}
      style={!options.length ? { display: 'none' } : {}}
      mode={theme.mode}
    >
      {options.length
        ? options.map((item) => (
            <div
              className={`d-flex ${item.type === active ? 'active' : ''}`}
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => {
                handleClick(item.type, data);
                setActive(item.type);
              }}
            >
              <div>{item.name}</div>
            </div>
          ))
        : ''}
    </OtherDropdownWrapper>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => OtherDropdown.handleClickOutside,
};

export default onClickOutside(OtherDropdown, clickOutsideConfig);
