import React from 'react';
import PropTypes from 'prop-types';
// import arrowLeft from '../../../../../../assets/images/arrowLeft.png';
import './index.scss';
import { ArrowBack } from '../../../../Icons';

function PageCount(props) {
  const { currentPage, totalPage, stepBackward, color } = props;
  return (
    <div className="page-count-countainer mt-5">
      <div className="navigation-arrow">
        {currentPage > 1 && (
          <div onClick={stepBackward}>
            <ArrowBack color={color} />
          </div>
        )}
      </div>
      <span style={{ color }}>{`${currentPage}/${totalPage}`}</span>
    </div>
  );
}

PageCount.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  stepBackward: PropTypes.func.isRequired,
};

export default PageCount;
