import { useRecoilValue } from 'recoil';
import React from 'react';
import DonationProgress from '../../Modals/Post/DonationModal/DonationProgress';
import imgPlaceholder from '../../../../assets/image/default-dp.svg';
import { userTheme } from '../../../../store/initialState';
import { numberFormat } from '../../../../utils/helper';
import PostImageCard from './PostImageCard';

const DonationCard = ({ Donation, description, profile, handleNavigate }) => {
  const theme = useRecoilValue(userTheme);

  const totalAmountDonated = Donation?.Donate?.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );
  // useEffect(() => {
  //   console.log('wayagram-donation-card', Donation);
  // }, [Donation]);
  return (
    <div className="Donation-description-box mt-3 wl-100">
      <div
        className="Donation-text add-cursor"
        onClick={() => handleNavigate()}
      >
        {Donation?.title}
      </div>
      <div>
        <div style={{ marginBottom: '2rem' }}>
          {Donation?.DonationImages?.length === 1 ? (
            <div className="img-div" onClick={() => handleNavigate()}>
              <a href={Donation?.DonationImages[0]?.imageUrl}>
                <i
                  className="mdi mdi-download dowload-icon"
                  alt="donation-img"
                />
              </a>
              <img src={Donation?.DonationImages[0]?.imageUrl} alt="pic" />
            </div>
          ) : (
            <PostImageCard PostImages={Donation?.DonationImages} />
          )}
        </div>
        {Donation?.displayTotalDonation && (
          <div className="Donation-progress mt-5">
            {`${numberFormat(
              Donation?.amountRaised || totalAmountDonated || 0
            )} raised out of
            ${numberFormat(Donation?.estimatedAmount)}`}
          </div>
        )}

        {Donation?.displayNumberOfDonors && (
          <div className="Donation-progress mt-3">
            {/* {`${Donation?.totalDonors || 0} person(s) donated`} */}
            {Donation?.totalDonors <= 1 || Donation?.Donate?.length <= 1
              ? `${
                  Donation?.totalDonors ||
                  (Donation?.Donate?.length === 1 &&
                    Donation?.Donate?.length) ||
                  0
                } person donated`
              : `${
                  Donation?.totalDonors ||
                  (Donation?.Donate?.length > 1 && Donation?.Donate?.length)
                } people donated`}
          </div>
        )}
        <DonationProgress
          current={Donation?.amountRaised || totalAmountDonated || 0}
          total={Donation?.estimatedAmount}
          description={description || ''}
          image={profile?.avatar || imgPlaceholder}
          title=""
          theme={theme}
        />
      </div>
    </div>
  );
};

export default DonationCard;
