import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  .inputsHolder {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  input:invalid {
    border: 2px solid red;
  }
  .otp-input {
    border: 2px solid #4f4f4f;
    border-radius: 4px;
    width: 56.71px !important;
    text-align: center;
    padding-bottom: 4px;
  }
  .sum-bot {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      background: rgb(255, 102, 52);
      border-radius: 4px;
      padding: 10px 120px;
      font-family: Lato;
      margin-block: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      outline: none;
      color: #ffffff;
      text-align: center;
    }
  }
  .sub-header {
    margin-bottom: 20px;
  }
`;

export default Wrapper;
