import React, { useContext, useState } from 'react';
import { Button, ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { customBaseUrl, httpPut } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { hideLoader } from '../../../../utils/loader';
import VerifyOtp from '../Otp/VerifyOtp';

export default function index(props) {
  const { showModal, hideModal } = props;
  const { userDetails } = useContext(ProfileContext);
  const { setReload, reload } = useContext(WalletContext);
  const [amount, setAmount] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [otp, setOtp] = useState('');

  const handleRedeemTransfer = async () => {
    setLoading(true);

    if (!otp || otp === '') {
      swal('Oops!', 'Otp cannot be empty', 'error');
      hideLoader(false);
      return;
    }

    const res = await httpPut(
      `/api/v1/wallet/non-waya/transaction/redeem/PIN`,
      {
        merchantId: userDetails.userId,
        tokenId: referenceCode,
        tokenPIN: otp,
      },
      customBaseUrl.walletUrl
    );
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success').then(() => {
        setReload(!reload);
        setLoading(false);
        setShowVerifyOtp(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const sendVerificationPin = async () => {
    setLoading(true);

    if (!referenceCode || referenceCode === '') {
      setLoading(false);
      swal('Wrong!', 'Retrieval code is required', 'error');
      return;
    }

    const postData = {
      amount,
      merchantId: userDetails.userId,
      token: referenceCode,
      tranCrncy: 'NGN',
      tranStatus: 'RESERVED',
    };

    const res = await httpPut(
      `/api/v1/wallet/non-waya/transaction/redeem/new`,
      postData,
      customBaseUrl.walletUrl
    );
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success');
      setShowVerifyOtp(true);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="retrieve-wrapper"
      centered
    >
      <ModalHeader className="text-center">Request Payment</ModalHeader>
      <ModalBody>
        <div className="text-center mt-3 mb-3" style={{ fontSize: '12px' }}>
          Please enter the 10 digit payment code sent to you
        </div>
        <form className="mx-auto">
          <div className="inputbox-with-one-input">
            <input
              placeholder="Enter amount"
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <span className="input-detail">Amount expecting</span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              placeholder="Enter the 10 digit pin"
              type="text"
              value={referenceCode}
              onChange={(e) => setReferenceCode(e.target.value)}
            />
            <span className="input-detail">10 digit code sent to you</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-3"
          color="primary"
          disabled={loading || false}
          loading={loading}
          onClick={() => {
            sendVerificationPin();
          }}
        >
          Retrieve Money
        </Button>
      </ModalFooter>
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Otp Confirmation"
          description="Please input the Otp sent to your email address or phone number"
          isResendOtp={false}
          buttonLabel="Submit"
          handleSubmit={handleRedeemTransfer}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
    </Modal>
  );
}
