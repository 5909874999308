import React, { createContext, useRef, useState, useEffect } from 'react';
import { getFriends } from '../../components/pages/Wayagram/container';
import { httpGet, customBaseUrl } from '../../action/http';
import { AppSocket } from '../../action/socket';

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const { children } = props;
  const [activeContact, setActiveContact] = useState([]);
  const [replyData, setReplyData] = useState(null);
  const [videoCallNotif, setVideoCallNotif] = useState(false);
  const [activeChats, setActiveChats] = useState([]);
  const [activeChatHistory, setActiveChatHistory] = useState([]);
  const [chatHistory, setChatHistory] = useState({});
  const activeChatsRef = useRef(activeChats);
  const activeContactRef = useRef(activeContact);
  const activeChatHistorysRef = useRef(activeChatHistory);
  const chatHistoriesRef = useRef(chatHistory);
  const [allContactsList, setAllContactsList] = useState([]);
  const [forwardMessage, setForwardMessage] = useState(null);
  const [unread, setUnread] = useState(0);
  const userId = localStorage.getItem('userId');
  const [reload, setReload] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [activeContactTyping, setActiveContactTyping] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendReadReceipt, setSendReadReceipt] = useState();
  const [showActiveContactlist, setShowActiveContactlist] = useState(true);
  const [scrollIntoLastView, setScrollIntoLastView] = useState(false);
  const [refreshActiveContact, setRefreshActiveContact] = useState(true);
  const wayagramProfile = localStorage.getItem('wayaProfileId');
  const [privacy, setPrivacy] = useState({
    profileId: wayagramProfile,
    onlineStatus: true,
    lastSeen: true,
    readReceipt: true,
    displayProfile: true,
  });
  const [contactSellerOnChat, setContactSellerOnChat] = useState(null);

  const getAllFriends = async () => {
    // setLoading(true);
    const data = await getFriends(userId);

    const friends = data?.friends || [];
    setAllContactsList(friends);
  };

  const getPrivacySettings = async () => {
    const res = await httpGet(
      `${customBaseUrl.chat}/get-settings?profileId=${wayagramProfile}`
    );

    if (res?.status) {
      setPrivacy({
        profileId: wayagramProfile,
        onlineStatus: res?.data?.onlineStatus && res?.data?.onlineStatus,
        lastSeen: res?.data?.lastSeen && res?.data?.lastSeen,
        readReceipt: res?.data?.readReceipt && res?.data?.readReceipt,
        displayProfile: res?.data?.displayProfile && res?.data?.displayProfile,
      });
    }
  };

  useEffect(() => {
    getAllFriends();
    getPrivacySettings();
  }, []);

  useEffect(() => {
    AppSocket.createConnection();
    return () => AppSocket.disconnect();
  }, []);

  useEffect(() => {
    AppSocket.io.emit('chat_overview');
  }, [AppSocket?.io?.connected, reload]);

  useEffect(() => {
    if (AppSocket?.io?.disconnected) {
      AppSocket.createConnection();
    }

    if (refreshActiveContact) {
      AppSocket.io.emit('fetchChatLists', {
        page: 1,
        limit: 50,
      });
      AppSocket.io.on('chatListUpdate', (chatList) => {
        const ChattingFriends = [];
        chatList?.data?.forEach((friend) => {
          if (friend?.recipientProfile?.profileId !== wayagramProfile) {
            // friend exist
            const friendExist = ChattingFriends.find(
              (item) => item?.profileId === friend?.recipientProfile?.profileId
            );

            if (!friendExist) {
              ChattingFriends.push({
                ...friend?.recipientProfile,
                unreadCount: friend?.unreadCount,
                content: friend?.content,
                isBlock: friend?.isBlock,
                senderProfileId: friend?.senderProfileId,
              });
            }
          } else if (friend?.senderProfile?.profileId !== wayagramProfile) {
            // friend exist
            const friendExist = ChattingFriends.find(
              (item) => item?.profileId === friend?.senderProfile?.profileId
            );

            if (!friendExist) {
              ChattingFriends.push({
                ...friend?.senderProfile,
                unreadCount: friend?.unreadCount,
                content: friend?.content,
                isBlock: friend?.isBlock,
                senderProfileId: friend?.senderProfileId,
              });
            }
          }
        });

        setActiveChats(ChattingFriends);
        setLoading(false);
        setRefreshActiveContact(false);
      });
    }
  }, [AppSocket?.io?.connected, refreshActiveContact]);

  useEffect(() => {
    const totalUnread = activeChats.reduce((acc, chat) => {
      return acc + chat.unreadCount;
    }, 0);
    setUnread(totalUnread);
  }, [activeChats]);

  return (
    <ChatContext.Provider
      value={{
        activeContact,
        setActiveContact,
        activeChats,
        setActiveChats,
        chatHistory,
        setChatHistory,
        activeChatHistory,
        setActiveChatHistory,
        activeChatsRef,
        activeChatHistorysRef,
        activeContactRef,
        chatHistoriesRef,
        replyData,
        setReplyData,
        allContactsList,
        setAllContactsList,
        forwardMessage,
        setForwardMessage,
        videoCallNotif,
        setVideoCallNotif,
        unread,
        setUnread,
        reload,
        setReload,
        isTyping,
        setIsTyping,
        activeContactTyping,
        setActiveContactTyping,
        loading,
        setLoading,
        sendReadReceipt,
        setSendReadReceipt,
        showActiveContactlist,
        setShowActiveContactlist,
        privacy,
        setPrivacy,
        scrollIntoLastView,
        setScrollIntoLastView,
        setRefreshActiveContact,
        contactSellerOnChat,
        setContactSellerOnChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
