/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { Chip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import { useQuery } from 'react-query';
import 'react-multi-carousel/lib/styles.css';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import imgPlaceholder from '../../../assets/image/default-dp.svg';
import { ModalContext } from '../../../store/context/ModalContext';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { WayagramContext } from '../../../store/context/WayagramContext';
//  import { responsive } from '../../../utils/device';
import { deletePost, getCarts, getFlyers } from '../../../utils/helper';
import GramCard from '../../shared/Cards/Gram';
import Layout from '../../shared/Layout';
// import Loader from '../../shared/Loader';
import CreateMoment from '../../shared/Modals/Post/CreateMoment';
import Pagination from '../../shared/Pagination/index';
import {
  getFeeds,
  getMyInterest,
  getUnAddedInterest,
  handleVerifyPaymentOnWayaquick,
} from './container';
import './index.scss';
import MomentCard, { EmptyMomentCard } from './MomentCard';
import QuickPostCard from './QuickPostCard';
import RightSidebar from './RightSidebar';
import { MomentWrapper } from './style';
import useStore from '../../../store/zustandStore/store';
// import { showLoader, hideLoader } from '../../../utils/loader';
import Share from '../../shared/Modals/share';

export default function Index(props) {
  const [loading, setLoading] = useState(false);
  const [moments, setMoments] = useState([]);
  const [allMoments, setAllMoments] = useState([]);
  // const [products, setProducts] = useState([]);
  const [interestData, setInterestData] = useState([]);
  const [showInterest, setShowInterset] = useState(false);
  const [page, setPage] = useState(10);
  const [showCreateMoment, setShowCreateMoment] = useState(false);
  const { setShowCreatePin, setModalAction } = useContext(ModalContext);
  const [unAddedInterest, setUnAddedInterest] = useState([]);
  const [showProducts, setShowProducts] = useState(false);

  const shareURL =
    process.env.REACT_APP_WEBSITE_APP || 'https://staging.wayagram.ng';

  const {
    wayagramProfile,
    wayagramProfileId,
    setReload,
    reloadMoment,
    reload,
    wayagramFeeds,
    setWayagramFeeds,
  } = useContext(WayagramContext);
  const { userDetails, reloadUser } = useContext(ProfileContext);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);

  const {
    getProductCart,
    getAllProducts,
    setCategories,
    // categories,
    setFlyers,
    // flyers,
    setPercentagePricing,
    setShowShareDonationModal,
    showShareDonationModal,
    donationDataForShare,
  } = useStore((state) => state);

  // const location = useLocation();
  const transDetails = JSON.parse(localStorage.getItem('tranId'));

  useEffect(() => {
    const handleVerifyPayment = async () => {
      if (!transDetails) return;
      if (transDetails) {
        await handleVerifyPaymentOnWayaquick({
          tranId: transDetails.tranId,
          paymentMethod: transDetails.paymentMethod,
        });
        localStorage.removeItem('tranId');
      }
    };
    handleVerifyPayment();
  }, []);

  const handleGetMoments = async () => {
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/group-moment-by-users?profile_id=${wayagramProfileId}`
      );
      // const res = await axios.get(
      //   `${customBaseUrl.wayagramUrl}/all-posts/moments`
      // );
      console.log('wayagram-moments', res?.data);
      if (res?.status) {
        //  const all = res.data.filter((m) => m?.moments.length > 0);
        const all = [];
        setAllMoments(res.data);
        setMoments(res.data);
        Object.keys(res?.data).map((e) => all.push([res?.data[e]]));
        setAllMoments(all);
        if (all.length < 2) {
          setShowProducts(true);
        } else {
          setShowProducts(false);
        }
      } else {
        setAllMoments([]);
        // setMoments(res.data);
        setShowProducts(true);
        return [];
      }
      return {};
    } catch (error) {
      return error.message;
    }
  };

  const getCategories = async () => {
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/categories`
      );

      if (res?.status) {
        setCategories(res?.data?.data);
      }
    } catch (error) {
      NotificationManager.error(error?.message, 'Oops!', 3000);
    }
  };
  const handleFetchFlyers = async () => {
    try {
      const res = await getFlyers();
      if (res?.status) {
        setFlyers(res?.data?.data);
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return {};
  };

  // useEffect(() => {
  //   getCategories();
  //   handleFetchFlyers();
  // }, []);

  const handleGetProducts = async () => {
    // showLoader();
    if (!wayagramProfileId) {
      const res = await axios.get(
        `${customBaseUrl.wayagramUrl}/all-posts/get-all-products?limit=50&page=${pageNo}`
      );
      if (res?.status) {
        return res.data?.data?.data;
      }
    }
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/get-all-products?limit=50&page=${pageNo}`
    );
    if (res?.status) {
      return res.data?.data;
    }
    return {};
  };

  const { data: products } = useQuery({
    queryKey: 'allProducts',
    queryFn: handleGetProducts,
    onSettled: (data) => {
      getAllProducts(data);
    },
  });

  const getAllInfo = async () => {
    try {
      const allFeeds = await getFeeds(wayagramProfileId, pageNo, pageSize);
      if (pageNo === 1) setWayagramFeeds(allFeeds);
      else setWayagramFeeds(allFeeds?.concat(wayagramFeeds) || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    // hideLoader();
  };
  const handlePage = () => {
    const size = unAddedInterest?.length;
    //  const increment = Math.ceil(size / page);
    if (page < size) setPage((prev) => prev + 10);
  };
  const handleInterest = async () => {
    setInterestData(unAddedInterest.slice(0, page));
  };

  // useEffect(() => {
  //   if (wayagramProfileId) {
  //     handleGetMoments(wayagramProfile.id);
  //   }
  //   // handleGetProducts();
  // }, [wayagramProfileId, reloadMoment, reload]);

  const getAllCartsItems = async () => {
    const res = await getCarts(wayagramProfileId);
    if (res?.status) {
      getProductCart(res?.data[0]?.Products);
      return res?.data;
    }
    return {};
  };

  const getPercentagePricing = async () => {
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/admin-get-all-percent-prices`
    );
    if (res?.status) {
      setPercentagePricing(res?.data?.data);
      return res?.data?.data;
    }
    return {};
  };

  useEffect(() => {
    setLoading(true);
    // showLoader();
    // if (wayagramProfileId) {
    getAllInfo();
    getAllCartsItems();
    getPercentagePricing();
    getCategories();
    handleFetchFlyers();
    handleGetMoments();
    // }
    return () => {
      setPageSize(10);
      // setPageNo(1);
    };
  }, [wayagramProfileId, reload, pageNo]);

  const handleDeletePost = async (postId) => {
    const res = await deletePost(postId);
    if (res?.status) {
      setWayagramFeeds(wayagramFeeds.filter((item) => item.id !== postId));
    }
  };

  useEffect(() => {
    if (userDetails?.userId && !userDetails?.pinCreated) {
      setShowCreatePin(true);
      setModalAction('createPin');
    }
    if (reloadUser) {
      const firstLogin = localStorage.getItem('firstLogin');
      if (firstLogin === 'true' && reloadUser) {
        // setShowProfile(true);

        localStorage.setItem('firstLogin', false);
      }
    }
  }, [userDetails?.userId, reloadUser]);

  useEffect(async () => {
    const resData = await getUnAddedInterest(wayagramProfileId);
    const resIntData = await getMyInterest(wayagramProfileId);
    setShowInterset(resIntData.length > 0 ?? false);
    setInterestData(resData.slice(0, page));
    setUnAddedInterest(resData);
  }, [moments, wayagramFeeds]);

  useEffect(() => {
    handleInterest();
  }, [page, unAddedInterest, reload]);

  const handleAddInterest = async (interestId) => {
    const postData = {
      interestId,
      profileId: wayagramProfileId,
    };
    const res = await httpPost(
      `/graph/user-interest/selectUserInterest`,
      postData,
      customBaseUrl.wayagramUrl
    );
    if (res?.status) {
      setReload(!reload);
      NotificationManager.success(res?.message, 'Done!', 3000);
    } else {
      NotificationManager.error(res?.message, 'Oops!', 3000);
    }
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2000, min: 1000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 2000, min: 624 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Layout route={props} currentPage="wayagram" pageName="Feed" showName>
      <div className="row wayagram-div m-2">
        <div className="col-md-8 col-sm-12 col-xs-12">
          <QuickPostCard profile={wayagramProfile} />
          <MomentWrapper className="row">
            <div className="moment-header">Moments</div>
            {moments ? (
              <Carousel
                swipeable
                draggable={false}
                //  showDots
                responsive={responsive}
                //  ssr={true} // means to render carousel on server-side.
                //  infinite={true}
                //  autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                //  removeArrowOnDeviceType={['tablet', 'mobile']}
                deviceType="desktop"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <EmptyMomentCard
                  image={wayagramProfile?.avatar}
                  imgPlaceholder={imgPlaceholder}
                  showPost={setShowCreateMoment}
                />
                {moments !== null &&
                  Object.keys(moments).map((data, i) => (
                    <MomentCard
                      data={moments[data]}
                      key={moments[data]?.id}
                      imgPlaceholder={imgPlaceholder}
                      moments={moments[data]}
                      allMoments={allMoments}
                      pid={wayagramProfileId}
                      setReload={setReload}
                      reloadMoment={reloadMoment}
                      reload={reload}
                      ind={i}
                    />
                  ))}
                {showProducts &&
                  products &&
                  Object.keys(products).map((data) => (
                    <MomentCard
                      data={products[data]}
                      key={products[data]?.id}
                      imgPlaceholder={imgPlaceholder}
                      moments={products[data]}
                      allMoments={products}
                      pid={wayagramProfileId}
                      setReload={setReload}
                      reloadMoment={reloadMoment}
                      type="product"
                      reload={reload}
                    />
                  ))}
                <div />
                <div />
                <div />
                <div />
              </Carousel>
            ) : (
              <EmptyMomentCard
                image={wayagramProfile?.avatar}
                imgPlaceholder={imgPlaceholder}
                showPost={setShowCreateMoment}
              />
            )}
          </MomentWrapper>
          {!showInterest && interestData.length > 0 && (
            <div>
              <div className="moment-header fw-bold">Interest</div>
              {interestData?.map((e) => (
                <Chip
                  className="mx-2 my-1"
                  key={e?.title}
                  label={e?.title}
                  onDelete={() => handleAddInterest(e?.id)}
                  deleteIcon={<Add />}
                />
              ))}
              <p className="text-center">
                {page < unAddedInterest?.length && (
                  <div className="text-center btn rounded" onClick={handlePage}>
                    Show More
                  </div>
                )}
              </p>
            </div>
          )}
          <MomentWrapper>
            <div className="moment-header">Post</div>
            {/* {loading && <Loader />} */}
            <div className="mt-0 scroll-wrapper">
              {wayagramFeeds?.length === 0 && !loading ? (
                <div className="mt-5 row d-grid justify-content-center align-items-center">
                  No post found
                </div>
              ) : (
                ''
              )}
              <Pagination
                dataLength={wayagramFeeds?.length || 0}
                setPageNo={setPageNo}
                pageNo={pageNo}
              >
                {wayagramFeeds && wayagramFeeds?.length
                  ? wayagramFeeds.map((post) => (
                      <GramCard
                        key={Math.random()}
                        data={post}
                        handleDeletePost={handleDeletePost}
                        fromLocation="feeds"
                      />
                    ))
                  : ''}
              </Pagination>
            </div>
          </MomentWrapper>
        </div>
        <RightSidebar isSearch />
      </div>
      {showCreateMoment && (
        <CreateMoment
          showModal={showCreateMoment}
          hideModal={setShowCreateMoment}
          set={setReload}
          id="create-moment-modal"
          mode="create"
        />
      )}

      {showShareDonationModal ? (
        <Share
          showModal={showShareDonationModal}
          hideModal={setShowShareDonationModal}
          title="Share"
          description="Share this donation with your friends"
          url={`${shareURL}/post/${donationDataForShare?.id}`}
          text={`${wayagramProfile?.displayName} has donated to this cause and is recommending you to take part.`}
          imageUrl={donationDataForShare?.Donation?.DonationImages[0]}
          data={donationDataForShare}
          profile={wayagramProfile}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
