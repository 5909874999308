import React from 'react';
import { Field } from 'formik';

const FormInput = ({ id, name, placeholder, as, type = 'text', children }) => {
  return (
    <Field
      id={id}
      name={name}
      placeholder={placeholder}
      type={type}
      as={as}
      className="form-control border border-light-subtle rounded outline-none"
    >
      {children}
    </Field>
  );
};

export default FormInput;
