import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import BiddingPage from './pages/BiddingPage';
import GetQuote from './Quote/GetQuote';
import BidCardDetails from './pages/BidCardDetails';

const Bid = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={BiddingPage} />
      <Route path={`${path}/quote`} component={GetQuote} />
      <Route path={`${path}/item/:quoteID`} component={BidCardDetails} />
    </Switch>
  );
};

export default Bid;
