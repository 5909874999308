import React, { useContext, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useRecoilValue } from 'recoil';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import '../DonationModal/index.scss';
import { customBaseUrl, httpPost } from '../../../../../action/http';
import { hideLoader, showLoader } from '../../../../../utils/loader';
import { WayagramContext } from '../../../../../store/context/WayagramContext';
import {
  getFriends,
  // handleGetGroupCategories,
  inviteToGroup,
} from '../../../../pages/Wayagram/container';
import { ProfileContext } from '../../../../../store/context/ProfileContext';
import GroupInfo from './GroupInfo';
import ProgressBar from '../DonationModal/ProgressBar';
import PageCount from '../DonationModal/Pagecount';
import StepButton from '../DonationModal/StepButton';
import { groupButtonTitle } from '../DonationModal/data';
import PublishGroup from './PublishGroup';
import { userTheme } from '../../../../../store/initialState';

const CreateGroupModal = (props) => {
  const theme = useRecoilValue(userTheme);
  const { showModal, hideModal } = props;
  // const [categories, setCategories] = useState([]);
  const [members, setMembers] = useState([]);
  const [values, setValues] = useState({
    title: '',
    privacyStatus: 'true',
    description: '',
    category: '',
    invitee: [],
    step: 0,
    totalSteps: 2,
  });
  const {
    profile: { userId, ...others },
  } = useContext(ProfileContext);
  const {
    wayagramProfile: profile,
    categories,
    reload,
    setReload,
  } = useContext(WayagramContext);
  console.log({ others, profile });
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllFriends = async () => {
    const friends = await getFriends(userId);
    setMembers(friends?.friends);
  };

  // const getCategories = async () => {
  //   const res = await handleGetGroupCategories();
  //   setCategories(res);
  // };

  useEffect(() => {
    // getCategories();
    getAllFriends();
  }, []);

  const handleInvite = async (invitee, id) => {
    await inviteToGroup(profile.id, invitee, id);
  };

  const handleSubmit = async () => {
    showLoader();
    const newForm = new FormData();
    newForm.append('userId', profile.id);
    newForm.append('name', values.title);
    newForm.append('description', values.description);
    newForm.append('category', values.category);
    newForm.append('customCategory', values.customCategory);
    if (values?.headerImage) newForm.append('headerImage', values?.headerImage);
    if (values?.image) newForm.append('image', values?.image);
    newForm.append('isPublic', values.privacyStatus || false);

    const res = await httpPost(
      `/main/group/create-group`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res?.status) {
      values?.invitee?.forEach(async (option) => {
        await handleInvite(option, res?.data?.id);
      });
      hideLoader();
      hideModal(false);
      setReload(!reload);
      NotificationManager.success(res?.message, 'Done!', 3000);
      return <Redirect to={`wayagram-group/${res?.data?.id}`} />;
    }
    hideLoader();
    swal('Oops!', res?.message, 'error');
    return null;
  };

  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader
          style={theme.modalStyle}
          className="text-left"
          toggle={() => hideModal(false)}
        >
          <div
            className="interest-header normal-header"
            style={theme.modalStyle}
          >
            <h3 className="interest-p1" style={theme.modalStyle}>
              Group Information
            </h3>
            <div className="description-header mt-3" style={theme.modalStyle}>
              <p className="description-text">
                Name your group, along with why attendees should come, including
                interesting highlights
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={theme.modalStyle}>
          <PageSwitch
            step={values.step}
            totalSteps={values.totalSteps}
            handleSteps={handleSteps}
            stepBackward={stepBackward}
            submitDonation={handleSubmit}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            categories={categories}
            members={members}
            theme={theme}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

function PageSwitch(props) {
  const {
    step,
    totalSteps,
    handleSteps,
    submitDonation,
    handleChange,
    stepBackward,
    values,
    setValues,
    categories,
    members,
    theme,
  } = props;
  const [errors, setErrors] = useState(null);
  const onAction = [handleSteps, submitDonation];

  const handleSubmit = () => {
    if (
      values.title === '' ||
      values.category === '' ||
      (values.category === 'Others' && values.customCategory === '') ||
      values.description === '' ||
      values?.invitee?.lenght < 1
    ) {
      setErrors('All input fields marked with an asterisk (*) are mandatory');
      return;
    }
    setErrors('');
    const nextAction = onAction[step];
    nextAction();
  };

  return (
    <div className="donation-container pb-5 mb-3">
      <PageCount
        currentPage={step + 1}
        totalPage={totalSteps}
        stepBackward={stepBackward}
        color={theme?.modalStyle?.color}
      />
      <ProgressBar totalSteps={totalSteps} step={step} />
      <div className="info-container">
        {step === 0 && (
          <GroupInfo
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            categories={categories}
            members={members}
            theme={theme}
          />
        )}
        {step === 1 && (
          <PublishGroup
            setPostData={setValues}
            postData={values}
            theme={theme}
          />
        )}
        <p className="text-primary text-center">{errors}</p>
      </div>
      <StepButton
        className="mb-3 mt-5"
        title={groupButtonTitle[step]}
        onClick={handleSubmit}
      />
    </div>
  );
}

export default CreateGroupModal;
