import React, { useState } from 'react';
import axios from 'axios';
import './BidModal.css';
import Backdrop from '../../../../../shared/backdrop/Backdrop';

// Function moved to the top of the file
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
  });
};

const BidModal = ({ onClose, quoteID }) => {
  const [amount, setBidAmount] = useState('');
  const [comment, setComment] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [bidPlaced, setBidPlaced] = useState(false);
  const [previousBid, setPreviousBid] = useState(null);
  const [error, setError] = useState('');

  const handlePlaceBid = async () => {
    try {
      const bidData = {
        userID: `2`,
        vendorName: `Stephen`,
        quoteID,
        amount,
        comment,
        file: uploadFile ? await convertFileToBase64(uploadFile) : null, // Convert file to base64
      };

      console.log(bidData);

      const response = await axios.post(
        'https://services.staging.wayagram.ng/wayagram-products/',
        bidData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setPreviousBid(response.data.amount);
      setBidPlaced(true);
    } catch (err) {
      // Renamed the `error` parameter to `err`
      setError('Failed to place bid. Please try again.');
    }
  };

  const handleBidChange = (amt) => {
    const newBidAmount = Math.max(0, parseFloat(amount || 0) + amt);
    setBidAmount(newBidAmount.toString());
  };

  return (
    <Backdrop onClose={onClose}>
      <div className="modal__header">
        <h2 className="modal__header-h2">Place a bid</h2>
        <button type="button" onClick={onClose} className="modal__close-button">
          &times;
        </button>
      </div>

      <div className="modal__content">
        {!bidPlaced ? (
          <>
            <div className="bidModal-amount-header">
              <label htmlFor="bidAmount" className="bidModal-amount-label">
                Input a custom amount
              </label>
              <p className="modal__time-remaining">
                4 days 12 hours | Monday 12th of April 2024
              </p>
            </div>

            <input
              type="number"
              id="amount"
              name="amount"
              placeholder="Enter bid amount"
              value={amount}
              onChange={(e) => setBidAmount(e.target.value)}
              className="modal-input-amount modal__input"
            />
            <button
              type="button"
              onClick={handlePlaceBid}
              className="modal__submit-button"
            >
              Place a bid
            </button>

            <label className="modal__upload-group-label">Media Upload</label>
            <p className="modal__upload-group-p">
              Upload a bright and clear image to make your product standout
            </p>
            <div className="file-upload">
              <input
                type="file"
                onChange={(e) => setUploadFile(e.target.files[0])}
                id="fileInput"
                multiple
              />
              <label htmlFor="fileInput">
                <i className="fa fa-cloud-upload" />
                Drag your file(s) or browse
              </label>
              <span className="file-upload-info">
                Max 2 MB files are allowed
              </span>
            </div>

            <textarea
              placeholder="Drop your comment"
              name="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="modal__input modal__textarea"
            />

            <p className="modal__footer">
              By placing a bid you are confirming that you will deliver if you
              are the winning bidder.
            </p>

            {error && <p className="modal__error">{error}</p>}
          </>
        ) : (
          <>
            <h3 className="modal__amount">
              NGN {parseFloat(amount).toLocaleString()}.00
            </h3>
            <p className="modal__time-remaining">
              4 days 12 hours | Monday 12th of April 2024
            </p>
            <p>Your previous bid: NGN {previousBid?.toLocaleString()}.00</p>
            <input
              type="number"
              name="amount"
              placeholder="Enter bid amount"
              value={amount}
              onChange={(e) => setBidAmount(e.target.value)}
              className="modal__input"
            />
            <div className="modal__controls">
              <button
                type="button"
                onClick={() => handleBidChange(-10000)}
                className="modal__control-btn"
              >
                -
              </button>
              <button
                type="button"
                onClick={handlePlaceBid}
                className="modal__submit-button"
              >
                Decrease bid
              </button>
              <button
                type="button"
                onClick={() => handleBidChange(10000)}
                className="modal__control-btn"
              >
                +
              </button>
            </div>
          </>
        )}
      </div>
    </Backdrop>
  );
};

export default BidModal;
