import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player/lazy';

const VideoPlayerModal = ({
  openVideoModal,
  setOpenVideoModal,
  videoToPlay,
}) => {
  return (
    <Modal
      isOpen={openVideoModal}
      id="viewer-modal"
      toggle={() => setOpenVideoModal(false)}
      centered
    >
      <ModalBody>
        <div>
          <ReactPlayer url={videoToPlay} controls playing playsinline />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoPlayerModal;
