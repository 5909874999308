import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ModalFooter, ModalBody } from 'reactstrap';
import leftArrow from '../../../../assets/image/left-arrow.png';
import plusIcon from '../../../../assets/image/plus-circle.png';
import amicoIcon from '../../../../assets/image/amico.png';
import './index.scss';
import WalletList from './WalletList';
import Container from '../../Container';
import { WalletContext } from '../../../../store/context/WalletContext';

export default function ManageWallet(props) {
  const { showModal, hideModal, setShowCreateWallet } = props;
  const { walletList } = useContext(WalletContext);

  if (!walletList.length) {
    return (
      <div>
        <Modal
          isOpen={showModal}
          id="manage-bank-modal"
          toggle={() => hideModal(false)}
          centered
        >
          <div className="header">
            <div className="row">
              <div className="col-3 h-left">
                <Container onClick={() => hideModal(false)}>
                  <img src={leftArrow} alt="left" />
                </Container>
              </div>
              <div className="col-6 h-center text-center">Manage Wallet</div>
              <div className="col-3 h-right text-center see">
                <Container
                  onClick={() => {
                    setShowCreateWallet(true);
                    // hideModal(false);
                  }}
                >
                  <img src={plusIcon} alt="plus" />
                </Container>
              </div>
            </div>
          </div>
          <ModalBody>
            <div className="img-center">
              <img src={amicoIcon} alt="bank" />

              <h4>Your wallet section looks empty</h4>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className="w-100 btn-primary mx-auto mb-3 p-2"
              color="primary"
              onClick={() => {
                setShowCreateWallet(true);
                hideModal(false);
              }}
            >
              Add Account
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  return (
    <WalletList
      showModal
      walletList={walletList}
      hideModal={hideModal}
      setShowCreateWallet={setShowCreateWallet}
    />
  );
}

ManageWallet.defaultProps = {
  showModal: false,
};

ManageWallet.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  setShowCreateWallet: PropTypes.func.isRequired,
};
