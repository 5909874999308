import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

export const CardContext = createContext();

export const CardContextProvider = (props) => {
  const { children } = props;
  const [reload, setReload] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [availableBank, setAvailableBank] = useState([]);

  const getCardList = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpGet(`${customBaseUrl.cardUrl}/card/list/${userId}`);
    if (res?.status) {
      setCardList(res.data);
    }
  };

  const getAvailableBanksForFunding = async () => {
    const res = await httpGet(
      'https://api.paystack.co/bank?gateway=emandate&pay_with_bank=true',
      true
    );
    setAvailableBank(res);
  };

  const getBankAccountList = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/list/${userId}`
    );
    if (res?.status) {
      setBankAccountList(res.data);
    }
  };

  const getBanks = async () => {
    const res = await httpGet(`${customBaseUrl.cardUrl}/bankAccount/getBanks`);
    if (res?.status) {
      setBankList(res.data);
    }
  };

  useEffect(() => {
    getCardList();
    getBankAccountList();
    getBanks();
    getAvailableBanksForFunding();
  }, [reload]);

  return (
    <CardContext.Provider
      value={{
        setCardList,
        cardList,
        reload,
        setReload,
        bankAccountList,
        bankList,
        availableBank,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
