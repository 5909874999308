import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { DeleteOutlined } from '@ant-design/icons';
// import { v4 as uuid } from 'uuid';
import { List, Avatar, Space } from 'antd';
import { previewDiv, RemoveDiv, PollContainer } from './style';
import Button from '../../Button';
import 'react-datepicker/dist/react-datepicker.css';

export function ImagePreviewOnly(props) {
  const { preview } = props;
  // console.log(preview);
  return (
    <div
      className="preview-div"
      style={!preview.length ? { display: 'none' } : {}}
    >
      <div className={`image-grid-div-${preview.length}`}>
        {preview.length
          ? preview.map((item, index) => (
              <div
                className="image-each"
                key={Math.random()}
                style={{
                  backgroundImage: `url(${item})`,
                  gridArea: `grid${index + 1}`,
                  position: 'relative',
                }}
              >
                <span className="cancel text-center d-flex justify-content-center align-items-center">
                  {' '}
                  <i className="mdi mdi-close text_danger" />{' '}
                </span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export function PollPreview(props) {
  const { setPostData, postData, theme } = props;
  return (
    <PollContainer>
      <div className="poll-container" style={theme.modalStyle}>
        <div className="w-100 my-4 px-4">
          <p className="mb-2">
            Show result
            <span className="text-primary">*</span>
          </p>
          <select
            className={`mb-1 form-select bg-${
              theme.mode === 'dark' ? 'secondary' : 'white'
            }`}
            value={postData.showResult}
            onChange={(e) =>
              setPostData({
                ...postData,
                showResult: e.target.value,
              })
            }
          >
            <option value="">Select option</option>
            <option value="false">Hide result</option>
            <option value="true">Show result</option>
          </select>
        </div>

        <div className="w-100 mb-4 px-4">
          <p className="mb-2">
            Show option result
            <span className="text-primary"> *</span>
          </p>
          <select
            className={`mb-1 form-select bg-${
              theme.mode === 'dark' ? 'secondary' : 'white'
            }`}
            value={postData.showOptionResult}
            onChange={(e) =>
              setPostData({
                ...postData,
                showOptionResult: e.target.value,
              })
            }
          >
            <option value="">Select option</option>
            <option value="false">Hide</option>
            <option value="true">Show</option>
          </select>
        </div>
        <div className="w-100 mb-4 px-4">
          <p className="mb-2">
            Select poll category
            <span className="text-primary"> *</span>
          </p>
          <select
            className={`mb-1 form-select bg-${
              theme.mode === 'dark' ? 'secondary' : 'white'
            }`}
            value={postData.pollCategory}
            onChange={(e) =>
              setPostData({
                ...postData,
                pollCategory: e.target.value,
              })
            }
          >
            <option value="">Select option</option>
            <option value="text">Text</option>
            <option value="image">Image</option>
            {/* <option value="video">Video</option> */}
          </select>
        </div>
        <div className="poll-duration">
          <p>Poll Start Date</p>
          <div className="period-container">
            <div className="period-div w-100">
              <span>
                Date
                <span className="text-primary"> *</span>
              </span>
              <DatePicker
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                name="startDate"
                //  showTimeSelect
                selected={parseInt(postData.startTime, 10)}
                minDate={new Date()}
                timeIntervals={15}
                onChange={(date) => {
                  setPostData({
                    ...postData,
                    startDate: date.getTime(),
                    startTime: date.getTime(),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="poll-duration">
          <p>Poll End Date</p>
          <div className="period-container">
            <div className="period-div w-100">
              <span>
                Date
                <span className="text-primary"> *</span>
              </span>
              <DatePicker
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                name="endDate"
                // showTimeSelect
                selected={parseInt(postData.endTime, 10)}
                minDate={new Date()}
                timeIntervals={15}
                onChange={(date) => {
                  setPostData({
                    ...postData,
                    endDate: date.getTime(),
                    endTime: date.getTime(),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-100 my-4 px-4">
          <div className="row">
            <div className="col-6">
              <p className="mb-2">
                Votes per user
                <span className="text-primary"> *</span>
              </p>
              <input
                type="number"
                min={1}
                className={`w-100 border-0 border-bottom bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                onChange={(e) =>
                  setPostData({ ...postData, voteLimit: e.target.value })
                }
                value={postData.voteLimit}
              />
            </div>
            <div className="col-6">
              <p className="mb-2">
                Select Poll Type
                <span className="text-primary"> *</span>
              </p>
              <select
                className={`mb-1 form-select bg-${
                  theme.mode === 'dark' ? 'secondary' : 'white'
                }`}
                value={postData.isPaid ? 'true' : 'false'}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    isPaid: e.target.value === 'true',
                    amount: e.target.value === 'false' ? 0 : postData.amount,
                  })
                }
              >
                <option value="">Select option</option>
                <option value="true">Pay to vote</option>
                <option value="false" selected>
                  Free to vote
                </option>
              </select>
            </div>
          </div>
        </div>
        {postData.isPaid && (
          <div className="w-100 mb-4 px-4">
            <p className="mb-2">
              Polls Amount
              <span className="text-primary">*</span>
            </p>
            <input
              onChange={(e) =>
                setPostData({ ...postData, amount: e.target.value })
              }
              disabled={!postData?.isPaid || false}
              type="number"
              value={postData.amount}
              className={`w-100 border-0 border-bottom bg-${
                theme.mode === 'dark' ? 'secondary' : 'white'
              }`}
              placeholder="N0.0"
              min={0}
            />
          </div>
        )}
      </div>
      <div className="termsnconditionText my-3">
        <input
          type="checkbox"
          checked={postData.accept}
          onClick={() => setPostData({ ...postData, accept: !postData.accept })}
        />
        By sending, you agree with our{' '}
        <a href="/terms.html" rel="noreferrer" target="_blank">
          terms and conditions
        </a>{' '}
        of poll creation
      </div>
    </PollContainer>
  );
}

export function PollOptionPreview(props) {
  const { handleGoBack, setPostData, postData, theme } = props;
  const [tempData, setTempData] = useState({
    option: '',
    file: '',
    description: '',
    preview: '',
  });
  const [dat, setDat] = useState(postData?.options ? postData?.options : []);
  const handleAddOption = () => {
    console.log(dat);
    setDat((item) => [
      ...item,
      {
        id: dat.length + 1,
        option: tempData.option,
        file: tempData.file,
        description: tempData.description,
        preview: tempData.preview,
      },
    ]);
    setTempData({ option: '', file: '', description: '', preview: '' });
  };

  const remove = (i) => {
    const sliced = dat.filter((item, index) => index !== i);
    setDat(sliced);
  };

  useEffect(() => {
    setPostData({ ...postData, options: dat });
  }, [dat]);

  const handlePreview = (e) => {
    const { files } = e.target;
    if (files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempData({
          ...tempData,
          file: files[0],
          preview: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <PollContainer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="mb-2">
              Poll option
              <span className="text-primary">*</span>
            </p>
            <input
              placeholder=""
              value={tempData.option}
              className={`w-100 border-0 border-bottom bg-${
                theme.mode === 'dark' ? 'secondary' : 'white'
              }`}
              onChange={(e) =>
                setTempData({
                  ...tempData,
                  option: e.target.value,
                })
              }
              required
            />
            <p className="mb-2 mt-4"> Poll option description </p>
            <input
              className={`w-100 border-0 border-bottom bg-${
                theme.mode === 'dark' ? 'secondary' : 'white'
              }`}
              placeholder=""
              value={tempData.description}
              onChange={(e) =>
                setTempData({
                  ...tempData,
                  description: e.target.value,
                })
              }
            />
            <div className="d-flex align-items-center justify-content-between">
              {postData.pollCategory === 'image' && (
                <input
                  className="w-50 mt-5"
                  type="file"
                  placeholder="add image"
                  onChange={handlePreview}
                  accept="image/*"
                />
              )}
              <div>
                <Button
                  className="btn btn-primary mb-3 mt-2"
                  disabled={tempData.option === ''}
                  color="primary"
                  content="Add Option"
                  onClick={handleAddOption}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
        <PollImagePreviewOnly
          data={dat}
          postData={postData}
          remove={remove}
          theme={theme}
        />
        <RemoveDiv className="mt-2">
          <p
            onClick={() => {
              handleGoBack(false);
              // setPostData({});
            }}
          >
            Go back
          </p>
        </RemoveDiv>
      </div>
      {/* <div className="poll-footer"> */}
      <div className="termsnconditionText my-3">
        <input type="checkbox" checked={postData.accept} />
        By sending, you agree with our{' '}
        <a href="/terms.html" rel="noreferrer" target="_blank">
          terms and conditions
        </a>{' '}
        of poll creation
        {/* </div> */}
      </div>
    </PollContainer>
  );
}

export function PaidPostPreview(props) {
  const {
    preview,
    setShowPreview,
    showPreview,
    setPreview,
    images,
    setImages,
    theme,
  } = props;

  // console.log(preview, images, 'is what');
  const handleProduct = (i) => {
    const sliced = preview.filter((item, index) => index !== i);
    setPreview(sliced);
    const slicedImg = images.filter((item, index) => index !== i);
    setImages(slicedImg);
    if (slicedImg.length <= 0) setShowPreview(false);
  };
  const style =
    !showPreview && !preview.length ? { display: 'none' } : previewDiv;

  return (
    <div className="preview-div" style={{ ...theme.modalStyle, ...style }}>
      <div
        className={`image-grid-div-${
          preview.length >= 4 ? '4' : preview.length
        }`}
      >
        {preview.length
          ? preview.map((item, index) => {
              const idx = index + 1;
              if (index <= 3) {
                return (
                  <div
                    key={item.type}
                    className="image-each"
                    style={{
                      backgroundImage: `url(${
                        item?.imageUrl || item.imageURL || item
                      })`,
                      gridArea: `grid${index + 1}`,
                      position: 'relative',
                    }}
                  >
                    {item?.type?.includes('video') && (
                      <video
                        className="video-upload"
                        playsInline
                        muted
                        //  autoPlay
                        loop
                      >
                        <source src={item?.imageUrl} type="video/webm" />
                        <source src={item?.imageUrl} type="video/mp4" />
                        <source src={item?.imageUrl} type="video/wmv" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <span>
                      {' '}
                      <i
                        onClick={() => handleProduct(index)}
                        className="mdi mdi-close text-danger"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#fff',
                          top: '5px',
                          right: '5px',
                          borderRadius: '50%',
                          height: '1em',
                          width: '1em',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />{' '}
                    </span>
                    {index === 3 && preview.length > 4 && (
                      <div className="overlay-img-text">
                        +{preview.length - idx}
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })
          : null}
      </div>
    </div>
  );
}

export function PollImagePreviewOnly(props) {
  const { data, postData, remove, theme } = props;
  return (
    <List
      itemLayout="vertical"
      dataSource={data}
      renderItem={(item, i) => (
        <List.Item
          key={Math.random()}
          extra={
            postData.pollCategory === 'image' && (
              <img
                width={100}
                alt="logo"
                src={item.preview || item.media_url}
              />
            )
          }
        >
          <List.Item.Meta
            avatar={
              postData.pollCategory === 'image' && (
                <Avatar src={item.preview || item.media_url} />
              )
            }
            title={<p style={{ color: theme?.style?.color }}>{item.option}</p>}
            description={
              <Space>
                <p style={{ color: theme?.style?.color }}>{item.description}</p>
                <DeleteOutlined onClick={() => remove(i)} />
              </Space>
            }
          />
        </List.Item>
      )}
    />
  );
}
