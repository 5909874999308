import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ModalContext } from '../../../../store/context/ModalContext';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';

const Index = (props) => {
  const { showModal, hideModal, id } = props;
  const { setReload, reload } = useContext(WalletContext);
  const { setShowPinVerify, verified, setModalAction } =
    useContext(ModalContext);
  const [createWalletMode, setCreateWalletMode] = useState(false);

  const handleAddWallet = async () => {
    showLoader();
    const userId = localStorage.getItem('userId');
    const postData = {
      userId,
    };
    const res = await httpPost(
      `/api/v1/wallet/create-wallet`,
      postData,
      customBaseUrl.walletUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
        setReload(!reload);
        setShowPinVerify(false);
        setModalAction('');
      });
    } else {
      hideLoader();
      setShowPinVerify(false);
      swal('Oops!', res.message, 'error');
    }
  };

  useEffect(() => {
    if (verified && createWalletMode) {
      handleAddWallet();
    }
  }, [verified]);

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalBody>
        <div>
          <h4 className="mt-5 mb-3 text-center">Create New Wallet</h4>
          <p className="text-secondary mb-3 text-center">
            Are you sure you want to create a new wallet ?
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="p-0" style={{ border: '1px solid  #F2F2F2' }}>
        <div className="col-12 m-0">
          <div className="row">
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              style={{ borderRight: '1px solid  #F2F2F2' }}
              onClick={() => hideModal(false)}
            >
              CANCEL
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              onClick={() => {
                setCreateWalletMode(true);
                setShowPinVerify(true);
                setModalAction('createWallet');
              }}
            >
              CONTINUE
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
