import React from 'react';
import PropTypes from 'prop-types';

function EventInfo(props) {
  const { handleFormInput, values, theme } = props;

  return (
    <div className="event-inner-content">
      <div className="input-container">
        <input
          name="eventName"
          value={values.eventName}
          onChange={(e) => {
            handleFormInput(e.target.value, 'eventName');
          }}
          placeholder="Title of event"
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
        />
        <span>
          What is the name of your event
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <input
          name="eventOrganizer"
          value={values.eventOrganizer}
          onChange={(e) => {
            handleFormInput(e.target.value, 'eventOrganizer');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Organizers Name"
        />
        <span>
          Organizers Name
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <textarea
          style={{
            overflow: 'hidden',
            height: '33px',
          }}
          name="details"
          value={values.details}
          onChange={(e) => {
            handleFormInput(e.target.value, 'details');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          placeholder="Event Description"
        />
        <span>
          Description of event
          <span className="text-primary"> *</span>
        </span>
      </div>
      <div className="input-container">
        <select
          name="isPrivate"
          onChange={(e) => {
            handleFormInput(e.target.value, 'isPrivate');
          }}
          className={`bg-${theme.mode === 'dark' ? 'secondary' : 'white'}`}
          value={values?.isPrivate}
        >
          <option value="">Select event type</option>
          <option value="false">Public</option>
          <option value="true">Private</option>
        </select>
        <span>Is the event private or public?</span>
      </div>
    </div>
  );
}

EventInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventInfo;
