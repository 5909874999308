import { atom, selectorFamily } from 'recoil';
import {
  getAllEvents,
  getNotifications,
} from '../components/pages/Wayagram/container';

export const darkStyle = {
  color: '#ffffff',
  background: '#000000',
};

export const lightStyle = {
  color: '#000000',
  background: '#ffffff',
};

export const darkModalStyle = {
  color: '#ffffff',
  background: '#0c0c0b',
};

export const lightModalStyle = {
  color: '#0c0c0b',
  background: '#ffffff',
};

const ls = localStorage.getItem('theme');
export const userTheme = atom({
  key: 'userTheme',
  default: {
    mode: ls || 'light',
    style: {
      color: ls === 'dark' ? '#ffffff' : '#000000',
      background: ls === 'dark' ? '#000000' : '#ffffff',
    },
    modalStyle: {
      color: ls === 'dark' ? '#ffffff' : '#0c0c0b',
      background: ls === 'dark' ? '#0c0c0b' : '#ffffff',
    },
  },
});

export const userState = atom({
  key: 'userState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

export const userIdState = atom({
  key: 'userId',
  default: null,
});

export const userRoleState = atom({
  key: 'userRoleState',
  default: null,
});

export const corporateState = atom({
  key: 'corporateState',
  default: false,
});

export const profileState = atom({
  key: 'profileState',
  default: null,
});

export const wayagramProfileState = atom({
  key: 'wayagramProfileState',
  default: null,
});

export const myBankAccountListState = atom({
  key: 'myBankAccountListState',
  default: [],
});

export const myCardListState = atom({
  key: 'myCardListState',
  default: [],
});

export const forceTodoUpdate = atom({
  key: 'forceTODO',
  default: 0,
});

export const forceDiscoverUpdate = atom({
  key: 'forceDiscoverUpdate',
  default: 'People',
});

export const sentPaymentRequestState = atom({
  key: 'sentPaymentRequestState',
  default: {},
});

export const defaultWalletState = atom({
  key: 'defaultWalletState',
  default: null,
});

export const webKyc = atom({
  key: 'webKyc',
  default: false,
});

export const webPosTab = atom({
  key: 'webPosTab',
  default: '1',
});

export const searchValue = atom({
  key: 'searchValue',
  default: '',
});

export const singlePoll = atom({
  key: 'singlePoll',
  default: null,
});

export const apiReload = atom({
  key: 'apiReload',
  default: true,
});

export const selectNotifications = selectorFamily({
  key: 'selectNotifications',
  get: (id, page) => async () => {
    try {
      const response = await getNotifications(id, page);
      return response || [];
    } catch (error) {
      console.error(`ERROR: \n${error}`);
      return [];
    }
  },
});

export const selectEvents = selectorFamily({
  key: 'selectEvents',
  get:
    (url) =>
    async ({ get }) => {
      const reload = get(apiReload);
      try {
        console.log(reload);
        const response = await getAllEvents(url);
        return response || [];
      } catch (error) {
        console.error(`ERROR: \n${error}`);
        return [];
      }
    },
});
